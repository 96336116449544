/**
* CorrelationVectorPlugin.ts
* @author Ram Thiru (ramthi) and Hector Hernandez (hectorh)
* @copyright Microsoft 2018
*/

import {
    IAppInsightsCore, IPlugin, ITelemetryPlugin, IExtendedConfiguration,
    IDiagnosticLogger, ITelemetryItem, LoggingSeverity, _ExtendedInternalMessageId
} from '@ms/1ds-core-js';
import { ICorrelationVectorPlugin } from './DataModel';
import { Cv } from './Cv';

export default class CorrelationVectorPlugin implements ICorrelationVectorPlugin {

    public identifier = 'CorrelationVectorPlugin';
    public priority = 159;
    public version = '2.1.1';

    private _nextPlugin: ITelemetryPlugin;
    private _traceLogger: IDiagnosticLogger;
    private _cv: Cv;
    private _cvInitialValue: string;

    constructor(cvInitialValue?: string) {
        this._cvInitialValue = cvInitialValue;
    }

    /**
     * Initialize the Correlation Vector plugin
     * @param {IExtendedConfiguration} config - The plugin configuration.
     * @param {IAppInsightsCore} core - Core reference.
     * @param {ITelemetryPlugin} extensions - All other extensions
     */
    initialize(coreConfig: IExtendedConfiguration, core: IAppInsightsCore, extensions: IPlugin[]) {
        this._traceLogger = core.logger;
        // Add default cv
        this._cv = this._cvInitialValue ? new Cv(this._cvInitialValue, core) : new Cv(null, core);
    }

    /**
    * Process a given event.
    * @param {ITelemetryItem} event - The event to be hydrated with CV.
    */
    public processTelemetry(event: ITelemetryItem) {
        event.ext = event.ext || {};
        event.ext['mscv'] = event.ext['mscv'] || {};
        // If cV is present do not override
        if (!event.ext['mscv']['cV']) {
            if (this._cv && this._cv.isInit()) {
                event.ext['mscv']['cV'] = this._cv.getValue();
            }
        }
        if (this._nextPlugin) {
            this._nextPlugin.processTelemetry(event);
        }
    }

    /**
    * Sets the next plugin
    * @param {ITelemetryPlugin} plugin - The next plugin to be setup
    */
    public setNextPlugin(plugin: ITelemetryPlugin) {
        this._nextPlugin = plugin;
    }

    /**
    * Get cV instance
    */
    public getCv(): Cv {
        if (!this._cv && this._traceLogger) {
            this._traceLogger.throwInternal(
                LoggingSeverity.WARNING,
                _ExtendedInternalMessageId.FailedToInitializeCorrelationVector, "Correlation Vector has not been initialized."
            )
        }
        return this._cv;
    }
}
