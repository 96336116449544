import { IBrowserWindow } from "../../browser-object-models/IBrowserWindow";
import { IEventBasicData } from "../commonSchema/IEventBasicData";
import { IPageActionData } from "../userActivityData/IPageActionData";
import { IPageActionPartCContent } from "./IPageActionPartCContent";
import { IUserActivityLogItem } from "../IUserActivityLogItem";
import { TrackingPartCContent } from "./TrackingPartCContent";

/**
 * Represents the parameters of the asimov pageaction event Part C content that is predefined by JSLL (Javascript logging library) of Asimov system and 1DS pipeline.
 * @class
 */
export class PageActionPartCContent extends TrackingPartCContent implements IPageActionPartCContent {

    /**
     * Initializes a new instance of the `PageActionPartCContent` class.
     * @param title {string} The page title.
     * @param eventData {string} The event associated with user action.
     * @param schemaVer {string} The schema version for the schema defined by AnalyticsSDK.
     * @param documentData? {string} The document or documents on which page view or page action is performed.
     * @param containerDocData? {string} The container document if there is any.
     * @param actionInputs? {string} The parameters that are passed as input to any user action.
     * @param selectionInputs? {string} The parameters that are passed as input to any user action in select scenarios such as dropdown, listbox etc.
     * @param selectedDocData? {string} The selected document data If a document is being added (or any other operation) to another document as part of an action.
     * @param browserSize? {string} The Size of the browser window. Ex: 1024x768.
     * @param screenRes? {string} The screen resolution. Ex: 1024x768.
     * @param cookieEnabled? {boolean} The flag to report if cookies enabled/disabled in the browser.
     * @param extendedData? {string} The additional data associated with the event.
     * @param resHeight? {number} The screen resolution height.
     * @param resWidth? {number} The screen resolution width.
     * @param referrerUri? {string} The referrer uri.
     * @param isJs? {boolean} The flag to report that the event is from JS. It will always be true for client logs.
     * @param isManual? {boolean} The flag to report that the event is manually triggered or not. It will always be true for the client logs.
     * @param serverImpressionGuid? {string} The server impression guid.
     */
    constructor(
        title: string,
        public eventData: string,
        schemaVer: string,
        documentData?: string,
        public containerDocData?: string,
        public actionInputs?: string,
        public selectionInputs?: string,
        public selectedDocData?: string,
        browserSize?: string,
        cookieEnabled?: boolean,
        extendedData?: string,
        public resHeight?: number,
        public resWidth?: number,
        public referrerUri?: string,
        isJs?: boolean,
        isManual?: boolean,
        serverImpressionGuid?: string) {
        super(title, schemaVer, documentData, browserSize, cookieEnabled, extendedData, isJs, isManual, serverImpressionGuid);
    }

    /**
     * Creates a new PageActionPartCContent from a given log item.
     * @param userActivityLogItem {IUserActivityLogItem} The log item.
     * @param windowContext {IBrowserWindow} The window object of the browser.
     * @param basicData {IEventBasicData} The basic parameters of the asimov events.
     * @param serverImpressionGuid {string} The server impression guid.
     * @return {IPageActionPartCContent} The page action p data.
     */
    public static createFrom(
        userActivityLogItem: IUserActivityLogItem,
        windowContext: IBrowserWindow,
        basicData: IEventBasicData,
        serverImpressionGuid: string): IPageActionPartCContent {

        let eventData;
        let actionInputs;
        let selectionInputs;
        let containerDocData;
        let selectedDocData;
        let referrerUri;
        let resHeight;
        let resWidth;

        let trackingPartCContent = TrackingPartCContent.createFrom(userActivityLogItem, windowContext, basicData, serverImpressionGuid);

        if (userActivityLogItem.eventData) {
            eventData = JSON.stringify(userActivityLogItem.eventData);
        }

        if (userActivityLogItem.trackingData) {
            let pageActionData = <IPageActionData>userActivityLogItem.trackingData;

            // Populate PART C Contents
            actionInputs = pageActionData.actionInputs ? JSON.stringify(pageActionData.actionInputs) : null;
            selectionInputs = pageActionData.selectionInputs && pageActionData.selectionInputs.length > 0 ? JSON.stringify(pageActionData.selectionInputs) : null;
            selectedDocData = pageActionData.selectedDocData && pageActionData.selectedDocData.length > 0 ? JSON.stringify(pageActionData.selectedDocData) : null;
            containerDocData = pageActionData.containerDocData ? JSON.stringify(pageActionData.containerDocData) : null;
            referrerUri = pageActionData.referrerUri;
            resHeight = pageActionData.resHeight;
            resWidth = pageActionData.resWidth;
        }

        if (windowContext && windowContext.screen) {
            if (!resHeight) {
                resHeight = windowContext.screen.height;
            }

            if (!resWidth) {
                resWidth = windowContext.screen.width;
            }
        }

        return new PageActionPartCContent(
            trackingPartCContent.title,
            eventData,
            trackingPartCContent.schemaVer,
            trackingPartCContent.documentData,
            containerDocData,
            actionInputs,
            selectionInputs,
            selectedDocData,
            trackingPartCContent.browserSize,
            trackingPartCContent.cookieEnabled,
            trackingPartCContent.extendedData,
            resHeight,
            resWidth,
            referrerUri,
            trackingPartCContent.isJs,
            trackingPartCContent.isManual,
            trackingPartCContent.serverImpressionGuid);
    }
}
