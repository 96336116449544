import * as axiosStatic from "axios";

/**
 * Contains a single part of a batch of http requests.
 * @class
 */
export class BatchPart<T> {

    /** 
     * The config of the http request that is to be sent.
     * @type {axiosStatic.RequestOptions}
     */
    public config: axiosStatic.RequestOptions;

    /** 
     * The success function to call when the batch call is done to resolve this request.
     * @type {(value: axiosStatic.Response) => void}
     */
    public resolve: (value: axiosStatic.Response) => void;

    /** 
     * The failure function to call when the batch call is done to reject this request.
     * @type {(error: any) => void}
     */
    public reject: (error: any) => void;

    /**
     * Initializes a new instance of the `BatchPart{T}` class.
     * @constructor
     * @param {axiosStatic.RequestOptions} config The config of the http request that is to be sent.
     */
    constructor(config: axiosStatic.RequestOptions) {

        if (!config) {
            throw "config must not be null";
        }

        this.config = config;
    }
}