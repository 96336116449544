/**
* RequestHandler.ts
* @author Hector Hernandez (hectorh)
* @copyright Microsoft 2019
*/
import { Utils } from '@ms/1ds-core-js';
import { DisabledPropertyName } from '@microsoft/applicationinsights-common';

export class RequestHandler {
    /**
    * Make backend request
    */
    public makeRequest(urlString: string, data: Uint8Array | string, method: string,
        oncomplete: (status: number, responseBody: string, headers: { [headerName: string]: string }) => void) {

        if (Utils.useXDomainRequest()) {
            // It doesn't support custom headers, so no action is taken with current requestHeaders
            let xdr = new XDomainRequest();
            xdr.open(method, urlString);
            //can't get the status code in xdr.
            xdr.onload = () => {
                // we will assume onload means the request succeeded.
                oncomplete(200, xdr.responseText, {});
            };
            xdr.onerror = () => {
                // we will assume onerror means we need to drop the events.
                oncomplete(400, xdr.responseText, {});
            };
            xdr.ontimeout = () => {
                // we will assume ontimeout means we need to retry the events.
                oncomplete(500, xdr.responseText, {});
            };
            xdr.send(data);
        } else if (Utils.isReactNative()) {
            //Use the fetch API to send events in React Native
            fetch(urlString, {
                body: data,
                method: method,
                credentials: 'include',
                [DisabledPropertyName]: true
            }).then((response) => {
                let headerMap = {};
                if (response.headers) {
                    response.headers.forEach((value: string, name: string) => {
                        headerMap[name] = value;
                    });
                }
                oncomplete(response.status, JSON.stringify(response.json()), headerMap);
            }).catch((error) => {
                //In case there is an error in the request. Set the status to 0
                oncomplete(0, error, {});
            });
        } else if (typeof XMLHttpRequest !== 'undefined') {
            let xhr = new XMLHttpRequest();
            xhr[DisabledPropertyName] = true;
            xhr.withCredentials = true;
            xhr.open(method, urlString);
            xhr.onload = () => {
                oncomplete(xhr.status, xhr.responseText, this._convertAllHeadersToMap(xhr.getAllResponseHeaders()));
            };
            xhr.onerror = () => {
                oncomplete(xhr.status, xhr.responseText, this._convertAllHeadersToMap(xhr.getAllResponseHeaders()));
            };
            xhr.ontimeout = () => {
                oncomplete(xhr.status, xhr.responseText, this._convertAllHeadersToMap(xhr.getAllResponseHeaders()));
            };
            xhr.send(data);
        }
    }

    /**
    * Converts the XHR getAllResponseHeaders to a map containing the header key and value.
    */
    private _convertAllHeadersToMap(headersString: string): { [headerName: string]: string } {
        let headers = {};
        if (headersString) {
            let headersArray = headersString.split('\n');
            for (let i = 0; i < headersArray.length; ++i) {
                let header = headersArray[i].split(': ');
                headers[header[0]] = header[1];
            }
        }
        return headers;
    }
}

