import * as axiosStatic from "axios";

import { AxiosWrapperAbstractBase } from "./AxiosWrapperAbstractBase";

/**
 * Base passthrough wrapper implementation for axios that can modify the request and response of all calls.
 * @class
 */
export class AxiosWrapperBase extends AxiosWrapperAbstractBase {

    /** axios instance to call through to.
     */
    private axios: axiosStatic.AxiosStatic;

    /**
     * Initializes a new instance of the `AxiosWrapperBase` class.
     * @constructor
     * @param {axiosStatic.AxiosStatic} axios Axios service instance.
     */
    constructor(
        axios: axiosStatic.AxiosStatic) {

        super();

        if (!axios) {
            throw "axios must not be null";
        }

        this.axios = axios;
    }

    /**
     * Method to send any type of http call (get/post/put/etc.).
     * @method
     * @param config {axiosStatic.RequestOptions} The request detail.
     * @returns {Promise<axiosStatic.Response>} The result of the call.
     */
    public sendRequest<T>(config: axiosStatic.RequestOptions): Promise<axiosStatic.Response> {

        if (!config) {
            throw "config must not be null";
        }

        this.onRequest(config);
        let response = <Promise<axiosStatic.Response>>(this.axios(config));
        this.onResponse(response);
        return response;
    }
}