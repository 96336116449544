/**
* WebAnalyticsPlugin.ts
* @author Ram Thiru (ramthi) and Hector Hernandez (hectorh)
* @copyright Microsoft 2018
* File containing the interfaces for WebAnalytics SDK.
*/
import {
    IAppInsightsCore, ITelemetryPlugin, IExtendedConfiguration, EventLatency,
    IPlugin, IExtendedAppInsightsCore, ITelemetryItem,
    IExtendedTelemetryItem, extend, createGuid, isValueAssigned, isDocumentObjectAvailable, LoggingSeverity, _ExtendedInternalMessageId
} from '@ms/1ds-core-js';
import { ApplicationInsights as InternalApplicationInsights } from '@microsoft/applicationinsights-analytics-js';
import {
    IWebAnalyticsConfiguration, ICorrelationVectorPlugin, ICoreData
} from './DataModel';
import { IContentHandler } from './interfaces/IContentHandler';
import { IAutoCaptureHandler } from "./interfaces/IAutoCaptureHandler";
import {
    IContentUpdateOverrideValues, IPageUnloadOverrideValues,
    IPageActionOverrideValues, IPageViewOverrideValues, IPageViewPerformanceOverrideValues
} from './interfaces/IOverrides';
import {
    IPageViewProperties, IPageActionProperties, IPageViewPerformanceProperties,
    IPageActionTelemetry, IPageViewTelemetry, IPageUnloadTelemetry, IPageUnloadProperties,
    IContentUpdateTelemetry, IContentUpdateProperties, IPageViewPerformanceTelemetry,
    IExceptionTelemetry,
    ITelemetryEventInternal
} from './interfaces/ITelemetryEvents';
import {
    _removeNonObjectsAndInvalidElements, _isElementDnt
} from './common/Utils';
import { DomContentHandler } from './handlers/DomContentHandler';
import { AutoCaptureHandler } from "./handlers/AutoCaptureHandler";
import { PageView } from './events/PageView';
import { PageAction } from './events/PageAction';
import { ContentUpdate } from './events/ContentUpdate';
import { PageUnload } from './events/PageUnload';
import { PageViewPerformance } from './events/PageViewPerformance';
import Id from './Id';
import Timespan from './Timespan';

const doNotTrackFieldName = 'data-bi-dnt';

export class ApplicationInsights extends InternalApplicationInsights {

    public initialize: (config: IExtendedConfiguration, core: IAppInsightsCore, extensions: IPlugin[]) => void;
    public identifier = 'WebAnalyticsPlugin';
    public id: Id;
    public version = '2.1.1';
    private pageView: PageView;
    private pageAction: PageAction;
    private contentUpdate: ContentUpdate;
    private pageUnload: PageUnload;
    private pageViewPerformance: PageViewPerformance;
    private _cvPlugin: ICorrelationVectorPlugin;
    private _config: IWebAnalyticsConfiguration;
    private _maxScroll: { h: number, v: number } = { h: 0, v: 0 };
    private _isPageUnloadFired = false;
    private _timespan: Timespan;
    private _contentHandler: IContentHandler;
    private _autoCaptureHandler: IAutoCaptureHandler;
    private baseInitialize: (config: IExtendedConfiguration, core: IAppInsightsCore, extensions: IPlugin[]) => void;

    /**
     * @constructor
     * @param {IWebAnalyticsConfiguration} WebAnalytics module configuration object.
     */
    constructor() {
        super();
        this.baseInitialize = this.initialize;
        this.initialize = this._firstPartyInitialize.bind(this);
    }

    /**
   * Update coreData configuration
   * @param {ICoreData} coreData
   * @return void
   */
    public updateCoreDataConfig(coreData: ICoreData) {
        this._config.coreData = extend(true, this._config.coreData, coreData);
    }

    /**
    * Merge passed in configuration with default configuration
    * @param {IWebAnalyticsConfiguration} overrideConfig
    * @return void
    */
    private _mergeConfig(overrideConfig: IWebAnalyticsConfiguration): IWebAnalyticsConfiguration {
        var defaultConfig: IWebAnalyticsConfiguration = {
            // General library settings
            useDefaultContentName: true,
            useShortNameForContentBlob: true,
            debounceMs: {
                scroll: 600,
                resize: 3000
            },
            biBlobAttributeTag: 'data-m',
            isLoggedIn: false,
            shareAuthStatus: false,
            cookiesToCollect: ['MSFPC', 'ANON'], // on init extend, this property will be stomped
            autoCapture: {
                pageView: true,
                onLoad: true,
                onUnload: true,
                click: true,
                scroll: false,
                resize: false,
                lineage: false,
                jsError: true
            },
            callback: {
                pageName: null,
                pageActionPageTags: null,
                pageViewPageTags: null,
                contentUpdatePageTags: null,
                pageActionContentTags: null,
                signedinStatus: null
            },

            // overrideValues to use instead of collecting automatically
            coreData: {
                referrerUri: isDocumentObjectAvailable ? document.referrer : '',
                requestUri: '',
                pageName: '',
                pageType: '',
                product: '',
                market: '',
                pageTags: {}
            },
            autoPopulateParentIdAndParentName: false
        };

        var attributesThatAreObjectsInConfig: any[] = [];
        for (var attribute in defaultConfig) {
            if (typeof defaultConfig[attribute] === 'object') {
                attributesThatAreObjectsInConfig.push(attribute);
            }
        }

        if (overrideConfig) {
            // delete attributes that should be object and 
            // delete properties that are null, undefined, ''
            _removeNonObjectsAndInvalidElements(overrideConfig, attributesThatAreObjectsInConfig);

            return extend(true, defaultConfig, overrideConfig);
        }
    }

    /**
     * Starts the WebAnalytics plugin
     * @param config The core configuration.
    */
    private _firstPartyInitialize(coreConfig: IExtendedConfiguration, core: IAppInsightsCore, extensions: IPlugin[]) {
        let extendedCore = <IExtendedAppInsightsCore>core;
        coreConfig.extensionConfig = coreConfig.extensionConfig || [];
        coreConfig.extensionConfig[this.identifier] = coreConfig.extensionConfig[this.identifier] || {};
        this._config = this._mergeConfig(coreConfig.extensionConfig[this.identifier]);
        var autoCapture = this._config.autoCapture;
        var existingGetWParamMethod = extendedCore.getWParam;
        extendedCore.getWParam = () => {
            var wparam = 0;
            if (this._config.mscomCookies) {
                wparam = wparam | 1;
            }

            return wparam | existingGetWParamMethod();
        };
        coreConfig.extensionConfig[this.identifier].disableExceptionTracking = !autoCapture.jsError;
        this.baseInitialize(coreConfig, core, extensions);
        // Default to DOM content handler
        this._contentHandler = this._contentHandler ? this._contentHandler : new DomContentHandler(this._config, this._logger);
        // Default to DOM autoCapture handler
        this._autoCaptureHandler = this._autoCaptureHandler ? this._autoCaptureHandler : new AutoCaptureHandler(this, this._logger);

        if (this._config.manageCv) {
            for (let i = 0; i < extensions.length; i++) {
                if ((<ITelemetryPlugin>(extensions[i])).identifier === 'CorrelationVectorPlugin') {
                    this._config.manageCv = true;
                    this._cvPlugin = extensions[i] as ICorrelationVectorPlugin;
                    break;
                }
            }
            if (!this._cvPlugin) {
                this._logger.throwInternal(
                    LoggingSeverity.WARNING,
                    _ExtendedInternalMessageId.CVPluginNotAvailable, "Automatic Cv management is set to \"true\" in config.  However, cv plugin is not available. Disabling automatic Cv management"
                )
                this._config.manageCv = false;
            }
        }

        this.id = new Id();
        this._timespan = new Timespan();
        let metaTags = this._contentHandler.getMetadata();
        this.pageView = new PageView(this, this._config, this._contentHandler, this.id, this._config.callback.pageViewPageTags, metaTags, this._logger);
        this.pageAction = new PageAction(this, this._config, this._contentHandler, this.id, this._config.callback.pageViewPageTags, metaTags, this._logger);
        this.contentUpdate = new ContentUpdate(this, this._config, this._contentHandler, this.id, this._config.callback.pageViewPageTags, metaTags, this._logger);
        this.pageUnload = new PageUnload(this, this._config, this.id, this._logger, this._timespan, this._maxScroll);
        this.pageViewPerformance = new PageViewPerformance(this, this._config, this._contentHandler, this.id, this._config.callback.pageViewPageTags, metaTags, this._logger);
        this._telemetryInitializers = [];
        // Note: PageView is sent as soon as init is called (i.e. right after the Web Analytics script is loaded).  
        // No Muid Sync will happen as we wait to send PV as soon as possible while Muid Sync requires document ready to happen.
        // This matches WEDCS in way of when they send PV without Muid Sync.
        if (autoCapture.pageView) {
            this._autoCaptureHandler.pageView();
        }
        if (autoCapture.onLoad) {
            this._autoCaptureHandler.onLoad();
        }
        // handle automatic event firing on user click
        if (autoCapture.click) {
            this._autoCaptureHandler.click();
        }
        // handle automatic event firing on user scroll
        if (autoCapture.scroll) {
            this._autoCaptureHandler.scroll(this._config.debounceMs);
        }
        // handle automatic event firing on user resize
        if (autoCapture.resize) {
            this._autoCaptureHandler.resize(this._config.debounceMs);
        }
        // measure maxScroll
        if (autoCapture.onUnload || this._config.manualPageUnload) {
            this._autoCaptureHandler.maxScroll(this._maxScroll);
        }
        if (autoCapture.onUnload) {
            this._autoCaptureHandler.onUnload();
        }
    }

    /**
    * Process a given event.
    * Hydrate with appropriate PartB and PartC data
    * @param {ITelemetryItem} evt - The event to be hydrated.
    */
    public processTelemetry(evt: ITelemetryItem) {
        let event = <IExtendedTelemetryItem>evt;
        if (event.baseType === 'PageviewData') {
            event.name = 'Ms.Web.PageView';
            event.latency = EventLatency.RealTime;
        } else if (event.baseType === 'ExceptionData') {
            event.name = 'Ms.Web.ClientError';
            event.latency = EventLatency.Normal;
            // Remove extra AI properties
            delete (event.baseData['aiDataContract']);
        } else if (event.baseType === 'PageviewPerformanceData') {
            event.name = 'Ms.Web.PageViewPerformance';
            event.latency = EventLatency.Normal;
            // Remove extra AI properties
            delete (event.baseData['isValid']);
            delete (event.baseData['durationMs']);
        }
        // Correlation
        var cv = null;
        if (event.baseType !== 'PageviewData') {
            // If automatic cV management is desired and cV plugin is available
            if (this._config.manageCv) {
                cv = this._cvPlugin.getCv();
                if (cv) {
                    cv.increment();
                }
            }
        } else {
            if (this._config.manageCv) {
                cv = this._cvPlugin.getCv();
                // Seed a new cV for each event
                if (!cv) {
                    cv = this._cvPlugin.getCv();
                } else {
                    cv.seed();
                }
            }
        }

        var doNotSendItem = false;
        var telemetryInitializersCount = this._telemetryInitializers.length;
        for (var i = 0; i < telemetryInitializersCount; ++i) {
            var telemetryInitializer = this._telemetryInitializers[i];
            if (telemetryInitializer) {
                if (telemetryInitializer.apply(null, [event]) === false) {
                    doNotSendItem = true;
                    break;
                }
            }
        }

        if (!doNotSendItem && this._nextPlugin) {
            this._nextPlugin.processTelemetry(event);
        }
    }

    /**
    * Sets the next plugin
    * @param {ITelemetryPlugin} plugin - The next plugin to be setup
    */
    public setNextPlugin(plugin: ITelemetryPlugin) {
        this._nextPlugin = plugin;
    }

    /**
     * Telemetry initializers are used to modify the contents of collected telemetry before being sent from the user's browser. 
     * They can also be used to prevent certain telemetry from being sent, by returning false.
     * @param {(item: IExtendedTelemetryItem) => boolean} telemetryInitializer - Telemetry Initializer
     */
    public addTelemetryInitializer(telemetryInitializer: (item: IExtendedTelemetryItem) => boolean | void) {
        this._telemetryInitializers.push(telemetryInitializer);
    }

    /**
   * API to send custom event
   * @param {IExtendedTelemetryItem} event - Custom event
   * @param {any} properties - Custom event properties (part C)
   */
    public trackEvent(event: IExtendedTelemetryItem, customProperties?: any): void {
        event.latency = event.latency || EventLatency.Normal;
        event.baseData = event.baseData || {};
        event.data = event.data || {};
        // Add extra Part C
        if (isValueAssigned(customProperties)) {
            for (var prop in customProperties) {
                if (customProperties.hasOwnProperty(prop)) {
                    event.data[prop] = customProperties[prop];
                }
            }
        }
        this.core.track(event);
    }

    /**
    * API to send pageView event
    * @param {IPageViewTelemetry} pageViewEvent - PageView event
    * @param {IPageViewProperties} properties - PageView properties (part C)
    */
    public trackPageView(pageViewEvent: IPageViewTelemetry, properties?: IPageViewProperties): void {
        this._resetPageUnloadProperties();
        // Initialize IDs to be used as parent and trace IDs
        this.id.initializeIds();
        pageViewEvent.id = this.id.getLastPageViewId();
        super.sendPageViewInternal(pageViewEvent, properties, this._getSystemProperties(pageViewEvent));
    }

    /**
    * API to create and send a populated PageView event
     * @param {IPageViewOverrideValues} overrideValues - Override values
     * @param {{ [name: string]: string | number | boolean | string[] | number[] | boolean[] }} customProperties - Custom properties(Part C)
    */
    public capturePageView(overrideValues?: IPageViewOverrideValues, customProperties?: { [name: string]: string | number | boolean | string[] | number[] | boolean[] | object }): void {
        this.pageView.capturePageView(overrideValues, customProperties);
    }

    /**
    * API to send PageViewPerformance event
     * @param {IPageViewPerformanceTelemetry} pageViewPerformance - PageViewPerformance event
    * @param {IPageViewPerformanceProperties} customProperties - PageViewPerformance properties (part C)
    */
    public trackPageViewPerformance(pageViewPerformance: IPageViewPerformanceTelemetry, customProperties?: IPageViewPerformanceProperties): void {
        super.sendPageViewPerformanceInternal(pageViewPerformance, customProperties, this._getSystemProperties(pageViewPerformance));
    }

    /**
   * API to create and send a populated PageViewPerformance event
    * @param {IPageViewPerformanceTelemetry} pageViewPerformance - PageViewPerformance event
   * @param {{ [name: string]: string | number | boolean | string[] | number[] | boolean[] }} customProperties - Custom properties(Part C)
   */
    public capturePageViewPerformance(overrideValues?: IPageViewPerformanceOverrideValues, customProperties?: { [name: string]: string | number | boolean | string[] | number[] | boolean[] | object }): void {
        this.pageViewPerformance.capturePageViewPerformance(overrideValues, customProperties);
    }

    /**
    * API to send Exception event
     * @param {IExceptionTelemetry} exception - Exception event
    * @param {any} customProperties - Exception properties (part C)
    */
    public trackException(exception: IExceptionTelemetry, customProperties?: any): void {
        exception.id = exception.id || createGuid();
        super.sendExceptionInternal(exception, customProperties, this._getSystemProperties(exception));
    }

    /**
    * API to send pageAction event
    * @param {IPageActionTelemetry} pageActionEvent - PageAction event
    * @param {IPageActionProperties} properties - PageAction properties(Part C)
    */
    public trackPageAction(pageActionEvent: IPageActionTelemetry, pageActionProperties?: IPageActionProperties): void {
        this.pageAction.trackPageAction(pageActionEvent, pageActionProperties);
    }

    /**
    * API to create and send a populated PageAction event 
    * @param {Element} element - DOM element
    * @param {IPageActionOverrideValues} overrideValues - PageAction overrides
    * @param {{ [name: string]: string | number | boolean | string[] | number[] | boolean[] }} customProperties - Custom properties(Part C)
    * @param {boolean} isRightClick - Flag for mouse right clicks
    */
    public capturePageAction(element: Element, overrideValues?: IPageActionOverrideValues, customProperties?: { [name: string]: string | number | boolean | string[] | number[] | boolean[] | object }, isRightClick?: boolean): void {
        if (!_isElementDnt(element, doNotTrackFieldName)) {
            this.pageAction.capturePageAction(element, overrideValues, customProperties, isRightClick);
        }

    }

    /**
      * API to send ContentUpdate event
      * @param {IContentUpdateTelemetry} contentUpdateEvent - ContentUpdate event
      * @param {IContentUpdateProperties} properties - ContentUpdate properties(Part C)
      */
    public trackContentUpdate(contentUpdateEvent: IContentUpdateTelemetry, properties?: IContentUpdateProperties): void {
        this.contentUpdate.trackContentUpdate(contentUpdateEvent, properties);
    }

    /**
  * API to create and send a populated ContentUpdate event 
  * @param {IContentUpdateOverrideValues} overrideValues - ContentUpdate overrides
  * @param {{ [name: string]: string | number | boolean | string[] | number[] | boolean[] }} customProperties - Custom properties(Part C)
  */
    public captureContentUpdate(overrideValues: IContentUpdateOverrideValues, customProperties?: { [name: string]: string | number | boolean | string[] | number[] | boolean[] | object }): void {
        this.contentUpdate.captureContentUpdate(overrideValues, customProperties);
    }

    /**
   * API to send PageUnload event
   * @param {IPageUnloadTelemetry} pageUnloadEvent - PageUnload event
   * @param {IPageUnloadProperties} properties - PageUnload properties(Part C)
   */
    public trackPageUnload(pageUnloadEvent: IPageUnloadTelemetry, properties?: IPageUnloadProperties): void {
        if (!this._isPageUnloadFired) {
            this._isPageUnloadFired = true;
            this.pageUnload.trackPageUnload(pageUnloadEvent, properties);
        }
    }

    /**
   * API to create and send a populated PageUnload event 
   * @param {IPageUnloadOverrideValues} overrideValues - PageUnload overrides
   * @param {{ [name: string]: string | number | boolean | string[] | number[] | boolean[] }} customProperties - Custom properties(Part C)
   */
    public capturePageUnload(overrideValues: IPageUnloadOverrideValues, customProperties?: { [name: string]: string | number | boolean | string[] | number[] | boolean[] | object }): void {
        if (!this._isPageUnloadFired) {
            this._isPageUnloadFired = true;
            this.pageUnload.capturePageUnload(overrideValues, customProperties);
        }
    }

    public _populatePageViewPerformance(pageViewPerformance: IPageViewPerformanceTelemetry) {
        this._pageViewPerformanceManager.populatePageViewPerformanceEvent(pageViewPerformance);
    }

    /**
    * Set custom content handler, need to be set before initialization
    * @param {IContentHandler} contentHandler - Content handler instance
    */
    public setContentHandler(contentHandler: IContentHandler) {
        this._contentHandler = contentHandler;
    }

    /**
    * Set custom auto capture handler, need to be set before initialization
    * @param {IAutoCaptureHandler} autoCaptureHandler - Content handler instance
    */
    public setAutoCaptureHandler(autoCaptureHandler: IAutoCaptureHandler) {
        this._autoCaptureHandler = autoCaptureHandler;
    }

    private _getSystemProperties(event: ITelemetryEventInternal) {
        var ext = {};
        if (event.isManual !== undefined) {
            ext['web'] = {};
            ext['web']['isManual'] = event.isManual !== undefined ? event.isManual : true;
            delete (event.isManual);
        }
        return ext;
    }

    /**
    * Resets the values used for pageUnload.
    */
    private _resetPageUnloadProperties() {
        this._timespan._recordTimeSpan('dwellTime', false);
        this._maxScroll.v = 0;
        this._isPageUnloadFired = false;
    }
}
