/**
* Web.ts
* @author Hector Hernandez (hectorh)
* @copyright Microsoft 2019
*/
import { getCookie, objDefineAccessors } from '@ms/1ds-core-js';
import { IPropertyConfiguration, IWebContext, IScreenResolution } from '../DataModels';

const BROWSERS = {
    MSIE: 'MSIE',
    CHROME: 'Chrome',
    FIREFOX: 'Firefox',
    SAFARI: 'Safari',
    EDGE: 'Edge',
    ELECTRON: 'Electron',
    SKYPE_SHELL: 'SkypeShell',
    PHANTOMJS: 'PhantomJS',
    OPERA: 'Opera'
};

const REGEX_VERSION = '([\\d,.]+)';
const UNKNOWN = 'Unknown';

export class Web implements IWebContext {
    /**
     * Domain of the page.
     */
    public domain: string;
    /**
     * Web browser name.
     */
    public browser: string;
    /**
     * Web browser version.
     */
    public browserVer: string;
    /**
    * Screen resolution.
    */
    public screenRes: string;

    /**
     * Returns whether the user has given consent for Cookies. 
     * This is configurable by users to define which cookie to look for marking this flag as true or false.
     * Note: This property will NOT exist in ES3/IE8 environment, if you need IE8 compatibility
     * use the method {@link Web#getUserConsent} from your code. For ES5+ environment this will be replaced with a 
     * property getter only.
     */
    public userConsent?:boolean;

    /**
    * Describes whether the user has given consent for Cookies. 
    * This is configurable by users to define which cookie to look for marking this flag as true or false.
    */
    getUserConsent(): boolean {
        return this._propertiesConfig.userConsented || (getCookie(this._propertiesConfig.userConsentCookieName) ? true : false);
    }

    private _propertiesConfig?: IPropertyConfiguration;

    constructor(propertiesConfig: IPropertyConfiguration) {
        this._propertiesConfig = propertiesConfig;
        //Add the domain
        if (typeof window !== 'undefined') {
            let windowLocation = window.location;
            if (windowLocation) {
                let domain = windowLocation.hostname;
                if (domain) {
                    this.domain = windowLocation.protocol === 'file:' ? 'local' : domain;
                }
            }
        }
        let userAgent = typeof navigator !== 'undefined' ? navigator.userAgent : '';
        if (propertiesConfig.userAgent) {
            userAgent = propertiesConfig.userAgent;
        }
        if (propertiesConfig.populateBrowserInfo) {
            if (userAgent) {
                let browserName = this._getBrowserName(userAgent);
                this.browser = browserName;
                this.browserVer = this._getBrowserVersion(userAgent, browserName);
            }
            var screenRes = this._getScreenResolution();
            this.screenRes = screenRes.w + 'X' + screenRes.h;
        }
    }

    private _getBrowserName(userAgent: string) {
        //Check for Opera first        
        if (this._userAgentContainsString('OPR/', userAgent)) {
            return BROWSERS.OPERA;
        }
        //Check for Phantom JS
        if (this._userAgentContainsString(BROWSERS.PHANTOMJS, userAgent)) {
            return BROWSERS.PHANTOMJS;
        }
        //Check for Edge
        if (this._userAgentContainsString(BROWSERS.EDGE, userAgent)) {
            return BROWSERS.EDGE;
        }
        //Check for Electron
        if (this._userAgentContainsString(BROWSERS.ELECTRON, userAgent)) {
            return BROWSERS.ELECTRON;
        }
        //Check for Chrome
        if (this._userAgentContainsString(BROWSERS.CHROME, userAgent)) {
            return BROWSERS.CHROME;
        }
        //Check for Internet Explorer
        if (this._userAgentContainsString('Trident', userAgent)) {
            return BROWSERS.MSIE;
        }
        //Check for Firefox
        if (this._userAgentContainsString(BROWSERS.FIREFOX, userAgent)) {
            return BROWSERS.FIREFOX;
        }
        //Check for Safari
        if (this._userAgentContainsString(BROWSERS.SAFARI, userAgent)) {
            return BROWSERS.SAFARI;
        }
        //Check for Skype shell
        if (this._userAgentContainsString(BROWSERS.SKYPE_SHELL, userAgent)) {
            return BROWSERS.SKYPE_SHELL;
        }
        return UNKNOWN;
    }

    private _userAgentContainsString(searchString: string, userAgent: string) {
        return userAgent.indexOf(searchString) > -1;
    }

    private _getBrowserVersion(userAgent: string, browserName: string) {
        if (browserName === BROWSERS.MSIE) {
            return this._getIeVersion(userAgent);
        } else {
            return this._getOtherVersion(browserName, userAgent);
        }
    }

    private _getIeVersion(userAgent: string) {
        let classicIeVersionMatches = userAgent.match(new RegExp(BROWSERS.MSIE + ' ' + REGEX_VERSION));
        if (classicIeVersionMatches) {
            return classicIeVersionMatches[1];
        } else {
            let ieVersionMatches = userAgent.match(new RegExp('rv:' + REGEX_VERSION));
            if (ieVersionMatches) {
                return ieVersionMatches[1];
            }
        }
    }

    private _getOtherVersion(browserString: string, userAgent: string) {
        if (browserString === BROWSERS.SAFARI) {
            browserString = 'Version';
        }
        let matches = userAgent.match(new RegExp(browserString + '/' + REGEX_VERSION));
        if (matches) {
            return matches[1];
        }
        return UNKNOWN;
    }

    /**
    * Get Screen resolution
    * @returns {ScreenResolution} - Screen resolution
    */
    private _getScreenResolution(): IScreenResolution {
        var screenRes: IScreenResolution = { h: 0, w: 0 };

        if (window.screen) {
            screenRes.h = screen.height;
            screenRes.w = screen.width;
        }

        return screenRes;
    }

    /**
     * Static constructor, attempt to create accessors
     */
    private static _staticInit = (() => {
        // Dynamically create get/set property accessors
        objDefineAccessors(Web.prototype, "userConsent", Web.prototype.getUserConsent);
    })();
}
