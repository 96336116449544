import { IArrayBufferConverter } from "./IArrayBufferConverter";
import { IError } from "../error/IError";
import { TypedPromise } from "cms-typed-promise";

/**
 * Provides functionality for converting to and from ArrayBuffer.
 * Currently only supports converting from ArrayBuffer to string.
 * @class
 */
export class ArrayBufferConverter implements IArrayBufferConverter {

    /**
     * The file reader factory to use.
     * @type { () => FileReader }
     */
    private fileReaderFactory: () => FileReader;

    /**
     * Initializes a new instance of the ArrayBufferConverter class.
     * @constructor
     * @param fileReaderFactory {() => FileReader} The optional file reader factory.
     */
    constructor(fileReaderFactory?: () => FileReader) {
        const defaultFactory = () => new FileReader();
        this.fileReaderFactory = fileReaderFactory || defaultFactory;
    }

    /**
     * Gets a string from given ArrayBuffer.
     * @method
     * @param buffer {ArrayBuffer} buffer to convert
     * @returns {Promise<string, IError<string, any>>} Promise for converted string if successful, otherwise error information.
     */
    public convertToString(buffer: ArrayBuffer): TypedPromise<string, IError<string, any>> {
        return new TypedPromise<string, IError<string, any>>((resolve, reject) => {
            const error: IError<string, any> = {
                type: "CannotConvert"
            };

            if (!buffer) {
                error.errorMessage = "Buffer is required.";
                reject(error);
                return;
            }

            const fileReader = this.fileReaderFactory();

            fileReader.onload = (e) => {
                resolve(<string>e.target["result"]);
            };

            try {
                const blob = new Blob([buffer]);
                fileReader.readAsText(blob);
            } catch (conversionFailure) {
                error.innerError = conversionFailure;
                reject(error);
            }
        });
    };
}