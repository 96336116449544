import { IBaseTrackingData } from "./userActivityData/IBaseTrackingData";
import { IEventData } from "./userActivityData/IEventData";
import { IUserActivityLogItem } from "./IUserActivityLogItem";
import { LogItem } from "./LogItem";
import { LogLevel } from "./LogLevel";
import { UserActivityType } from "./UserActivityType";

/**
 * Class to construct the user activity log item.
 * @class
 */
export class UserActivityLogItem extends LogItem implements IUserActivityLogItem {

    /** 
     * The correlation vector.
     * @type {string}
     */
    public correlationVector?: string;

    /** 
     * The page view or page action tracking data.
     * @type {IBaseTrackingData}
     */
    public trackingData?: IBaseTrackingData;

    /** 
     * Title of the page.
     * @type {string}
     */
    public pageTitle?: string;

    /** 
     * Uri of the page.
     * @type {string}
     */
    public pageUri?: string;

    /** 
     * The event data.
     * @type {IEventData}
     */
    public eventData?: IEventData;

    /** 
     * The user activity type.
     * @type {UserActivityType}
     */
    private _userActivityType: UserActivityType;

    /**
     * Initializes a new instance of the `UserActivityLogItem` class.
     * @constructor
     * @param pageName {string} The name of the page.
     * @param userActivityType {userActivityType} The user activity type.
     * @param correlationVector? {string} The correlation vector.
     * @param trackingData? {IBaseTrackingData} The page view or page action tracking data.
     * @param title? {string} Title of the page.
     * @param pageUri? {string} Uri of the page.
     * @param extendedData? {any} Additional data values, if any.
     * @param eventData? {string} The event data.
     */
    constructor(
        public pageName: string,
        userActivityType: UserActivityType,
        correlationVector?: string,
        trackingData?: IBaseTrackingData,
        pageTitle?: string,
        pageUri?: string,
        extendedData?: any,
        eventData?: IEventData) {

        super(pageName, extendedData);

        this.LogLevel = LogLevel.Activity;
        this._userActivityType = userActivityType;
        this.correlationVector = correlationVector;
        this.trackingData = trackingData;
        this.pageTitle = pageTitle;
        this.pageUri = pageUri;
        this.eventData = eventData;
    }

    /** 
     * Gets the user activity type.
     * @property 
     * @type {UserActivityType}
     */
    get userActivityType(): UserActivityType {
        return this._userActivityType;
    }
}