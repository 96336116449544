import * as axiosStatic from "axios";
import * as datajs from "cms-external-datajs/batch-formatting";

import { IActivityLogItem } from "clientcore-infrastructure-analytics/models/IActivityLogItem";
import { IInstrumentationService } from "clientcore-infrastructure-analytics/IInstrumentationService";
import { IODataBatchClient } from "./IODataBatchClient";
import { IODataBatchError } from "./IODataBatchError";
import { IODataBatchRequest } from "./IODataBatchRequest";
import { IODataBatchResponse } from "./IODataBatchResponse";
import { NetworkActivityLogItem } from "clientcore-infrastructure-analytics/models/NetworkActivityLogItem";
import { extend } from "lodash-es";

/**
 * Client that bundles multiple http requests into a single multipart OData batching request.
 * @class
 */
export class ODataBatchClient implements IODataBatchClient {

    /**
     * A regular expression that parses the domain from a url.
     * @type {RegExp}
     */
    private static DomainParser: RegExp = /\/\/(.[^/]+)/;

    /**
     * The default header name for correlation vector.
     * @type {string}
     */
    private static DefaultCvHeaderName: string = "MS-CV";

    /**
     * The axios instance that the batch request will be sent with.
     * @type {axiosStatic.AxiosStatic}
     */
    private axios: axiosStatic.AxiosStatic;

    /**
     * The domain name of the batch server.
     * @type {string}
     */
    private hostName: string;

    /**
     * Initializes a new instance of the `ODataBatchClient` class.
     * @constructor
     * @param batchEndpointUrl {string} The batch url endpoint.
     * @param instrumentationService {IInstrumentationService} The instrumentation service to log messages to.
     * @param axios? {axiosStatic.AxiosStatic} The axios instance that the batch request will be sent with (this may be wrapped, but not with a batching wrapper).
     */
    constructor(
        private batchEndpointUrl: string,
        private instrumentationService: IInstrumentationService,
        axios?: axiosStatic.AxiosStatic) {

        if (!batchEndpointUrl) {
            throw "batchEndpointUrl must not be null";
        }

        if (!instrumentationService) {
            throw "instrumentationService must not be null";
        }

        this.batchEndpointUrl = batchEndpointUrl;
        this.axios = axios || axiosStatic;

        var hostNameParseResult = ODataBatchClient.DomainParser.exec(batchEndpointUrl);
        if (hostNameParseResult && hostNameParseResult.length > 1) {
            this.hostName = hostNameParseResult[1];
        }
    }

    /**
     * Process the given error response by converting to the external format and rejecting our promise.
     * @param {IODataBatchRequest[]} requests The array of requests that resulted in the error.
     * @param {axiosStatic.Response} error The error returned by Axios.
     * @param {(error?: any) => void} reject The reject function of the promise we need to fail.
     */
    private static processErrorResponse(
        requests: IODataBatchRequest[],
        response: axiosStatic.Response,
        reject: (error?: any) => void): void {

        let errorResponse: IODataBatchError = {
            requests: requests,
            data: response.data,
            status: response.status,
            statusText: response.statusText,
            headers: response.headers
        };

        reject(errorResponse);
    }

    /**
     * Batches requests together and returns responses as an array.
     * @param {IODataBatchRequest[]} requests An array of requests to make.
     * @param parentActivity? {IActivityLogItem} The parent activity item.
     * @returns {Promise<IODataBatchResponse[]>} The array of responses.
     */
    public executeAsBatch(requests: IODataBatchRequest[], parentActivity?: IActivityLogItem): Promise<IODataBatchResponse[]> {

        if (requests == null || requests.length === 0) {
            throw "ODataBatchClient.executeAsBatch requests parameters should not be null or empty.";
        }

        // Create individual parts of the batch request.
        let batchRequests: datajs.BatchRequest[] = [];

        // Create Network activity (Outgoing service request) for batch request.
        // Network activity of the batch should be created before batch parts in order to maintain the order of correlation vector.
        let networkActivities: NetworkActivityLogItem[] = [];
        let batchNetworkActivity = this.instrumentationService.activityLoggingService.createNetworkActivity(
            "ODataBatchClient.executeAsBatch",
            "MainBatchRequest",
            this.batchEndpointUrl,
            "POST",
            null,
            null,
            parentActivity);

        for (let i = 0; i < requests.length; i++) {

            let contentType = "application/http";
            if (requests[i].method.toLowerCase() === "put" || requests[i].method.toLowerCase() === "post" || requests[i].method.toLowerCase() === "patch") {
                contentType = "application/json";
            }

            // Extend provided headers with required values for batching.
            let headers: { [name: string]: string } = <{ [name: string]: string }>extend({}, requests[i].headers);
            headers = <{ [name: string]: string }>extend(
                {
                    "Content-Type": contentType + "; msgtype=request",
                    "host": this.hostName,
                    "Accept": "application/json, text/plain, */*"
                },
                headers);

            // Create individual network activities to log Outgoing service request.
            // Separate OSRs will be logged for each individual requests and one for overall batch request.
            if (batchNetworkActivity) {
                let networkActivity = this.instrumentationService.activityLoggingService.createNetworkActivity(
                    "ODataBatchClient.executeAsBatch",
                    "SubBatchRequest",
                    requests[i].uri,
                    requests[i].method.toLowerCase(),
                    null,
                    null,
                    parentActivity);

                // Add correlation vector header
                if (networkActivity && networkActivity.correlationVector) {
                    headers[ODataBatchClient.DefaultCvHeaderName] = networkActivity.correlationVector;
                }

                networkActivities.push(networkActivity);
            }

            // Stringify the data if needed. This batch client expects application/json for content type.
            let requestBody = requests[i].data;
            if (requestBody && typeof requestBody !== "string") {
                requestBody = JSON.stringify(requestBody);
            }

            // Create datajs batch request from incoming request parameter.
            let batchRequest: datajs.BatchRequest = {
                requestUri: requests[i].uri,
                method: requests[i].method,
                headers: headers,
                body: requestBody
            };
            batchRequests.push(batchRequest);
        }

        // Create wrapper batch request.
        let requestData: datajs.RequestData = { __batchRequests: batchRequests };

        // Make the service call using data js, and an DataJSHttpClient implemented using axios.
        return new Promise<IODataBatchResponse[]>((resolve, reject) => {

            // Serialize the batch request data and setup the batch headers.
            let serializedBatch = datajs.serializeBatch(requestData);
            let headers = {
                "Accept": "application/json, text/plain, */*",
                "Content-Type": "multipart/mixed; boundary=" + serializedBatch.boundary
            };

            // Add correlation vector header
            if (batchNetworkActivity && batchNetworkActivity.correlationVector) {
                headers[ODataBatchClient.DefaultCvHeaderName] = batchNetworkActivity.correlationVector;
            }

            this.axios.post(this.batchEndpointUrl, serializedBatch.body, { headers: headers }).then(response => {
                // The batch request succeeded, but individual requests inside the batch may have succeeded or failed independently.
                var data = datajs.parseBatch(response.data, response.headers);
                this.instrumentationService.activityLoggingService.endSuccessfulNetworkActivity(batchNetworkActivity, null, response.status);
                this.processSuccessResponse(requests, data, resolve, reject, networkActivities);
            }).catch(error => {
                // The entire batch request failed, so individual requests cannot be evaluated for success or failure.
                if (error instanceof Error) {
                    this.instrumentationService.activityLoggingService.endFailedNetworkActivity(batchNetworkActivity, null, null, error.message);

                    // If the error is of type Error it means an error was thrown before the request could be sent.
                    reject(error);
                } else {
                    this.instrumentationService.activityLoggingService.endFailedNetworkActivity(batchNetworkActivity, null, error.status);
                    ODataBatchClient.processErrorResponse(requests, error, reject);
                }
            });
        });
    }

    /**
     * Process the given success responses by converting to the external format and resolving our promise.
     * @param requests {IODataBatchRequest[]} The array of requests that resulted in the success.
     * @param data {DataJS.ResponsesData} The parsed batch response.
     * @param resolve {(value?: IODataBatchResponse[])} The resolve function of the promise if we can succeed.
     * @param reject {(error?: any) => void} The reject function of the promise if we need to fail.
     * @param networkActivities {NetworkActivityLogItem[]} List of network activities associated with batch parts.
     */
    private processSuccessResponse(
        requests: IODataBatchRequest[],
        data: datajs.ResponsesData,
        resolve: (value?: IODataBatchResponse[]) => void,
        reject: (error?: any) => void,
        networkActivities: NetworkActivityLogItem[]): void {

        if (data.__batchResponses == null || data.__batchResponses.length === 0) {
            reject("ODataBatchClient batch response should not be null or empty.");
        }

        // Loop through all responses and convert each.
        let batchGetCallsResponses: IODataBatchResponse[] = [];
        for (let i = 0; i < data.__batchResponses.length; i++) {

            // In case of failure the response is located in a seperate response object.
            let internalResponse = data.__batchResponses[i];
            let responseBase: datajs.ResponseDataBase = <datajs.ResponseData>internalResponse;
            if ((<any>internalResponse).response) {
                let responseError = (<datajs.ResponseError>internalResponse);
                responseBase = responseError.response;

                // Log the outgoing service request. If activity logging not enabled then there won't be any network activities.
                if (networkActivities.length > 0) {
                    this.instrumentationService.activityLoggingService.endFailedNetworkActivity(networkActivities[i], null, responseBase.statusCode, responseError.message);
                }
            } else {
                if (networkActivities.length > 0) {
                    this.instrumentationService.activityLoggingService.endSuccessfulNetworkActivity(networkActivities[i], null, responseBase.statusCode);
                }
            }

            // Convert internal datajs response to ODataBatchService response format.
            let externalResponse: IODataBatchResponse = {
                request: requests[i],
                status: responseBase.statusCode,
                statusText: responseBase.statusText,
                data: responseBase.body,
                headers: responseBase.headers
            };

            batchGetCallsResponses.push(externalResponse);
        }

        resolve(batchGetCallsResponses);
    }
}
