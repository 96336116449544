/**
 * Enum for input method types.
 * @readonly
 * @enum {number}
 */
export enum ActionInputMethodType {

    /**
     * Default, uninitialized value.
     */
    Undefined = 0,

    /**
     * The value is indeterminable.
     */
    Unknown = 1,

    /**
     * The value is known to be something other than available on this list.
     */
    Other = 2,

    /**
     * Keyboard or numeric keypad.
     */
    Keyboard = 3,

    /**
     * Mice, trackball, or mouse-substitute technology.
     */
    Mouse = 4,

    /**
     * Touch-based gesture via a TouchPad such as two-finger scrolling.
     */
    TouchPad = 5,

    /**
     * Touch-based gestures via a touch-enabled screen such as two-finger scrolling.
     */
    TouchScreen = 6,

    /**
     * Pen/stylus.
     */
    Pen = 7,

    /**
     * Xbox controller, joystick, PC-based game pad, etc.
     */
    GameController = 8,

    /**
     * Speach recognition, voice navigation, or other spoken language interface.
     */
    Voice = 9,

    /**
     * Non-touch-based NUI such as motion captured by the Xbox Kinect.
     */
    UserMotion = 10,

    /**
     * NFC such as an RFID connection to a mobile device.
     */
    NearFieldCommunication = 11,

    /**
     * Sevice sensor such as accelerometer or a light sensor.
     */
    DeviceSensor = 12,

    /**
     * Controls on Bluetooth-connected device such as a headset or speakers
     */
    Bluetooth = 13
}