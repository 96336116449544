import { enc as Encoding } from "crypto-js";
import { HmacSHA256 } from "crypto-js";

/**
 * Used to generate tokens for the PROSE service.
 * Javascript port of Tools-Library-Authentication/Authentication/Token.cs C# class
 * Original: https://atpservices.visualstudio.com/DefaultCollection/Tools/Delta%20Team/_git/Tools-Library-Authentication?path=%2FAuthentication%2FToken.cs&version=GBdevelopment&_a=contents
 * @class
 */
export class Token {

    /**
     * Generates an agent token. The token is escaped as a data string for a URI.
     * @param sharedKey {string} The shared key.
     * @param spn {string} The SPN email address.
     * @param message {string} The shared message to sign.
     * @returns {string} The token.
     */
    public static generateToken(sharedKey: string, spn: string, message: string): string {

        var now = new Date();
        var paddedMinutes = ("0" + now.getMinutes()).slice(-2);
        var paddedDay = ("0" + now.getDate()).slice(-2);
        var date = now.getFullYear() + paddedMinutes + paddedDay;

        // Formats the time like "2016-04-01 14:06:35Z"
        var isoNow = now.toISOString();
        var formattedDate = isoNow.slice(0, 10);
        var formattedTime = isoNow.slice(11, 19);
        var formattedNow = `${formattedDate} ${formattedTime}Z`;

        var dataToSign = date + message;

        var signature = this.generateSignature(sharedKey, dataToSign);

        var token = `${spn}_${formattedNow}_${signature}`;

        return encodeURIComponent(token);
    }

    /**
     * Creates a signature string based on shared key.
     * @param sharedKey {string} The shared key.
     * @param dataToSign {string} The data to sign.
     * @returns {string} The signature.
     */
    public static generateSignature(sharedKey: string, dataToSign: string): string {

        // Equivalent of C# 'var encodedKey = Encoding.Unicode.GetBytes(sharedKey)', and then transforming it to a string.
        var encodedKey = "";
        for (var i = 0; i < sharedKey.length; i++) {
            encodedKey += sharedKey[i];
            encodedKey += String.fromCharCode(0);
        }

        var parsedData = Encoding.Base64.parse(dataToSign);
        var hash = HmacSHA256(parsedData, encodedKey);

        return Encoding.Base64.stringify(hash);
    }
}