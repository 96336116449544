/**
* Device.ts
* @author Hector Hernandez (hectorh)
* @copyright Microsoft 2019
*/

import { IDeviceContext } from '../DataModels';

export class Device implements IDeviceContext {
    /**
     * Device local ID.
     */
    public localId?: string;
    /**
     * Device class.
     */
    public deviceClass?: string;
    /**
     * Device make.
     */
    public make?: string;
    /**
     * Device model.
     */
    public model?: string;
}
