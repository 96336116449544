import { IEventBasicData } from "./IEventBasicData";
import { ILogItem } from "../ILogItem";
import { IOperationalEventContent } from "./IOperationalEventContent";
import { LogLevel } from "../LogLevel";
import { LogType } from "../LogType";

/**
 * Represents the asimov tracing and error event content schema that is registered with JSLL (Javascript logging library) of Asimov pipeline. Also, used in 1DS pipeline.
 * @class
 */
export class OperationalEventContent implements IOperationalEventContent {

    /**
     * Initializes a new instance of the `OperationalEventContent` class.
     * @param message {string} The log message.
     * @param logLevel {string} The log level.
     * @param logType {string} The log type.
     * @param activityId {string} The activity id.
     * @param parentActivityId {string} The parent activity id.
     * @param application {string} The application name.
     * @param source {string} The source name.
     * @param impressionGuid {string} The impression guid.
     * @param tenant {string} The tenant name or id.
     * @param data {string} The additional data with open JSON schema.
     * @param uri {string} The page uri.
     * @param pageTitle {string} The page title.
     * @param serverImpressionGuid {string} The server impression guid.
     */
    constructor(
        public message: string,
        public logLevel: string,
        public logType: string,
        public activityId: string,
        public parentActivityId: string,
        public application: string,
        public source: string,
        public impressionGuid: string,
        public tenant: string,
        public data: string,
        public uri: string,
        public pageTitle: string,
        public serverImpressionGuid: string) {
    }

    /**
     * Creates a new OperationalEventContent from a given log item.
     * @param logItem {ILogItem} The log item.
     * @param basicData {IEventBasicData} the basic parameters of the asimov events.
     * @param application {string} The application name.
     * @param source {string} The source name.
     * @param impressionGuid {string} The impression guid.
     * @param tenant {string} The tenant name or id.
     * @param serverImpressionGuid {string} The server impression guid.
     * @return {IOperationalEventContent} The operational event content data.
     */
    public static createFrom(
        logItem: ILogItem,
        basicData: IEventBasicData,
        application: string,
        source: string,
        impressionGuid: string,
        tenant: string,
        serverImpressionGuid: string): IOperationalEventContent {

        return new OperationalEventContent(
            logItem.Message,
            LogLevel[logItem.LogLevel],
            LogType[logItem.LogType],
            logItem.ActivityId,
            logItem.ParentActivityId,
            application,
            source,
            impressionGuid,
            tenant,
            JSON.stringify(basicData.customData),
            basicData.pageUri,
            basicData.title,
            serverImpressionGuid
        );
    }
}
