import { EventBasicData } from "../commonSchema/EventBasicData";
import { EventLatency } from "@ms/1ds-core-js";
import { IBrowserWindow } from "../../browser-object-models/IBrowserWindow";
import { ILogItem } from "../ILogItem";
import { IOperationalEventContent } from "../commonSchema/IOperationalEventContent";
import { IOneDsEventData } from "./IOneDsEventData";
import { IOneDsExtension } from "./IOneDsExtension";
import { OperationalEventContent } from "../commonSchema/OperationalEventContent";
import { OneDsNamespaceProfile } from "./OneDsNamespaceProfile";

/**
 * Represents the parameters of the 1DS tracing event schema.
 * @class
 */
export class OneDsTracingEventData implements IOneDsEventData<IOperationalEventContent> {

    /**
     * Initializes a new instance of the `OneDsTracingEventData` class.
     * @param name {string} The name(namespace) of the tracing custom event.
     * @param time {string} The date and time of the event in ISO format.
     * @param data {[key: string]: IOperationalEventContent | string} The tracing event data contains activity data and namepsace.
     * @param ext {IOneDsExtension} The extension fields.
     * @param latency {number} The latency corresponding to EventLatency.
     * @param sync {boolean} The flag to set if the event is to be sent synchronously.
     */
    constructor(
        public name: string,
        public time: string,
        public data: { [key: string]: IOperationalEventContent | string },
        public ext?: IOneDsExtension,
        public latency?: number,
        public sync?: boolean) {
    }

    /**
     * Creates a new TracingEventData from a given log item.
     * @param logItem {ILogItem} The log item.
     * @param windowContext {IBrowserWindow} The window object of the browser.
     * @param application {string} The application name.
     * @param source {string} The source name.
     * @param impressionGuid {string} The impression guid.
     * @param tenant {string} The tenant name or id.
     * @param time {string} The date and time of the event in ISO format.
     * @param serverImpressionGuid {string} The server impression guid.
     * @param oneDsNamespaceProfile {OneDsNamespaceProfile} The namespace profile for the 1DS events.
     * @param latency {number} The latency corresponding to EventLatency.
     * @param sync {boolean} The flag to set if the event is to be sent synchronously.
     * @return {OneDsTracingEventData} The tracing event data.
     */
    public static createFrom(
        logItem: ILogItem,
        windowContext: IBrowserWindow,
        application: string,
        source: string,
        impressionGuid: string,
        tenant: string,
        time: string,
        serverImpressionGuid: string,
        oneDsNamespaceProfile: OneDsNamespaceProfile,
        latency?: number,
        sync?: boolean): OneDsTracingEventData {

        var basicData = EventBasicData.createFrom(logItem, windowContext);
        var operationalEventContent = OperationalEventContent.createFrom(logItem, basicData, application, source, impressionGuid, tenant, serverImpressionGuid);

        const eventData: { [key: string]: IOperationalEventContent | string } = {
            baseData: operationalEventContent,
            baseType: oneDsNamespaceProfile.clientTraceDataSchemaNamespace
        };

        const ext = {
            mscv: { cV: basicData.correlationVector },
            web: { isManual: true }
        };

        return new OneDsTracingEventData(oneDsNamespaceProfile.clientTraceSchemaNamespace, time, eventData, ext, latency || EventLatency.Normal, sync);
    }
}
