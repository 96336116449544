/**
 * Enum for tansmission profile for 1DS batched events.
 * @readonly
 * @enum {number}
 */
export enum OneDsTransmissionProfile {

    /**
     * Normal Latency events are sent every 2 sec and RealTime latency events are sent every 1 sec..
     */
    RealTime = "REAL_TIME",

    /**
     * Normal Latency events are sent every 6 sec and RealTime latency events are sent every 3 sec.
     */
    NearRealTime = "NEAR_REAL_TIME",

    /**
     * Normal Latency events are sent every 18 sec and RealTime latency events are sent every 9 sec.
     */
    BestEffort = "BEST_EFFORT",

    /**
     * Custom profile. The settings customTransmissionFrequencyForNoramlLatencyEvents and customTransmissionFrequencyForRealTimeLatencyEvents are required.
     */
    Custom = "CUSTOM"
}
