/**
* Utils.ts
* @author  Ram Thiru (ramthi@microsoft.com)
* @copyright Microsoft 2017
* File containing utility functions.
*/

import { isValueAssigned } from '@ms/1ds-core-js';
import { IQosConfiguration } from '../DataModel';

export function _removeNonObjectsAndInvalidElements(overrideConfig: IQosConfiguration, attributeNamesExpectedObjects: Array<string>) {
    /// <summary> Finds attributes in overrideConfig which are invalid or should be objects
    /// and deletes them. useful in override config</summary>
    /// <param type='object'>override config object</param>
    /// <param type=''> attributes that should be objects in override config object </param>

    _removeInvalidElements(overrideConfig);
    for (var i in attributeNamesExpectedObjects) {
        if (attributeNamesExpectedObjects.hasOwnProperty(i)) {
            var objectName = attributeNamesExpectedObjects[i];
            if (typeof overrideConfig[objectName] === 'object') {
                _removeInvalidElements(overrideConfig[objectName]);
            } else {
                delete overrideConfig[objectName];
            }
        }
    }
}

export function _removeInvalidElements(object: Object) {
    /// <summary> Finds attributes in object which are invalid 
    /// and deletes them. useful in override config </summary>
    /// <remarks> Because the config object 'callback' contains only functions, 
    /// when it is stringified it returns the empty object. This explains
    /// the workaround regarding 'callback' </remarks>
    /// <param type='object'>override config object</param>

    for (var property in object) {
        if (!isValueAssigned(object[property]) ||
            (JSON.stringify(object[property]) === '{}' && (property !== 'callback'))) {
            delete object[property];
        }
    }
}

export function _attachEvent(obj: any, eventNameWithoutOn: any, handlerRef: any) {
    var result = false;
    if (isValueAssigned(obj)) {
        if (isValueAssigned(obj.attachEvent)) {
            // IE before version 9                    
            obj.attachEvent('on' + eventNameWithoutOn, handlerRef);
            result = true;
        } else {
            if (isValueAssigned(obj.addEventListener)) {
                // all browsers except IE before version 9
                obj.addEventListener(eventNameWithoutOn, handlerRef, false);
                result = true;
            }
        }
    }
    return result;
}
