import { EventBasicData } from "../commonSchema/EventBasicData";
import { EventLatency } from "@ms/1ds-core-js";
import { IBrowserWindow } from "../../browser-object-models/IBrowserWindow";
import { IOutgoingServiceRequestEventContent } from "../commonSchema/IOutgoingServiceRequestEventContent";
import { IOneDsEventData } from "./IOneDsEventData";
import { IOneDsExtension } from "./IOneDsExtension";
import { NetworkActivityLogItem } from "../NetworkActivityLogItem";
import { OutgoingServiceRequestEventContent } from "../commonSchema/OutgoingServiceRequestEventContent";
import { OneDsNamespaceProfile } from "./OneDsNamespaceProfile";

/**
 * Represents the parameters of the 1DS outgoing service request (OSR) event data.
 * @class
 */
export class OneDsOutgoingServiceRequestEventData implements IOneDsEventData<IOutgoingServiceRequestEventContent> {

    /**
     * Initializes a new instance of the `OneDsOutgoingServiceRequestEventData` class.
     * @param name {string} The name(namespace) of the event.
     * @param time {string} The date and time of the event in ISO format.
     * @param data {[key: string]: IOutgoingServiceRequestEventContent | string} The event data contains OSR data and namepsace.
     * @param ext {IOneDsExtension} The extension fields.
     * @param latency {number} The latency corresponding to EventLatency.
     * @param sync {boolean} The flag to set if the event is to be sent synchronously.
     */
    constructor(
        public name: string,
        public time: string,
        public data: { [key: string]: IOutgoingServiceRequestEventContent | string },
        public ext?: IOneDsExtension,
        public latency?: number,
        public sync?: boolean) {
    }

    /**
     * Creates a new OneDsOutgoingServiceRequestEventData from a given log item.
     * @param logItem {ILogItem} The log item.
     * @param windowContext {IBrowserWindow} The window object of the browser.
     * @param application {string} The application name.
     * @param source {string} The source name.
     * @param impressionGuid {string} The impression guid.
     * @param tenant {string} The tenant name or id.
     * @param time {string} The date and time of the event in ISO format.
     * @param serverImpressionGuid {string} The server impression guid.
     * @param oneDsNamespaceProfile {OneDsNamespaceProfile} The namespace profile for the 1DS events.
     * @param latency {number} The latency corresponding to EventLatency.
     * @param sync {boolean} The flag to set if the event is to be sent synchronously.
     * @return {OneDsOutgoingServiceRequestEventData} The OSR event data.
     */
    public static createFrom(
        logItem: NetworkActivityLogItem,
        windowContext: IBrowserWindow,
        application: string,
        source: string,
        impressionGuid: string,
        tenant: string,
        time: string,
        serverImpressionGuid: string,
        oneDsNamespaceProfile: OneDsNamespaceProfile,
        latency?: number,
        sync?: boolean): OneDsOutgoingServiceRequestEventData {

        var basicData = EventBasicData.createFrom(logItem, windowContext);
        var osrEventContent = OutgoingServiceRequestEventContent.createFrom(logItem, basicData, application, source, impressionGuid, tenant, serverImpressionGuid);

        const eventData: { [key: string]: IOutgoingServiceRequestEventContent | string } = {
            baseData: osrEventContent,
            baseType: oneDsNamespaceProfile.clientOsrDataSchemaNamespace
        };

        const ext = {
            mscv: { cV: logItem.correlationVector },
            web: { isManual: true }
        };

        return new OneDsOutgoingServiceRequestEventData(oneDsNamespaceProfile.clientOsrSchemaNamespace, time, eventData, ext, latency || EventLatency.Normal, sync);
    }
}
