/**
* Loc.ts
* @author Hector Hernandez (hectorh)
* @copyright Microsoft 2019
*/

export class Loc {
    /**
     * 	Time zone on the device (ISO 8601).
     */
    public tz: string;

    constructor() {
        //Add time zone
        let timeZone = new Date().getTimezoneOffset();
        let minutes = timeZone % 60;
        let hours = (timeZone - minutes) / 60;
        let timeZonePrefix = '+';
        if (hours > 0) {
            timeZonePrefix = '-';
        }
        hours = Math.abs(hours);
        minutes = Math.abs(minutes);

        this.tz = timeZonePrefix + (hours < 10 ? '0' + hours : hours.toString()) + ':'
            + (minutes < 10 ? '0' + minutes : minutes.toString());

    }
}
