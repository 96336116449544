/**
 * Enum for Log Type
 * @readonly
 * @enum {number}
 */
export enum LogType {
    Default = 0,
    BeginActivity = 1001,
    EndActivity = 1002,
    Transfer = 1003,
    BeginSession = 1004,
    EndSession = 1005,
    BeginNetworkActivity = 1006,
    EndNetworkActivity = 1007,
    Instrumentation = 2001
}
