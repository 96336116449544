import * as axiosStatic from "axios";

import { AxiosWrapperBase } from "../axios-wrapper/AxiosWrapperBase";
import { ILoggingService } from "clientcore-infrastructure-analytics/ILoggingService";
import { IRetryManager } from "./IRetryManager";
import { LoggedExceptionManager } from "clientcore-infrastructure-analytics/LoggedExceptionManager";

/**
 * Wraps axios and adds retry logic for requests that result in transient errors.
 * @class
 */
export class AxiosRetryWrapper extends AxiosWrapperBase {

    /**
     * es6-promise service instance.
     * @type {Promise}
     */
    private promiseService: typeof Promise;

    /**
     * Retry manager for repeating requests that result in transient errors.
     * @type {IRetryManager<axiosStatic.Response>}
     */
    private retryManager: IRetryManager<axiosStatic.Response>;

    /**
     * Used for logging the exceptions.
     * @type {LoggedExceptionManager}
     */
    private loggedExceptionManager: LoggedExceptionManager;

    /**
     * Initializes a new instance of the AxiosRetryWrapper class.
     * @constructor
     * @param retryManager {IRetryManager<axiosStatic.Response>} Retry manager for repeating requests that result in transient errors.
     * @param loggingService {ILoggingService}
     * @param promiseService? {Promise} es6-promise service instance.
     * @param axios? {axiosStatic.AxiosStatic} Axios service instance.
     */
    constructor(
        retryManager: IRetryManager<axiosStatic.Response>,
        loggingService: ILoggingService,
        promiseService?: typeof Promise,
        axios?: axiosStatic.AxiosStatic) {

        if (!loggingService) {
            throw "loggingService must not be null.";
        }

        const loggedExceptionManager = new LoggedExceptionManager(loggingService);

        if (!retryManager) {
            loggedExceptionManager.fatal("retryManager must not be null.");
        }

        super(axios || axiosStatic);

        this.promiseService = promiseService || Promise;
        this.retryManager = retryManager;
        this.loggedExceptionManager = loggedExceptionManager;
    }

    /**
     * Overrides the sendRequest function to add retry logic.
     * @param config {axiosStatic.RequestOptions} The request details.
     * @returns {Promise<axiosStatic.Response>} The result of the call.
     */
    public sendRequest<T>(config: axiosStatic.RequestOptions): Promise<axiosStatic.Response> {

        if (!config) {
            this.loggedExceptionManager.fatal("config must not be null");
        }

        // Retry manager will retry any request if needed as configured.
        return this.retryManager.executeRequest(() => super.sendRequest(config), config);
    }
}