/**
* ApplicationInsights.ts
* @author Abhilash Panwar (abpanwar) Hector Hernandez (hectorh)
* @copyright Microsoft 2018
* Main class containing all the APIs.
*/
import { AppInsightsCore, IPlugin, LoggingSeverity, _ExtendedInternalMessageId } from '@ms/1ds-core-js';
import { PropertiesPlugin } from '@ms/1ds-properties-js';
import { PostChannel } from '@ms/1ds-post-js';
import { IExtendedConfiguration } from './DataModels';
export default class ApplicationInsights extends AppInsightsCore {
    private _postChannel: PostChannel;
    private _propertyManager: PropertiesPlugin;

    /**
     * Initialize the sdk.
     * @param {IExtendedConfiguration} config - The configuration to initialize the SDK.
     * @param {IPlugin[]} extensions          - An array of extensions that are to be used by the core.
     */
    initialize(config: IExtendedConfiguration, extensions?: IPlugin[]): void {
        this._postChannel = new PostChannel();
        this._propertyManager = new PropertiesPlugin();
        let plugins: IPlugin[] = [this._propertyManager];
        if (extensions) {
            plugins = plugins.concat(extensions);
        }
        if (config.channels && config.channels.length > 0) {
            // Add post channel to first fork if not available
            var postFound = false;
            for (var j = 0; j < config.channels[0].length; j++) {
                if (config.channels[0][j].identifier === this._postChannel.identifier) {
                    postFound = true;
                    break;
                }
            }
            if (!postFound) {
                config.channels[0].push(this._postChannel);
            }
        } else {
            config.channels = [[this._postChannel]];
        }
        config.extensionConfig = config.extensionConfig || [];
        config.extensionConfig[this._postChannel.identifier] = config ? config.channelConfiguration : {};
        config.extensionConfig[this._propertyManager.identifier] = config ? config.propertyConfiguration : {};

        try {
            super.initialize(config, plugins);
        } catch (error) {
            this.logger.throwInternal(
                LoggingSeverity.CRITICAL,
                _ExtendedInternalMessageId.FailedToInitializeSDK, "Failed to initialize SDK." + error
            )
        }
    }

    /**
     * Gets the property manager to set custom properties and system properties (part A), that should be applied
     * to all events or events with a specific instrumentation key.
     * @returns {PropertyManager} The property manager object.
     */
    getPropertyManager(): PropertiesPlugin {
        return this._propertyManager;
    }

    /**
     * Gets the post channel to configure and set the transmission profiles.
     * @returns {PostChannel} The post channel object.
     */
    getPostChannel(): PostChannel {
        return this._postChannel;
    }
}
