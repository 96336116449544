import { Guid } from "cms-infrastructure-javascriptextensions/Guid";

import { IActivityLogItem } from "./IActivityLogItem";
import { LogItem } from "./LogItem";
import { LogLevel } from "./LogLevel";
import { LogType } from "./LogType";

export class ActivityLogItem extends LogItem implements IActivityLogItem {

    /* Few property names are in UpperCamelCase (PascalCase) to keep them consistent with the existing webservice contract */

    /** 
     * The flag to indicate if the activity is completed or not.
     * @property 
     * @type {boolean}
     */
    // tslint:disable-next-line: ext-variable-name
    public Ended: boolean;

    /** 
     * The correlation vector of the latest child.
     * @property 
     * @type {boolean}
     */
    public latestChildCorrelationVector: string;

    /** 
     * The correlation vector.
     * @property 
     * @type {string}
     */
    public correlationVector?: string;

    /**
     * Initializes a new instance of the `ActivityLogItem` class.
     * @constructor
     * @param Name {string} The name of the activity.
     * @param activityLoggingService {IActivityLoggingService} The activity logging service.
     * @param parentActivityId {string} The activity Id of the parent activity.
     * @param data {any} Any additional JSON data.
     * @param correlationVector? {string} The correlation vector.
     */
    constructor(name: string, parentActivityId?: string, data?: any, correlationVector?: string) {
        super(name, data, LogType.BeginActivity, Guid.newGuid(), new Date(), parentActivityId);

        this.LogLevel = LogLevel.Activity;
        this.Ended = false;
        this.correlationVector = correlationVector;
    }
}