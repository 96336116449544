/**
 * Error types for ADAL Authentication.
 * @type
 */
export type AdalAuthenticationErrorType =

    /*
     * Authentication acquire token error.
     */
    "AdalAuthenticationAquireTokenError";

/**
 * Predefine AdalAuthenticationErrorType constants to match the type
 */
// supress the tslint rule for variable name. since this is a string enum, we want the const to be the same name as the type so that it behaves as a string enum when importing once, rather than import the type and const individually.
// tslint:disable-next-line: ext-variable-name
export const AdalAuthenticationErrorType = {
    AdalAuthenticationAquireTokenError: "AdalAuthenticationAquireTokenError" as AdalAuthenticationErrorType
};
