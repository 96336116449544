/**
 * Enum for 1DS environments.
 * @readonly
 * @enum {number}
 */
export enum OneDsEnvironment {
    Dev = 0,
    Int  = 1,
    Ppe  = 2,
    Prod = 3
}
