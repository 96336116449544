import { IBrowserWindow } from "../../browser-object-models/IBrowserWindow";
import { IEventBasicData } from "./IEventBasicData";
import { ILogItem } from "../ILogItem";
import { cloneDeep } from "lodash-es";

/**
 * Represents the basic parameters of the asimov events.
 * @class
 */
export class EventBasicData implements IEventBasicData {

    /**
     * The key for title.
     * @type {string}
     */
    private static TitleKey: string = "page-title-override";

    /**
     * The key for page uri.
     * @type {string}
     */
    private static PageUriKey: string = "page-uri-override";

    /**
     * The key for correlation vector field.
     * @type {string}
     */
    private static CorrelationVectorKey: string = "correlationVector";

    /**
     * Initializes a new instance of the `EventBasicData` class.
     * @param title {string} The page title.
     * @param pageUri {string} The page uri.
     * @param correlationVector {string} The correlation vector.
     * @param customData {any} The custom data.
     */
    constructor(
        public title: string,
        public pageUri: string,
        public correlationVector: string,
        public customData: any) {
    }

    /**
     * Creates a new EventBasicData from a given log item.
     * @param logItem {ILogItem} The log item.
     * @param windowContext? {IBrowserWindow} The window object of the browser.
     * @return {IEventBasicData} The basic event data.
     */
    public static createFrom(logItem: ILogItem, windowContext?: IBrowserWindow): IEventBasicData {

        var title = "";
        var pageUri = "";
        var correlationVector = "";
        var customData = {};

        var log = logItem ? cloneDeep(logItem) : null;

        if (log && log.Data) {
            title = log.Data[EventBasicData.TitleKey] ? log.Data[EventBasicData.TitleKey] : title;
            pageUri = log.Data[EventBasicData.PageUriKey] ? log.Data[EventBasicData.PageUriKey] : pageUri;
            correlationVector = log.Data[EventBasicData.CorrelationVectorKey] ? log.Data[EventBasicData.CorrelationVectorKey] : "";

            // Delete already populated values from the data to avoid duplication
            // TODO: This is a legacy code and the functionality provided here is to override page title and uri using extended data. As the library is at much advanced level now, override values can be passed as method parameters. Once new signatures are implemented, adding override values to extended data and deletion of values won't be required at all.
            delete log.Data[EventBasicData.TitleKey];
            delete log.Data[EventBasicData.PageUriKey];
            delete log.Data[EventBasicData.CorrelationVectorKey];

            customData = log.Data;
        }

        if (windowContext) {

            // if title is still empty
            if (!title && windowContext.document && windowContext.document.title) {
                title = windowContext.document.title;
            }

            // if page uri is still empty
            if (!pageUri && windowContext.location && windowContext.location.href) {
                pageUri = windowContext.location.href;
            }
        }

        return new EventBasicData(title, pageUri, correlationVector, customData);
    }
}
