/**
* RecordBatcher.ts
* @author Abhilash Panwar (abpanwar) Hector Hernandez (hectorh)
* @copyright Microsoft 2018
*/
import { IPostTransmissionTelemetryItem } from './DataModels';

/**
* Class to batch events.
*/
export default class RecordBatcher {
    private _currentBatch: { [token: string]: IPostTransmissionTelemetryItem[] } = {};
    private _currentNumEventsInBatch = 0;

    constructor(private _outboundQueue: { [token: string]: IPostTransmissionTelemetryItem[] }[], private _maxNumberOfEvents: number) {
    }

    /**
     * Add an event to the current batch.
     * If the event is synchronous, it will also return the batch containing only the synchronous event.
     * @param {IPostTransmissionTelemetryItem} event - The event that needs to be batched.
     * @return {{ [token: string]: IPostTransmissionTelemetryItem[] }} If the event is synchronous, it will also return the
     * batch containing only the synchronous event. Otherwise returns null.
     */
    addEventToBatch(event: IPostTransmissionTelemetryItem): { [token: string]: IPostTransmissionTelemetryItem[] } | null {
        let apiKey = event.iKey;
        if (apiKey) {
            if (event.sync) {
                //batch sync event into its own batch
                let syncBatch = {};
                syncBatch[apiKey] = [event];
                return syncBatch;
            } else {
                if (this._currentNumEventsInBatch >= this._maxNumberOfEvents) {
                    this.flushBatch();
                }
                if (this._currentBatch[apiKey] === undefined) {
                    this._currentBatch[apiKey] = [];
                }
                this._currentBatch[apiKey].push(event);
                this._currentNumEventsInBatch++;
            }
        }
        return null;
    }

    /**
     * Flush the current batch so that it is added to the outbound queue.
     */
    flushBatch() {
        if (this._currentNumEventsInBatch > 0) {
            this._outboundQueue.push(this._currentBatch);
            this._currentBatch = {};
            this._currentNumEventsInBatch = 0;
        }
    }

    /**
     * Check if there is a batch that contains events.
     */
    hasBatch() {
        return this._currentNumEventsInBatch > 0;
    }
}
