/**
* Constants.ts
* @author Ram Thiru (ramthi) and Hector Hernandez(hectorh)
* @copyright Microsoft 2018
*/
export const COMMON_SCHEMA_VER = '2.1';
export const MSCONTENT_PARTB_VERSION = '1.0';
export const CONTENT_VERSION = '2.0';
export const MAX_CONTENTNAME_LENGTH = 200;
export const IS_SENSITIVE_FLAG = 0x080000;
export const APPID_PREFIX = 'JS:';
