import { IAppender } from "../appenders/IAppender";
import { ILogger } from "./ILogger";
import { ILogItem } from "../models/ILogItem";
import { LogLevel } from "../models/LogLevel";

/**
 * Class to log the messages by sending messages to all registered appenders.
 * @class
 */
export class Logger implements ILogger {

    /**
     * Name of the logger.
     */
    private loggerName: string;

    /**
     * The log level (Threshold)
     */
    private logLevel: LogLevel;

    /**
     * The appenders.
     */
    private appenders: Array<IAppender>;

    /**
     * Initializes a new instance of the `Logger` class.
     * @constructor
     * @param loggerName {string} Name of the logger.
     * @param logLevel {LogLevel} The log level (Threshold).
     */
    constructor(loggerName: string, logLevel?: LogLevel) {
        this.loggerName = loggerName;

        // By default, keep the log level to Error
        this.logLevel = logLevel || LogLevel.Error;
        this.appenders = new Array<IAppender>();
    }

    /**
     * Gets the logger name.
     * @method
     * @returns {string} The logger name.
     */
    public getLoggerName(): string {
        return this.loggerName;
    }

    /**
     * Sets the log level.
     * @method
     * @param logLevel {LogLevel} Logging level or threshold.
     */
    public setLevel(logLevel: LogLevel): void {
        this.logLevel = logLevel;
    }

    /**
     * Gets the log level.
     * @method
     * @returns {LogLevel} The Logging level or threshold.
     */
    public getLevel(): LogLevel {
        return this.logLevel;
    }

    /**
     * Adds the given appender.
     * @method
     * @param appender {IAppender} Appender to be added.
     */
    public addAppender(appender: IAppender): void {
        if (appender) {
            this.appenders.push(appender);
        }
    }

    /**
     * Removes the given appender.
     * @method
     * @param appender {IAppender} Appender to be removed.
     * @returns {boolean} True, if the appender is removed. Otherwise, False.
     */
    public removeAppender(appender: IAppender): boolean {
        if (appender) {
            var index = this.appenders.indexOf(appender);
            if (index < 0) {
                return false;
            }

            this.appenders.splice(index, 1);
            return true;
        }

        return false;
    }

    /**
     * Clears all appenders for the current logger.
     * @method
     */
    public removeAllAppenders(): void {
        this.appenders = new Array<IAppender>();
    }

    /**
     * Evaluates if the logger is enabled for the given log level.
     * @method
     * @param logLevel {LogLevel} The Logging level or threshold.
     * @returns {boolean} True, if the logger is enabled for the level. Otherwise, False.
     */
    public isEnabledFor(logLevel: LogLevel): boolean {

        // If log level threshold is greater than the given log level then it is enabled.
        return this.logLevel >= logLevel;
    }

    /**
     * Logs the log item.
     * @method
     * @param logLevel {LogLevel} The logging level or threshold.
     * @param logItem {ILogItem} The log item.
     */
    public log(logLevel: LogLevel, logItem: ILogItem): void {
        if (this.isEnabledFor(logLevel)) {
            try {
                this.appenders.forEach((appender) => { appender.append(logItem); });
            } catch (error) {
                console.error(error);
            }
        }
    }

    /**
     * Flushes the log items explicitly if appenders are using batching.
     * @method
     */
    public flush(): void {
        this.appenders.forEach((appender) => { appender.flush(); });
    }
}
