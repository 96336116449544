import { IProxyMetadata } from "./IProxyMetadata";
import { IRpcServer } from "./IRpcServer";

/**
 * Allows registration of services on remote (server) side.
 * @class
 */
export class RpcServer implements IRpcServer {

    /**
     * The list of registered services.
     * @type {{ [serviceName: string]: any }}
     */
    private services: { [serviceName: string]: any };

    /**
     * Initializes a new instance of the RpcServer class.
     * @constructor
     */
    constructor() {
        this.services = {};
    }

    /**
     * Register the given service.
     * @param serviceName {string} The name of the service to register.
     * @param service {any} The service to register.
     */
    public registerService(serviceName: string, service: any): void {

        if (!serviceName) {
            throw "The serviceName parameter is required";
        }

        if (!service) {
            throw "The service parameter is required";
        }

        this.services[serviceName] = service;
    }

    /**
     * Call a service with the given values.
     * @param serviceName {string} The name of the service to call.
     * @param serviceMethod {string} The name of the service method to call.
     * @param parameters {any[]} The parameters to pass to the service method.
     * @returns {Promise<any>} The service result.
     */
    public callService(serviceName: string, serviceMethod: string, parameters: any[]): Promise<any> {

        if (!serviceName) {
            throw "The serviceName parameter is required";
        }

        if (!serviceMethod) {
            throw "The serviceMethod parameter is required";
        }

        if (!parameters) {
            throw "The parameters parameter is required";
        }

        // Provide a built in service to get metadata to generate proxies from.
        if (serviceName === "rpc.ServiceMetadata" && serviceMethod === "getProxyMetadata") {

            // Check that the service name was provided as a parameter.
            if (parameters.length === 0) {
                return Promise.reject(`A service name parameter is required for the rpc.ServiceMetadata.getProxyMetadata service.`);
            }

            // Check that the service we need to get metadata for has been registered.
            if (!this.services[parameters[0]]) {
                return Promise.reject(`No service with the name '${parameters[0]}' has been registered.`);
            }

            return Promise.resolve(this.getProxyMetadata(parameters[0]));
        }

        // Check that the service has been registered.
        if (!this.services[serviceName]) {
            return Promise.reject(`No service with the name '${serviceName}' has been registered.`);
        }

        // Check that the service method exists
        if (!this.services[serviceName][serviceMethod]) {
            return Promise.reject(`No method with name '${serviceMethod}' exists on service '${serviceName}'.`);
        }

        // Call through to the actual service
        return this.services[serviceName][serviceMethod].apply(this.services[serviceName], parameters);
    };

    /**
     * Get metadata for the requested service allowing the client to build a proxy for the service.
     * @param serviceName {string} The name of the service to get proxy metadata for.
     * @returns {IProxyMetadata} The service result.
     */
    private getProxyMetadata(serviceName: string): IProxyMetadata {

        let metadata: IProxyMetadata = {};

        let service = this.services[serviceName];
        for (var member in service) {
            if (service.hasOwnProperty(member)) {
                metadata[member] = typeof (service[member]);
            }
        }

        return metadata;
    }
}