import { IException } from "./IException";
import { IFinally } from "./IFinally";
import { ITryOrCatch } from "./ITryOrCatch";
import { CatchBlock } from "./CatchBlock";

/**
 * Implements logic for a fluid design try catch block with strong typing.
 * @class
 */
export class TryCatchFinallyBlock {

    /**
     * The try block.
     * @type {string}
     */
    private tryBlock: () => void;

    /**
     * The list of catch blocks.
     * @type {string}
     */
    private catchList: CatchBlock[];

    /**
     * The finally block.
     * @type {string}
     */
    private finallyBock: () => void;

    /**
     * Initializes a new instance of the `TryCatchBlock` class.
     * @constructor
     * @param {function} tryBlock The try block to execute.
     */
    constructor(tryBlock: () => void) {
        this.tryBlock = tryBlock;
        this.catchList = [];
    }

    /**
     * Adds a catch handler.
     * @method
     * @param {exceptionType} any The type of exception to catch.
     * @param {function} catchBlock The catch block to execute if the exception is caught.
     * @returns {ITryOrCatch} A fluid design object to add more steps.
     */
    public catch<TException extends IException>(exceptionType: any, catchBlock: (exception: TException) => void): ITryOrCatch {
        this.catchList.push(new CatchBlock(exceptionType, catchBlock));
        return this;
    }

    /**
     * Adds a finally handler.
     * @method
     * @param {exceptionType} any The type of exception to catch.
     * @param {function} finallyBlock The catch block to execute if the exception is caught.
     * @returns {IFinally} A fluid design object to add more steps.
     */
    public finally(finallyBlock: () => void): IFinally {
        this.finallyBock = finallyBlock;
        return this;
    }

    /**
     * Execute the whole fluid design try catch finally block.
     * @method
     */
    public execute(): void {
        try {
            this.tryBlock();
        } catch (e) {
            let handled = false;

            // Check each catch block in turn for a matching exception type.
            for (let i = 0; i < this.catchList.length; i++) {
                let catchBlock = this.catchList[i];
                if (e instanceof catchBlock.exceptionType) {
                    catchBlock.catchBlock(e);
                    handled = true;

                    // Stop executing catch blocks once we have matched one.
                    break;
                }
            }

            // Rethrow the exception if our exception type wasn't matched.
            if (!handled) {
                throw e;
            }
        } finally {
            if (this.finallyBock) {
                this.finallyBock();
            }
        }
    }
}
