/**
* timespan.ts
* @author Ram Thiru (ramthi)
* @copyright Microsoft 2018
*/

export default class Timespan {
    private _timers: any[];

    public constructor() {
        this._timers = [];
    }

    /**
     * Records the current time on a timer object identified by the counterName passed in
     * Returns a number > 0  that denotes the milliseconds elapsed between start and current time, if isComplete param is set to true.
     * @param {string} counterName - Unique name for the counter instance that needs to be operated on.
     * @isComplete {boolena} - indicates if the call is to complete a timer or start a new one.
     */
    public _recordTimeSpan(counterName: string, isComplete: boolean): number {
        var timestamp = new Date().getTime();

        if (!isComplete) {
            this._timers[counterName] = timestamp;
        } else {
            return timestamp - this._timers[counterName];
        }
    }
}
