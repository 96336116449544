/**
 * Stores an exception type and catch block combination.
 * @class
 */
export class CatchBlock {
    /**
     * Initializes a new instance of the `CatchBlock` class.
     * @constructor
     * @param {exceptionType} any The type of exception to catch.
     * @param {function} catchBlock The catch block to execute if the exception is caught.
     */
    constructor(public exceptionType: any, public catchBlock: (exception: any) => void) {
    }
}