import { BaseService } from "./BaseService";
import { IBaseTrackingData } from "./models/userActivityData/IBaseTrackingData";
import { IEventData } from "./models/userActivityData/IEventData";
import { ILogger } from "./loggers/ILogger";
import { IPageActionData } from "./models/userActivityData/IPageActionData";
import { IPageViewData } from "./models/userActivityData/IPageViewData";
import { IUserActivityLogItem } from "./models/IUserActivityLogItem";
import { IUserActivityTrackingService } from "./IUserActivityTrackingService";
import { Logger } from "./loggers/Logger";
import { LogLevel } from "./models/LogLevel";
import { UserActivityLogItem } from "./models/UserActivityLogItem";
import { UserActivityType } from "./models/UserActivityType";

/**
 * Class to track the user activity.
 * @class
 */
export class UserActivityTrackingService extends BaseService implements IUserActivityTrackingService {
    /**
     * Dictionary to the mapping of name to user activity tracking service
     */
    private static InstancesMap: { [name: string]: IUserActivityTrackingService; } = {};

    /**
     * Initializes a new instance of the `UserActivityTrackingService` class.
     * @constructor
     * @param logger {ILogger}The logger.
     */
    constructor(logger: ILogger) {
        super(logger);
    }

    /**
     * Gets the instance of the `ActivityLoggingService` class.
     * @method
     * @param loggerName {string} Name of the logger.
     * @return {IUserActivityTrackingService} The user activity tracking service.
     */
    public static getInstance(loggerName?: string): IUserActivityTrackingService {
        var name = loggerName || "Default";
        var instance = UserActivityTrackingService.InstancesMap[name];
        if (instance) {
            return instance;
        }

        var logger = new Logger(name, LogLevel.Activity);
        instance = new UserActivityTrackingService(logger);
        UserActivityTrackingService.InstancesMap[name] = instance;

        return instance;
    }

    /**
     * Returns whether the activity tracking is enabled or not.
     * @method
     * @return {boolean} True, if enabled. Otherwise, false.
     */
    public isActivityTrackingEnabled(): boolean {
        return this.logger.isEnabledFor(LogLevel.Activity);
    }

    /**
     * Tracks the page view.
     * @method
     * @param pageName {string} Name of the page.
     * @param correlationVector? {string} The correlation vector.
     * @param pageViewData? {IPageViewData} The data to be tracked for the page view.
     * @param extendedData? {any} Additional data values, if any.
     * @param title? {string} Title of the page. If not provided, library populates the value from browser window.
     * @param pageUri? {string} Uri of the page. If not provided, library populates the value from browser window.
     */
    public logPageView(pageName: string, correlationVector?: string, pageViewData?: IPageViewData, extendedData?: any, title?: string, pageUri?: string): void {

        if (this.isActivityTrackingEnabled()) {
            this.logger.log(LogLevel.Activity, this.getUserActivityLogItem(pageName, correlationVector, pageViewData, title, pageUri, extendedData));
        }
    }

    /**
     * Tracks the page actions and events.
     * @method
     * @param eventData {IEventData} The data related to page action or event.
     * @param pageName {string} Name of the page.
     * @param correlationVector? {string} The correlation vector.
     * @param pageActionData? {IPageActionData} The data to be tracked for the page action/ event.
     * @param extendedData? {any} Additional data values, if any.
     * @param title? {string} Title of the page. If not provided, library populates the value from browser window.
     * @param pageUri? {string} Uri of the page. If not provided, library populates the value from browser window.
     */
    public logPageActionEvent(eventData: IEventData, pageName: string, correlationVector?: string, pageActionData?: IPageActionData, extendedData?: any, title?: string, pageUri?: string): void {

        if (this.isActivityTrackingEnabled()) {

            if (!eventData) {
                throw "''eventData' required for logging page action event.";
            }

            if (eventData.elementTitle && eventData.eventType && eventData.elementTitle.trim() && eventData.eventType.trim()) {
                this.logger.log(LogLevel.Activity, this.getUserActivityLogItem(pageName, correlationVector, pageActionData, title, pageUri, extendedData, eventData));
            }
        }
    }

    /**
     * Creates user activity log item.
     * @method
     * @param pageName {string} Name of the page.
     * @param correlationVector? {string} The correlation vector.
     * @param trackingData? {IBaseTrackingData} The page view or page action tracking data.
     * @param title? {string} Title of the page.
     * @param pageUri? {string} Uri of the page.
     * @param extendedData? {any} Additional data values, if any.
     * @param eventData? {string} The event data.
     * @returns {IUserActivityLogItem} The user activity log item.
     */
    private getUserActivityLogItem(pageName: string, correlationVector?: string, trackingData?: IBaseTrackingData, title?: string, pageUri?: string, extendedData?: any, eventData?: IEventData): IUserActivityLogItem {

        if (!pageName) {
            throw "''pageName' is required for logging user activity.";
        }

        var logItem;
        if (eventData) {
            logItem = new UserActivityLogItem(pageName, UserActivityType.PageAction, correlationVector, trackingData, title, pageUri, extendedData, eventData);
        } else {
            logItem = new UserActivityLogItem(pageName, UserActivityType.PageView, correlationVector, trackingData, title, pageUri, extendedData);
        }

        return logItem;
    }
} 