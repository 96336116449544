/**
* pageView.ts
* @author Ram Thiru (ramthi) and Hector Hernandez (hectorh)
* @copyright Microsoft 2018
*/

import { isValueAssigned } from '@ms/1ds-core-js';
import * as DataCollector from '../DataCollector';
import { IPageViewOverrideValues } from '../interfaces/IOverrides';
import { IPageViewTelemetry, IPageViewProperties } from '../interfaces/ITelemetryEvents';
import { WebEvent } from './WebEvent';

export class PageView extends WebEvent {

    /**
      * API to populate and send a PageView event
      * @param {IPageViewOverrideValues} overrideValues - PageView overrides
      * @param {{ [name: string]: string | number | boolean | string[] | number[] | boolean[] }} customProperties - Custom properties(Part C)
      */
    public capturePageView(overrideValues?: IPageViewOverrideValues, customProperties?: { [name: string]: string | number | boolean | string[] | number[] | boolean[] | object }): void {
        overrideValues = !isValueAssigned(overrideValues) ? {} : overrideValues;
        var pageViewEvent: IPageViewTelemetry = {};
        var properties: IPageViewProperties = isValueAssigned(customProperties) ? customProperties : {};
        this._setCommonProperties(pageViewEvent, properties, overrideValues);
        // Add pageView fields
        pageViewEvent.refUri = isValueAssigned(overrideValues.referrerUri) ? overrideValues.referrerUri : this._config.coreData.referrerUri;
        pageViewEvent.isManual = !overrideValues.isAuto;
        let cookiesValue = DataCollector._getClientCookies(this._config, this._traceLogger);
        if (cookiesValue) {
            properties.cookies = cookiesValue;
        }
        properties.behavior = this._getBehavior(overrideValues);
        this._webAnalyticsPlugin.trackPageView(pageViewEvent, properties);
    }
}
