/**
* PropertiesPlugin.ts
* @author Abhilash Panwar (abpanwar) Hector Hernandez (hectorh)
* @copyright Microsoft 2018
*/
import {
    ITelemetryPlugin, IPlugin, IEventProperty,
    IExtendedConfiguration, IAppInsightsCore, ITelemetryItem, Utils
} from '@ms/1ds-core-js';
import { TelemetryContext } from './TelemetryContext';
import { IPropertyConfiguration } from './DataModels';
import { Extensions } from './Extensions';

export default class PropertiesPlugin implements ITelemetryPlugin {
    public identifier = 'SystemPropertiesCollector';
    public priority = 3;
    public version = '2.1.1';
    private context: TelemetryContext;
    private _nextPlugin: ITelemetryPlugin;
    private _config: IPropertyConfiguration;
    private _properties:
        { [name: string]: string | number | boolean | string[] | number[] | boolean[] | IEventProperty } = {};

    initialize(coreConfig: IExtendedConfiguration, core: IAppInsightsCore, extensions: IPlugin[]) {
        let extConfig = coreConfig.extensionConfig = coreConfig.extensionConfig || [];
        this._config = extConfig[this.identifier] || {};
        extConfig[this.identifier] = this._config;
        this.context = new TelemetryContext(coreConfig, this._config, core);
    }

    /**
     * Process the event and add part A fields to it.
     * @param {object} event - The event that needs to be stored.
     */
    processTelemetry(event: ITelemetryItem) {
        let evtExt = event.ext = event.ext ? event.ext : {};
        event.data = event.data ? event.data : {};
        evtExt[Extensions.AppExt] = evtExt[Extensions.AppExt] || {};
        evtExt[Extensions.UserExt] = evtExt[Extensions.UserExt] || {};
        evtExt[Extensions.WebExt] = evtExt[Extensions.WebExt] || {};
        evtExt[Extensions.OSExt] = evtExt[Extensions.OSExt] || {};
        evtExt[Extensions.SdkExt] = evtExt[Extensions.SdkExt] || {};
        evtExt[Extensions.IntWebExt] = evtExt[Extensions.IntWebExt] || {};
        evtExt[Extensions.UtcExt] = evtExt[Extensions.UtcExt] || {};
        evtExt[Extensions.LocExt] = evtExt[Extensions.LocExt] || {};
        evtExt[Extensions.DeviceExt] = evtExt[Extensions.DeviceExt] || {};
        evtExt[Extensions.TraceExt] = evtExt[Extensions.TraceExt] || {};

        var context = this.context;
        context.applyApplicationContext(event);
        context.applyUserContext(event);
        context.applyWebContext(event);
        context.applyOsContext(event);
        context.applySdkContext(event);
        context.applyIntWebContext(event);
        context.applyUtcContext(event);
        context.applyLocContext(event);
        context.applySessionContext(event);
        context.applyDeviceContext(event);
        context.applyAITraceContext(event);

        //Delete empty ext fields
        Utils.arrForEach(Utils.objKeys(evtExt), (key) => {
            if (Utils.objKeys(evtExt[key]).length === 0) {
                delete evtExt[key];
            }
        })

        //Add custom properties
        this._addPropertiesIfAbsent(this._properties, event.data);
        if (this._nextPlugin) {
            this._nextPlugin.processTelemetry(event);
        }
    }

    /**
     * Sets the plugin that comes after the current plugin. It is upto the current plugin to call its next plugin when any
     * method on it is called.
     * @param {object} plugin - The next plugin.
     */
    setNextPlugin(plugin: ITelemetryPlugin) {
        this._nextPlugin = plugin;
    }

    /**
     * Get properties context to override or specify specific part A properties
     */
    getPropertiesContext(): TelemetryContext {
        return this.context;
    }

    /**
     * Sets a custom property to be sent with every event. IEventProperty can be used to tag the property as
     * pii or customer content.
     * @param {string} name                                                            - The name of the property.
     * @param {string|number|boolean|string[]|number[]|boolean[]|IEventProperty} value - The context property's value.
     */
    setProperty(name: string, value: string | number | boolean | string[] | number[] | boolean[] | IEventProperty) {
        this._properties[name] = value;
    }

    private _addPropertiesIfAbsent(inputMap: { [name: string]: any }, outputMap: { [name: string]: any }) {
        if (inputMap) {
            for (let name in inputMap) {
                if (inputMap.hasOwnProperty(name)) {
                    if (!outputMap[name]) {
                        outputMap[name] = inputMap[name];
                    }
                }
            }
        }
    }
}
