/**
* AuthPlugin.ts
* @author Hector Hernandez (hectorh)
* @copyright Microsoft 2019
*/
import {
    ITelemetryPlugin, IPlugin,
    IExtendedConfiguration, IAppInsightsCore, ITelemetryItem, Utils, IExtendedAppInsightsCore,
    LoggingSeverity, _ExtendedInternalMessageId, IDiagnosticLogger, addPageUnloadEventListener
} from '@ms/1ds-core-js';
import { IAuthenticationConfiguration } from './DataModels';
import { WebAuthHandler } from './WebAuthHandler'
import { AuthType } from './Enums';

const bouncedAuthCookieName: string = 'authBounced';
const aadloginUrl: string = 'https://login.microsoftonline.com';
const defaultHandShakeTimeoutMS = 5000;

export class AuthPlugin implements ITelemetryPlugin {
    public identifier = 'AuthPlugin';
    public priority = 190;
    public version = '2.1.1';
    private _nextPlugin: ITelemetryPlugin;
    private _authHandler: WebAuthHandler;
    private _handshakeInProgress: boolean;
    private _authHandhshakeTimeout: any;
    private _authHandhshakeTimeoutMs: number;
    private _eventQueue: Array<ITelemetryItem> = [];
    private _config: IAuthenticationConfiguration;
    private _core: IExtendedAppInsightsCore;
    private _logger: IDiagnosticLogger;

    initialize(coreConfig: IExtendedConfiguration, core: IAppInsightsCore, extensions: IPlugin[]) {
        coreConfig.extensionConfig = coreConfig.extensionConfig || [];
        this._config = coreConfig.extensionConfig[this.identifier] || {};
        coreConfig.extensionConfig[this.identifier] = this._config;
        this._handshakeInProgress = false;
        this._authHandhshakeTimeoutMs = this._config.handShakeTimeoutMs ? this._config.handShakeTimeoutMs : defaultHandShakeTimeoutMS;
        //Override endpointUrl if provided in config
        let endpointUrl = this._config.overrideAuthEndpointUrl ? this._config.overrideAuthEndpointUrl : coreConfig.endpointUrl;
        this._core = <IExtendedAppInsightsCore>core;
        this._logger = core.logger;
        this._authHandler = new WebAuthHandler(endpointUrl, this._config.authType, this._logger);
        var existingGetWParamMethod = this._core.getWParam;
        this._core.getWParam = () => {
            var wparam = 0;
            wparam = this._config.authType === AuthType.MSA ? wparam | 8 : wparam | 16;
            return wparam | existingGetWParamMethod();
        };

        // If page is closed release queue
        addPageUnloadEventListener(() => { this._releaseEventQueue(); });

        // Add listener to receive messages from Collector to determine when auth handshake is done
        // This message will be sent from iframe to parent window
        if (typeof window !== 'undefined') {
            if (window.addEventListener) {
                window.addEventListener("message", (msg) => { this._receiveMessage(msg, endpointUrl); });
            }
            else if ((<any>window).attachEvent) {
                (<any>window).attachEvent("onmessage", (msg) => { this._receiveMessage(msg, endpointUrl); });
            }
        }
    }

    /**
     * Process the event and add it to an internal queue if handshake in process
     * @param {object} event - The event to process
     */
    processTelemetry(event: ITelemetryItem) {
        // User is logged in
        if (this._config.loggedInStatusCallback() == true) {
            // Check for bounced cookie
            let authBounced = Utils.getCookie(bouncedAuthCookieName);
            if (!authBounced) {
                // Create bounced cookie to avoid multiple handshake processes
                const halfHour = 0.5 / 24;
                Utils.setCookie(bouncedAuthCookieName, Utils.toISOString(new Date()), halfHour);
                // Start hand shake process
                this._handshakeInProgress = true;
                // Timeout handshake if it takes more than 5 seconds
                this._authHandhshakeTimeout = setTimeout(() => { this._completeAuthHandshake(); }, this._authHandhshakeTimeoutMs);
                this._authHandler.startAuthHandshake();
            }
        }

        if (this._handshakeInProgress) {
            this._eventQueue.push(event);
        } else {
            if (this._nextPlugin) {
                this._nextPlugin.processTelemetry(event);
            }
        }
    }

    /**
     * Sets the plugin that comes after the current plugin. It is upto the current plugin to call its next plugin when any
     * method on it is called.
     * @param {object} plugin - The next plugin.
     */
    setNextPlugin(plugin: ITelemetryPlugin) {
        this._nextPlugin = plugin;
    }

    /**
    * Clear 1DS authentication cookies
    */
    signOut() {
        this._authHandler.signOutAndClearCookies();
    }

    /**
     * Attaches to message, to receive responses from Collector.
     */
    private _receiveMessage(event: any, endpointUrl: string) {
        let a = document.createElement("a");
        a.href = endpointUrl;
        let endpointOrigin = a.protocol + "//" + a.hostname;
        // login.microsoftonline.com is workaround for IE10 where the origin is the iframe of origin not origin of request
        if (event.origin == endpointOrigin || event.origin == aadloginUrl) {
            if (event.data) {
                try {
                    this._completeAuthHandshake();
                }
                catch (e) {
                    this._logger.throwInternal(
                        LoggingSeverity.CRITICAL,
                        _ExtendedInternalMessageId.AuthRedirectFail, "Error receiving auth redirect message: " + e
                    )
                }
            }
        }
    }

    /**
    * Complete auth handhshake, cleanup and release the event queue
    */
    private _completeAuthHandshake() {
        clearTimeout(this._authHandhshakeTimeout);
        // Remove auth iFrame if present
        this._authHandler.cleanAuthIFrame();
        this._handshakeInProgress = false
        this._releaseEventQueue();
    }

    /**
    * Release internal event queue
    */
    private _releaseEventQueue() {
        if (this._nextPlugin) {
            Utils.arrForEach(this._eventQueue, event => {
                this._nextPlugin.processTelemetry(event);
            });
        }
        this._eventQueue = [];
    }
}

