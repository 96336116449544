/**
* Utc.ts
* @author Hector Hernandez (hectorh)
* @copyright Microsoft 2019
*/

export class Utc {
    /**
     * 	The effective sample rate for this event at the time it was generated by a client (either the default value specified 
     * in the event definition, the system default or a dynamic override from the Settings Service). The valid range is from 
     * a minimum value of one out of every 100 million devices which is "0.000001", all the way up to all devices which is 
     * "100". If this field does not exist then you should assume its value is 100.
     */
    public popSample: number;

    constructor() {
        this.popSample = 100;
    }
}
