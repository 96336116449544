import { ActivityLoggingService } from "./ActivityLoggingService";
import { IActivityLoggingService } from "./IActivityLoggingService";
import { IInstrumentationService } from "./IInstrumentationService";
import { ILoggingService } from "./ILoggingService";
import { IPerfCounterLoggingService } from "./IPerfCounterLoggingService";
import { IUserActivityTrackingService } from "./IUserActivityTrackingService";
import { LoggingService } from "./LoggingService";
import { PerfCounterLoggingService } from "./PerfCounterLoggingService";
import { UserActivityTrackingService } from "./UserActivityTrackingService";

/**
 * Class to aggregate multiple instrumentation objects.
 * @class
 */
export class InstrumentationService implements IInstrumentationService {

    /** 
     * The logging service.
     * @property 
     * @type {ILoggingService}
     */
    private loggingServiceInstance: ILoggingService;

    /** 
     * The activity logging service.
     * @property 
     * @type {IActivityLoggingService}
     */
    private activityServiceInstance: IActivityLoggingService;

    /** 
     * The user activity tracking service.
     * @property 
     * @type {IUserActivityTrackingService}
     */
    private userActivityServiceInstance: IUserActivityTrackingService;

    /** 
     * The perf counter logging service.
     * @property 
     * @type {IPerfCounterLoggingService}
     */
    private perfCounterServiceInstance: IPerfCounterLoggingService;

    /**
     * Initializes a new instance of the `InstrumentationService` class.
     * @constructor
     * @param loggingService? {ILoggingService} The logging service.
     * @param activityLoggingService? {IActivityLoggingService} The activity logging service.
     * @param userActivityTrackingService? {IUserActivityTrackingService} The user activity tracking service.
     * @param perfCounterLoggingService? {IPerfCounterLoggingService} The perf counter logging service.
     */
    constructor(loggingService?: ILoggingService, activityLoggingService?: IActivityLoggingService, userActivityTrackingService?: IUserActivityTrackingService, perfCounterLoggingService?: IPerfCounterLoggingService) {

        this.loggingServiceInstance = loggingService || LoggingService.getInstance();
        this.activityServiceInstance = activityLoggingService || ActivityLoggingService.getInstance();
        this.userActivityServiceInstance = userActivityTrackingService || UserActivityTrackingService.getInstance();
        this.perfCounterServiceInstance = perfCounterLoggingService || PerfCounterLoggingService.getInstance();
    }

    /** 
     * The logging service.
     * @property 
     * @type {ILoggingService}
     */
    get loggingService(): ILoggingService {
        return this.loggingServiceInstance;
    }

    /** 
     * The activity logging service.
     * @property 
     * @type {IActivityLoggingService}
     */
    get activityLoggingService(): IActivityLoggingService {
        return this.activityServiceInstance;
    }

    /** 
     * The user activity tracking service.
     * @property 
     * @type {IUserActivityTrackingService}
     */
    get userActivityTrackingService(): IUserActivityTrackingService {
        return this.userActivityServiceInstance;
    }

    /** 
     * The perf counter logging service.
     * @property 
     * @type {IPerfCounterLoggingService}
     */
    get perfCounterLoggingService(): IPerfCounterLoggingService {
        return this.perfCounterServiceInstance;
    }

    /**
     * Gets the logging service instance by logger name.
     * @method 
     * @param loggerName {string} The logger name.
     * @return {ILoggingService} The logging service instance. Returns null if no instance with the name exists.
     */
    public getLoggingServiceByName(loggerName: string): ILoggingService {

        this.checkLoggerName(loggerName);
        return LoggingService.getInstance(loggerName);
    }

    /**
     * Gets the activity logging service instance by logger name.
     * @method 
     * @param loggerName {string} The logger name.
     * @return {IActivityLoggingService} The activity logging service instance. Returns null if no instance with the name exists.
     */
    public getActivityLoggingServiceByName(loggerName: string): IActivityLoggingService {

        this.checkLoggerName(loggerName);
        return ActivityLoggingService.getInstance(loggerName);
    }

    /**
     * Gets the user activity tracking service instance by logger name.
     * @method 
     * @param loggerName {string} The logger name.
     * @return {IUserActivityTrackingService} The user activity tracking service instance. Returns null if no instance with the name exists.
     */
    public getUserActivityTrackingServiceByName(loggerName: string): IUserActivityTrackingService {

        this.checkLoggerName(loggerName);
        return UserActivityTrackingService.getInstance(loggerName);
    }

    /**
     * Gets the performance counter service instance by logger name.
     * @method 
     * @param loggerName {string} The logger name.
     * @return {IPerfCounterLoggingService} The performance counter service instance. Returns null if no instance with the name exists.
     */
    public getPerfCounterLoggingServiceByName(loggerName: string): IPerfCounterLoggingService {

        this.checkLoggerName(loggerName);
        return PerfCounterLoggingService.getInstance(loggerName);
    }

    /**
     * Adds an instance of the activity logging service to the dictionary.
     * @method 
     * @param serviceInstance {IActivityLoggingService} The service instance to be added.
     */
    public addActivityLoggingService(serviceInstance: IActivityLoggingService): void {

        this.checkServiceInstance(serviceInstance);
        ActivityLoggingService.getInstance(serviceInstance.getLoggerName());
    }

    /**
     * Checks logger name for null or empty value.
     * @method 
     * @param loggerName {string} The logger name.
     */
    private checkLoggerName(loggerName: string): void {

        if (!loggerName) {
            throw "'loggerName' is required.";
        }
    }

    /**
     * Checks the service instance value for null.
     * @method 
     * @param serviceInstance {ILoggingService | IActivityLoggingService | IUserActivityTrackingService | IPerfCounterLoggingService} The service instance to be checked.
     */
    private checkServiceInstance(serviceInstance: ILoggingService | IActivityLoggingService | IUserActivityTrackingService | IPerfCounterLoggingService): void {

        if (!serviceInstance) {
            throw "'serviceInstance' is required.";
        }
    }
} 