import { Guid } from "cms-infrastructure-javascriptextensions/Guid";

import { LogItem } from "./LogItem";
import { LogType } from "./LogType";
import { LogLevel } from "./LogLevel";

/**
 * Represents a log item for a network activity.
 * @class
 */
export class NetworkActivityLogItem extends LogItem {

    /** 
     * The flag to indicate if the activity is completed or not.
     * @property 
     * @type {boolean}
     */
    // tslint:disable-next-line: ext-variable-name
    public Ended: boolean;

    /**
     * The time it took for the network activity to execute, in ms.
     * @property
     * @type {number}
     */
    public elapsedTimeInMs: number;

    /**
     * If the network request was successful or not.
     * @property
     * @type {boolean}
     */
    public successful: boolean;

    /**
     * The resulting HTTP status code of the network request.
     * @property
     * @type {number}
     */
    public responseStatusCode: number;

    /**
     * The error message from the network request, if any.
     * @property
     * @type {string}
     */
    public errorMessage: string;

    /**
     * The size of the response.
     * @property
     * @type {number}
     */
    public responseSize: number;

    /** 
     * The correlation vector.
     * @property 
     * @type {string}
     */
    public correlationVector?: string;

    /** 
     * The URI for the request.
     * @property 
     * @type {string}
     */
    public requestUri?: string;

    /** 
     * The HTTP method of the request.
     * @property 
     * @type {string}
     */
    public requestHttpMethod?: string;

    /** 
     * The name of the current operation.
     * @property 
     * @type {string}
     */
    public currentOperationName?: string;

    /**
     * Initializes a new instance of the `NetworkActivityLogItem` class.
     * @constructor
     * @param name {string}  The name of the activity.
     * @param serviceName {string} The name of the service being called.
     * @param requestUri? {string} The URI for the request.
     * @param requestHttpMethod? {string} The HTTP method of the request.
     * @param currentOperationName? {string} The name of the current operation.
     * @param correlationVector? {string} The correlation vector.
     * @param parentActivityId? {string} Parent activity id.
     * @param data {any} Any additional JSON data.
     */
    constructor(
        name: string,
        public serviceName: string,
        requestUri?: string,
        requestHttpMethod?: string,
        currentOperationName?: string,
        correlationVector?: string,
        parentActivityId?: string,
        data?: any) {

        super(name, data, LogType.BeginNetworkActivity, Guid.newGuid(), new Date(), parentActivityId);

        this.LogLevel = LogLevel.Activity;
        this.Ended = false;
        this.requestUri = requestUri;
        this.requestHttpMethod = requestHttpMethod;
        this.currentOperationName = currentOperationName;
        this.correlationVector = correlationVector;
    }
}