/**
* Cv.ts
* @author Ram Thiru (ramthi) and Hector Hernandez (hectorh)
* @copyright Microsoft 2018
*/

import { IAppInsightsCore, IDiagnosticLogger, LoggingSeverity, _ExtendedInternalMessageId, DiagnosticLogger } from '@ms/1ds-core-js';

class CvConstant {
    public baseLength: number;
    public maxCorrelationVectorLength: number;
    public validationPattern: RegExp;
}

export class Cv {

    private _base = '';
    private _currentElement = 0;
    private _traceLogger: IDiagnosticLogger;
    private _relatedCv: string;
    private _base64CharSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

    // cv version-dependent constants
    private _cv1Constants: CvConstant = new CvConstant();
    private _cv2Constants: CvConstant = new CvConstant();
    private _currentCvConstants: CvConstant;
    private _cvVersionAtLatestValidityCheck = 2;

    public constructor(initValue?: string, core?: IAppInsightsCore) {
        this._traceLogger = core ? core.logger : new DiagnosticLogger();
        this._cv1Constants.maxCorrelationVectorLength = 63;
        this._cv1Constants.baseLength = 16;
        this._cv1Constants.validationPattern = new RegExp('^[' + this._base64CharSet + ']{' + this._cv1Constants.baseLength.toString() + '}(.[0-9]+)*$');

        this._cv2Constants.maxCorrelationVectorLength = 127;
        this._cv2Constants.baseLength = 22;
        this._cv2Constants.validationPattern = new RegExp('^[' + this._base64CharSet + ']{' + this._cv2Constants.baseLength.toString() + '}(.[0-9]+)*$');

        this._currentCvConstants = this._cv2Constants;
        this._init(initValue);
    }

    public isInit(): boolean {
        return this._isValid(this.storedCv());
    }

    public storedCv(): string {
        /// <summary> creates the cv element from stored variables </summary>
        /// <returns> cV element in string format </returns>
        return this._base.concat('.', this._currentElement.toString());
    }

    public getValue(): string {
        /// <summary>
        /// Privileged method to serialize the current value of the Correlation Vector.
        /// CV should not be read from meta tag, but this is a breaking change.
        /// </summary>
        /// <returns type='string'>Serialized value of the Correlation Vector</returns>
        var value = this.storedCv();
        if (this._isValid(value)) {
            return value;
        }
    }

    public getRelatedCv(): string {
        return this._relatedCv;
    }

    public incrementExternal(externalCv: string) {
        /// <summary> this method verifies and increments external cVs </summary>
        /// <param name='externalCv' type='string'> the cv to be incremented </param>
        /// <returns type='string'> the incremented cv if the incrementation was succesful </returns>
        if (this._isValid(externalCv)) {
            // decompose external cV
            var externalCvParts = externalCv.split('.');
            var numberOfCvParts = externalCvParts.length;

            // increment last part of cV
            externalCvParts[numberOfCvParts - 1] = (parseInt(externalCvParts[numberOfCvParts - 1], 10) + 1).toString();

            // recombine cV
            var incrementedCv = '';
            for (var i = 0; i < numberOfCvParts; i++) {
                incrementedCv += (externalCvParts[i]);
                if (i < (numberOfCvParts - 1)) {
                    incrementedCv += '.';
                }
            }

            // to support cv 1 & 2
            var maxLength = (externalCvParts[0].length === this._cv2Constants.baseLength) ?
                this._cv2Constants.maxCorrelationVectorLength : this._cv1Constants.maxCorrelationVectorLength;

            if (incrementedCv.length <= maxLength) {
                return incrementedCv;
            }
        }
    }

    public canExtend() {
        /// <summary>
        /// Private method to check if the Correlation Vector can be extended
        /// </summary>
        /// <param name='ref' type='reference'>Object reference to the Correlation Vector</param>
        /// <returns type='boolean'>True if the Correlation Vector can be extended, false otherwise.</returns>
        var currentCv = this.storedCv();
        if (this._isValid(currentCv)) {
            return this._isLeqThanMaxCorrelationVectorLength(currentCv.length + 2);
        }
        return false;
    }

    public canIncrement() {
        /// <summary>
        /// Private method to check if the Correlation Vector can be incremented
        /// </summary>
        /// <returns type='boolean'>True if the Correlation Vector can be incremented, false otherwise.</returns>
        if (this._isValid(this.storedCv())) {
            return this._isLeqThanMaxCorrelationVectorLength(this._base.length + 1 + ((this._currentElement + 1) + '').length);
        }
        return false;
    }

    /**
    * @ Sets the base for the cv object
    * @ If the passed in value is a cv itself Base and digits, then the passed in value 
    * @ is stored in RelatedCV and a new CV is seeded.
    * @param {string} _base   - The base value of the cv.
    */
    public setValue(_base: string) {
        if (this._isValid(_base)) {
            var lastIndex = _base.lastIndexOf('.');
            if (lastIndex > 1) {
                this._relatedCv = _base;
                this._init();
            } else {
                this._base = _base;
                this._currentElement = 0;
            }
            this.extend();
            return true;
        } else {
            this._traceLogger.throwInternal(
                LoggingSeverity.WARNING,
                _ExtendedInternalMessageId.InvalidCorrelationValue, "Cannot set invalid correlation vector value"
            )
            return false;
        }
    }

    public seed() {
        var result = '';

        for (var i = 0; i < this._currentCvConstants.baseLength; i++) {
            result += this._base64CharSet.charAt(Math.floor(Math.random() * this._base64CharSet.length));
        }

        return result;
    }

    public extend(): string {
        if (this.canExtend()) {
            this._base = this._base.concat('.', this._currentElement.toString());
            this._currentElement = 0;
            return this.storedCv();
        }
    }

    public increment() {

        if (this.canIncrement()) {
            this._currentElement = this._currentElement + 1;
            return this.storedCv();
        }
    }

    public validateWithCv1(_cv: string) {
        if (this._cv1Constants.validationPattern.test(_cv) && _cv.length <= this._cv1Constants.maxCorrelationVectorLength) {
            return true;
        }
    }

    public validateWithCv2(_cv: string) {
        if (this._cv2Constants.validationPattern.test(_cv) && _cv.length <= this._cv2Constants.maxCorrelationVectorLength) {
            return true;
        }
    }

    public useCv1() {
        this._currentCvConstants = this._cv1Constants;
    }

    public useCv2() {
        this._currentCvConstants = this._cv2Constants;
    }

    private _init(cvInitValue?: string) {
        if (cvInitValue) {
            return this.setValue(cvInitValue);
        } else {
            this._base = this.seed();
            this._currentElement = 0;
            return this.getValue();
        }
    }

    private _isLeqThanMaxCorrelationVectorLength(length: number) {
        if (this._cvVersionAtLatestValidityCheck === 1) {
            return length <= this._cv1Constants.maxCorrelationVectorLength;
        } else {
            return length <= this._cv2Constants.maxCorrelationVectorLength;
        }
    }

    private _isValid(_cv: string): boolean {
        if (_cv) {
            var baseValue = _cv.split('.')[0];

            if (baseValue) {
                if (baseValue.length === 16) {
                    this._cvVersionAtLatestValidityCheck = 1;
                    return this.validateWithCv1(_cv);
                } else if (baseValue.length === 22) {
                    this._cvVersionAtLatestValidityCheck = 2;
                    return this.validateWithCv2(_cv);
                }
            }
        }
    }
}
