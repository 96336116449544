/**
 * The namespace profile for the 1DS events.
 * @class
 */
export class OneDsNamespaceProfile {

    /**
     * The base name.
     * @type {string}
     */
    private static BaseSchemaNamespace: string = "Microsoft.OnlinePublishing.Instrumentation.";

    /**
     * The events namespace suffix.
     * @type {string}
     */
    private static EventsNamespaceSuffix: string = "Events.";

    /**
     * The events data namespace suffix.
     * @type {string}
     */
    private static EventsDataNamespaceSuffix: string = "Data";

    /**
     * The client activity event name.
     * @type {string}
     */
    private static ClientActivityEventName: string = "ClientActivity";

    /**
     * The client error event name.
     * @type {string}
     */
    private static ClientErrorEventName: string = "ClientError";

    /**
     * The client outgoing service request event name.
     * @type {string}
     */
    private static ClientOsrEventName: string = "ClientOutgoingRequest";

    /**
     * The client page action event name.
     * @type {string}
     */
    private static ClientPageActionEventName: string = "ClientPageAction";

    /**
     * The client page view event name.
     * @type {string}
     */
    private static ClientPageViewEventName: string = "ClientPageView";

    /**
     * The client performance counter event name.
     * @type {string}
     */
    private static ClientPerfCounterEventName: string = "ClientPerfCounter";

    /**
     * The client ptrace event name.
     * @type {string}
     */
    private static ClientTraceEventName: string = "ClientTrace";

    /**
     * The client activity event namespace.
     * @type {string}
     */
    public clientActivitySchemaNamespace: string;

    /**
     * The key for client activity event data namespace.
     * @type {string}
     */
    public clientActivityDataSchemaNamespace: string;

    /**
     * The client error event namespace.
     * @type {string}
     */
    public clientErrorSchemaNamespace: string;

    /**
     * The key for client error event data namespace.
     * @type {string}
     */
    public clientErrorDataSchemaNamespace: string;

    /**
     * The client outgoing service request event namespace.
     * @type {string}
     */
    public clientOsrSchemaNamespace: string;

    /**
     * The key for client outgoing service request event data namespace.
     * @type {string}
     */
    public clientOsrDataSchemaNamespace: string;

    /**
     * The client page action event namespace.
     * @type {string}
     */
    public clientPageActionSchemaNamespace: string;

    /**
     * The key for client page action event data namespace.
     * @type {string}
     */
    public clientPageActionDataSchemaNamespace: string;

    /**
     * The client page view event namespace.
     * @type {string}
     */
    public clientPageViewSchemaNamespace: string;

    /**
     * The key for client page view event data namespace.
     * @type {string}
     */
    public clientPageViewDataSchemaNamespace: string;

    /**
     * The client performance counter event namespace.
     * @type {string}
     */
    public clientPerfCounterSchemaNamespace: string;

    /**
     * The key for client performance counter event data namespace.
     * @type {string}
     */
    public clientPerfCounterDataSchemaNamespace: string;

    /**
     * The client trace event namespace.
     * @type {string}
     */
    public clientTraceSchemaNamespace: string;

    /**
     * The key for client trace event data namespace.
     * @type {string}
     */
    public clientTraceDataSchemaNamespace: string;

    /**
     * Initializes a new instance of the `OneDsNamespaceProfile` class.
     * @constructor
     * @param name {string} The name(namespace) of the event profile.
     */
    constructor(public profileName: string) {
        if (!profileName) {
            throw "'name' is required for constructing OneDsNamespaceProfile";
        }

        const eventsNamespace = OneDsNamespaceProfile.BaseSchemaNamespace + profileName + OneDsNamespaceProfile.EventsNamespaceSuffix;

        this.clientActivitySchemaNamespace = eventsNamespace + OneDsNamespaceProfile.ClientActivityEventName;
        this.clientActivityDataSchemaNamespace = this.clientActivitySchemaNamespace + OneDsNamespaceProfile.EventsDataNamespaceSuffix;

        this.clientErrorSchemaNamespace = eventsNamespace + OneDsNamespaceProfile.ClientErrorEventName;
        this.clientErrorDataSchemaNamespace = this.clientErrorSchemaNamespace + OneDsNamespaceProfile.EventsDataNamespaceSuffix;

        this.clientOsrSchemaNamespace = eventsNamespace + OneDsNamespaceProfile.ClientOsrEventName;
        this.clientOsrDataSchemaNamespace = this.clientOsrSchemaNamespace + OneDsNamespaceProfile.EventsDataNamespaceSuffix;

        this.clientPageActionSchemaNamespace = eventsNamespace + OneDsNamespaceProfile.ClientPageActionEventName;
        this.clientPageActionDataSchemaNamespace = this.clientPageActionSchemaNamespace + OneDsNamespaceProfile.EventsDataNamespaceSuffix;

        this.clientPageViewSchemaNamespace = eventsNamespace + OneDsNamespaceProfile.ClientPageViewEventName;
        this.clientPageViewDataSchemaNamespace = this.clientPageViewSchemaNamespace + OneDsNamespaceProfile.EventsDataNamespaceSuffix;

        this.clientPerfCounterSchemaNamespace = eventsNamespace + OneDsNamespaceProfile.ClientPerfCounterEventName;
        this.clientPerfCounterDataSchemaNamespace = this.clientPerfCounterSchemaNamespace + OneDsNamespaceProfile.EventsDataNamespaceSuffix;

        this.clientTraceSchemaNamespace = eventsNamespace + OneDsNamespaceProfile.ClientTraceEventName;
        this.clientTraceDataSchemaNamespace = this.clientTraceSchemaNamespace + OneDsNamespaceProfile.EventsDataNamespaceSuffix;
    }
}
