/**
* DataCollector.ts
* @author Ram Thiru (ramthi) and Hector Hernandez (hectorh)
* @copyright Microsoft 2018
*/
import { IDiagnosticLogger, isValueAssigned, isDocumentObjectAvailable, isWindowObjectAvailable, LoggingSeverity, _ExtendedInternalMessageId } from '@ms/1ds-core-js';
import { _findClosestAnchor } from './common/Utils';
import { IWebAnalyticsConfiguration } from './DataModel';
import { IOverrideValues } from './interfaces/IOverrides';

var clickCaptureInputTypes = { BUTTON: true, CHECKBOX: true, RADIO: true, RESET: true, SUBMIT: true };

/**
* Get Image href of a given HTMLImageElement
* @param {HTMLImageElement} - An html image element
* @returns {string} - Href value.
*/
export function _getImageHref(element: HTMLImageElement): string {
    var temp = element;
    if (temp) {
        var parent = _findClosestAnchor(<Element>temp);
        if ((<any>parent).length === 1) {
            if (parent[0].href) {
                return parent[0].href;
            } else if (parent[0].src) {
                return (parent[0].src);
            }
        }
    }
    return '';
}

/**
* Check if a given element has an element has data-dc attribute defined with a value 'pii'
* @param {Element} element - An html element
* @returns {boolean} - Flag indicating if an element is market PII.
*/
export function _isPii(element: Element) {
    if (!element || !element.attributes) {
        return false;
    }

    try {
        var pii = element.getAttribute('data-dc');

        if (isValueAssigned(pii)) {
            if (pii.toLowerCase() === 'pii') {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    } catch (e) {
        return false;
    }
}

/**
* Get URI, sanitize the value if configured on
* @param {IWebAnalyticsConfiguration} config - Configuration
* @param {any} location - window.location or document.location
* @returns {string} - Flag indicating if an element is market PII.
*/
export function _getUri(config: IWebAnalyticsConfiguration, location: any): string {
    if (config.coreData && config.coreData.requestUri && config.coreData.requestUri !== '') {
        return config.coreData.requestUri;
    }
    return _sanitizeUrl(config, location);
}

/**
* Sanitize URL values 
* @param {IWebAnalyticsConfiguration} config - Configuration
* @param {any} location - window.location or document.location
* @returns {string} - Flag indicating if an element is market PII.
*/
export function _sanitizeUrl(config: IWebAnalyticsConfiguration, location: any): string {
    var url = location.protocol + '//' + (location.hostname || location.host) +         // location.hostname is not supported on Opera and Opera for Android
        (isValueAssigned(location.port) ? ':' + location.port : '') +
        location.pathname;

    if (config.urlCollectHash) { // false by default
        url += (location.hash || '');
    }

    if (config.urlCollectQuery) { // true by default
        var query = location.search;
        if (!query) {
            // in older browsers the query parameters can be contained in the hash
            var hash = location.hash || '';
            var queryIndex = hash.indexOf('?');
            if (queryIndex !== -1) {
                query = hash.slice(queryIndex);
            }
        }
        return url + query;
    }
    return url;
}

/**
* Gets the pageName from the DOM or by calling a override if set.
* @param {IWebAnalyticsConfiguration} config - configuration object
* @returns {string} - Page name.
*/
export function _getPageName(config: IWebAnalyticsConfiguration, overrideValues: IOverrideValues) {
    /// <summary>
    ///  Gets the pageName from the DOM or by calling a override if set.
    /// </summary>

    if (overrideValues && overrideValues.pageName) {
        return overrideValues.pageName;
    } else if (config.callback && typeof (config.callback.pageName) === 'function') {
        return config.callback.pageName();
    } else if (config.coreData && config.coreData.pageName) {
        return config.coreData.pageName;
    } else {
        var pagename = isWindowObjectAvailable ? window.location.pathname : '';
        var fragments = pagename.split('/');
        if (fragments && fragments[fragments.length - 1] !== '') {
            pagename = fragments[fragments.length - 1];
        } else {
            pagename = 'Undefined';
        }
        return pagename;
    }
}

/**
* Gets the document's title.  Title returned is trimmed to 150 characters
* @returns {string} - Document title.
*/
export function _getTitle(): string {
    return isDocumentObjectAvailable ? document.title.substring(0, 150) : '';
}

/**
* Gets client cookies
* @returns {string} - Cookies.
*/
export function _getClientCookies(config: IWebAnalyticsConfiguration, traceLogger: IDiagnosticLogger) {
    var cookies = '';
    var uniqueCookies = {};
    var mergedCookies: string[] = [];
    var cookiesConfig = config.cookiesToCollect;

    if (config.shareAuthStatus === false) {
        // collect ANON if it is in cookiesConfig
        mergedCookies = cookiesConfig;
    } else {
        // do not collect ANON even if it is in cookiesConfig
        for (var i = 0; i < cookiesConfig.length; i++) {
            if (cookiesConfig[i] !== 'ANON') {
                mergedCookies.push(cookiesConfig[i]);
            }
        }
    }
    var key: string;
    var cookieValue: string;
    try {

        try {
            // adding a try catch block to ensure any mis-handling 
            // of the varCustomerCookies variable by adopters is not impacting the rest.
            if (isWindowObjectAvailable && (<any>window).varCustomerCookies && (<any>window).varCustomerCookies.length > 0) {
                mergedCookies = mergedCookies.concat((<any>window).varCustomerCookies);
            }
        } catch (e) {
            traceLogger.throwInternal(
                LoggingSeverity.CRITICAL,
                _ExtendedInternalMessageId.FailedToGetCookies, "Failed to get cookies "
            )
        }

        for (key in mergedCookies) {
            // de-dupe the list, no value is necessary hence ''
            if (!uniqueCookies.hasOwnProperty(mergedCookies[key])) {
                uniqueCookies[mergedCookies[key]] = '';
                if (isDocumentObjectAvailable) {
                    cookieValue = decodeURIComponent(document.cookie.replace(
                        new RegExp('(?:(?:^|.*;)\\s*' + encodeURIComponent(mergedCookies[key]).replace(/[\-\.\+\*]/g, '\\$&') +
                            '\\s*\\=\\s*([^;]*).*$)|^.*$'), '$1'));
                    if (cookieValue !== '') {
                        cookies += mergedCookies[key] + '=' + cookieValue + ';';
                    }
                }
            }
        }
    } catch (e) {
        traceLogger.throwInternal(
            LoggingSeverity.CRITICAL,
            _ExtendedInternalMessageId.FailedToGetCookies, "Failed to get cookies "
        )
    }
    return cookies;
}

/**
 * 
* Get click target
* @returns {string} - Click target URI
*/
export function _getClickTarget(element: any) {
    var clickTarget = '';
    switch (element.tagName) {
        case 'A':
        case 'AREA':
            clickTarget = element.href || '';
            break;
        case 'IMG':
            clickTarget = _getImageHref(<HTMLImageElement>element);
            break;
        case 'INPUT':
            var type = element.type;
            if (type && (clickCaptureInputTypes[type.toUpperCase()])) {
                if (element.form) {
                    clickTarget = element.form.action || (isWindowObjectAvailable ? window.location.pathname : '');
                } else {
                    clickTarget = isWindowObjectAvailable ? window.location.pathname : '';
                }
            }
            break;
        default:
            break;
    }
    return clickTarget;
}

