import * as axiosStatic from "axios";

import { AxiosWrapperBase } from "../axios-wrapper/AxiosWrapperBase";
import { IAuthenticationManager } from "./IAuthenticationManager";

/**
 * Wraps axios and adds authentication headers to every request being sent.
 * @class
 */
export class AxiosAuthenticationWrapper extends AxiosWrapperBase {

    /**
     * es6-promise service instance.
     * @type {Promise}
     */
    private promiseService: typeof Promise;

    /**
     * The service that ensures that the user is authenticated and provides security tokens.
     * @type {IAuthenticationManager}
     */
    private authenticationManager: IAuthenticationManager;

    /**
     * Initializes a new instance of the `AxiosAuthenticationWrapper` class.
     * @constructor
     * @param {IAuthenticationManager} authenticationManager The service that ensure that the user is authenticated and provides security tokens.
     * @param {Promise} promiseService es6-promise service instance.
     * @param {axiosStatic.AxiosStatic} axios Axios service instance.
     */
    constructor(
        authenticationManager: IAuthenticationManager,
        promiseService?: typeof Promise,
        axios?: axiosStatic.AxiosStatic) {

        if (!authenticationManager) {
            throw "authenticationManager must not be null";
        }

        super(axios || axiosStatic);

        this.promiseService = promiseService || Promise;
        this.authenticationManager = authenticationManager;
    }

    /**
     * Add the security token to every request before it is sent.
     * @param config {axiosStatic.RequestOptions} The request detail.
     * @returns {void}
     */
    protected onRequest<T>(config: axiosStatic.RequestOptions): void {

        if (!config) {
            throw "config must not be null";
        }

        var token = this.authenticationManager.getToken();
        if (token) {
            if (!config.headers) {
                config.headers = {};
            }
            config.headers["Authorization"] = "Bearer " + token;
        }
    }

    /**
     * Check if the response came back as unauthorized and trigger re-authentication if it did.
     * @param result {Promise<axiosStatic.Response>} The response detail.
     * @returns {Promise<axiosStatic.Response>} The result of the call with any required changes added.
     */
    protected onResponse<T>(result: Promise<axiosStatic.Response>): Promise<axiosStatic.Response> {

        if (!result) {
            throw "result must not be null";
        }

        result.catch((error) => {
            if (error.status === 401) {
                this.authenticationManager.triggerLogin();
            }
        });

        return result;
    }
}