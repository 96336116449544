import { IError } from  "./IError";
import { BaseErrorType } from "./BaseErrorType";

export class Error<T, TInner> implements IError<T, TInner> {
    /**
     * Type of the error
     * @type {T}
     */
    public get type(): T { return this._type; }
    private _type: T;

    /**
     * Message for the error
     * @type {string}
     */
    public get errorMessage(): string { return this._errorMessage; }
    private _errorMessage: string;

    /**
     * Origin of the error
     * @type {string}
     */
    public get source(): string { return this._source; }
    private _source: string;

    /**
     * inner error
     * @type {IError<any>}
     */
    public get innerError(): TInner { return this._innerError; }
    private _innerError: TInner;

    /**
     * Initializes a new instance of the Error class
     * @param type {T} the error type string literal
     * @param errorMessage {string} error message
     * @param source {string} error origin
     * @param innerError {IError<any>} inner error
     * @constructor
     */
    constructor(type: T, errorMessage?: string, source?: string, innerError?: TInner) {
        if (!type) {
            throw new Error<BaseErrorType, any>(BaseErrorType.ArgumentNullException, "type is required for instantiate new Error instance.");
        }

        this._type = type;
        this._errorMessage = errorMessage;
        this._source = source;
        this._innerError = innerError;
    }
}