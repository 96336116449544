import * as axiosStatic from "axios";

import { merge } from "lodash-es";

/**
 * Abstract Base wrapper implementation for axios that does not provide an implementation for the passthrough to axios.
 * @class
 */
export abstract class AxiosWrapperAbstractBase {

    /**
     * Method to send any type of http call (get/post/put/etc.).
     * @method
     * @param config {axiosStatic.RequestOptions} The request detail.
     * @returns {Promise<axiosStatic.Response>} The result of the call.
     */
    public abstract sendRequest<T>(config: axiosStatic.RequestOptions): Promise<axiosStatic.Response>;

    /**
     * Convenient alias for doing a get service call.
     * @method
     * @param url {string} The url to make the service call to.
     * @param config {axiosStatic.InstanceOptions} Additional settings for the service call.
     * @returns {Promise<axiosStatic.Response>} The result of the call.
     */
    public get<T>(url: string, config?: axiosStatic.InstanceOptions): Promise<axiosStatic.Response> {
        return this.callWithMergedConfig("get", url, config);
    }

    /**
     * Convenient alias for doing a delete service call.
     * @method
     * @param url {string} The url to make the service call to.
     * @param config {axiosStatic.InstanceOptions} Additional settings for the service call.
     * @returns {Promise<axiosStatic.Response>} The result of the call.
     */
    public delete<T>(url: string, config?: axiosStatic.InstanceOptions): Promise<axiosStatic.Response> {
        return this.callWithMergedConfig("delete", url, config);
    }

    /**
     * Convenient alias for doing a head service call.
     * @method
     * @param url {string} The url to make the service call to.
     * @param config {axiosStatic.InstanceOptions} Additional settings for the service call.
     * @returns {Promise<axiosStatic.Response>} The result of the call.
     */
    public head<T>(url: string, config?: axiosStatic.InstanceOptions): Promise<axiosStatic.Response> {
        return this.callWithMergedConfig("head", url, config);
    }

    /**
     * Convenient alias for doing a post service call.
     * @method
     * @param url {string} The url to make the service call to.
     * @param data {any} The data to send as the body of the request.
     * @param config {axiosStatic.InstanceOptions} Additional settings for the service call.
     * @returns {Promise<axiosStatic.Response>} The result of the call.
     */
    public post<T>(url: string, data?: any, config?: axiosStatic.InstanceOptions): Promise<axiosStatic.Response> {
        return this.callWithMergedConfigWithData("post", url, data, config);
    }

    /**
     * Convenient alias for doing a put service call.
     * @method
     * @param url {string} The url to make the service call to.
     * @param data {any} The data to send as the body of the request.
     * @param config {axiosStatic.InstanceOptions} Additional settings for the service call.
     * @returns {Promise<axiosStatic.Response>} The result of the call.
     */
    public put<T>(url: string, data?: any, config?: axiosStatic.InstanceOptions): Promise<axiosStatic.Response> {
        return this.callWithMergedConfigWithData("put", url, data, config);
    }

    /**
     * Convenient alias for doing a patch service call.
     * @method
     * @param url {string} The url to make the service call to.
     * @param data {any} The data to send as the body of the request.
     * @param config {axiosStatic.InstanceOptions} Additional settings for the service call.
     * @returns {Promise<axiosStatic.Response>} The result of the call.
     */
    public patch<T>(url: string, data?: any, config?: axiosStatic.InstanceOptions): Promise<axiosStatic.Response> {
        return this.callWithMergedConfigWithData("patch", url, data, config);
    }

    /**
     * Overridable method to do pre-processing before the request is sent.
     * @method
     * @param config {axiosStatic.RequestOptions} The request detail.
     */
    protected onRequest<T>(config: axiosStatic.RequestOptions): void { } // tslint:disable-line

    /**
     * Overridable method to do post-processing after the response is received.
     * @method
     * @param result {Promise<axiosStatic.Response>} The response detail.
     * @returns {Promise<axiosStatic.Response>} The result of the call with any required changes added.
     */
    protected onResponse<T>(result: Promise<axiosStatic.Response>): Promise<axiosStatic.Response> {
        return result;
    }

    /**
     * Merge the given method and url into the given config object and call through to axios.
     * @method
     * @param method {string} The type of http call to make.
     * @param url {string} The url to make the service call to.
     * @param config {axiosStatic.InstanceOptions} The request detail.
     * @returns {Promise<axiosStatic.Response>} The combined configuration.
     */
    private callWithMergedConfig<T>(method: string, url: string, config?: axiosStatic.InstanceOptions): Promise<axiosStatic.Response> {
        var mergedConfig = merge(config || {}, {
            method: method,
            url: url
        });

        return this.sendRequest<T>(mergedConfig);
    }

    /**
     * Merge the given method, url and data into the given config object and call through to axios.
     * @method
     * @param method {string} The type of http call to make.
     * @param url {string} The url to make the service call to.
     * @param data {any} The data to send as the body of the request.
     * @param config {axiosStatic.InstanceOptions} The request detail.
     * @returns {Promise<axiosStatic.Response>} The combined configuration.
     */
    private callWithMergedConfigWithData<T>(method: string, url: string, data: any, config?: axiosStatic.InstanceOptions): Promise<axiosStatic.Response> {

        if (!url) {
            throw "url must not be null";
        }

        var mergedConfig = merge(config || {}, {
            method: method,
            url: url,
            data: data
        });

        return this.sendRequest<T>(mergedConfig);
    }
}