/**
 * Basic stop watch, provides elapsed in ms.
 */
export class StopWatch {
    /**
     * start time in tick
     * @type {number}
     */
    private startTime: number;

    /**
     * end time in tick
     * @type {number}
     */
    private endTime: number;

    /**
     * start timer
     * @returns {number} the time it started
     */
    public start(): number {
        this.startTime = Date.now();
        return this.startTime;
    }

    /**
     * end timer
     * @returns {number} the time it ended
     */
    public stop(): number {
        this.endTime = Date.now();
        return this.endTime;
    }

    /**
     * elapsed time in ms
     * @returns {number} the time between start and end in ms.
     */
    public elapsedMilliseconds(): number {
        if (!this.startTime || !this.endTime) {
            return 0;
        }
        return this.endTime - this.startTime;
    }
}