import * as axiosStatic from "axios";

import { AdalAuthenticationErrorType } from "../authentication/AdalAuthenticationErrorType";
import { AxiosWrapperBase } from "./axios-wrapper";
import { Error } from "../error/Error";
import { IAdalAxiosAuthenticationWrapperContext } from "../authentication/authentication";
import { IInstrumentationService } from "clientcore-infrastructure-analytics/IInstrumentationService";
import { IMsalAxiosAuthenticationWrapperContext } from "../authentication/IMsalAxiosAuthenticationWrapperContext";

/**
 * Base Axios wrapper that contains logic to send HTTP requests with a Bearer token in the header.
 * @class TokenBearerAxiosWrapper
 */
export class TokenBearerAxiosWrapper extends AxiosWrapperBase {

    /**
     * Creates an instance of TokenBearerAxiosWrapper.
     * @param authenticationContext {(IAdalAxiosAuthenticationWrapperContext | IMsalAxiosAuthenticationWrapperContext)} The client authentication context.
     * @param instrumentationService {IInstrumentationService} An instance of the instrumentation service.
     * @param [promiseService=Promise] {typeof Promise} Promise service instance.
     * @param [axios] {axiosStatic.AxiosStatic} Axios service instance.
     */
    constructor(
        private authenticationContext: IAdalAxiosAuthenticationWrapperContext | IMsalAxiosAuthenticationWrapperContext,
        protected instrumentationService: IInstrumentationService,
        protected promiseService: typeof Promise = Promise,
        axios?: axiosStatic.AxiosStatic) {

        super(axios || axiosStatic);
    }

   /**
    * Overrides the sendRequest function to add an authentication token.
    * @param config {axiosStatic.RequestOptions} The request details.
    * @returns {Promise<axiosStatic.Response>} The result of the call.
    */
    public sendRequest<T>(config: axiosStatic.RequestOptions): Promise<axiosStatic.Response> {

        return new this.promiseService<axiosStatic.Response>((resolve, reject) => {

            // try and get a resource id for the url that is currently being requested.
            const resourcePromise = this.authenticationContext.getResourceForEndpoint(config.url) as Promise<string | string[]>;

            resourcePromise.then((resourceId) => {
                // if a resource id was not found, just send the request without an Authorization header.
                if (!resourceId) {
                    const messageCallback = () => `TokenBearerAxiosWrapper.sendRequest: No adal resourceId defined for url "${config.url}". Please add a resource for this url, or stop using the TokenBearerAxiosWrapper with this service.`;
                    this.instrumentationService.loggingService.warnCallback(messageCallback, null, { url: config.url });
                    super.sendRequest(config).then(resolve).catch(reject);
                    return;
                }

                const messageCallback = () => `TokenBearerAxiosWrapper.sendRequest: Found resourceId "${resourceId}" for url "${config.url}".`;
                this.instrumentationService.loggingService.traceCallback(messageCallback, null, { url: config.url, resourceId: resourceId });

                this.getTokenAndSendRequest(config, resourceId, resolve, reject);
            }).catch((error) => {
                const messageCallback = () => `TokenBearerAxiosWrapper.sendRequest: Resolving adal resourceId failed for url "${config.url}". Please add a resource for this url, or stop using the TokenBearerAxiosWrapper with this service.`;
                this.instrumentationService.loggingService.warnCallback(messageCallback, null, { url: config.url, error: error });

                // since getting a resource id failed, just send the request without an Authorization header.
                super.sendRequest(config).then(resolve).catch(reject);
                return;
            });
        });
    }

    /**
     * Get a token for the given resource and send a request with the token attached.
     * @param config {axiosStatic.RequestOptions} The request details.
     * @param resource {string} The id of the resource that we are trying to access.
     * @param resolve {(value: axiosStatic.Response) => void} Callback to resolve the promise for this wrapper.
     * @param reject {(error: any) => void} Callback to reject the promise for this wrapper.
     */
    protected getTokenAndSendRequest(config: axiosStatic.RequestOptions, resource: string | string[], resolve: (value: axiosStatic.Response) => void, reject: (error: any) => void): void {

        // get a token for the given resource.
        const acquireToken = this.authenticationContext.acquireToken as ((resource: string | string[]) => Promise<string>);

        const acquireTokenPromise = acquireToken.call(this.authenticationContext, resource);

        acquireTokenPromise.then((token) => {

            // attach the token to the request and send the request.
            if (!config.headers) {
                config.headers = {};
            }
            config.headers.Authorization = "Bearer " + token;
            super.sendRequest(config).then(resolve).catch(reject);

        }).catch((errorMessage) => {

            // if we couldn't get a token for the resource we should fail the request.
            const messageCallback = () => `TokenBearerAxiosWrapper.sendRequest: Couldn't get a token for resource "${resource}" and url "${config.url}" because of error "${errorMessage}".`;
            this.instrumentationService.loggingService.errorCallback(messageCallback, null, { url: config.url, resource: resource, errorMessage: errorMessage });
            reject(new Error(AdalAuthenticationErrorType.AdalAuthenticationAquireTokenError, messageCallback(), "TokenBearerAxiosWrapper", errorMessage));
            return;
        });
    }
}