import { BrowserBasedDataProvider } from "./BrowserBasedDataProvider";
import { IBaseTrackingData } from "../userActivityData/IBaseTrackingData";
import { IBrowserWindow } from "../../browser-object-models/IBrowserWindow";
import { IEventBasicData } from "../commonSchema/IEventBasicData";
import { ITrackingPartCContent } from "./ITrackingPartCContent";
import { IUserActivityLogItem } from "../IUserActivityLogItem";
import { isEmpty } from "lodash-es";

/**
 * Represents the parameters of the PART C content that is common for both page view and page action events.
 * @class
 */
export class TrackingPartCContent implements ITrackingPartCContent {

    /**
     * The schema version for the schema defined by AnalyticsSDK.
     * @type {string}
     */
    private static SchemaVersion: string = "1.1.0";

    /**
     * The Incremental number for every event triggered in a session. This will help to understand the user flow.
     * @type {number}
     */
    private static EventSequenceNumber: number = 0;

    /** 
     * The event sequence number.
     * @property 
     * @type {number}
     */
    public eventSeqNum: number; // Having only getter skips the property during serialization.

    /**
     * Initializes a new instance of the `TrackingPartCContent` class.
     * @param title {string} The page title.
     * @param schemaVer {string} The schema version for the schema defined by AnalyticsSDK.
     * @param documentData? {string} The document or documents on which page view or page action is performed.
     * @param browserSize? {string} The Size of the browser window. Ex: 1024x768.
     * @param cookieEnabled? {boolean} The flag to report if cookies enabled/disabled in the browser.
     * @param extendedData? {string} The additional data associated with the event.
     * @param isJs? {boolean} The flag to report that the event is from JS. It will always be true for client logs.
     * @param isManual? {boolean} The flag to report that the event is manually triggered or not. It will always be true for the client logs.
     * @param serverImpressionGuid? {string} The server impression guid.
     */
    constructor(
        public title: string,
        public schemaVer: string,
        public documentData?: string,
        public browserSize?: string,
        public cookieEnabled?: boolean,
        public extendedData?: string,
        public isJs?: boolean,
        public isManual?: boolean,
        public serverImpressionGuid?: string) {

        // Increment EventSequenceNumber for every initialization of the tracking event.
        this.eventSeqNum = ++TrackingPartCContent.EventSequenceNumber;
    }

    /**
     * Creates a new TrackingPartCContent from a given log item.
     * @param userActivityLogItem {IUserActivityLogItem} The log item.
     * @param windowContext {IBrowserWindow} The window object of the browser.
     * @param basicData {IEventBasicData} The basic parameters of the asimov events.
     * @param serverImpressionGuid {string} The server impression guid.
     * @return {TrackingPartCContent} The part C content that is common for both page view and page action events.
     */
    protected static createFrom(
        userActivityLogItem: IUserActivityLogItem,
        windowContext: IBrowserWindow,
        basicData: IEventBasicData,
        serverImpressionGuid: string): ITrackingPartCContent {

        let partCContent: ITrackingPartCContent = {
            title: userActivityLogItem.pageTitle || basicData.title,
            extendedData: isEmpty(basicData.customData) ? null : JSON.stringify(basicData.customData),
            schemaVer: TrackingPartCContent.SchemaVersion,
            eventSeqNum: TrackingPartCContent.EventSequenceNumber,
            serverImpressionGuid: serverImpressionGuid,
            isJs: true,
            isManual: true
        };

        if (userActivityLogItem.trackingData) {
            let trackingData = <IBaseTrackingData>userActivityLogItem.trackingData;

            // Populate PART C Contents
            partCContent.documentData = trackingData.documentData ? JSON.stringify(trackingData.documentData) : null;
            partCContent.browserSize = trackingData.browserSize;
            partCContent.cookieEnabled = trackingData.cookieEnabled;
        }

        // Populate browser based values
        if (!partCContent.browserSize) {
            partCContent.browserSize = BrowserBasedDataProvider.getBrowserSize(windowContext);
        }

        if (!partCContent.cookieEnabled) {
            partCContent.cookieEnabled = BrowserBasedDataProvider.cookieEnabled(windowContext);
        }

        return partCContent;
    }
}
