// import { SessionManager } from '@microsoft/applicationinsights-properties-js';
import { isString, objDefineAccessors } from '@ms/1ds-core-js';

export class Session {

    setId(id: string) {
        this._customId = id;
    }

    getId(): string {
        if (isString(this._customId)) {
            return this._customId;
        }
        else {
            return this.automaticId;
        }
    }

    /**
     * Returns or sets the id as known by the logging library.
     * Note: This property will NOT exist in ES3/IE8 environment, if you need IE8 compatibility
     * use the methods {@link Session#getId} and  {@link Session#setId} from your code. For ES5+ environment this will be replaced with a 
     * property getter only.
     */
    public id?: string;

    /**
    * The date at which this guid was genereated.
    * Per the spec the ID will be regenerated if more than acquisitionSpan milliseconds ellapse from this time.
    */
    public acquisitionDate?: number;

    /**
     * The date at which this session ID was last reported.
     * This value should be updated whenever telemetry is sent using this ID.
     * Per the spec the ID will be regenerated if more than renewalSpan milliseconds elapse from this time with no activity.
     */
    public renewalDate?: number;

    private _customId: string;

    /**
     * The automatically generated session ID using SessionManager.
     */
    public automaticId?: string;

    /**
     * Static constructor, attempt to create accessors
     */
    private static _staticInit = (() => {
        // Dynamically create get/set property accessors
        objDefineAccessors(Session.prototype, "id", Session.prototype.getId, Session.prototype.setId);
    })();
}

