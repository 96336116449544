/**
* AITrace.ts
* @author Hector Hernandez (hectorh)
* @copyright Microsoft 2019
*/

import { isWindowObjectAvailable } from '@ms/1ds-core-js';
import { ITraceContext } from '../DataModels';
import { generateW3CId } from '../Utils';
import { IPropertyConfiguration } from '../DataModels';

export class Trace implements ITraceContext {

    public traceId: string;
    public parentId: string;
    public name: string;

    constructor(propertiesConfig: IPropertyConfiguration, id?: string, parentId?: string, name?: string) {
        if (propertiesConfig.enableApplicationInsightsTrace) {
            this.traceId = id || generateW3CId();
            this.parentId = parentId;
            this.name = name;
            if (isWindowObjectAvailable && window.location && window.location.pathname) {
                this.name = window.location.pathname;
            }
        }
    }
}