/**
* HttpManager.ts
* @author Abhilash Panwar (abpanwar) Hector Hernandez (hectorh)
* @copyright Microsoft 2018
*/
import {
    NotificationManager, EventsDiscardedReason, isReactNative, isValueAssigned, isString,
    isBeaconsSupported, FullVersionString, useXDomainRequest, IExtendedAppInsightsCore, setCookie
} from '@ms/1ds-core-js';
import { IXHROverride, IPostTransmissionTelemetryItem, ICollectorResult, IPostChannel } from './DataModels';
import Serializer from './Serializer';
import RetryPolicy from './RetryPolicy';
import EVTKillSwitch from './KillSwitch';
import EVTClockSkewManager from './ClockSkewManager';

const MaxConnections = 2;
const MaxRetries = 1;
const Method = 'POST';
const DisabledPropertyName: string = "Microsoft_ApplicationInsights_BypassAjaxInstrumentation";

/**
 * Class managing the sending of requests.
 */
export default class HttpManager {
    private _urlString: string = '?cors=true&content-type=application/x-json-stream&client-id=NO_AUTH&client-version='
        + FullVersionString;
    private _killSwitch: EVTKillSwitch = new EVTKillSwitch();
    private _paused = false;
    private _clockSkewManager = new EVTClockSkewManager();
    private _useBeacons = false;
    private _activeConnections = 0;
    private _postManager: IPostChannel;
    private _httpInterface: IXHROverride | undefined;
    private _notificationManager: NotificationManager | undefined;
    private _core: IExtendedAppInsightsCore;
    private _customHttpInterface = true;
    private _queryStringParameters: { name: string, value: String }[];

    /**
     * @constructor
     * @param {object} requestQueue   - The queue that contains the requests to be sent.
     */
    constructor(public _requestQueue: { [token: string]: IPostTransmissionTelemetryItem[] }[]) {
        this._queryStringParameters = [];
    }

    /**
     * @constructor
     * @param {object} requestQueue   - The queue that contains the requests to be sent.
     * @param {string} endpointUrl   - The collector url to which the requests must be sent.
     * @param {object} postManager   - The post manager that we should add requests back to if needed.
     * @param {object} httpInterface - The http interface that should be used to send HTTP requests.
     */
    initialize(endpointUrl: string, core: IExtendedAppInsightsCore, postChannel: IPostChannel, httpInterface: IXHROverride, notificationManager: NotificationManager) {
        this._urlString = endpointUrl + this._urlString;
        this._core = core;
        this._postManager = postChannel;
        this._httpInterface = httpInterface;
        this._notificationManager = notificationManager;
        if (!this._httpInterface) {
            this._customHttpInterface = false;
            this._useBeacons = !isReactNative(); //Only use beacons if not running in React Native
            this._httpInterface = {
                sendPOST: (urlString: string, data: Uint8Array | string,
                    oncomplete: (status: number, headers: { [headerName: string]: string }, response?: string) => void, sync?: boolean, ) => {
                    if (useXDomainRequest()) {
                        // It doesn't support custom headers, so no action is taken with current requestHeaders
                        let xdr = new XDomainRequest();
                        xdr.open(Method, urlString);
                        //can't get the status code in xdr.
                        xdr.onload = () => {
                            // we will assume onload means the request succeeded.
                            oncomplete(200, {}, xdr.responseText);
                            this._handleCollectorResponse(xdr.responseText);
                        };
                        xdr.onerror = () => {
                            // we will assume onerror means we need to drop the events.
                            oncomplete(400, {});
                        };
                        xdr.ontimeout = () => {
                            // we will assume ontimeout means we need to retry the events.
                            oncomplete(500, {});
                        };
                        xdr.send(data);
                    } else if (isReactNative()) {
                        //Use the fetch API to send events in React Native
                        fetch(urlString, {
                            body: data,
                            method: Method,
                            credentials: 'include',
                            [DisabledPropertyName]: true
                        }).then((response) => {
                            let headerMap = {};
                            let responseText = '';
                            if (response.headers) {
                                response.headers.forEach((value: string, name: string) => {
                                    headerMap[name] = value;
                                });
                            }
                            if (response.body) {
                                response.text().then(function (text) {
                                    responseText = text;
                                })
                            }
                            oncomplete(response.status, headerMap, responseText);
                            this._handleCollectorResponse(responseText);
                        }).catch((error) => {
                            //In case there is an error in the request. Set the status to 0
                            //so that the events can be retried later.
                            oncomplete(0, {});
                        });
                    } else if (typeof XMLHttpRequest !== 'undefined') {
                        let xhr = new XMLHttpRequest();
                        xhr[DisabledPropertyName] = true;
                        xhr.withCredentials = true;
                        xhr.open(Method, urlString, !sync);
                        xhr.onload = () => {
                            oncomplete(xhr.status, this._convertAllHeadersToMap(xhr.getAllResponseHeaders()), xhr.responseText);
                            this._handleCollectorResponse(xhr.responseText);
                        };
                        xhr.onerror = () => {
                            oncomplete(xhr.status, this._convertAllHeadersToMap(xhr.getAllResponseHeaders()));
                        };
                        xhr.ontimeout = () => {
                            oncomplete(xhr.status, this._convertAllHeadersToMap(xhr.getAllResponseHeaders()));
                        };
                        xhr.send(data);
                    }
                }
            };
        }
    }

    /**
     * Add query string parameter to url
     * @param {string} name   - Header name.
     */
    public addQueryStringParameter(name: string, value: string) {
        for (let i = 0; i < this._queryStringParameters.length; i++) {
            if (this._queryStringParameters[i].name === name) {
                this._queryStringParameters[i].value = value;
                return;
            }
        }
        this._queryStringParameters.push({ name: name, value: value });
    }

    /**
     * Check if there is an idle connection overwhich we can send a request.
     * @return {boolean} True if there is an idle connection, false otherwise.
     */
    hasIdleConnection(): boolean {
        return this._activeConnections < MaxConnections;
    }

    /**
     * Send requests in the request queue up if there is an idle connection, sending is
     * not pause and clock skew manager allows sending request.
     */
    sendQueuedRequests() {
        while (this.hasIdleConnection() && !this._paused && this._requestQueue.length > 0
            && this._clockSkewManager.allowRequestSending()) {
            this._activeConnections++;
            this._sendRequest(this._requestQueue.shift(), 0, false);
        }
        //No more requests to send, tell TPM to try to schedule timer
        //in case it was waiting for idle connections
        if (this.hasIdleConnection()) {
            this._postManager._scheduleTimer();
        }
    }

    /**
     * Check if there are no active requests being sent.
     * @return {boolean} True if idle, false otherwise.
     */
    isCompletelyIdle(): boolean {
        return this._activeConnections === 0;
    }

    /**
     * Queue all the remaning requests to be sent. The requests will be
     * sent using HTML5 Beacons if they are available.
     */
    teardown() {
        while (this._requestQueue.length > 0) {
            this._sendRequest(this._requestQueue.shift(), 0, true);
        }
    }

    /**
     * Pause the sending of requests. No new requests will be sent.
     */
    pause() {
        this._paused = true;
    }

    /**
     * Resume the sending of requests.
     */
    resume() {
        this._paused = false;
        this.sendQueuedRequests();
    }

    /**
     * Removes any pending requests to be sent.
     */
    removeQueuedRequests() {
        this._requestQueue.length = 0;
    }

    /**
     * Sends a request synchronously to the Aria collector. This api is used to send
     * a request containing a single immediate event.
     *
     * @param request - The request to be sent.
     * @param token   - The token used to send the request.
     */
    sendSynchronousRequest(request: { [token: string]: IPostTransmissionTelemetryItem[] }) {
        //This will not take into account the max connections restriction. Since this is sync, we can
        //only send one of this request at a time and thus should not worry about multiple connections
        //being used to send synchronoush events.
        //Increment active connection since we are still going to use a connection to send the request.
        this._activeConnections++;
        //For sync requests we will not wait for the clock skew.
        this._sendRequest(request, 0, false, true);
    }

    private _sendRequest(request: { [token: string]: IPostTransmissionTelemetryItem[] } | undefined, retryCount: number, isTeardown: boolean,
        isSynchronous = false) {
        if (request) {
            if (this._paused) {
                this._activeConnections--;
                this._postManager._addBackRequest(request);
                return;
            }
            let tokenCount = 0;
            let apikey = '';
            for (let token in request) {
                if (request.hasOwnProperty(token)) {
                    if (!this._killSwitch.isTenantKilled(token)) {
                        if (apikey.length > 0) {
                            apikey += ',';
                        }
                        apikey += token;
                        tokenCount++;
                    } else {
                        if (this._notificationManager) {
                            this._notificationManager.eventsDiscarded(request[token], EventsDiscardedReason.KillSwitch);
                        }
                        delete request[token];
                    }
                }
            }
            if (tokenCount > 0) {
                let payloadResult = Serializer.getPayloadBlob(request);
                if (payloadResult.remainingRequest) {
                    this._requestQueue.push(payloadResult.remainingRequest);
                }

                let urlString = this._urlString + '&apikey=' + apikey + '&upload-time='
                    + Date.now().toString();
                let msfpc = this._getMsfpc(request);
                if (isValueAssigned(msfpc)) {
                    urlString = urlString + '&ext.intweb.msfpc=' + msfpc;
                }
                if (this._clockSkewManager.shouldAddClockSkewHeaders()) {
                    urlString = urlString + '&time-delta-to-apply-millis=' + this._clockSkewManager.getClockSkewHeaderValue();
                }
                if (this._core.getWParam) {
                    urlString = urlString + '&w=' + this._core.getWParam();
                }
                for (let i = 0; i < this._queryStringParameters.length; i++) {
                    urlString = urlString + '&' + this._queryStringParameters[i].name + '=' + this._queryStringParameters[i].value;
                }
                for (let token in request) {
                    if (request.hasOwnProperty(token)) {
                        //Increment the send attempt count
                        for (let i = 0; i < request[token].length; ++i) {
                            request[token][i].sendAttempt > 0 ? request[token][i].sendAttempt++ : request[token][i].sendAttempt = 1;
                        }
                    }
                }

                // Send all data using beacon if closing mode is on or channel was teared down
                if (!this._customHttpInterface && this._useBeacons && isBeaconsSupported() && isTeardown) {
                    this._sendUsingBeacons(urlString, payloadResult.payloadBlob, request);
                    return;
                }

                //Send sync requests if the request is immediate or we are tearing down telemetry.
                if (this._httpInterface) {
                    this._httpInterface.sendPOST(urlString, payloadResult.payloadBlob, (status, headers) => {
                        this._retryRequestIfNeeded(status, headers, request, tokenCount, apikey, retryCount, isTeardown, isSynchronous);
                    }, isTeardown || isSynchronous);
                }
            } else if (!isTeardown) {
                this._handleRequestFinished(null, {}, isTeardown, isSynchronous);
            }
        }
    }

    public _sendUsingBeacons(urlString: string, payload: string, request: { [token: string]: IPostTransmissionTelemetryItem[] }) {

        if (navigator.sendBeacon(urlString, payload)) {
            //Request sent via beacon.
            this._handleRequestFinished(true, request, true, true);
            return;
        }
        else {
            // Split data and try to send as much events as possible
            for (let token in request) {
                if (request.hasOwnProperty(token)) {
                    for (let i = 0; i < request[token].length; ++i) {
                        if (navigator.sendBeacon(urlString, Serializer.getEventBlob(request[token][i]))) {
                            //Request sent via beacon.
                            this._notifyEventCompleted(true, [request[token][i]]);
                        }
                        else {
                            // Events not sent because queue is full
                            return;
                        }
                    }
                }
            }
        }
    }

    private _retryRequestIfNeeded(status: number, headers: { [headerName: string]: string },
        request: { [token: string]: IPostTransmissionTelemetryItem[] }, tokenCount: number,
        apikey: string, retryCount: number, isTeardown: boolean, isSynchronous: boolean) {
        let shouldRetry = true;
        if (typeof status !== 'undefined') {
            if (headers) {
                let killedTokens = this._killSwitch.setKillSwitchTenants(headers['kill-tokens'],
                    headers['kill-duration-seconds']);
                this._clockSkewManager.setClockSkew(headers['time-delta-millis']);
                for (let i = 0; i < killedTokens.length; ++i) {
                    if (this._notificationManager) {
                        this._notificationManager.eventsDiscarded(request[killedTokens[i]], EventsDiscardedReason.KillSwitch);
                    }
                    delete request[killedTokens[i]];
                    tokenCount--;
                }
            } else {
                this._clockSkewManager.setClockSkew('');
            }
            if (status === 200) {
                this._handleRequestFinished(true, request, isTeardown, isSynchronous);
                return;
            }
            if (!RetryPolicy.shouldRetryForStatus(status) || tokenCount <= 0) {
                shouldRetry = false;
            }
        }
        if (shouldRetry) {
            if (isSynchronous) {
                //Synchronous events only contain a single event so the apiKey is equal to the token for that event.
                //Convert the event to RealTime/Critical and add back to queue to be sent as High event.
                this._activeConnections--;
                this._postManager._addBackRequest(request);
            } else if (retryCount < MaxRetries) {
                setTimeout(() => this._sendRequest(request, retryCount + 1, false),
                    RetryPolicy.getMillisToBackoffForRetry(retryCount));
            } else {
                this._activeConnections--;
                this._postManager._backOffTransmission();
                this._postManager._addBackRequest(request);
            }
        } else {
            this._handleRequestFinished(false, request, isTeardown, isSynchronous);
        }
    }

    private _handleRequestFinished(success: boolean | null, request: { [token: string]: IPostTransmissionTelemetryItem[] },
        isTeardown: boolean, isSynchronous: boolean) {
        if (success) {
            this._postManager._clearBackOff();
        }
        for (let token in request) {
            if (request.hasOwnProperty(token)) {
                this._notifyEventCompleted(success, request[token], EventsDiscardedReason.NonRetryableStatus);
            }
        }
        this._activeConnections--;
        if (!isSynchronous && !isTeardown) {
            //Only continue sending more requests as long as the current request was not an synchronous request or sent
            //during teardown. We want to return after just sending this one sync request.
            this.sendQueuedRequests();
        }
    }

    // Call notfication manager for completed requests
    private _notifyEventCompleted(success: boolean, event: IPostTransmissionTelemetryItem[], discardedReason?: number) {
        if (this._notificationManager) {
            if (success) {
                this._notificationManager.eventsSent(event);
            } else {
                this._notificationManager.eventsDiscarded(event, discardedReason);
            }
        }
    }

    /**
     * Converts the XHR getAllResponseHeaders to a map containing the header key and value.
     */
    private _convertAllHeadersToMap(headersString: string): { [headerName: string]: string } {
        let headers = {};
        if (isString(headersString)) {
            let headersArray = headersString.split('\n');
            for (let i = 0; i < headersArray.length; ++i) {
                let header = headersArray[i].split(': ');
                headers[header[0]] = header[1];
            }
        }
        return headers;
    }

    private _getMsfpc(requestDictionary: { [token: string]: IPostTransmissionTelemetryItem[] }): string {
        for (let token in requestDictionary) {
            if (requestDictionary.hasOwnProperty(token)) {
                for (let i = 0; i < requestDictionary[token].length; ++i) {
                    if (requestDictionary[token][i].ext && requestDictionary[token][i].ext['intweb'] &&
                        isValueAssigned(requestDictionary[token][i].ext['intweb']['msfpc'])) {
                        return encodeURIComponent(requestDictionary[token][i].ext['intweb']['msfpc']);
                    }
                }
            }
        }
        return '';
    }

    private _handleCollectorResponse(responseText: string): void {
        try {
            let response = <ICollectorResult>JSON.parse(responseText);
            if (isValueAssigned(response.webResult) && isValueAssigned(response.webResult.msfpc)) {
                // Set cookie
                setCookie('MSFPC', response.webResult.msfpc, 365);
            }
        }
        catch (ex) {

        }
    }
}
