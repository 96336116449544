/**
* DataModels.ts
* @author Abhilash Panwar (abpanwar)
* @copyright Microsoft 2018
* File containing the interfaces for Post channel module.
*/
import { IExtendedTelemetryItem } from '@ms/1ds-core-js';

/**
 * The IChannelConfiguration interface holds the configuration details passed to Post module.
 */
export interface IChannelConfiguration {
    /**
     * [Optional] The number of events that can be kept in memory before the SDK starts to drop events. By default, this is 10,000.
     */
    eventsLimitInMem?: number;
    /**
     * [Optional] The HTTP override that should be used to send requests, as an AWTXHROverride object.
     */
    httpXHROverride?: IXHROverride;
    /**
    * Override for Instrumentation key 
    */
    overrideInstrumentationKey?: string;
    /**
    * Override for Endpoint where telemetry data is sent
    */
    overrideEndpointUrl?: string;
    /**
     * The master off switch.  Do not send any data if set to TRUE
     */
    disableTelemetry?: boolean;
}

/**
 * The IXHROverride interface overrides the way HTTP requests are sent.
 */
export interface IXHROverride {
    /**
     * This method sends data to the specified URI using a POST request. If sync is true, 
     * then the request is sent synchronously. The <i>oncomplete</i> function should always be called after the request is
 * completed (either successfully or timed out or failed due to errors).
     */
    sendPOST: (urlString: string, data: Uint8Array | string,
        oncomplete: (status: number, headers: { [headerName: string]: string }, response?: string) => void, sync?: boolean) => void;
}

/**
 * The is the object returned when serializing the request.
 */
export interface IPayloadBlobWithMetadata {
    /**
     * Serialized payload blob that needs to be sent.
     */
    payloadBlob: string;
    /**
     * The remaning events in the request that were not serialized because the request 
     * exceeded the maximum size. We will send this request separately.
     */
    remainingRequest: { [token: string]: IPostTransmissionTelemetryItem[] };
}

/**
 * An interface which extends the telemetry event to track send attempts.
 */
export interface IPostTransmissionTelemetryItem extends IExtendedTelemetryItem {
    /**
     * The number of times the telemtry item has been attempted to be sent.
     */
    sendAttempt?: number;
}

/**
 * Real Time profile (default profile). RealTime Latency events are sent every 1 sec and
 * Normal Latency events are sent every 2 sec.
 */
export const RT_PROFILE = 'REAL_TIME';
/**
 * Near Real Time profile. RealTime Latency events are sent every 3 sec and
 * Normal Latency events are sent every 6 sec.
 */
export const NRT_PROFILE = 'NEAR_REAL_TIME';
/**
 * Best Effort. RealTime Latency events are sent every 9 sec and
 * Normal Latency events are sent every 18 sec.
 */
export const BE_PROFILE = 'BEST_EFFORT';
/**
 * An interface representing Collector Web responses.
 */
export interface ICollectorResult {
    /**
    * Number of events accepted.
    */
    acc?: number
    /**
   * Number of events rejected.
   */
    rej?: number
    /**
     * Web Result.
     */
    webResult?: ICollectorWebResult;

}
/**
 * An interface representing Collector Web responses.
 */
export interface ICollectorWebResult {
    /**
     * MSFPC cookie.
     */
    msfpc?: string;
    /**
     * Authentication error.
     */
    authError?: string;
    /**
     * Auth Login URL.
     */
    authLoginUrl?: string;
}
/**
 * Post channel interface
 */
export interface IPostChannel {
    /**
     * Add back the events from a failed request back to the queue. 
     * @param {object} request - The request whose events need to be added back to the batcher.
     */
    _addBackRequest(request: { [token: string]: IPostTransmissionTelemetryItem[] }): void;
    /**
    * Try to schedule the timer after which events will be sent. If there are 
    * no events to be sent, or there is already a timer scheduled, or the
    * http manager doesn't have any idle connections this method is no-op.
    */
    _scheduleTimer(): void;
    /**
     * Backs off tranmission. This exponentially increases all the timers.
     */
    _backOffTransmission(): void;
    /**
    * Clears backoff for tranmission.
    */
    _clearBackOff(): void;
}