import * as axiosStatic from "axios";

import { IAdalAxiosAuthenticationWrapperContext } from "./IAdalAxiosAuthenticationWrapperContext";
import { IInstrumentationService } from "clientcore-infrastructure-analytics/IInstrumentationService";
import { TokenBearerAxiosWrapper } from "../axios-wrapper/TokenBearerAxiosWrapper";

/**
 * Wraps axios and adds an authentication token from adal to the request headers.
 * @class
 */
export class AdalAxiosAuthenticationWrapper extends TokenBearerAxiosWrapper {

    /**
     * Initializes a new instance of the AdalAxiosAuthenticationWrapper class.
     * @constructor
     * @param authenticationContext {IAdalAxiosAuthenticationWrapperContext} The adal authenticationContext to use to get tokens.
     * @param instrumentationService {IInstrumentationService} The instrumentation service to log messages to.
     * @param promiseService? {Promise} es6-promise service instance.
     * @param axios? {axiosStatic.AxiosStatic} Axios service instance.
     */
    constructor(
        authenticationContext: IAdalAxiosAuthenticationWrapperContext,
        instrumentationService: IInstrumentationService,
        promiseService?: typeof Promise,
        axios?: axiosStatic.AxiosStatic) {

        super(authenticationContext, instrumentationService, promiseService, axios || axiosStatic);

        if (!authenticationContext) {
            throw "'authenticationContext' can't be null";
        }

        if (!instrumentationService) {
            throw "'instrumentationService' can't be null";
        }

        this.promiseService = promiseService || Promise;
    }
}