/**
* TelemetryContext.ts
* @author Hector Hernandez (hectorh)
* @copyright Microsoft 2019
*/

export class AppExtensionKeys {
    public static id = 'id';
    public static ver = 'ver';
    public static appName = 'name';
    public static locale = 'locale';
    public static expId = 'expId';
    public static env = 'env';
}

export class WebExtensionKeys {
    public static domain = 'domain';
    public static browser = 'browser';
    public static browserVer = 'browserVer';
    public static screenRes = 'screenRes';
    public static userConsent = 'userConsent';
}

export class UserExtensionKeys {
    public static locale = 'locale';
    public static localId = 'localId';
    public static id = 'id';
}

export class OSExtKeys {
    public static osName = 'name';
    public static ver = 'ver';
}

export class SDKExtKeys {
    public static ver = 'ver';
    public static seq = 'seq';
    public static installId = 'installId';
    public static epoch = 'epoch';
}

export class IntWebExtKeys {
    public static msfpc = 'msfpc';
    public static anid = 'anid';
    public static serviceName = 'serviceName';
}

export class UtcExtKeys {
    public static popSample = 'popSample';
}

export class LocExtKeys {
    public static tz = 'tz';
}

export class SessionExtKeys {
    public static sessionId = 'sesId';
}

export class DeviceExtKeys {
    public static localId = 'localId';
    public static deviceClass = 'deviceClass';
    public static make = 'make';
    public static model = 'model';
}

export class TraceExtKeys {
    public static traceId = 'traceID';
    public static traceName = 'name';
    public static parentId = 'parentID';
}
