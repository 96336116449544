import { IActivityLogItem } from "clientcore-infrastructure-analytics/models/IActivityLogItem";
import { ILoggingService } from "clientcore-infrastructure-analytics/ILoggingService";
import { IRetryManager } from "./IRetryManager";
import { ITransientErrorManager } from "./ITransientErrorManager";
import { LoggedExceptionManager } from "clientcore-infrastructure-analytics/LoggedExceptionManager";

/**
 * ExponentialBackoffRetryManager implements retry logic for transient errors.
 * Responses are of type T. It uses ITransientErrorManager<T> for determining whether given response is considered transient.
 * When encountering transient errors, the request is tried again and each retry is delayed exponentially using following calculation:
 * Math.min(this.baseRetryDelay * Math.pow(this.factor, retry), this.maxRetryDelay).
 * The request is retried until maximum retry count is reached or the request completes successfully or fails with a non-transient error.
 * Each delay is offset by a random number between a minimum and maximum offset range, so that in the cases of when there is a failure where many clients fail at once (e.g. network outage) there is not a huge amount of requests at the same time to the server.
 * @class
 */
export class ExponentialBackoffRetryManager<T> implements IRetryManager<T> {

    /**
     * Maximum allowed number of retries.
     * @type {number}
     */
    private static MaxAllowedRetries: number = 10;

    /**
     * Maximum allowed time in milliseconds to delay each retry.
     * @type {number}
     */
    private static MaxAllowedRetryDelay: number = 2000;

    /**
     * Default base delay between each retries.
     * @type {number}
     */
    private static DefaultBaseRetryDelay: number = 100;

    /**
     * Default number of retries.
     * @type {number}
     */
    private static DefaultMaxRetries: number = 5;

    /**
     * Default exponential factor to use to increase each retry delay.
     * @type {number}
     */
    private static DefaultFactor: number = 2;

    /**
     * Default minimum offset for each delay.
     * @type {number}
     */
    private static DefaultMinOffset: number = 1;

    /**
     * Default maximum offset for each delay.
     * @type {number}
     */
    private static DefaultMaxOffset: number = 25;

    /**
     * Used for delaying each retry.
     * @type {WindowOrWorkerGlobalScope}
     */
    private windowOrWorkerGlobalScope: WindowOrWorkerGlobalScope;

    /**
     * The Math Library.
     * @type {Math}
     */
    private math: Math;

    /**
     * Maximum number of retries.
     * Default is 5.
     * @type {number}
     */
    private maxRetries: number;

    /**
     * Base delay value in milliseconds to delay each retry.
     * Default value is 100.
     * @type {number}
     */
    private baseRetryDelay: number;

    /**
     * Maximum delay in milliseconds between each retry.
     * Default value is 2000.
     * @type {number}
     */
    private maxRetryDelay: number;

    /**
     * The exponential factor to use for delaying each subsequent retry.
     * Default value is 2.
     * @type {number}
     */
    private factor: number;

    /**
     * Minimum delay offet in milliseconds.
     * Default value is 1.
     * @type {number}
     */
    private minOffset: number;

    /**
     * Maximum delay offet in milliseconds.
     * Default value is 25.
     * @type {number}
     */
    private maxOffset: number;

    /** Initializes a new instance of the 'ExponentialBackoffRetryManager' class.
     * @constructor
     * @param transientErrorManager {ITransientErrorManager<T>} Used for determining whether given response is considered transient.
     * @param loggingService {ILoggingService}
     * @param windowOrWorkerGlobalScope? {WindowOrWorkerGlobalScope} For delaying retry of failing requests.
     * @param maxRetries? {number} The maximum number of times to retry the operation. Default is 5 and maximum value allowed is 10.
     * @param baseRetryDelay? {number} The base delay in milliseconds for each retry. Default is 100.
     * @param maxRetryDelay? {number} The maximum number of milliseconds between two retries. Default and maximum allowed is 2000.
     * @param factor? {number} The exponential factor to use. Default is 2.
     * @param minOffset? {number} The minimum delay in milliseconds offset to use. Default is 1.
     * @param maxOffset? {number} The maximum delay in milliseconds offset to use. Default is 25.
     */
    constructor(
        private transientErrorManager: ITransientErrorManager<T>,
        private loggingService: ILoggingService,
        windowOrWorkerGlobalScope?: WindowOrWorkerGlobalScope,
        maxRetries?: number,
        baseRetryDelay?: number,
        maxRetryDelay?: number,
        factor?: number,
        minOffset?: number,
        maxOffset?: number,
        math?: Math) {

        if (!loggingService) {
            throw ("loggingService is required for ExponentialBackoffRetryManager.");
        }

        const loggedExceptionManager = new LoggedExceptionManager(loggingService);

        if (!transientErrorManager) {
            loggedExceptionManager.fatal("transientErrorManager is required for ExponentialBackoffRetryManager.");
        }

        if (maxRetries && (maxRetries < 1 || maxRetries > ExponentialBackoffRetryManager.MaxAllowedRetries)) {
            loggedExceptionManager.fatal(`maxRetries should be at least 1 and less than ${ExponentialBackoffRetryManager.MaxAllowedRetries}.`);
        }

        if (baseRetryDelay && baseRetryDelay < ExponentialBackoffRetryManager.DefaultBaseRetryDelay) {
            loggedExceptionManager.fatal("baseRetryDelay has to be a positive value greater than or equal to 100.");
        }

        if (baseRetryDelay && maxOffset && baseRetryDelay <= maxOffset) {
            loggedExceptionManager.fatal("baseRetryDelay must not be smaller than the maximum delay offset.");
        }

        if (maxRetryDelay && (maxRetryDelay <= 0 || maxRetryDelay > ExponentialBackoffRetryManager.MaxAllowedRetryDelay)) {
            loggedExceptionManager.fatal(`maxRetryDelay has to be a positive value less than ${ExponentialBackoffRetryManager.MaxAllowedRetryDelay}.`);
        }

        if (factor && factor <= 0) {
            loggedExceptionManager.fatal("factor has to be a positive value.");
        }

        this.maxRetries = maxRetries || ExponentialBackoffRetryManager.DefaultMaxRetries;
        this.baseRetryDelay = baseRetryDelay || ExponentialBackoffRetryManager.DefaultBaseRetryDelay;
        this.maxRetryDelay = maxRetryDelay || ExponentialBackoffRetryManager.MaxAllowedRetryDelay;
        this.factor = factor || ExponentialBackoffRetryManager.DefaultFactor;
        this.windowOrWorkerGlobalScope = windowOrWorkerGlobalScope || window;
        this.minOffset = minOffset || ExponentialBackoffRetryManager.DefaultMinOffset;
        this.maxOffset = maxOffset || ExponentialBackoffRetryManager.DefaultMaxOffset;
        this.math = math || Math;

        this.loggingService.infoCallback(() => "ExponentialBackoffRetryManager has been configured.", null, { maxRetries: maxRetries, minTimeout: baseRetryDelay, maxTimeout: maxRetryDelay, factor: factor });
    }

    /**
     * Execute given request and retry it as configured.
     * @param requestCallback {() => Promise<T>} The request to execute and retry.
     * @param requestInfo? {any} Additional request info to include in logging.
     * @param activity? {IActivityLogItem} Optional activity to associate logged messages with.
     * @returns {Promise<T>}
     */
    public executeRequest(
        requestCallback: () => Promise<T>,
        requestInfo?: any,
        activity?: IActivityLogItem): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            this.tryRequest(requestCallback, resolve, reject, 0 /* currentRetry is 0 based */, requestInfo, activity);
        });
    }

    /**
     * Try the request if it fails with a transient error.
     * @param requestCallback {() => Promise<T>} The request to execute and retry.
     * @param resolve {(result?: any) => void} for resolving the promise.
     * @param reject {(error: any) => void} for rejecting the promise.
     * @param currentRetry {number} current retry count, 0 based.
     * @param requestInfo? {TRequestInfo} Additional request info to include in logging.
     * @param activity? {IActivityLogItem} Optional activity to associate logged messages with.
     * @returns {void}
     */
    private tryRequest(
        requestCallback: () => Promise<T>,
        resolve: (result?: any) => void,
        reject: (error: any) => void,
        currentRetry: number,
        requestInfo?: any,
        activity?: IActivityLogItem): void {

        requestCallback().then((response) => {
            this.loggingService.traceCallback(() => "Request has succeeded.", activity, { error: response, currentRetry: currentRetry, requestInfo: requestInfo });
            resolve(response);
        }).catch((error) => {
            // Check if the error is transient and retry if we didn't exceed the retry count.
            if ((currentRetry < this.maxRetries) && this.transientErrorManager.isTransientError(error)) {
                // Offset for delay, in the instance where there is some sort of outage and all clients retry at the same interval. In this case it will stagger the retries.
                const offset = parseFloat((this.math.random() * (this.minOffset - this.maxOffset) + this.maxOffset).toFixed(3));
                // Weather to add or subtract the random element.
                let plusOrMinus = this.math.random() < 0.5 ? -1 : 1;
                // Delay the retry based on the factor and the current retry count. Do not exceed maximum delay allowed.
                const delay = this.math.min(this.baseRetryDelay * this.math.pow(this.factor, currentRetry), this.maxRetryDelay);
                // Offset the delay either positively or negatively by the offset.
                let delayWithOffset = delay + (offset * plusOrMinus);
                this.loggingService.infoCallback(() => `Retrying the request in ${delayWithOffset} milliseconds.`, activity, { error: error, currentRetry: currentRetry + 1, requestInfo: requestInfo });
                this.windowOrWorkerGlobalScope.setTimeout(() => this.tryRequest(requestCallback, resolve, reject, currentRetry + 1), delayWithOffset);
            } else {
                // Give up retrying.
                this.loggingService.traceCallback(
                    () => (currentRetry < this.maxRetries) ? `ExponentialBackoffRetryManager has exceeded maximum retries ${this.maxRetries}` : "Request has failed.",
                    activity,
                    { error: error, currentRetry: currentRetry, requestInfo: requestInfo });
                reject(error);
            }
        });
    }
}