/**
* Sdk.ts
* @author Hector Hernandez (hectorh)
* @copyright Microsoft 2019
*/

import {
    CoreUtils, IExtendedConfiguration, IPropertyStorageOverride,
    FullVersionString, cookieAvailable, setCookie, getCookie, deleteCookie
} from '@ms/1ds-core-js';

const DEVICE_ID_COOKIE = 'MicrosoftApplicationsTelemetryDeviceId';

export class Sdk {
    /**
     * ID incremented for each event.
     */
    public seq: number;
    /**
     * ID created on first-time SDK initialization.
     */
    public installId: string;
    /**
     * ID incremented for each SDK initialization..
     */
    public epoch: string;

    private _sequenceId = 0;
    private _propertyStorage: IPropertyStorageOverride;

    constructor(coreConfig: IExtendedConfiguration) {
        this._propertyStorage = coreConfig.propertyStorageOverride;
        //Start sequence
        this.seq = this._sequenceId;
        this.epoch = (new Date).getTime().toString();

        //Only collect device id if it can be stored
        if (!coreConfig.disableCookiesUsage && cookieAvailable() || this._propertyStorage) {
            let deviceId = this._getData(DEVICE_ID_COOKIE);
            if (!deviceId) {
                deviceId = CoreUtils.newGuid();
            }
            this._saveData(DEVICE_ID_COOKIE, deviceId);
            this.installId = deviceId;
        } else {
            deleteCookie(DEVICE_ID_COOKIE);
        }
    }

    // Get seq value
    public getSequenceId() {
        return ++this._sequenceId;
    }

    private _saveData(name: string, value: string) {
        var propertyStorage = this._propertyStorage;
        if (propertyStorage) {
            propertyStorage.setProperty(name, value);
        } else {
            //Expires in 365 days
            setCookie(name, value, 365);
        }
    }

    private _getData(name: string) {
        if (this._propertyStorage) {
            return this._propertyStorage.getProperty(name) || '';
        } else {
            return getCookie(name);
        }
    }
}
