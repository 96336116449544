/**
* OperatingSystem.ts
* @author Hector Hernandez (hectorh)
* @copyright Microsoft 2019
*/

import { IPropertyConfiguration, IOperatingSystemContext } from '../DataModels';

const OPERATING_SYSTEMS = {
    WINDOWS: 'Windows',
    MACOSX: 'Mac OS X',
    ANDROID: 'Android'
};
const OSNAMEREGEX = {
    WIN: /(windows|win32)/i,
    WINRT: / arm;/i,
    WINPHONE: /windows\sphone\s\d+\.\d+/i,
    OSX: /(macintosh|mac os x)/i,
    IOS: /(ipad|iphone|ipod)(?=.*like mac os x)/i,
    LINUX: /(linux|joli|[kxln]?ubuntu|debian|[open]*suse|gentoo|arch|slackware|fedora|mandriva|centos|pclinuxos|redhat|zenwalk)/i,
    ANDROID: /android/i,
    CROS: /CrOS/i
};
const VERSION_MAPPINGS = {
    '5.1': 'XP',
    '6.0': 'Vista',
    '6.1': '7',
    '6.2': '8',
    '6.3': '8.1',
    '10.0': '10'
};
const REGEX_VERSION = '([\\d,.]+)';
const REGEX_VERSION_MAC = '([\\d,_,.]+)';
const UNKNOWN = 'Unknown';

export class OperatingSystem implements IOperatingSystemContext {
    /**
     * Operating system name.
     */
    public name: string;
    /**
     * Semantic: major.minor where major and minor are decimal numbers. Indicates the major and minor version of the operating system.
     */
    public ver: string;

    constructor(propertiesConfig: IPropertyConfiguration) {
        let userAgent = typeof navigator !== 'undefined' ? navigator.userAgent : '';
        if (propertiesConfig.userAgent) {
            userAgent = propertiesConfig.userAgent;
        }
        if (userAgent && propertiesConfig.populateOperatingSystemInfo) {
            let osName = this._getOsName(userAgent.toLowerCase());
            this.name = osName;
            this.ver = this._getOsVersion(userAgent, osName);
        }
    }

    private _getOsName(lowerCaseUserAgent: string) {
        if (lowerCaseUserAgent.match(OSNAMEREGEX.WINPHONE)) {
            return 'Windows Phone';
        }
        if (lowerCaseUserAgent.match(OSNAMEREGEX.WINRT)) {
            return 'Windows RT';
        }
        if (lowerCaseUserAgent.match(OSNAMEREGEX.WIN)) {
            return OPERATING_SYSTEMS.WINDOWS;
        }
        if (lowerCaseUserAgent.match(OSNAMEREGEX.IOS)) {
            return 'iOS';
        }
        if (lowerCaseUserAgent.match(OSNAMEREGEX.ANDROID)) {
            return OPERATING_SYSTEMS.ANDROID;
        }
        if (lowerCaseUserAgent.match(OSNAMEREGEX.LINUX)) {
            return 'Linux';
        }
        if (lowerCaseUserAgent.indexOf('x11') !== -1) {
            return 'Unix';
        }
        if (lowerCaseUserAgent.indexOf('blackberry') !== -1) {
            return 'BlackBerry';
        }
        if (lowerCaseUserAgent.indexOf('symbian') !== -1) {
            return 'Symbian';
        }
        if (lowerCaseUserAgent.indexOf('nokia') !== -1) {
            return 'Nokia';
        }
        if (lowerCaseUserAgent.match(OSNAMEREGEX.OSX)) {
            return OPERATING_SYSTEMS.MACOSX;
        }
        if (lowerCaseUserAgent.match(OSNAMEREGEX.CROS)) {
            return 'Chrome OS';
        }
        return UNKNOWN;
    }

    private _getOsVersion(userAgent: string, osName: string) {
        if (osName === OPERATING_SYSTEMS.WINDOWS) {
            return this._getGenericOsVersion(userAgent, 'Windows NT');
        }
        if (osName === OPERATING_SYSTEMS.ANDROID) {
            return this._getGenericOsVersion(userAgent, osName);
        }
        if (osName === OPERATING_SYSTEMS.MACOSX) {
            return this._getMacOsxVersion(userAgent);
        }
        return UNKNOWN;
    }

    private _getGenericOsVersion(userAgent: string, osName: string) {
        let ntVersionMatches = userAgent.match(new RegExp(osName + ' ' + REGEX_VERSION));
        if (ntVersionMatches) {
            if (VERSION_MAPPINGS[ntVersionMatches[1]]) {
                return VERSION_MAPPINGS[ntVersionMatches[1]];
            }
            return ntVersionMatches[1];
        }
        return UNKNOWN;
    }

    private _getMacOsxVersion(userAgent: string) {
        let macOsxVersionInUserAgentMatches = userAgent.match(
            new RegExp(OPERATING_SYSTEMS.MACOSX + ' ' + REGEX_VERSION_MAC)
        );
        if (macOsxVersionInUserAgentMatches) {
            let versionString = macOsxVersionInUserAgentMatches[1].replace(/_/g, '.');
            if (versionString) {
                var delimiter = this._getDelimiter(versionString);
                if (delimiter) {
                    let components = versionString.split(delimiter);
                    return components[0];
                } else {
                    return versionString;
                }
            }
        }
        return UNKNOWN;
    }

    private _getDelimiter(versionString: string) {
        if (versionString.indexOf('.') > -1) {
            return '.';
        }
        if (versionString.indexOf('_') > -1) {
            return '_';
        }
        return null;
    }
}
