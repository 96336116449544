import { IBrowserWindow } from "../../browser-object-models/IBrowserWindow";
import { IEventBasicData } from "../commonSchema/IEventBasicData";
import { IPageViewPartCContent } from "./IPageViewPartCContent";
import { IUserActivityLogItem } from "../IUserActivityLogItem";
import { TrackingPartCContent } from "./TrackingPartCContent";

/**
 * Represents the parameters of the asimov PageView event Part C content that is predefined by JSLL (Javascript logging library) of Asimov and 1DS pipeline.
 * @class
 */
export class PageViewPartCContent extends TrackingPartCContent implements IPageViewPartCContent {

    /**
     * Initializes a new instance of the `PageViewPartCContent` class.
     * @param title {string} The page title.
     * @param schemaVer {string} The schema version for the schema defined by AnalyticsSDK.
     * @param documentData? {string} The document or documents on which page view or page action is performed.
     * @param browserSize? {string} The Size of the browser window. Ex: 1024x768.
     * @param cookieEnabled? {boolean} The flag to report if cookies enabled/disabled in the browser.
     * @param extendedData? {string} The additional data associated with the event.
     * @param isJs? {boolean} The flag to report that the event is from JS. It will always be true for client logs.
     * @param isManual? {boolean} The flag to report that the event is manually triggered or not. It will always be true for the client logs.
     * @param serverImpressionGuid? {string} The server impression guid.
     */
    constructor(
        title: string,
        schemaVer: string,
        documentData?: string,
        browserSize?: string,
        cookieEnabled?: boolean,
        extendedData?: string,
        isJs?: boolean,
        isManual?: boolean,
        serverImpressionGuid?: string) {
        super(title, schemaVer, documentData, browserSize, cookieEnabled, extendedData, isJs, isManual, serverImpressionGuid);
    }

    /**
     * Creates a new PageViewPartCContent from a given log item.
     * @param userActivityLogItem {IUserActivityLogItem} The log item.
     * @param windowContext {IBrowserWindow} The window object of the browser.
     * @param eventSeqNum {number} The event sequence number.
     * @param serverImpressionGuid? {string} The server impression guid.
     * @return {IPageViewPartCContent} The page action p data.
     */
    public static createFrom(
        userActivityLogItem: IUserActivityLogItem,
        windowContext: IBrowserWindow,
        basicData: IEventBasicData,
        serverImpressionGuid: string): IPageViewPartCContent {

        let trackingPartCContent = TrackingPartCContent.createFrom(userActivityLogItem, windowContext, basicData, serverImpressionGuid);

        return new PageViewPartCContent(
            trackingPartCContent.title,
            trackingPartCContent.schemaVer,
            trackingPartCContent.documentData,
            trackingPartCContent.browserSize,
            trackingPartCContent.cookieEnabled,
            trackingPartCContent.extendedData,
            trackingPartCContent.isJs,
            trackingPartCContent.isManual,
            trackingPartCContent.serverImpressionGuid);
    }
}
