/**
* User.ts
* @author Hector Hernandez (hectorh)
* @copyright Microsoft 2019
*/

import {
    IExtendedConfiguration, cookieAvailable, getCookie,
    objDefineAccessors, IDiagnosticLogger
} from '@ms/1ds-core-js';
import { IUserContext, IPropertyConfiguration } from '../DataModels';
import * as Utils from '../Utils'

export class User implements IUserContext {

    static cookieSeparator: string = '|';
    static userCookieName: string = 'ai_user';

    /**
     * Returns or sets the userId as known by the logging library.
     * Note: This property will NOT exist in ES3/IE8 environment, if you need IE8 compatibility
     * use the methods {@link User#getLocalId} and  {@link User#setLocalId} from your code. For ES5+ environment this will be replaced with a 
     * property getter only.
     */
    public localId?:string|undefined;

    /**
     * The userId as known by the logging library.
     */
    getLocalId(): string | undefined {
        if (this._customLocalId) {
            return this._customLocalId;
        }
        let muidValue = getCookie('MUID');
        if (muidValue) {
            this.setLocalId('t:' + muidValue);
        }
        return undefined;
    }
    setLocalId(value: string) {
        this._customLocalId = value;
    }

    /**
     * Language and region (IETF language tag).
     */
    public locale: string;

    /**
    * UserID used by ApplicationInsights
    */
    public id: string;

    /**
     * The account acquisition date.
     */
    public accountAcquisitionDate: string;

    private _customLocalId: string;

    private _logger: IDiagnosticLogger;

    constructor(coreConfig: IExtendedConfiguration, propertiesConfig: IPropertyConfiguration, logger?: IDiagnosticLogger) {
        this._logger = logger;
        //Add MUID in user localId
        if (!coreConfig.disableCookiesUsage && cookieAvailable()) {
            let muidValue = getCookie('MUID');
            if (muidValue) {
                this.setLocalId('t:' + muidValue);
            }
            if (propertiesConfig.enableApplicationInsightsUser) {
                // get userId or create new one if none exists
                const aiUser = getCookie(User.userCookieName);
                if (aiUser) {
                    const params = aiUser.split(User.cookieSeparator);
                    if (params.length > 0) {
                        this.id = params[0];
                    }
                }
                if (!this.id) {
                    this.id = Utils.newId();
                    const date = new Date();
                    const acqStr = Utils.toISOString(date);
                    this.accountAcquisitionDate = acqStr;
                    // without expiration, cookies expire at the end of the session
                    // set it to 365 days from now
                    // 365 * 24 * 60 * 60 * 1000 = 31536000000 
                    date.setTime(date.getTime() + 31536000000);
                    const newCookie = [this.id, acqStr];
                    const cookieDomain = propertiesConfig.cookieDomain ? propertiesConfig.cookieDomain : undefined;
                    Utils._setCookie(this._logger, User.userCookieName, newCookie.join(User.cookieSeparator) + ';expires=' + date.toUTCString(), cookieDomain);
                }
            }
        }
        //Add user language
        if (typeof navigator !== 'undefined') {
            let nav = navigator;
            this.locale = nav.userLanguage || nav.language;
        }
    }

    /**
     * Static constructor, attempt to create accessors
     */
    private static _staticInit = (() => {
        // Dynamically create get/set property accessors
        objDefineAccessors(User.prototype, "localId", User.prototype.getLocalId, User.prototype.setLocalId);
    })();
}
