import { EventBasicData } from "../commonSchema/EventBasicData";
import { EventLatency } from "@ms/1ds-core-js";
import { IActivityEventContent } from "../commonSchema/IActivityEventContent";
import { IActivityLogItem } from "../IActivityLogItem";
import { IBrowserWindow } from "../../browser-object-models/IBrowserWindow";
import { IOneDsEventData } from "./IOneDsEventData";
import { IOneDsExtension } from "./IOneDsExtension";
import { OneDsNamespaceProfile } from "./OneDsNamespaceProfile";
import { OperationalEventContent } from "../commonSchema/OperationalEventContent";

/**
 * Represents the parameters of the activity custom event schema to log into 1DS system.
 * @class
 */
export class OneDsActivityEventData implements IOneDsEventData<IActivityEventContent> {

    /**
     * Initializes a new instance of the `OneDsActivityEventData` class.
     * @constructor
     * @param name {string} The name(namespace) of the activity custom event.
     * @param time {string} The date and time of the event in ISO format.
     * @param data {[key: string]: IActivityEventContent | string} The activity event data contains activity data and namepsace.
     * @param ext {IOneDsExtension} The extension fields.
     * @param latency {number} The latency corresponding to EventLatency.
     * @param sync {boolean} The flag to set if the event is to be sent synchronously.
     */
    constructor(
        public name: string,
        public time: string,
        public data: { [key: string]: IActivityEventContent | string },
        public ext?: IOneDsExtension,
        public latency?: number,
        public sync?: boolean) {
    }

    /**
     * Creates a new OneDsActivityEventData from a given log item.
     * @param logItem {IActivityLogItem} The log item.
     * @param windowContext {IBrowserWindow} The window object of the browser.
     * @param application {string} The application name.
     * @param source {string} The source name.
     * @param impressionGuid {string} The impression guid.
     * @param tenant {string} The tenant name or id.
     * @param time {string} The date and time of the event in ISO format.
     * @param serverImpressionGuid {string} The server impression guid.
     * @param oneDsNamespaceProfile {OneDsNamespaceProfile} The namespace profile for the 1DS events.
     * @param latency {number} The latency corresponding to EventLatency.
     * @param sync {boolean} The flag to set if the event is to be sent synchronously.
     * @return {OneDsActivityEventData} The activity event data.
     */
    public static createFrom(
        logItem: IActivityLogItem,
        windowContext: IBrowserWindow,
        application: string,
        source: string,
        impressionGuid: string,
        tenant: string,
        time: string,
        serverImpressionGuid: string,
        oneDsNamespaceProfile: OneDsNamespaceProfile,
        latency?: number,
        sync?: boolean): OneDsActivityEventData {

        const basicData = EventBasicData.createFrom(logItem, windowContext);
        const eventContent: IActivityEventContent = OperationalEventContent.createFrom(logItem, basicData, application, source, impressionGuid, tenant, serverImpressionGuid);

        eventContent.elapsedTimeInMs = logItem.elapsedTimeInMs;

        const eventData: { [key: string]: IActivityEventContent | string } = {
            baseData: eventContent,
            baseType: oneDsNamespaceProfile.clientActivityDataSchemaNamespace
        };

        const ext = {
            mscv: { cV: logItem.correlationVector },
            web: { isManual: true }
        };

        return new OneDsActivityEventData(oneDsNamespaceProfile.clientActivitySchemaNamespace, time, eventData, ext, latency || EventLatency.Normal, sync);
    }
}
