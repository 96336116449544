/**
* WebAuthHandler.ts
* @author Hector Hernandez (hectorh)
* @copyright Microsoft 2019
*/

import { AuthType } from './Enums';
import { RequestHandler } from './RequestHandler';
import { IDiagnosticLogger, LoggingSeverity, _ExtendedInternalMessageId } from '@ms/1ds-core-js';

export class WebAuthHandler {
    private _loginIframeId: string;
    private _endpointUrl = '';
    private _getLoginUrlPath: string;
    private _signOutPath: string;
    private _requestHandler: RequestHandler;
    private _logger: IDiagnosticLogger;

    constructor(endpointUrl: string, authType: number, logger: IDiagnosticLogger) {
        let authTypeResource = '';
        switch (authType) {
            case AuthType.AAD:
                authTypeResource = 'aad.js';
                break;
            case AuthType.MSA:
                authTypeResource = 'msa.js';
                break;
        }
        this._logger = logger;
        this._endpointUrl = endpointUrl;
        this._getLoginUrlPath = this._endpointUrl + 'auth/GetLoginUrl/' + authTypeResource;
        this._signOutPath = this._endpointUrl + 'auth/SignOut/' + authTypeResource;
        this._requestHandler = new RequestHandler();
    }

    /**
    * Start auth handhshake process using iframe
    */
    public startAuthHandshake() {
        this._requestHandler.makeRequest(this._getLoginUrlPath, '', 'GET', (status: number, responseBody: string, headers: { [headerName: string]: string }) => {
            try {
                var json = JSON.parse(responseBody);
                let loginUrl = json.webResult.authLoginUrl;
                // Create iframe
                let loginIframe = document.createElement("iframe");
                var randomNumber = Math.floor(Math.random() * Math.floor(10000));
                loginIframe.id = "telframe" + randomNumber;
                loginIframe.style.display = "none";
                loginIframe.src = loginUrl;
                document.body.appendChild(loginIframe);
                this._loginIframeId = loginIframe.id;
            }
            catch (e) {
                this._logger.throwInternal(
                    LoggingSeverity.CRITICAL,
                    _ExtendedInternalMessageId.AuthHandShakeError, "Error with auth handshake process: " + e
                )
            }
        });
    }

    /**
    * Remove auth handhsake iframe from DOM
    */
    public cleanAuthIFrame() {
        // Remove auth iFrame if present
        let authIframe = document.getElementById(this._loginIframeId)
        if (authIframe) {
            document.body.removeChild(authIframe);
        }
    }


    /**
    * Sign out and remove 1DS server auth cookies
    */
    public signOutAndClearCookies() {
        this._requestHandler.makeRequest(this._signOutPath, '', 'POST',
            (status: number, responseBody: string, headers: { [headerName: string]: string }) => {
                // Cookies deleted
            }
        );
    }
}

