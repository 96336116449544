import * as axiosStatic from "axios";

import { AxiosWrapperBase } from "../axios-wrapper/AxiosWrapperBase";

/**
 * Adds a timout value to the axios wrapper, so that a timeout value can be set.
 * @class
 */
export class TimeoutWrapper extends AxiosWrapperBase {

    /**
     * Initializes a new instance of the TimeoutWrapper class.
     * @param timeout {number}  the timeout to use.
     * @param axios? {axiosStatic.AxiosStatic}  axios to wrap around.
     */
    constructor(
        private timeout: number,
        axios?: axiosStatic.AxiosStatic
    ) {
        super(axios || axiosStatic);
        if (!timeout) {
            throw "timeout must have a value.";
        }
    }

    /**
     * Add the security token to every request before it is sent.
     * @param config {axiosStatic.RequestOptions} The request detail.
     * @returns {void}
     */
    protected onRequest<T>(config: axiosStatic.RequestOptions): void {

        if (!config) {
            throw "config must not be null";
        }

        config.timeout = this.timeout;
    }
}