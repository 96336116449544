/**
* BaseCore.ts
* Base Core is a subset of 1DS Web SDK Core. The purpose of Base Core is to generate a smaller bundle size while providing essential features of Core. Features that are not included in Base Core are: 
* 1. Internal logging 
* 2. Sending notifications on telemetry sent/discarded
* @author Abhilash Panwar (abpanwar) Hector Hernandez (hectorh)
* @copyright Microsoft 2018
*/
import { BaseCore as InternalCore, IPlugin, LoggingSeverity } from '@microsoft/applicationinsights-core-js';
import { IExtendedConfiguration, IExtendedTelemetryItem, IExtendedAppInsightsCore } from './DataModels';
import { _ExtendedInternalMessageId } from './Enums';
import { FullVersionString } from './Utils';

export default class BaseCore extends InternalCore implements IExtendedAppInsightsCore {

    // Function to get w parameter used by OneCollector
    public getWParam: () => number;

    /**
     * Initialize the sdk.
     * @param {IExtendedConfiguration} config - The configuration to initialize the SDK.
     * @param {IPlugin[]} extensions          - An array of extensions that are to be used by the core.
     */
    initialize(config: IExtendedConfiguration, extensions: IPlugin[]) {
        if (config) {
            if (!config.endpointUrl) {
                config.endpointUrl = 'https://browser.events.data.microsoft.com/OneCollector/1.0/';
            }
        }
        this.getWParam = () => {
            return typeof document !== 'undefined' ? 0 : -1;
        };
        try {
            super.initialize(config, extensions);
        } catch (e) {
            this.logger.throwInternal(
                LoggingSeverity.CRITICAL,
                _ExtendedInternalMessageId.ErrorProvidedChannels, "Channels must be provided through config.channels only"
            )
        }
    }

    track(telemetryItem: IExtendedTelemetryItem) {
        if (telemetryItem) {
            telemetryItem.ext = telemetryItem.ext || {};
            telemetryItem.ext.sdk = telemetryItem.ext.sdk || {};
            telemetryItem.ext.sdk.ver = FullVersionString;
        }
        super.track(telemetryItem);
    }
}
