import { IAppender } from "./appenders/IAppender";
import { ILogger } from "./loggers/ILogger";
import { LogLevel } from "./models/LogLevel";

/**
 * Base class to have common code across different logging services.
 * @class
 */
export class BaseService {

    /**
     * The key for correlation vector field.
     * @type {string}
     */
    // tslint:disable-next-line: ext-variable-name
    public static CorrelationVectorKey: string = "correlationVector";

    /**
     * The logger.
     */
    private _logger: ILogger;

    /**
     * Initializes a new instance of the `LoggingService` class.
     * @constructor
     * @param loggerName {string} Name of the logger.
     */
    constructor(logger: ILogger) {
        if (!logger) {
            throw "'logger' parameter can't be null";
        }

        this._logger = logger;
    }

    /**
     * Gets the logger.
     * @property
     * @returns {Logger} The logger.
     */
    get logger(): ILogger
    {
        return this._logger;
    }

    /**
     * Gets the logger name.
     * @method
     * @returns {string} The logger name.
     */
    public getLoggerName(): string {
        return this.logger.getLoggerName();
    }

    /**
     * Sets the log level.
     * @method
     * @param logLevel {LogLevel} Logging level or threshold.
     */
    public setLevel(logLevel?: LogLevel): void {
        this.logger.setLevel(logLevel);
    }

    /**
     * Gets the log level.
     * @method
     * @returns {LogLevel} The Logging level or threshold.
     */
    public getLevel(): LogLevel {
        return this.logger.getLevel();
    }

    /**
     * Flushes the log items explicitly if appenders are using batching.
     * @method
     */
    public flush(): void {
        this.logger.flush();
    }

    /**
     * Adds the given appender.
     * @method
     * @param appender {IAppender} Appender to be added.
     */
    public addAppender(appender: IAppender): void {
        this.logger.addAppender(appender);
    }

    /**
     * Removes the given appender.
     * @method
     * @param appender {IAppender} Appender to be removed.
     */
    public removeAppender(appender: IAppender): void {
        this.logger.removeAppender(appender);
    }

    /**
     * Clears all appenders for the current logger.
     * @method
     */
    public removeAllAppenders(): void {
        this.logger.removeAllAppenders();
    }
}
 