import { IAdalAngular } from "adal-types";
import { IAdalAxiosAuthenticationWrapperContext } from "./IAdalAxiosAuthenticationWrapperContext";

/**
 * Converts the interface of selected methods on an Adal Angular service object from the adal library to match the required interface
 * of the AdalAxiosAuthenticationWrapper.
 * @class
 */
export class AdalAngularAdapter implements IAdalAxiosAuthenticationWrapperContext {

    /**
     * The adal angular service to use to get tokens.
     * @type {IAdalAngular}
     */
    private adalAngular: IAdalAngular;

    /**
     * Initializes a new instance of the AdalAngularAdapter class.
     * @constructor
     * @param adalAngular {any} The adal angular service to use to get tokens.
     */
    constructor(adalAngular: any) {

        if (!adalAngular) {
            throw "'adalAngular' can't be null";
        }

        if (!adalAngular.getResourceForEndpoint || !adalAngular.acquireToken) {
            throw "'adalAngular' needs to implement getResourceForEndpoint and acquireToken";
        }

        this.adalAngular = adalAngular;
    }

    /**
     * Gets a resource id for a service url.
     * @param endpoint {string} The service url to get a resource id for.
     * @returns {Promise<string>} A promise for the resource id of the requested service url.
     */
    public getResourceForEndpoint(endpoint: string): Promise<string> {
        return Promise.resolve(this.adalAngular.getResourceForEndpoint(endpoint));
    }

    /**
     * Gets a authentication token for a given resource id.
     * @param resourceId {string} The resource id to request a token for.
     * @returns {Promise<string>} A promise to return the token for the requested resource id.
     */
    public acquireToken(resourceId: string): Promise<string> {
        return this.adalAngular.acquireToken(resourceId);
    }
}