import { IRpcServer } from "../IRpcServer";
import { IServerTransport } from "../IServerTransport";

import { IWindowPostMessageRequest } from "./IWindowPostMessageRequest";
import { IWindowPostMessageResponse } from "./IWindowPostMessageResponse";

/**
 * Provides ability to receive and respond to messages using window.postMessage.
 * @class
 */
export class WindowPostMessageServerTransport implements IServerTransport {

    /**
     * The domain name of the iframe to receive messages from.
     * @type {string}
     */
    private targetOrigin: string;

    /**
     * The server that will determine the right service to call.
     * @type {IRpcServer}
     */
    private server: IRpcServer;

    /**
     * The window object of the local iframe to listen for request messages at.
     * @type {Window}
     */
    private localWindow: Window;

    /**
     * The window object of the client iframe to post response messages to.
     * @type {Window}
     */
    private remoteWindow: Window;

    /**
     * Initializes a new instance of the PostMessageServerTransport class.
     * @constructor
     * @param targetOrigin {string} The domain name of the iframe to receive messages from.
     * @param server {IRpcServer} The server that will determine the right service to call.
     * @param localWindow {Window} The window object of the local iframe to listen for request messages at.
     * @param remoteWindow {Window} The window object of the client iframe to post response messages to.
     */
    constructor(targetOrigin: string, server: IRpcServer, localWindow: Window, remoteWindow: Window) {

        if (!targetOrigin) {
            throw "The targetOrigin parameter is required";
        }

        if (!server) {
            throw "The server parameter is required";
        }

        if (!localWindow) {
            throw "The localWindow parameter is required";
        }

        if (!remoteWindow) {
            throw "The remoteWindow parameter is required";
        }

        this.targetOrigin = targetOrigin;
        this.server = server;
        this.remoteWindow = remoteWindow;
        this.localWindow = localWindow;
        this.receiveMessage = this.receiveMessage.bind(this);
    }

    /**
     * Start listening for messages posted to the server window.
     */
    public startListening(): void {
        this.localWindow.addEventListener("message", this.receiveMessage, false);
    }

    /**
     * Stop listening for messages posted to the server window.
     */
    public stopListening(): void {
        this.localWindow.removeEventListener("message", this.receiveMessage, false);
    }

    /**
     * Processes messages received from the client.
     * @param event {MessageEvent} The event arguments.
     * @returns {void}
     */
    private receiveMessage(event: MessageEvent): void {
        // Check that the origin is allowed and the request contains the fields we require.
        if (event.origin === this.targetOrigin &&
            event.data &&
            event.data.senderId &&
            event.data.requestId &&
            event.data.serviceName &&
            event.data.serviceMethod &&
            event.data.parameters) {

            let request = <IWindowPostMessageRequest>event.data;
            this.server.callService(request.serviceName, request.serviceMethod, request.parameters).then((data) => {
                // Send back a success response.
                let response: IWindowPostMessageResponse = {
                    senderId: request.senderId,
                    requestId: request.requestId,
                    data: data,
                    error: null
                };
                this.remoteWindow.postMessage(response, this.targetOrigin);
            }).catch((error) => {
                // Send back an error response.
                let response: IWindowPostMessageResponse = {
                    senderId: request.senderId,
                    requestId: request.requestId,
                    data: null,
                    error: error
                };
                this.remoteWindow.postMessage(response, this.targetOrigin);
            });
        }
    }
}