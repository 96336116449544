/**
 * Enum for Log level.
 * @readonly
 * @enum {number}
 */
export enum LogLevel {
    All = 128,
    Activity = 64,
    Trace = 32,
    Debug = 16,
    Info = 8,
    Warn = 4,
    Error = 2,
    Fatal = 1,
    Off = 0
}
