/**
* IncomingQosEvent.ts
* @author Hector Hernandez (hectorh)
* @copyright Microsoft 2018
*/

import { IExtendedTelemetryItem, isValueAssigned } from '@ms/1ds-core-js';
import QosEvent from './QosEvent';
import { IIncomingServiceRequest } from '../interfaces/ITelemetryEvents';

export default class IncomingQosEvent {

    private _qosEvent: QosEvent;

    constructor() {
        this._qosEvent = new QosEvent();
    }

    public _setQosProperties(event: IExtendedTelemetryItem, eventData: IIncomingServiceRequest) {
        this._qosEvent._setCommonProperties(event, eventData);
        if (isValueAssigned(eventData.callerIpAddress)) {
            event.baseData['callerIpAddress'] = eventData.callerIpAddress;
        }
        if (isValueAssigned(eventData.callerName)) {
            event.baseData['callerName'] = eventData.callerName;
        }
        if (isValueAssigned(eventData.requestSizeBytes)) {
            event.baseData['requestSizeBytes'] = eventData.requestSizeBytes;
        }
        if (isValueAssigned(eventData.operationVersion)) {
            event.baseData['operationVersion'] = eventData.operationVersion;
        }
    }
}
