/**
* PostManager.ts
* @author Abhilash Panwar (abpanwar) Hector Hernandez (hectorh)
* @copyright Microsoft 2018
*/
import {
    ITelemetryPlugin, IChannelControls, IExtendedConfiguration, IExtendedAppInsightsCore,
    EventLatency, NotificationManager, EventsDiscardedReason, IPlugin, ITelemetryItem, 
    IAppInsightsCore, isValueAssigned, addPageUnloadEventListener
} from '@ms/1ds-core-js';
import {
    IChannelConfiguration, RT_PROFILE, NRT_PROFILE, IPostChannel,
    BE_PROFILE, IXHROverride, IPostTransmissionTelemetryItem
} from './DataModels';
import HttpManager from './HttpManager';
import RecordBatcher from './RecordBatcher';
import RetryPolicy from './RetryPolicy';

const FlushCheckTimer = 250;
const MaxNumberEventPerBatch = 500;
const EventsDroppedAtOneTime = 20;
const MaxSendAttempts = 6;
const MaxBackoffCount = 4;
/**
 * Class that manages adding events to inbound queues and batching of events
 * into requests.
 */
export default class PostChannel implements IChannelControls, IPostChannel {

    public identifier = 'PostChannel';
    public priority = 1011;
    public version = '2.1.1';
    private _nextPlugin: ITelemetryPlugin;
    private _config: IChannelConfiguration;
    private _core: IExtendedAppInsightsCore;
    private _batcher: RecordBatcher;
    private _isTeardownCalled = false;
    private _syncFlushBatcher: RecordBatcher;
    private _isCurrentlyFlushingNow = false;
    private _flushQueue: { (): void; }[] = [];
    private _databaseAdded = false;
    private _paused = false;
    private _queueSize = 0;
    private _queueSizeLimit = 10000;
    private _profiles: { [profileName: string]: number[] } = {};
    private _currentProfile = RT_PROFILE;
    private _timeout: any = null;
    private _currentBackoffCount = 0;
    private _timerCount = 0;
    private _xhrOverride: IXHROverride | undefined;
    private _flushCallback = -1;
    private _notificationManager: NotificationManager | undefined;
    private _flushOutboundQueue: { [token: string]: IPostTransmissionTelemetryItem[] }[] = [];
    private _httpManager: HttpManager;
    private _outboundQueue: { [token: string]: IPostTransmissionTelemetryItem[] }[] = [];
    private _inboundQueues: { [eventLatency: number]: IPostTransmissionTelemetryItem[][] } = {};

    constructor() {
        this._inboundQueues[EventLatency.RealTime] = [];
        this._inboundQueues[EventLatency.CostDeferred] = [];
        this._inboundQueues[EventLatency.Normal] = [];
        this._initializeProfiles();
        this._addEmptyQueues();
        this._batcher = new RecordBatcher(this._outboundQueue, MaxNumberEventPerBatch);
        this._syncFlushBatcher = new RecordBatcher(this._flushOutboundQueue, MaxNumberEventPerBatch);
        this._httpManager = new HttpManager(this._outboundQueue);
    }

    /**
     * Start the queue manager to batch and send events via post.
     * @param config The core configuration.
     */
    initialize(coreConfig: IExtendedConfiguration, core: IAppInsightsCore, extensions: IPlugin[]) {
        this._core = <IExtendedAppInsightsCore>core;
        coreConfig.extensionConfig = coreConfig.extensionConfig || [];
        coreConfig.extensionConfig[this.identifier] = coreConfig.extensionConfig[this.identifier] || {};
        this._config = coreConfig.extensionConfig[this.identifier];
        if (this._config.eventsLimitInMem > 0) {
            this._queueSizeLimit = this._config.eventsLimitInMem;
        }
        if (this._config.httpXHROverride && this._config.httpXHROverride.sendPOST) {
            this._xhrOverride = this._config.httpXHROverride;
        }
        if (isValueAssigned(coreConfig.anonCookieName)) {
            this._httpManager.addQueryStringParameter('anoncknm', coreConfig.anonCookieName);
        }
        //Override endpointUrl if provided in Post config
        let endpointUrl = this._config.overrideEndpointUrl ? this._config.overrideEndpointUrl : coreConfig.endpointUrl;
        this._notificationManager = coreConfig.extensionConfig.NotificationManager;
        this._httpManager.initialize(endpointUrl, this._core, this, this._xhrOverride, this._notificationManager);
        for (let i = 0; i < extensions.length; ++i) {
            if ((<ITelemetryPlugin>(extensions[i])).identifier && (<ITelemetryPlugin>(extensions[i])).identifier === 'LocalStorage') {
                this._databaseAdded = true;
            }
        }
        // When running in Web browsers try to send all telemetry if page is unloaded
        addPageUnloadEventListener(() => { this.releaseAllQueuesUsingBeacons(); });
    }

    /**
     * Add an event to the appropriate inbound queue based on its latency.
     * @param {ITelemetryItem} ev - The event to be added to the queue.
     */
    processTelemetry(ev: ITelemetryItem): void {
        var event = <IPostTransmissionTelemetryItem>ev;
        if (!this._config.disableTelemetry && !this._isTeardownCalled) {
            //Override iKey if provided in Post config
            if (this._config.overrideInstrumentationKey) {
                event.iKey = this._config.overrideInstrumentationKey;
            }
            //If send attempt field is undefined we should set it to 0.
            if (!event.sendAttempt) {
                event.sendAttempt = 0;
            }
            // Add default latency
            if (!event.latency) {
                event.latency = EventLatency.Normal;
            }

            // Remove extra AI properties if present
            if (event.ext && event.ext['trace']) {
                delete (event.ext['trace']);
            }
            if (event.ext && event.ext['user'] && event.ext['user']['id']) {
                delete (event.ext['user']['id']);
            }

            if (event.sync) {
                //If the transmission is backed off then do not send synchronous events.
                //We will convert these events to Real time latency instead.
                if (this._currentBackoffCount > 0 || this._paused) {
                    event.latency = EventLatency.RealTime;
                    event.sync = false;
                } else {
                    //Log event synchronously
                    if (this._httpManager) {
                        this._httpManager.sendSynchronousRequest(this._batcher.addEventToBatch(event));
                        return;
                    }
                }
            }
            if (this._queueSize < this._queueSizeLimit) {
                this._addEventToProperQueue(event);
            } else {
                //Drop old event from lower or equal latency
                if (this._dropEventWithLatencyOrLess(event.latency)) {
                    this._addEventToProperQueue(event);
                } else {
                    //Can't drop events from current queues because the all the slots are taken by queues that are being flushed.
                    if (this._notificationManager) {
                        this._notificationManager.eventsDiscarded([event], EventsDiscardedReason.QueueFull);
                    }
                }
            }
            this._scheduleTimer();
        }
        if (this._nextPlugin) {
            this._nextPlugin.processTelemetry(event);
        }
    }

    /**
     * Batch all current events in the queues and send them.
     */
    teardown() {
        this.releaseAllQueuesUsingBeacons();
        this._isTeardownCalled = true;
    }

    /**
     * Pause the tranmission of any requests
     */
    pause() {
        this._clearTimeout();
        this._paused = true;
        this._httpManager.pause();
        if (this._databaseAdded) {
            this._queueSize -= (this._inboundQueues[EventLatency.RealTime][0].length +
                this._inboundQueues[EventLatency.CostDeferred][0].length +
                this._inboundQueues[EventLatency.Normal][0].length);
            this._inboundQueues[EventLatency.RealTime][0] = [];
            this._inboundQueues[EventLatency.CostDeferred][0] = [];
            this._inboundQueues[EventLatency.Normal][0] = [];
            this._httpManager.removeQueuedRequests();
        }
    }

    /**
     * Resumes transmission of events.
     */
    resume() {
        this._paused = false;
        this._httpManager.resume();
        this._scheduleTimer();
    }

    /**
     * Sets the plugin that comes after the current plugin. It is upto the current plugin to call its next plugin when any
     * method on it is called.
     * @param {object} plugin - The next plugin.
     */
    setNextPlugin(plugin: ITelemetryPlugin) {
        this._nextPlugin = plugin;
    }

    /**
     * Load custom tranmission profiles. Each profile should have timers for
     * real time, and normal.  Each profile should make sure 
     * that a each latency timer is a multiple of the latency higher than it.
     * Setting the timer value to -1 means that the events for that latency will 
     * not be sent. Note that once a latency has been set to not send, all latencies
     * below it will also not be sent. The timers should be in the form of [low, normal, high].
     * e.g Custom: [30,10,5] 
     * This also removes any previously loaded custom profiles.
     * @param {object} profiles - A dictionary containing the transmit profiles.
     */
    public _loadTransmitProfiles(profiles: { [profileName: string]: number[] }) {
        this._resetTransmitProfiles();
        for (let profileName in profiles) {
            if (profiles.hasOwnProperty(profileName)) {
                if (profiles[profileName].length < 2) {
                    continue;
                }
                profiles[profileName].splice(0, profiles[profileName].length - 2);
                //Make sure if a higher latency is set to not send then dont send lower latency
                if (profiles[profileName][1] < 0) {
                    profiles[profileName][0] = -1;
                }
                //Make sure each latency is multiple of the latency higher then it. If not a multiple
                //we round up so that it becomes a multiple.
                if (profiles[profileName][1] > 0 && profiles[profileName][0] > 0) {
                    let timerMultiplier = profiles[profileName][0] / profiles[profileName][1];
                    profiles[profileName][0] = Math.ceil(timerMultiplier) * profiles[profileName][1];
                }
                this._profiles[profileName] = profiles[profileName];
            }
        }
    }

    /**
     * Flush to send data immediately; channel should default to sending data asynchronously
     * @param async: send data asynchronously when true
     * @param callback: if specified, notify caller when send is complete
     */
    flush(async = true, callback?: () => void) {
        if (!this._paused) {
            this._clearTimeout();
            if (async) {
                this._addEmptyQueues();
                if (!this._isCurrentlyFlushingNow) {
                    this._isCurrentlyFlushingNow = true;
                    setTimeout(() => this._flushImpl(callback), 0);
                } else {
                    this._flushQueue.push(callback);
                }
            } else {
                //Sync send events in the queue currently
                this._batchEvents(EventLatency.Normal, this._syncFlushBatcher);
                for (let i = 0; i < this._flushOutboundQueue.length; i++) {
                    this._httpManager.sendSynchronousRequest(this._flushOutboundQueue[i]);
                }
                if (callback !== null && callback !== undefined) {
                    callback();
                }
            }
        }
    }

    /**
     * Set AuthMsaDeviceTicket header 
     * @param {string} ticket - Ticket value.
     */
    public setMsaAuthTicket(ticket: string) {
        this._httpManager.addQueryStringParameter('AuthMsaDeviceTicket', ticket);
    }

    /**
     * Set the transmit profile to be used. This will change the tranmission timers 
     * based on the transmit profile.
     * @param {string} profileName - The name of the transmit profile to be used.
     */
    public _setTransmitProfile(profileName: string) {
        if (this._currentProfile !== profileName && this._profiles[profileName] !== undefined) {
            this._clearTimeout();
            this._currentProfile = profileName;
            this._scheduleTimer();
        }
    }

    /**
     * Batch and send events currently in the queue for the given latency.
     * @param {number} latency - Latency for which to send events.
     */
    public _sendEventsForLatencyAndAbove(latency: number) {
        this._batchEvents(latency);
        this._httpManager.sendQueuedRequests();
    }

    /**
     * Check if the inbound queues or batcher has any events that can be sent presently.
     * @return {boolean} True if there are events, false otherwise.
     */
    public _hasEvents() {
        return (this._inboundQueues[EventLatency.RealTime][0].length > 0
            || this._inboundQueues[EventLatency.CostDeferred][0].length > 0
            || this._inboundQueues[EventLatency.Normal][0].length > 0 || this._batcher.hasBatch())
            && this._httpManager.hasIdleConnection();
    }

    /**
     * Add back the events from a failed request back to the queue. 
     * @param {object} request - The request whose events need to be added back to the batcher.
     */
    public _addBackRequest(request: { [token: string]: IPostTransmissionTelemetryItem[] }) {
        if (!this._paused || !this._databaseAdded) {
            for (let token in request) {
                if (request.hasOwnProperty(token)) {
                    //Check if the request being added back is for a sync event in which case mark it no longer a sync event
                    if (request[token].length === 1 && request[token][0].sync) {
                        request[token][0].latency = EventLatency.RealTime;
                        request[token][0].sync = false;
                    }
                    for (let i = 0; i < request[token].length; ++i) {
                        if (request[token][i].sendAttempt < MaxSendAttempts) {
                            this.processTelemetry(request[token][i]);
                        } else {
                            if (this._notificationManager) {
                                this._notificationManager.eventsDiscarded([request[token][i]], EventsDiscardedReason.NonRetryableStatus);
                            }
                        }
                    }
                }
            }
        }
    }

    /**
     * Try to schedule the timer after which events will be sent. If there are 
     * no events to be sent, or there is already a timer scheduled, or the
     * http manager doesn't have any idle connections this method is no-op.
     */
    public _scheduleTimer() {
        let timer = this._profiles[this._currentProfile][1];
        if (!this._timeout && timer >= 0 && !this._paused) {
            if (this._hasEvents()) {
                //If the transmission is backed off make the timer atleast 1 sec to allow for backoff.
                if (timer === 0 && this._currentBackoffCount > 0) {
                    timer = 1;
                }
                let timerMultiplier = 1000;
                if (this._currentBackoffCount > 0) {
                    timerMultiplier = RetryPolicy.getMillisToBackoffForRetry(this._currentBackoffCount - 1);
                }
                this._timeout = setTimeout(() => this._batchAndSendEvents(), timer * timerMultiplier);
            } else {
                this._timerCount = 0;
            }
        }
    }

    /**
     * Backs off tranmission. This exponentially increases all the timers.
     */
    public _backOffTransmission() {
        if (this._currentBackoffCount < MaxBackoffCount) {
            this._currentBackoffCount++;
            this._clearTimeout();
            this._scheduleTimer();
        }
    }

    /**
     * Clears backoff for tranmission.
     */
    public _clearBackOff() {
        if (this._currentBackoffCount > 0) {
            this._currentBackoffCount = 0;
            this._clearTimeout();
            this._scheduleTimer();
        }
    }

    private _clearTimeout() {
        if (this._timeout) {
            clearTimeout(this._timeout);
            this._timeout = null;
            this._timerCount = 0;
        }
    }

    private _batchAndSendEvents() {
        let latency = EventLatency.RealTime;
        this._timerCount++;
        if (this._timerCount === 2) {
            latency = EventLatency.Normal;
            this._timerCount = 0;
        }
        this._sendEventsForLatencyAndAbove(latency);
        this._timeout = null;
        this._scheduleTimer();
    }

    // Try to send all queued events using beacons if available
    private releaseAllQueuesUsingBeacons() {
        this._clearTimeout();
        //Cancel all flush callbacks
        if (this._flushCallback > 0) {
            clearTimeout(this._flushCallback);
        }
        if (!this._paused && !this._databaseAdded) {
            //Merge all queues incase flush were queued
            this._mergeQueues();
            this._batchEvents(EventLatency.Normal);
            // Queue all the remaning requests to be sent. The requests will be sent using HTML5 Beacons if they are available.
            this._httpManager.teardown();
        }
    }

    /**
     * Remove the first queues for all latencies in the inbound queues map. This is called 
     * when transmission manager has finished flushing the events in the old queues. We now make
     * the next queue the primary queue.
     */
    private _removeFirstQueues() {
        this._inboundQueues[EventLatency.RealTime].shift();
        this._inboundQueues[EventLatency.CostDeferred].shift();
        this._inboundQueues[EventLatency.Normal].shift();
    }

    /**
     * Add empty queues for all latencies in the inbound queues map. This is called
     * when Transmission Manager is being flushed. This ensures that new events added 
     * after flush are stored separately till we flush the current events.
     */
    private _addEmptyQueues() {
        this._inboundQueues[EventLatency.RealTime].push([]);
        this._inboundQueues[EventLatency.CostDeferred].push([]);
        this._inboundQueues[EventLatency.Normal].push([]);
    }

    private _mergeQueues() {
        // We need to combine queues for each latency from 1 to 3.
        for (let latency = EventLatency.Normal; latency <= EventLatency.RealTime; latency++) {
            for (let i = 1; i < this._inboundQueues[latency].length; i++) {
                while (this._inboundQueues[latency][i].length > 0) {
                    this._inboundQueues[latency][0].push(this._inboundQueues[latency][i].pop());
                }
            }
        }
    }

    private _addEventToProperQueue(event: IPostTransmissionTelemetryItem) {
        if (!this._paused || !this._databaseAdded) {
            this._queueSize++;
            this._inboundQueues[event.latency][this._inboundQueues[event.latency].length - 1].push(event);
        }
    }

    private _dropEventWithLatencyOrLess(latency: number): boolean {
        let currentLatency = EventLatency.Normal;
        while (currentLatency <= latency) {
            if (this._inboundQueues[currentLatency][this._inboundQueues[currentLatency].length - 1].length > 0) {
                //Dropped oldest events from lowest possible latency
                let droppedEvents =
                    this._inboundQueues[currentLatency][this._inboundQueues[currentLatency].length - 1].splice(0, EventsDroppedAtOneTime);
                if (this._notificationManager) {
                    this._notificationManager.eventsDiscarded(droppedEvents, EventsDiscardedReason.QueueFull);
                }
                return true;
            }
            currentLatency++;
        }
        return false;
    }

    private _batchEvents(latency: number, batcher = this._batcher) {
        let latencyToProcess = EventLatency.RealTime;
        while (latencyToProcess >= latency) {
            while (this._inboundQueues[latencyToProcess][0].length > 0) {
                let event = this._inboundQueues[latencyToProcess][0].pop();
                this._queueSize--;
                batcher.addEventToBatch(event);
            }
            latencyToProcess--;
        }
        batcher.flushBatch();
    }

    private _flushImpl(callback: () => void) {
        if (this._hasEvents()) {
            this._sendEventsForLatencyAndAbove(EventLatency.Normal);
        }
        this._checkOutboundQueueEmptyAndSent(() => {
            //Move the next queues to be primary
            this._removeFirstQueues();
            if (callback !== null && callback !== undefined) {
                callback();
            }
            if (this._flushQueue.length > 0) {
                this._flushCallback = setTimeout(() => this._flushImpl(this._flushQueue.shift()), 0);
            } else {
                this._isCurrentlyFlushingNow = false;
                if (this._hasEvents()) {
                    this._scheduleTimer();
                }
            }
        });
    }

    private _checkOutboundQueueEmptyAndSent(callback: () => void) {
        if (this._httpManager.isCompletelyIdle()) {
            callback();
        } else {
            this._flushCallback = setTimeout(() => this._checkOutboundQueueEmptyAndSent(callback), FlushCheckTimer);
        }
    }

    /**
     * Resets the transmit profiles to the default profiles of Real Time, Near Real Time 
     * and Best Effort. This removes all the custom profiles that were loaded.
     */
    private _resetTransmitProfiles() {
        this._clearTimeout();
        this._initializeProfiles();
        this._currentProfile = RT_PROFILE;
        this._scheduleTimer();
    }

    private _initializeProfiles() {
        this._profiles = {};
        this._profiles[RT_PROFILE] = [2, 1];
        this._profiles[NRT_PROFILE] = [6, 3];
        this._profiles[BE_PROFILE] = [18, 9];
    }
}
