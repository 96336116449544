import * as axiosStatic from "axios";

import { ITransientErrorManager } from "./ITransientErrorManager";

/**
 * TransientAxiosErrorManager allows checking transient errors for Axios responses.
 * @class
 */
export class TransientAxiosErrorManager implements ITransientErrorManager<axiosStatic.Response> {

    /**
     * Default list of errors considered transient.
     * @type {number[]}
     */
    // tslint:disable-next-line:ext-variable-name
    public static readonly DefaultTransitErrors: number[] = [408, 429, 500, 502, 503, 504];

    /**
     * Instantiate a new instance of TransientAxiosErrorManager class.
     * @constructor
     * @param transientErrors {number[]} Optional, list of error codes considered as transient.
     * @description By default the following errors are considered transient:
     * 408 Request Timeout
     * 429 Too Many Requests
     * 500 Internal Server Error
     * 502 Bad Gateway
     * 503 Service Unavailable
     * 504 Gateway Timeout
     */
    constructor(private transientErrors: number[] = TransientAxiosErrorManager.DefaultTransitErrors) { }

    /**
     * Checks given response against list of transient errors.
     * @param response {axiosStatic.Response} Response for an Axios request.
     * @returns {boolean}
     */
    public isTransientError(response: axiosStatic.Response): boolean {
        return (this.transientErrors.indexOf(response.status) >= 0);
    }
}