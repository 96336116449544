/**
* PageUnload.ts
* @author Ram Thiru (ramthi) and Hector Hernandez (hectorh)
* @copyright Microsoft 2018
*/

import { WebEvent } from './WebEvent';
import {
    IExtendedTelemetryItem, IDiagnosticLogger, EventLatency,
    isValueAssigned, isDocumentObjectAvailable, isWindowObjectAvailable
} from '@ms/1ds-core-js';
import { IPageUnloadOverrideValues } from '../interfaces/IOverrides';
import { IPageUnloadTelemetry, IPageUnloadProperties } from '../interfaces/ITelemetryEvents';
import { _getViewportDimensions } from '../common/Utils';
import { IWebAnalyticsConfiguration } from '../DataModel';
import { ApplicationInsights } from '../WebAnalyticsPlugin';
import Timespan from '../Timespan';
import Id from '../Id';

export class PageUnload extends WebEvent {

    private _timestamp: Timespan;
    private _maxScroll: any;

    /**
  * @param {ApplicationInsights} _webAnalyticsPlugin - Web Analytics plugin instance
  * @param {WebAnalyticsConfiguration} _config - WebAnalytics configuration object
  * @param {IContentHandler} contentHandler - Content handler
  * @param {Id} _id - Id object
  * @param {IDiagnosticLogger} _traceLogger - Trace logger to log to console.
  * @param {Timespan} _timestamp - Timespan instance.
  */
    constructor(protected _webAnalyticsPlugin: ApplicationInsights, protected _config: IWebAnalyticsConfiguration,
        protected _id: Id, protected _traceLogger: IDiagnosticLogger,
        timestamp: Timespan, maxScroll: any) {
        super(_webAnalyticsPlugin, _config, null, _id, {}, {}, _traceLogger);
        this._timestamp = timestamp;
        this._maxScroll = maxScroll;
    }

    /**
    * API to send PageUnload event
    * @param {IPageUnloadTelemetry} pageUnloadEvent - PageUnload event
    * @param {IPageUnloadProperties} properties - PageUnload properties(Part C)
    */
    public trackPageUnload(pageUnloadEvent: IPageUnloadTelemetry, properties?: IPageUnloadProperties): void {
        // Get part A properties
        var ext = {};
        ext['web'] = {};
        ext['web']['isManual'] = pageUnloadEvent.isManual;
        let event: IExtendedTelemetryItem = {
            name: 'Ms.Web.PageUnload',
            baseType: 'PageUnloadData',
            ext: ext,
            data: {},
            baseData: {},
            latency: EventLatency.RealTime
        };
        event.baseData['name'] = pageUnloadEvent.name;
        event.baseData['uri'] = pageUnloadEvent.uri;
        event.baseData['id'] = pageUnloadEvent.id;
        event.baseData['properties'] = pageUnloadEvent.properties;
        event.baseData['ver'] = pageUnloadEvent.ver;
        event.baseData['market'] = pageUnloadEvent.market;
        event.baseData['pageType'] = pageUnloadEvent.pageType;
        event.baseData['isLoggedIn'] = pageUnloadEvent.isLoggedIn;
        for (let property in properties) {
            if (properties.hasOwnProperty(property)) {
                if (!event.data[property]) {
                    event.data[property] = properties[property];
                }
            }
        }
        this._webAnalyticsPlugin.core.track(event);
    }

    /**
   * API to create and send a populated PageUnload event 
   * @param {IPageUnloadOverrideValues} overrideValues - PageUnload overrides
   * @param {{ [name: string]: string | number | boolean | string[] | number[] | boolean[] }} customProperties - Custom properties(Part C)
   */
    public capturePageUnload(overrideValues?: IPageUnloadOverrideValues, customProperties?: { [name: string]: string | number | boolean | string[] | number[] | boolean[] | object }): void {
        overrideValues = !isValueAssigned(overrideValues) ? {} : overrideValues;
        var event: IPageUnloadTelemetry = {};
        var properties: IPageUnloadProperties = isValueAssigned(customProperties) ? customProperties : {};
        var scrollHeight = isDocumentObjectAvailable ? document.body.scrollHeight : 0;
        this._setBasicProperties(event, overrideValues);
        event.isManual = !overrideValues.isAuto;
        // Set PartC values
        properties.dwellTime = this._timestamp._recordTimeSpan('dwellTime', true);
        properties.scrollDepth = overrideValues.scrollDepth || this._maxScroll.v.toString() + '/' + scrollHeight.toString();
        properties.vpHeight = _getViewportDimensions().h;
        properties.vScrollOffset = overrideValues.vScrollOffset || this._maxScroll.v;
        if (isWindowObjectAvailable) {
            var windowPerformanceTiming = window.performance ? window.performance.timing : undefined;
            if (windowPerformanceTiming && windowPerformanceTiming.loadEventStart && windowPerformanceTiming.navigationStart) {
                if (windowPerformanceTiming.loadEventStart > 0) {
                    properties.pageLoadTime = windowPerformanceTiming.loadEventStart - windowPerformanceTiming.navigationStart;
                }
            }
        }
        this.trackPageUnload(event, properties);
    }
}
