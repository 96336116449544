/**
* Serializer.ts
* @author Abhilash Panwar (abpanwar) Hector Hernandez(hectorh)
* @copyright Microsoft 2018
*/
import {
    IEventProperty, getTenantId, sanitizeProperty, getCommonSchemaMetaData, isArray
} from '@ms/1ds-core-js';
import { IPayloadBlobWithMetadata, IPostTransmissionTelemetryItem } from './DataModels';

const RequestSizeLimitBytes = 3984588;  //approx 3.8 Mb
const MaxRecordSize = 2000000; //approx 2 Mb

/**
* Class to handle serialization of event and request.
* Currently uses Bond for serialization. Please note that this may be subject to change.
*/
export default class Serializer {
    /**
     * Serialies a request using Bond.
     * @param { { [token: string]: IPostTransmissionTelemetryItem[] }} requestDictionary - A dictionary containing the token to
     * event mapping.
     * @param {number} tokenCount                                                - Number of tenant tokens to be sent in the request.
     * @return {IPayloadBlobWithMetadata} The payload object with some metadata.
     */
    static getPayloadBlob(requestDictionary: { [token: string]: IPostTransmissionTelemetryItem[] }): IPayloadBlobWithMetadata {
        let payload = '';
        let requestFull = false;
        let remainingRequest: { [token: string]: IPostTransmissionTelemetryItem[] } = {};
        for (let token in requestDictionary) {
            if (!requestFull) {
                if (requestDictionary.hasOwnProperty(token)) {
                    for (let i = 0; i < requestDictionary[token].length; ++i) {
                        if (payload) {
                            payload = payload + '\n';
                        }
                        let currentSize = payload.length;
                        payload = payload + this.getEventBlob(requestDictionary[token][i]);
                        if (payload.length - currentSize > MaxRecordSize) {
                            //Single event size exceeded
                            //EVTNotificationManager.eventsRejected([requestDictionary[token][i]], EVTEventsRejectedReason.SizeLimitExceeded);
                            delete requestDictionary[token][i];
                            payload = payload.substring(0, currentSize);
                        }
                        if (payload.length > RequestSizeLimitBytes) {
                            //Request size exceeded
                            remainingRequest = {};
                            let currentPackage = requestDictionary[token];
                            requestDictionary[token] = currentPackage.splice(0, i);
                            remainingRequest[token] = currentPackage;
                            break;
                        }
                    }
                }
            } else {
                remainingRequest[token] = requestDictionary[token];
                delete requestDictionary[token];
            }
        }
        return { payloadBlob: payload, remainingRequest: remainingRequest };
    }

    /**
     * Bond serialize the event.
     * @param {object} eventData - The event that needs to be serialized.
     * @return {string} The serialized json event.
     */
    static getEventBlob(eventData: IPostTransmissionTelemetryItem): string {
        const data = 'data';
        const baseData = 'baseData';
        const ext = 'ext';
        let serializedEvent = {};
        serializedEvent['name'] = eventData.name;
        serializedEvent['time'] = eventData.time;
        serializedEvent['ver'] = eventData.ver;
        serializedEvent['iKey'] = 'o:' + getTenantId(eventData.iKey);
        //part A
        if (eventData.ext) {
            serializedEvent[ext] = {};
            for (let key in eventData.ext) {
                if (eventData.ext.hasOwnProperty(key)) {
                    serializedEvent[ext][key] = {};
                    for (let subKey in eventData.ext[key]) {
                        if (eventData.ext[key].hasOwnProperty(subKey)) {
                            // Only add populated properties
                            if (eventData.ext[key][subKey]) {
                                serializedEvent[ext][key][subKey] = sanitizeProperty(subKey, eventData.ext[key][subKey]).value;
                            }
                        }
                    }
                }
            }
        }
        serializedEvent[data] = {};
        serializedEvent[data][baseData] = {};
        serializedEvent[data]['baseType'] = eventData.baseType;
        //part B
        if (eventData.baseData) {
            for (let key in eventData.baseData) {
                if (eventData.baseData.hasOwnProperty(key)) {
                    let value = sanitizeProperty(key, eventData.baseData[key]);
                    this._addJSONProperty(serializedEvent[data][baseData], key, value);
                    this._addJSONPropertyMetaData(serializedEvent[ext], baseData + '.' + key, value);
                }
            }
        }
        //part C
        if (eventData.data) {
            for (let key in eventData.data) {
                if (eventData.data.hasOwnProperty(key)) {
                    let value = sanitizeProperty(key, eventData.data[key]);
                    this._addJSONProperty(serializedEvent[data], key, value);
                    this._addJSONPropertyMetaData(serializedEvent[ext], key, value);
                }
            }
        }
        return JSON.stringify(serializedEvent);
    }

    private static _addJSONProperty(json: {}, propertyKey: string, propertyValue: IEventProperty | null) {
        if (propertyValue) {
            let jsonToPutProperty = json;
            let splitKey = propertyKey.split('.');
            for (let i = 0; i < splitKey.length - 1; ++i) {
                if (!jsonToPutProperty[splitKey[i]]) {
                    jsonToPutProperty[splitKey[i]] = {};
                }
                jsonToPutProperty = jsonToPutProperty[splitKey[i]];
            }
            jsonToPutProperty[splitKey[splitKey.length - 1]] = propertyValue.value;
        }
    }

    private static _addJSONPropertyMetaData(json: {}, propertyKey: string, propertyValue: IEventProperty | null) {
        if (propertyValue) {
            const metadata = 'metadata';
            const f = 'f';
            let encodedTypeValue = getCommonSchemaMetaData(propertyValue.value, propertyValue.kind, propertyValue.propertyType);
            if (encodedTypeValue > -1) {
                //Add the metadata
                let jsonToPutMetadata = json;
                if (!jsonToPutMetadata[metadata]) {
                    jsonToPutMetadata[metadata] = {
                        f: {}
                    };
                }
                jsonToPutMetadata = jsonToPutMetadata[metadata][f];
                let splitKey = propertyKey.split('.');
                for (let i = 0; i < splitKey.length - 1; ++i) {
                    if (!jsonToPutMetadata[splitKey[i]]) {
                        jsonToPutMetadata[splitKey[i]] = {
                            f: {}
                        };
                    }
                    jsonToPutMetadata = jsonToPutMetadata[splitKey[i]][f];
                }
                if (isArray(propertyValue.value)) {
                    jsonToPutMetadata[splitKey[splitKey.length - 1]] = {
                        'a': {
                            't': encodedTypeValue
                        }
                    };
                } else {
                    jsonToPutMetadata[splitKey[splitKey.length - 1]] = {
                        't': encodedTypeValue
                    };
                }
            }
        }
    }
}
