import { EventBasicData } from "../commonSchema/EventBasicData";
import { EventLatency } from "@ms/1ds-core-js";
import { IBrowserWindow } from "../../browser-object-models/IBrowserWindow";
import { IOneDsEventData } from "./IOneDsEventData";
import { IOneDsExtension } from "./IOneDsExtension";
import { IPageViewEventContent } from "../commonSchema/IPageViewEventContent";
import { IUserActivityLogItem } from "../IUserActivityLogItem";
import { PageViewEventContent } from "../commonSchema/PageViewEventContent";
import { OneDsNamespaceProfile } from "./OneDsNamespaceProfile";

/**
 * Represents the parameters of the page view event that is predefined by JSLL (Javascript logging library) of Asimov and 1DS pipeline.
 * @class
 */
export class OneDsPageViewEventData implements IOneDsEventData<IPageViewEventContent> {

    /**
     * Initializes a new instance of the `OneDsPageViewEventData` class.
     * @param name {string} The name(namespace) of the page view custom event.
     * @param time {string} The date and time of the event in ISO format.
     * @param data {[key: string]: IPageViewEventContent | string} The event data contains page view data and namepsace.
     * @param ext {IOneDsExtension} The extension fields.
     * @param latency {number} The latency corresponding to EventLatency.
     * @param sync {boolean} The flag to set if the event is to be sent synchronously.
     */
    constructor(
        public name: string,
        public time: string,
        public data: { [key: string]: IPageViewEventContent | string },
        public ext?: IOneDsExtension,
        public latency?: number,
        public sync?: boolean) {
    }

    /**
     * Creates a new PageViewEventData from a given log item.
     * @param userActivityLogItem {IUserActivityLogItem} The user activity log item.
     * @param windowContext {IBrowserWindow} The window object of the browser.
     * @param application {string} The application name.
     * @param source {string} The source name.
     * @param impressionGuid {string} The impression guid.
     * @param tenant {string} The tenant name.
     * @param time {string} The date and time of the event in ISO format.
     * @param serverImpressionGuid? {string} The server impression guid.
     * @param productName {string} The product name.
     * @param buildVesrion {string} The build version.
     * @param latency {number} The latency corresponding to EventLatency.
     * @param oneDsNamespaceProfile {OneDsNamespaceProfile} The namespace profile for the 1DS events.
     * @param sync {boolean} The flag to set if the event is to be sent synchronously.
     * @return {IPageViewEventData} The page view event data.
     */
    public static createFrom(
        logItem: IUserActivityLogItem,
        windowContext: IBrowserWindow,
        application: string,
        source: string,
        impressionGuid: string,
        tenant: string,
        time: string,
        serverImpressionGuid: string,
        productName: string,
        buildVersion: string,
        oneDsNamespaceProfile: OneDsNamespaceProfile,
        latency?: number,
        sync?: boolean): OneDsPageViewEventData {

        var basicData = EventBasicData.createFrom(logItem, windowContext);
        var pageViewEventContent = PageViewEventContent.createFrom(logItem, basicData, windowContext, impressionGuid, serverImpressionGuid, application, source, productName, buildVersion, tenant);

        const eventData: { [key: string]: IPageViewEventContent | string } = {
            baseData: pageViewEventContent,
            baseType: oneDsNamespaceProfile.clientPageViewDataSchemaNamespace
        };

        const ext = {
            mscv: { cV: logItem.correlationVector },
            web: { isManual: true }
        };

        return new OneDsPageViewEventData(oneDsNamespaceProfile.clientPageViewSchemaNamespace, time, eventData, ext, latency || EventLatency.RealTime, sync);
    }
}
