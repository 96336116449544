import { IPerfCounterItem } from "./IPerfCounterItem";
import { LogItem } from "./LogItem";
import { LogType } from "./LogType";

/**
 * Performance counter item class
 * @class
 */
export class PerfCounterItem extends LogItem implements IPerfCounterItem {

    /**
     * The perf counter value
     * @type {number}
     */
    // tslint:disable-next-line: ext-variable-name
    public CounterValue: number;

    /**
     * The perf counter name
     * @type {string}
     */
    // tslint:disable-next-line: ext-variable-name
    public get CounterName(): string { return this._counterName; }
    private _counterName: string;

    /**
     * constructor
     * @param counterName {string} perf counter name
     * @param counterValue {number} perf counter value
     * @param data? {any} open schema custom data
     * @param activityId? {string} activity id
     * @param logDateTime? {Date} specify the log time, default is now.
     */
    constructor(counterName: string, counterValue: number, data?: any, activityId?: string, logDateTime?: Date) {
        super(counterName, data, LogType.Instrumentation, activityId, logDateTime);
        this._counterName = counterName;
        this.CounterValue = counterValue;
    }

    /**
     * increment the counter value
     * @type {void}
     */
    public incrementValue(): void {
        if (typeof (this.CounterValue) === "number") {
            this.CounterValue++;
        } else {
            throw("ERROR: Counter value is never initialized, cannot perform increment action.");
        }
    }
}