/**
* ContentUpdate.ts
* @author Ram Thiru (ramthi) and Hector Hernandez (hectorh)
* @copyright Microsoft 2018
*/

import { WebEvent } from './WebEvent';
import { IExtendedTelemetryItem, isValueAssigned, isDocumentObjectAvailable } from '@ms/1ds-core-js';
import { IContentUpdateOverrideValues } from '../interfaces/IOverrides';
import { IContentUpdateTelemetry, IContentUpdateProperties } from '../interfaces/ITelemetryEvents';
import { CONTENT_VERSION } from '../common/Constants';
import {
    _isElementTrulyVisible, _bracketIt,
    _getScrollOffset, _getViewportDimensions, _isElementDnt
} from '../common/Utils';

export class ContentUpdate extends WebEvent {

    /**
      * API to send ContentUpdate event
      * @param {IContentUpdateTelemetry} contentUpdateEvent - ContentUpdate event
      * @param {IContentUpdateProperties} properties - ContentUpdate properties(Part C)
      */
    public trackContentUpdate(contentUpdateEvent: IContentUpdateTelemetry, properties?: IContentUpdateProperties): void {
        // Get part A properties
        var ext = {};
        ext['web'] = {};
        ext['web']['isManual'] = contentUpdateEvent.isManual;
        let event: IExtendedTelemetryItem = {
            name: 'Ms.Web.ContentUpdate',
            baseType: 'ContentUpdateData',
            ext: ext,
            data: {},
            baseData: {},
            latency: 3
        };
        event.baseData['name'] = contentUpdateEvent.name;
        event.baseData['uri'] = contentUpdateEvent.uri;
        event.baseData['market'] = contentUpdateEvent.market;
        event.baseData['pageType'] = contentUpdateEvent.pageType;
        event.baseData['isLoggedIn'] = contentUpdateEvent.isLoggedIn;
        event.baseData['id'] = contentUpdateEvent.id;
        event.baseData['properties'] = contentUpdateEvent.properties;
        event.baseData['ver'] = contentUpdateEvent.ver;
        event.baseData['actionType'] = contentUpdateEvent.actionType;
        event.baseData['behavior'] = contentUpdateEvent.behavior;
        event.baseData['pageHeight'] = contentUpdateEvent.pageHeight;
        event.baseData['content'] = contentUpdateEvent.content;
        event.baseData['contentVer'] = contentUpdateEvent.contentVer;
        event.baseData['vpHeight'] = contentUpdateEvent.vpHeight;
        event.baseData['vpWidth'] = contentUpdateEvent.vpWidth;
        event.baseData['vScrollOffset'] = contentUpdateEvent.vScrollOffset;
        event.baseData['hScrollOffset'] = contentUpdateEvent.hScrollOffset;
        for (let property in properties) {
            if (properties.hasOwnProperty(property)) {
                if (!event.data[property]) {
                    event.data[property] = properties[property];
                }
            }
        }
        this._webAnalyticsPlugin.core.track(event);
    }

    /**
   * API to create and send a populated ContentUpdate event 
   * @param {IContentUpdateOverrideValues} overrideValues - ContentUpdate overrides
   * @param {{ [name: string]: string | number | boolean | string[] | number[] | boolean[] }} customProperties - Custom properties(Part C)
   */
    public captureContentUpdate(overrideValues?: IContentUpdateOverrideValues, customProperties?: { [name: string]: string | number | boolean | string[] | number[] | boolean[] | object }): void {
        overrideValues = !isValueAssigned(overrideValues) ? {} : overrideValues;
        var event: IContentUpdateTelemetry = {};
        var properties: IContentUpdateProperties = isValueAssigned(customProperties) ? customProperties : {};
        this._setCommonProperties(event, properties, overrideValues);
        event.behavior = this._getBehavior(overrideValues);
        if (isValueAssigned(overrideValues.behavior)) {
            event.behavior = overrideValues.behavior;
        }
        if (isValueAssigned(overrideValues.actionType)) {
            event.actionType = overrideValues.actionType;
        }

        let viewportDim = _getViewportDimensions();
        let scrollOffset = _getScrollOffset();

        event.pageHeight = isDocumentObjectAvailable ? document.body.scrollHeight : null;
        event.vpHeight = viewportDim.h;
        event.vpWidth = viewportDim.w;
        event.vScrollOffset = scrollOffset.v;
        event.hScrollOffset = scrollOffset.h;
        event.contentVer = CONTENT_VERSION;
        event.isManual = !overrideValues.isAuto;
        var content = this._getContentFormatted(overrideValues.content) || JSON.stringify(this._contentHandler.getVisibleContent());
        if (content) {
            event.content = content;
        }

        // Set PartC values
        if (isValueAssigned(overrideValues.isDomComplete)) {
            properties.isDomComplete = overrideValues.isDomComplete;
        } else {
            properties.isDomComplete = false;
        }
        this.trackContentUpdate(event, properties);
    }

    private _getContentFormatted(content: any) {
        if (isValueAssigned(content)) {
            if (Object.prototype.toString.call(content) === '[object Array]') {
                return JSON.stringify(content);
            } else {
                return _bracketIt(JSON.stringify(content));
            }
        }
        return undefined;
    }
}
