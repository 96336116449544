/**
* QosPlugin.ts
* @author Hector Hernandez (hectorh)
* @copyright Microsoft 2018
* File containing QoS plugin.
*/

import {
    IPlugin, ITelemetryPlugin, IExtendedConfiguration, IAppInsightsCore,
    IExtendedTelemetryItem, IDiagnosticLogger, ITelemetryItem, EventLatency,
    isValueAssigned, extend, createGuid, LoggingSeverity, _ExtendedInternalMessageId
} from '@ms/1ds-core-js';
import {
    IQosConfiguration, CorrelationVectorPlugin, WebAnalyticsPlugin, Cv
} from './DataModel';
import { IDependencyTelemetry, IDependencyTelemetryProperties, IIncomingServiceRequest, IOutgoingServiceRequest } from './interfaces/ITelemetryEvents';
import OutgoingQosEvent from './events/OutgoingQosEvent';
import IncomingQosEvent from './events/IncomingQosEvent';
import { _removeNonObjectsAndInvalidElements } from './common/Utils';
import { _getMetaData, _collectMetaTags, _getPageName, _getUri } from './common/DataCollector';
import { AjaxPlugin, ajaxRecord, XMLHttpRequestInstrumented, IInstrumentationRequirements } from '@microsoft/applicationinsights-dependencies-js';
import { RemoteDependencyData } from '@microsoft/applicationinsights-common';
import Id from './Id';

const MS_CV = 'MS-CV';

export default class QosPlugin extends AjaxPlugin implements ITelemetryPlugin, IInstrumentationRequirements {

    public identifier = 'QosPlugin';
    public id: Id;
    public version = '2.1.1';
    protected _nextPlugin: ITelemetryPlugin;
    private _cvPlugin: CorrelationVectorPlugin;
    private _traceLogger: IDiagnosticLogger;
    protected _core: IAppInsightsCore;
    public _qosConfig: IQosConfiguration;
    private _outgoingQosEvent: OutgoingQosEvent;
    private _incomingQosEvent: IncomingQosEvent;
    private _metaTags: any = { market: '', serverImpressionGuid: '' };

    /**
     * @constructor
     * @param {object} CorrelationVectorConfiguration object.
     */
    constructor() {
        super();
        this._outgoingQosEvent = new OutgoingQosEvent();
        this._incomingQosEvent = new IncomingQosEvent();
    }

    /**
    * Merge passed in configuration with default configuration
    * @param {WebAnalyticsConfiguration} overrideConfig
    * @return void
    */
    private _mergeConfig(overrideConfig: IQosConfiguration): IQosConfiguration {
        var defaultConfig: IQosConfiguration = {
            enableCorsCorrelation: false,
            correlationHeaderExcludedDomains: [],
            disableCorrelationHeaders: false,
            // Unlimited autocollection
            maxAjaxCallsPerView: -1,
            disableAjaxTracking: false,
            disableFetchTracking: false,
            enableCvHeaders: false,
            appId: undefined,
            callback: {
                pageName: null,
                id: null
            },
            // overrideValues SDK should use instead of collecting automatically
            coreData: {
                requestUri: '',
                pageName: ''
            }
        };

        var attributesThatAreObjectsInConfig: any[] = [];
        for (var attribute in defaultConfig) {
            if (typeof defaultConfig[attribute] === 'object') {
                attributesThatAreObjectsInConfig.push(attribute);
            }
        }

        if (overrideConfig) {
            // delete attributes that should be object and 
            // delete properties that are null, undefined, ''
            _removeNonObjectsAndInvalidElements(overrideConfig, attributesThatAreObjectsInConfig);

            return extend(true, defaultConfig, overrideConfig);
        }
    }

    /**
     * Override of parent method, executed when auto capture is enabled for XMLHttpRequest or Fetch API
     * @param {ajaxRecord} ajaxData - Outgoing Ajax call.
     * @param {Request | string} input - Fetch API request input
     * @param {RequestInit} init - Fetch API initiator
     * @param {XMLHttpRequestInstrumented} xhr - XMLHttpRequest
     */
    public includeCorrelationHeaders(ajaxData: ajaxRecord, input?: Request | string, init?: RequestInit, xhr?: XMLHttpRequestInstrumented): any {
        var dependencyRequest = super.includeCorrelationHeaders(ajaxData, input, init, xhr);
        if (this._qosConfig.enableCvHeaders) {
            var cv: Cv = this.getCv();
            if (input) { // Fetch
                if (cv) {
                    cv.increment();
                    //Request object as input
                    if (isValueAssigned((<Request>input).url)) {
                        (<any>dependencyRequest).headers.append(MS_CV, cv.getValue());
                    } else if (isValueAssigned(init)) {
                        //Init properties
                        if (isValueAssigned(init.headers)) {
                            dependencyRequest.headers[MS_CV] = cv.getValue();
                        } else {
                            dependencyRequest.headers = { MS_CV: cv.getValue() };
                        }
                    } else {
                        // Only URL specified in fetch
                        dependencyRequest = { headers: { MS_CV: cv.getValue() } };
                    }
                }
            } else if (xhr) { // XHR
                if (cv) {
                    cv.increment();
                    dependencyRequest.setRequestHeader(MS_CV, cv.getValue());
                }
            }
        }
        return dependencyRequest;
    }

    /**
     * Initialize the Qos plugin
     * @param {IExtendedConfiguration} config - The plugin configuration.
     * @param {IAppInsightsCore} core - Core reference.
     * @param {ITelemetryPlugin} extensions - All other extensions
     */
    initialize(coreConfig: IExtendedConfiguration, core: IAppInsightsCore, extensions: IPlugin[]) {
        coreConfig.extensionConfig = coreConfig.extensionConfig || [];
        coreConfig.extensionConfig[this.identifier] = coreConfig.extensionConfig[this.identifier] || {};
        this._qosConfig = this._mergeConfig(coreConfig.extensionConfig[this.identifier]);
        // Update parent config
        coreConfig.extensionConfig[AjaxPlugin.identifier] = this._qosConfig;
        super.initialize(coreConfig, core, extensions);
        this._core = core;
        this._traceLogger = core.logger;
        var metaTags = _collectMetaTags(this._qosConfig);
        this._metaTags.market = _getMetaData(metaTags, this._qosConfig.coreData, 'market');
        this._metaTags.serverImpressionGuid = _getMetaData(metaTags, this._qosConfig.coreData, 'serverImpressionGuid');

        // Iterate other loaded extensions
        var correlationVectorExtension = null;
        var webAnalyticsExtension = null;
        for (let i = 0; i < extensions.length; i++) {
            if ((<ITelemetryPlugin>(extensions[i])).identifier === 'CorrelationVectorPlugin') {
                correlationVectorExtension = extensions[i] as CorrelationVectorPlugin;
            } else if ((<ITelemetryPlugin>(extensions[i])).identifier === 'WebAnalyticsPlugin') {
                webAnalyticsExtension = extensions[i] as WebAnalyticsPlugin;
            }
        }

        // Use WebAnalytics Id if available
        if (webAnalyticsExtension && webAnalyticsExtension.id) {
            this.id = webAnalyticsExtension.id;
        } else {
            this.id = new Id();
        }

        if (this._qosConfig.enableCvHeaders) {
            if (correlationVectorExtension) {
                this._qosConfig.enableCvHeaders = true;
                this._cvPlugin = correlationVectorExtension;
            } else {
                this._traceLogger.throwInternal(
                    LoggingSeverity.WARNING,
                    _ExtendedInternalMessageId.CVPluginNotAvailable, "Enable cV headers it is set to \"true\" in config.  However, cv plugin is not available. Disabling adding Cv in dependency requests "
                )
                this._qosConfig.enableCvHeaders = false;
            }
        }
    }

    public processTelemetry(evt: ITelemetryItem): void {
        let event = <IExtendedTelemetryItem>evt;
        switch (event.baseType) {
            case RemoteDependencyData.dataType:
                if (event.name !== 'Ms.Qos.OutgoingServiceRequest') {
                    event.name = 'Ms.Web.OutgoingRequest';
                    event.baseData = event.baseData || {};
                    event.baseData.properties = event.baseData.properties || {};
                    event.baseData.properties['parentId'] = event.baseData.properties['parentId'] || this.id.getLastPageViewId();
                    // Remove extra AI properties
                    delete (event.baseData['method']);
                }
                event.latency = EventLatency.Normal;
                break;
            default:
                break;
        }

        if (this._nextPlugin && this._nextPlugin.processTelemetry) {
            this._nextPlugin.processTelemetry(event);
        }
    }

    /**
    * Track DependencyData event
    * @param {IDependencyTelemetry} dependency -DependencyData event
    * @param {IDependencyTelemetryProperties} properties - DependencyData properties
    */
    public trackDependencyData(dependency: IDependencyTelemetry, properties?: IDependencyTelemetryProperties) {
        this.trackDependencyDataInternal(dependency, properties);
    }

    /**
    * Track DependencyData event internal (Called by automatic tracking scenarios)
    * @param {IDependencyTelemetry} dependency -DependencyData event
    * @param {IDependencyTelemetryProperties} properties - DependencyData properties
    */
    protected trackDependencyDataInternal(dependency: IDependencyTelemetry, properties?: { [key: string]: any }, systemProperties?: { [key: string]: any }) {
        // Part A
        let ext = systemProperties || {};
        if (isValueAssigned(dependency.cV)) {
            ext['mscv'] = {};
            ext['mscv']['cV'] = dependency.cV;
        }
        dependency.id = this._qosConfig.callback && this._qosConfig.callback.id ? this._qosConfig.callback.id() : createGuid();
        // Part C
        properties = properties || {};
        properties.pageName = isValueAssigned(properties.pageName) ? properties.pageName : _getPageName(this._qosConfig);
        properties.uri = isValueAssigned(properties.uri) ? properties.uri : _getUri(this._qosConfig);
        properties.market = isValueAssigned(properties.market) ? properties.market : this._metaTags.market;
        properties.serverImpressionGuid = isValueAssigned(properties.serverImpressionGuid) ? properties.serverImpressionGuid : this._metaTags.serverImpressionGuid;
        super.trackDependencyDataInternal(dependency, properties, ext);
    }

    /**
     * Track OutgoingServiceRequest event
     * @param {IOutgoingServiceRequest} eventData - OutgoingServiceRequest event data
     */
    public trackServiceOutgoingQos(eventData: IOutgoingServiceRequest): void {
        let event: IExtendedTelemetryItem = {
            name: 'Ms.Qos.OutgoingServiceRequest',
            baseType: 'RemoteDependencyData',
            ext: {},
            baseData: {},
            data: {},
            latency: 3
        };

        this._outgoingQosEvent._setQosProperties(event, eventData);
        this._core.track(event);
    }

    /**
     * Track IncomingServiceRequest event
     * @param {IIncomingServiceRequest} eventData - IncomingServiceRequest event data
     */
    public trackServiceIncomingQos(eventData: IIncomingServiceRequest): void {
        let event: IExtendedTelemetryItem = {
            name: 'Ms.Qos.IncomingServiceRequest',
            baseType: 'RequestData',
            ext: {},
            baseData: {},
            data: {},
            latency: 3
        };
        this._incomingQosEvent._setQosProperties(event, eventData);
        this._core.track(event);
    }

    public getCv(): Cv {
        if (this._cvPlugin) {
            return this._cvPlugin.getCv();
        }
        return null;
    }
}
