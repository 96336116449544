/**
* PageViewPerformance.ts
* @author Hector Hernandez (hectorh)
* @copyright Microsoft 2019
*/
import { isValueAssigned } from '@ms/1ds-core-js';
import { IPageViewPerformanceOverrideValues } from '../interfaces/IOverrides';
import { IPageViewPerformanceTelemetry, IPageViewPerformanceProperties } from '../interfaces/ITelemetryEvents';
import { WebEvent } from './WebEvent';

export class PageViewPerformance extends WebEvent {

    /**
      * API to populate and send a PageViewPerformance event
      * @param {IPageViewPerformanceOverrideValues} overrideValues - PageView overrides
      * @param {{ [name: string]: string | number | boolean | string[] | number[] | boolean[] }} customProperties - Custom properties(Part C)
      */
    public capturePageViewPerformance(overrideValues?: IPageViewPerformanceOverrideValues, customProperties?: { [name: string]: string | number | boolean | string[] | number[] | boolean[] | object }): void {
        overrideValues = !isValueAssigned(overrideValues) ? {} : overrideValues;
        var event: IPageViewPerformanceTelemetry = {};
        var properties: IPageViewPerformanceProperties = isValueAssigned(customProperties) ? customProperties : {};
        this._setBasicProperties(event, overrideValues);
        event.isManual = !overrideValues.isAuto;
        properties.behavior = this._getBehavior(overrideValues);
        properties.vpHeight = overrideValues.vpHeight;
        properties.vpWidth = overrideValues.vpWidth;
        properties.framework = overrideValues.framework;
        properties.systemTiming = overrideValues.systemTiming;
        properties.customTiming = overrideValues.customTiming;
        this._webAnalyticsPlugin._populatePageViewPerformance(event);
        this._webAnalyticsPlugin.trackPageViewPerformance(event, properties);
    }
}
