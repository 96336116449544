/**
* IntWeb.ts
* @author Hector Hernandez (hectorh)
* @copyright Microsoft 2019
*/
import { getCookie, objDefineAccessors } from '@ms/1ds-core-js';
import { IPropertyConfiguration } from '../DataModels';

export class IntWeb {

    /**
     * Return the Anonymous visitor Id in the hosting domain.
     * Note: This property will NOT exist in ES3/IE8 environment, if you need IE8 compatibility
     * use the method {@link IntWeb#getMsfpc} from your code. For ES5+ environment this will be replaced with a 
     * property getter only.
     */
    public msfpc?:string;

    /**
     * Return the ANON - MSA Anonymous ID.
     * Note: This property will NOT exist in ES3/IE8 environment, if you need IE8 compatibility
     * use the method {@link IntWeb#getAnid} from your code. For ES5+ environment this will be replaced with a 
     * property getter only.
     */
    public anid?:string;

    /**
     * Anonymous visitor Id in hosting domain.
     */
    getMsfpc(): string {
        return getCookie('MSFPC');
    };
    /**
     * ANON - MSA Anonymous ID.
     */
    getAnid(): string {
        return getCookie('ANON').slice(0, 34);
    };
    /**
     * Service name.
     */
    public serviceName: string;

    constructor(propertiesConfig: IPropertyConfiguration) {
        this.serviceName = propertiesConfig.serviceName ? propertiesConfig.serviceName : '';
    }

    /**
     * Static constructor, attempt to create accessors
     */
    private static _staticInit = (() => {
        // Dynamically create get/set property accessors
        objDefineAccessors(IntWeb.prototype, "msfpc", IntWeb.prototype.getMsfpc);
        objDefineAccessors(IntWeb.prototype, "anid", IntWeb.prototype.getAnid);
    })();
}
