import { IBrowserWindow } from "../../browser-object-models/IBrowserWindow";
import { IEventBasicData } from "../commonSchema/IEventBasicData";
import { IPageActionData } from "../userActivityData/IPageActionData";
import { IPageActionPartBContent } from "./IPageActionPartBContent";
import { IUserActivityLogItem } from "../IUserActivityLogItem";
import { TrackingPartBContent } from "./TrackingPartBContent";

/**
 * Represents the parameters of the asimov pageaction event Part B content that is predefined by JSLL (Javascript logging library) of Asimov and 1DS pipeline.
 * @class
 */
export class PageActionPartBContent extends TrackingPartBContent implements IPageActionPartBContent {

    /**
     * Initializes a new instance of the `PageActionPartBContent` class.
     * @param ver {string} The content Part B version as per JSLL.
     * @param pageName {string} The page name.
     * @param uri {string} The page uri.
     * @param pageType? {string} The page type.
     * @param pageTags? {string} The tags or additional data associated with the event.
     * @param screenState? {number} The state of the application on the screen such as Full, Minimized, SnappedOneHalf etc.
     * @param actionType? {string} The device, mechanism, or apparatus used to “input” or trigger the action such as a keyboard, controller, mouse, gesture, etc.
     * @param impressionGuid? {string} The impression guid that changes for every page load.
     * @param contentVer? {string} The content JSON version.
     * @param destUri? {string} The destination page Uri.
     */
    constructor(
        ver: string,
        pageName: string,
        uri: string,
        pageType?: string,
        pageTags?: string,
        screenState?: number,
        actionType?: string,
        impressionGuid?: string,
        contentVer?: string,
        public destUri?: string) {
        super(ver, pageName, uri, pageType, pageTags, screenState, actionType, impressionGuid, contentVer);
    }

    /**
     * Creates a new PageActionPartBContent from a given log item.
     * @param userActivityLogItem {IUserActivityLogItem} The log item.
     * @param windowContext {IBrowserWindow} The window object of the browser.
     * @param toolsProduct {string} The tools product name.
     * @param toolsBuild {string} The tools build version.
     * @param impressionGuid {string} The impression guid.
     * @param basicData {IEventBasicData} The basic parameters of the asimov events.
     * @return {IPageActionPartBContent} The page action part B content.
     */
    public static createFrom(
        userActivityLogItem: IUserActivityLogItem,
        windowContext: IBrowserWindow,
        toolsProduct: string,
        toolsBuild: string,
        impressionGuid: string,
        basicData: IEventBasicData): IPageActionPartBContent {

        let trackingPartBContent = TrackingPartBContent.createFrom(userActivityLogItem, windowContext, toolsProduct, toolsBuild, impressionGuid, basicData);

        let destUri;
        if (userActivityLogItem.trackingData) {
            let partBContent = <IPageActionData>userActivityLogItem.trackingData;
            destUri = partBContent.destUri;
        }

        return new PageActionPartBContent(
            trackingPartBContent.ver,
            trackingPartBContent.pageName,
            trackingPartBContent.uri,
            trackingPartBContent.pageType,
            trackingPartBContent.pageTags,
            trackingPartBContent.screenState,
            trackingPartBContent.actionType,
            trackingPartBContent.impressionGuid,
            trackingPartBContent.contentVer,
            destUri);
    }
}
