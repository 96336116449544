/**
 * Enum for 1DS routing profile. It defines predefined namespaces, pipeline settings and backend configurations for each profile.
 * For custom profiles, please contact package owners to configure the profiles.
 * @readonly
 * @enum {string}
 */
export enum OneDsRoutingProfile {

    /**
     * Profile for Catalyst Tool.
     */
    CAT = "Cat",

    /**
     * Profile for Feed Management Tool.
     */
    FMT = "Fmt",

    /**
     * Profile for Microsoft News Studio Tool.
     */
    MNS = "Mns",

    /**
     * Profile for Partner Hub Tool.
     */
    PH = "Ph",

    /**
     * Profile for Zenith1 Tool.
     */
    Zenith = "Zen",

    /**
     * Custom profile for other tool.
     */
    CUSTOM = "Custom"
}
