/**
* ClockSkewManager.ts
* @author Abhilash Panwar (abpanwar)
* @copyright Microsoft 2018
*/
/**
* Class to manage clock skew correction.
*/
export default class ClockSkewManager {
    private _allowRequestSending = true;
    private _shouldAddClockSkewHeaders = true;
    private _isFirstRequest = true;
    private _clockSkewHeaderValue = 'use-collector-delta';
    private _clockSkewSet = false;

    /**
     * Determine if the request can be sent.
     * @return {boolean} True if requests can be sent, false otherwise.
     */
    allowRequestSending(): boolean {
        if (this._isFirstRequest && !this._clockSkewSet) {
            this._isFirstRequest = false;
            this._allowRequestSending = false;
            return true;
        }
        return this._allowRequestSending;
    }

    /**
     * Determine if clock skew headers should be added to the request.
     * @return {boolean} True if clock skew headers should be added, false otherwise.
     */
    shouldAddClockSkewHeaders(): boolean {
        return this._shouldAddClockSkewHeaders;
    }

    /**
     * Gets the clock skew header value.
     * @return {string} The clock skew header value.
     */
    getClockSkewHeaderValue(): string {
        return this._clockSkewHeaderValue;
    }

    /**
     * Sets the clock skew header value. Once clock skew is set this method
     * is no-op.
     * @param {string} timeDeltaInMillis - Time delta to be saved as the clock skew header value.
     */
    setClockSkew(timeDeltaInMillis: string) {
        if (!this._clockSkewSet) {
            if (timeDeltaInMillis) {
                this._clockSkewHeaderValue = timeDeltaInMillis;
            } else {
                this._shouldAddClockSkewHeaders = false;
            }
            this._clockSkewSet = true;
            this._allowRequestSending = true;
        }
    }
}
