import { ConsoleAppender } from "./ConsoleAppender";
import { LogLevel } from "../models/LogLevel";

/**
 * Class to represent a browser console appender.
 * @class
 */
export class BrowserConsoleAppender extends ConsoleAppender {
    /**
     * Initializes a new instance of the `BrowserConsoleAppender` class.
     * @constructor
     * @param logLevel {LogLevel} Logging level or threshold.
     */
    public constructor(logLevel: LogLevel) {
        super(logLevel, window.console);
    }

    /**
     * Returns a string representation of the appender.
     * @method
     * @return {string} The string representation of the appender.
     */
    public toString(): string {
        return "BrowserConsoleAppender";
    }
}
