import * as axiosStatic from "axios";

import { BatchPart } from "./BatchPart";
import { IActivityLogItem } from "clientcore-infrastructure-analytics/models/IActivityLogItem";
import { IBatchingManager } from "./IBatchingManager";
import { IODataBatchClient } from "../odata-batching/IODataBatchClient";
import { IODataBatchError } from "../odata-batching/IODataBatchError";
import { IODataBatchRequest } from "../odata-batching/IODataBatchRequest";
import { IODataBatchResponse } from "../odata-batching/IODataBatchResponse";
import { IODataBatchResponseInfo } from "../odata-batching/IODataBatchResponseInfo";
import { default as buildURL } from "axios/lib/helpers/buildURL"; // tslint:disable-line
import { clone } from "lodash-es";

/**
 * Maintains batches of unsent http requests, and allows a consumer to control when these batches of service calls are sent to the server.
 * @class
 */
export class BatchingManager implements IBatchingManager {

    /**
     * es6-promise service instance.
     * @type {Promise}
     */
    private promiseService: typeof Promise;

    /**
     * Client that bundles multiple http requests into a single multipart OData batching request.
     * @type {IODataBatchClient}
     */
    private odataBatchClient: IODataBatchClient;

    /**
     * The array of requests that are waiting to be sent.
     * Also stores callback methods to use to resolve or reject the individual request once it comes back from the server.
     * @type {BatchPart<any>[]}
     */
    private currentBatch: BatchPart<any>[] = [];

    /**
     * Initializes a new instance of the `BatchingManager` class.
     * @constructor
     * @param odataBatchClient {IODataBatchClient} Client that bundles multiple http requests into a single multipart OData batching request.
     * @param promiseService? {Promise} es6-promise service instance.
     */
    constructor(
        odataBatchClient: IODataBatchClient,
        promiseService?: typeof Promise) {

        if (!odataBatchClient) {
            throw "odataBatchClient must not be null";
        }

        this.odataBatchClient = odataBatchClient;
        this.promiseService = promiseService || Promise;
    }

    /**
     * Gets the path of the given url without the domain if there is a domain portion.
     * @method
     * @param {string} url The url to get the path of.
     * @returns The path of the given url.
     */
    private static getUrlPathWithoutDomain(url: string): string {

        let path = url;
        let slashStart = url.indexOf("//");

        if (slashStart >= 0) {
            let pathStart = url.indexOf("/", slashStart + 2);
            path = url.substr(pathStart);
        }

        return path;
    }

    /**
     * Convert from an OData response type to an Axios response type.
     * @method
     * @param {IODataBatchResponseInfo} response The OData response to convert.
     * @param {axiosStatic.RequestOptions} config The Axios original axios request.
     * @returns {axiosStatic.Response} The converted Axios response.
     */
    private static convertFromODataToAxios(response: IODataBatchResponseInfo, config: axiosStatic.RequestOptions): axiosStatic.Response {

        // Try and parse the data into JSON if possible.
        let parsedData: any = undefined;
        if (response.headers["Content-Type"] && response.headers["Content-Type"].indexOf("application/json") > -1 && typeof response.data === "string") {
            try {
                parsedData = JSON.parse(response.data);
            } catch (e) { } // tslint:disable-line
        }

        let convertedResponse: axiosStatic.Response = {
            config: config,
            data: parsedData || response.data,
            status: response.status,
            statusText: response.statusText,
            headers: response.headers
        };

        return convertedResponse;
    }

    /**
     * Adds a request to the batch.
     * @method
     * @param config {axiosStatic.RequestOptions} The axios request configuration.
     * @returns {Promise<axiosStatic.Response>} A promise that is resolved after the batch is executed.
     */
    public addRequestToBatch<T>(config: axiosStatic.RequestOptions): Promise<axiosStatic.Response> {

        if (!config) {
            throw "config must not be null";
        }

        let batchPart = new BatchPart(config);

        // Create a promise that is hooked up to the resolve and reject functions on the batch part,
        // so that we can call those later to resolve or reject the promise.
        let promise = new this.promiseService((resolve, reject) => {
            batchPart.resolve = resolve;
            batchPart.reject = reject;
        });

        // Store the batch part for later when we'll send it and have to resolve it.
        this.currentBatch.push(batchPart);

        return promise;
    }

    /**
     * Causes the batch that has been built up to be sent.
     * @method
     * @param parentActivity? {IActivityLogItem} The parent activity item.
     * @returns A promise that finishes when the batch request completes.
     */
    public sendBatch(parentActivity?: IActivityLogItem): Promise<IODataBatchResponse[]> {

        // Copy the current batch and empty out the array so that a new batch can be built up.
        let batchToSend = clone(this.currentBatch);
        this.currentBatch.length = 0;

        // Create a batch service call entry for each item in the current batch.
        let requests: IODataBatchRequest[] = [];
        batchToSend.forEach((value, index) => {

            // Get the path of the request without the domain and use the internal axios buildUrl function to add query string parameters
            // so that our query string is exactly the same as if built by axios.
            let path = buildURL(BatchingManager.getUrlPathWithoutDomain(value.config.url), value.config.params);

            // Create the OData Batch request and add it to our list to execute later.
            let request: IODataBatchRequest = {
                method: value.config.method,
                headers: <{ [name: string]: string }>value.config.headers,
                uri: path,
                data: value.config.data
            };

            requests.push(request);
        });

        // Send the batch request.
        let responsePromise = this.odataBatchClient.executeAsBatch(requests, parentActivity);

        // Convert each response back into the axios format, and resolve/reject the promise for each request.
        responsePromise.then((responses) => {
            for (var i = 0; i < batchToSend.length; i++) {

                let response = responses[i];
                let batchRequestPart = batchToSend[i];

                let convertedResponse = BatchingManager.convertFromODataToAxios(response, batchRequestPart.config);

                // Reject the request if it doesn't fall within the 200 range.
                if (convertedResponse.status < 200 || convertedResponse.status > 299) {
                    batchRequestPart.reject(convertedResponse);
                } else {
                    batchRequestPart.resolve(convertedResponse);
                }
            }
        }).catch((error: IODataBatchError) => {
            for (let i = 0; i < batchToSend.length; i++) {
                var batchRequestPart = batchToSend[i];

                // TODO: Decide if we should inject different error info when the whole batch has failed.
                let convertedResponse = BatchingManager.convertFromODataToAxios(error, batchRequestPart.config);
                batchRequestPart.reject(convertedResponse);
            }
        });

        return responsePromise;
    }
}