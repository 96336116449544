/**
* DateCollector.ts
* @author  Ram Thiru (ramthi@microsoft.com)
* @copyright Microsoft 2017
* File containing utility functions.
*/
import { extend } from '@ms/1ds-core-js';
import { IQosConfiguration, ICoreData } from '../DataModel';

export function _collectMetaTags(config: IQosConfiguration) {
    /// <summary>Collect data from meta tags. Assign specific field values 
    /// in the event object.Return an object that is a kvp of awa- and ms.tags. </summary>
    /// <param type='Object'>Event object whose fields will be assigned to</param>
    /// <returns type='Object'>Metatags collection/property bag</returns>
    var msTags = {};
    var awaTags = {};
    // Collect the awa-* tags.
    awaTags = _getMetaDataFromDOM('awa-', true);

    // Collect the legacy ms.* tags and append them to the metaTags.
    msTags = _getMetaDataFromDOM('ms.', false);
    return extend(true, awaTags, msTags);
}

export function _getMetaData(awaTags: any, coreData: ICoreData, metaTagName: string): string {
    /// <summary>
    ///     If overrideValue is specified in the config that takes precedence.  
    ///     However, in order to prevent the tag value found in the meta element in the dom from being used
    ///     we must extract (remove) the given tag from the pageTags collection.
    /// <param type='Object' name='awaTags'>contains meta tags</param>

    var data = _extractFieldFromObject(awaTags, metaTagName); // Do not optimize this.  We need to extract the field irrespective of whether coreData has a value or not.
    if (coreData[metaTagName]) {
        return coreData[metaTagName];
    } else {
        return data;
    }
}

export function _getMetaDataFromDOM(prefix: string, removePrefix: boolean): any {
    var metaElements: any;
    var metaData = {};
    if (typeof document !== 'undefined' && document) {
        metaElements = document.querySelectorAll('meta');
        for (var i = 0; i < metaElements.length; i++) {
            var meta = metaElements[i];
            if (meta.name) {
                var mt = meta.name.toLowerCase();
                if (mt.indexOf(prefix) === 0) {
                    var name = removePrefix ? meta.name.replace(prefix, '') : meta.name;
                    metaData[name] = meta.content;
                }
            }
        }
    }

    return metaData;
}

export function _extractFieldFromObject(obj: Object, fieldName: string): any {
    /// <summary>Returns the specified field and also removes the property from the object if exists. </summary>
    /// <param type='Object'>The object</param>
    /// <param type='String'>Name of the field/property to be extracted</param>
    /// <returns type='String'>Value of the specified tag</returns>

    var fieldValue: any;
    if (obj && obj[fieldName]) {
        fieldValue = obj[fieldName];
        delete obj[fieldName];
    }

    return fieldValue;
}

export function _getUri(config: IQosConfiguration): string {
    return !config.coreData.requestUri ? window.location.href : config.coreData.requestUri;
}

export function _getPageName(config: IQosConfiguration) {
    /// <summary>
    ///  Gets the pageName from the DOM or by calling a override if set.
    /// </summary>

    if (config.callback && typeof (config.callback.pageName) === 'function') {
        return config.callback.pageName();
    } else if (config.coreData.pageName) {
        return config.coreData.pageName;
    } else {
        var pagename = typeof window !== 'undefined' && window.location.pathname;
        var framents = pagename.split('/');
        if (framents.length > 2 && framents[2] !== '') {
            pagename = framents[2];
        } else {
            pagename = 'Home';
        }
        return pagename;
    }
}
