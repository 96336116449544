import { ILoggingService } from "./ILoggingService";

/**
 * LoggedExceptionManager class for throwing exceptions that are logged.
 * @class
 */
export class LoggedExceptionManager {

    /**
     * Initializes a new instance of the 'LoggedExceptionManager' class.
     * @constructor
     * @param loggingService {ILoggingService} 
     */
    constructor(private loggingService: ILoggingService) {
        if (!loggingService) {
            throw ("loggingService is required for LoggedExceptionManager.");
        }
    }

    /**
     * Logs an error message and throws an exception.
     * @param errorMessage {string}
     * @returns {void} 
     */
    public error(errorMessage: string): void {
        this.loggingService.error(errorMessage);
        throw (errorMessage);
    }

    /**
     * Logs a fatal error message and throws an exception.
     * @param errorMessage {string}
     * @returns {void} 
     */
    public fatal(errorMessage: string): void {
        this.loggingService.fatal(errorMessage);
        throw (errorMessage);
    }
}