/**
* Application.ts
* @author Hector Hernandez (hectorh)
* @copyright Microsoft 2019
*/
import { getCookie, objDefineAccessors } from '@ms/1ds-core-js';
import { IAppContext, IPropertyConfiguration } from '../DataModels';

export class Application implements IAppContext {
    /**
     * The application ID.
     */
    public id?: string;
    /**
     * The application version.
     */
    public ver?: string;

    /**
     * The application name.
     */
    public name?: string;

    /**
     * The application locale.
     */
    public locale?: string;

    /**
    * The application environment.
    */
    public env?: string;

    /**
     * Returns the application experiment Id.
     * Note: This property will NOT exist in ES3/IE8 environment, if you need IE8 compatibility
     * use the method {@link Application#getExpId} from your code. For ES5+ environment this will be replaced with a 
     * property getter only.
     */
    public expId?:string;

    /**
    * The application experiment Id.
    */
    getExpId(): string {
        return this._propertiesConfig.expId ? this._readExpIdFromCoreData(this._propertiesConfig.expId) : this._readExpIdFromCookie();
    };

    private appExpId: string = null;

    private flightIdNameSpaces = [
        'AX', // ASIMOV
        'EX', // EXP
        'SF', // CARBONFLIGHT
        'CS', // CARBONSERVICE
        'CF', // CONFIGFLIGHT
        'CT', // CONTROLTOWER
        'CU', // CUSTOM
        'DC', // DEVCENTER
        'DF', // DRIVERFLIGT
        'H5', // HFIVE
        'HL', // HOLOLENS
        'WS', // WINDOWS
        'WP'  // WINDOWSPHONE
    ];

    private expIdCookieName = 'Treatments';
    private _propertiesConfig?: IPropertyConfiguration;

    constructor(propertiesConfig: IPropertyConfiguration) {
        this._propertiesConfig = propertiesConfig;
        //Add app language
        if (typeof document !== 'undefined') {
            let documentElement = document.documentElement;
            if (documentElement) {
                this.locale = documentElement.lang;
            }
        }
        this.env = propertiesConfig.env ? propertiesConfig.env : this._getMetaDataFromDOM('awa-')['env'];
    }

    /**
    * Retrieve a specified metadata tag value from the DOM.
    * @param {string} prefix - Prefix to search the metatags with.
    * @returns {object} - Metadata collection/property bag
    */
    private _getMetaDataFromDOM(prefix: string): any {
        var metaElements: any;
        var metaData = {};
        metaElements = typeof document !== 'undefined' && document.querySelectorAll('meta');
        for (var i = 0; i < metaElements.length; i++) {
            var meta = metaElements[i];
            if (meta.name) {
                var mt = meta.name.toLowerCase();
                if (mt.indexOf(prefix) === 0) {
                    var name = meta.name.replace(prefix, '');
                    metaData[name] = meta.content;
                }
            }
        }
        return metaData;
    }

    /// <summary>Validate each flight id in appExpId against the app flight ID
    /// format and create a comma seperated appExpId with valid flight ids.Ignore invalid flight IDs< /summary>
    /// <param type='Object'>A list of comma seperated appExpId</param>
    /// <returns type='void'>none</returns>
    private _setAppExpId(appExpIdNew: string): void {
        if (!appExpIdNew) {
            this.appExpId = null;
            return;
        } else if (appExpIdNew === this.appExpId) {
            return; // Nothing to do if seen before
        } else {
            // Reset to empty first. The new but invalid appExpId should not be logged and we cannot leave the old one hanging around
            // since it means old experiment id cannot represent the new experiment with an invalid appExpId.
            this.appExpId = null;
            var expIdArray = appExpIdNew.split(',');

            for (var i = 0; i < expIdArray.length; i++) {
                if (this._isValidAppFlightId(expIdArray[i])) {
                    if (!this.appExpId) {
                        this.appExpId = expIdArray[i];
                    } else {
                        this.appExpId += ',' + expIdArray[i];
                    }
                } else {
                    // this._traceLogger.w('Unsupported flight id format for this app expId: ' + expIdArray[i]);
                }
            }
        }
    }

    /// <summary>Get experiment ids based on flight ID string format.</summary>
    /// <returns type='true'>Comma seperated experiment ids</returns>
    private _getAppExpId(): string {
        return this.appExpId;
    }

    private _readExpIdFromCookie(): string {
        this._setAppExpId(getCookie(this.expIdCookieName));
        return this._getAppExpId();
    }

    private _readExpIdFromCoreData(expId: string): string {
        this._setAppExpId(expId);
        return this._getAppExpId();
    }

    /// <summary>Verify an expId against the CS2.1 spec</summary>
    /// <param type='Object'>expId to verify in string format</param>
    /// <returns type='true'>true if expId is valid, false otherwise</returns>
    private _isValidAppFlightId(appFlightId: string) {
        if (!appFlightId || appFlightId.length < 4) {
            return false;
        }
        var isValid = false,
            MAXFLIGHTIDLENGTH = 256,
            curNameSpace = (appFlightId.substring(0, 3)).toString().toUpperCase();

        // The prefix check must include ':', else strings starting with prefixes will slip through
        let flightIdNameSpaces = this.flightIdNameSpaces;
        for (var i = 0; i < flightIdNameSpaces.length; i++) {
            if (flightIdNameSpaces[i] + ':' === curNameSpace && appFlightId.length <= MAXFLIGHTIDLENGTH) {
                isValid = true;
                break;
            }
        }
        return isValid;
    }

    /**
     * Static constructor, attempt to create accessors
     */
    private static _staticInit = (() => {
        // Dynamically create get/set property accessors
        objDefineAccessors(Application.prototype, "expId", Application.prototype.getExpId);
    })();
}
