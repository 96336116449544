/**
* Extensions.ts
* @author Hector Hernandez (hectorh)
* @copyright Microsoft 2019
*/

export class Extensions {
    public static UserExt = 'user';
    public static DeviceExt = 'device';
    public static TraceExt = 'trace';
    public static WebExt = 'web';
    public static AppExt = 'app';
    public static OSExt = 'os';
    public static SdkExt = 'sdk';
    public static IntWebExt = 'intweb';
    public static UtcExt = 'utc';
    public static LocExt = 'loc';
}
