import { ApplicationInsights, IExtendedConfiguration } from "@ms/1ds-analytics-js";
import { ApplicationInsights as WebAnalyticsPluginApplicationInsights, IWebAnalyticsConfiguration } from "@ms/1ds-wa-js";
import { AuthPlugin, IAuthenticationConfiguration } from "@ms/1ds-auth-js";
import { BaseAppender } from "./BaseAppender";
import { CorrelationVectorPlugin } from "@ms/1ds-cv-js";
import { EventLatency } from "@ms/1ds-core-js";
import { Guid } from "cms-infrastructure-javascriptextensions/Guid";
import { IActivityLogItem } from "../models/IActivityLogItem";
import { IBrowserWindow } from "../browser-object-models/IBrowserWindow";
import { ILogItem } from "../models/ILogItem";
import { IOneDsAppenderConfig } from "./IOneDsAppenderConfig";
import { IPerfCounterItem } from "../models/models";
import { IQosConfiguration, QosPlugin } from "@ms/1ds-qos-js";
import { IUserActivityLogItem } from "../models/IUserActivityLogItem";
import { LogLevel } from "../models/LogLevel";
import { LogType } from "../models/LogType";
import { NetworkActivityLogItem } from "../models/NetworkActivityLogItem";
import { OneDsActivityEventData } from "../models/oneDs/OneDsActivityEventData";
import { OneDsEnvironment } from "./OneDsEnvironment";
import { OneDsErrorEventData } from "../models/oneDs/OneDsErrorEventData";
import { OneDsTracingEventData } from "../models/oneDs/OneDsTracingEventData";
import { OneDsSendMode } from "./OneDsSendMode";
import { OneDsPerfCounterEventData } from "../models/oneDs/OneDsPerfCounterEventData";
import { UserActivityType } from "../models/UserActivityType";
import { OneDsOutgoingServiceRequestEventData } from "../models/oneDs/OneDsOutgoingServiceRequestEventData";
import { OneDsPageActionEventData } from "../models/oneDs/OneDsPageActionEventData";
import { OneDsPageViewEventData } from "../models/oneDs/OneDsPageViewEventData";
import { OneDsTransmissionProfile } from "./OneDsTransmissionProfile";
import { OneDsUserAuthMethod } from "./OneDsUserAuthMethod";
import { OneDsRoutingProfile } from "./OneDsRoutingProfile";
import { OneDsNamespaceProfile } from "../models/oneDs/OneDsNamespaceProfile";

/**
 * Class to send the telemetry event to 1DS pipeline.
 * @class
 */
export class OneDsWebAppender extends BaseAppender {

    /**
     * The analytics SKU of 1DS pipeline.
     * @type {ApplicationInsights}
     */
    protected analytics: ApplicationInsights;

    /**
     * The web analytics plugin of 1DS pipeline.
     * @type {WebAnalyticsPluginApplicationInsights}
     */
    protected webAnalyticsPlugin: WebAnalyticsPluginApplicationInsights;

    /**
     * The impression guid that works as a session id.
     * @type {string}
     */
    private serverImpressionGuid: string;

    /**
     * The namespace profile for the 1DS events.
     * @type {OneDsNamespaceProfile}
     */
    private oneDsNamespaceProfile: OneDsNamespaceProfile;

    /**
     * Initializes a new instance of the `OneDsWebAppender` class.
     * @constructor
     * @param config {IOneDsAppenderConfig} The configuration settings defining the parameters required for 1DS appender.
     * @param windowContext? {IBrowserWindow} The window object of the browser.
     * @param logLevel {LogLevel} Logging level or threshold.
     */
    constructor(
        private config: IOneDsAppenderConfig,
        private windowContext?: IBrowserWindow,
        logLevel?: LogLevel) {

        super(logLevel != null && logLevel != undefined ? logLevel : LogLevel.Activity);

        if (!config) {
            throw "'config' is required for constructing OneDsWebAppender";
        }

        if (!config.application) {
            throw "'config.application' is required for constructing OneDsWebAppender";
        }

        if (!config.instrumentationKey) {
            throw "'config.instrumentationKey' is required for constructing OneDsWebAppender";
        }

        if (this.config.oneDsRoutingProfile === OneDsRoutingProfile.CUSTOM && !this.config.oneDsCustomRoutingProfile) {
            throw "'config.config.oneDsCustomRoutingProfile' is required for constructing OneDsWebAppender because config.oneDsRoutingProfile is set to CUSTOM.";
        }

        // Initialize 1DS with config parameters
        this.initialize();
    }

    /**
     * Returns a string representation of the appender.
     * @method
     * @return {string} The string representation of the appender.
     */
    public toString(): string {
        return "OneDsWebAppender";
    }

    /**
     * Appender specific method to append a log message. In this case, appender logs the message to 1DS.
     * @method
     * @param logItems {Array<ILogItem>} The collection of log items.
     */
    protected log(logItems: Array<ILogItem>): void {

        // Impression guid changes for each page view event. There will be multilple impression guids for each serverImpressionGuid (serverImpressionGuid will be same for whole session) in SPA.
        let impressionGuid = this.webAnalyticsPlugin.id.getLastPageViewId();
        const isSync = this.config.sendMode === OneDsSendMode.SendEventsImmediately;
        logItems.forEach((logItem) => {

            if (logItem.LogType === LogType.EndNetworkActivity) {
                // Send OSR event
                this.analytics.track(OneDsOutgoingServiceRequestEventData.createFrom(
                    <NetworkActivityLogItem>logItem,
                    this.windowContext,
                    this.config.application,
                    this.config.source, impressionGuid,
                    this.config.tenant,
                    logItem.LogDateTime.toISOString(),
                    this.serverImpressionGuid,
                    this.oneDsNamespaceProfile,
                    EventLatency.RealTime,
                    isSync));
            } else if (logItem.LogLevel === LogLevel.Activity && logItem.LogType === LogType.Default) {
                // Log item is a user activity log item

                let userActivityLogItem = <IUserActivityLogItem>logItem;
                if (userActivityLogItem.userActivityType === UserActivityType.PageAction) {

                    // Construct PageActionEeventData and send the event to 1DS a custom event
                    this.analytics.track(OneDsPageActionEventData.createFrom(
                        userActivityLogItem,
                        this.windowContext,
                        this.config.application,
                        this.config.source,
                        impressionGuid,
                        this.config.tenant,
                        logItem.LogDateTime.toISOString(),
                        this.serverImpressionGuid,
                        this.config.productName,
                        this.config.buildVersion,
                        this.oneDsNamespaceProfile,
                        EventLatency.RealTime,
                        true));
                } else if (userActivityLogItem.userActivityType === UserActivityType.PageView) {
                    this.webAnalyticsPlugin.id.initializeIds();

                    // Construct PageViewEeventData and send the event to 1DS a custom event
                    this.analytics.track(OneDsPageViewEventData.createFrom(
                        userActivityLogItem,
                        this.windowContext,
                        this.config.application,
                        this.config.source,
                        impressionGuid,
                        this.config.tenant,
                        logItem.LogDateTime.toISOString(),
                        this.serverImpressionGuid,
                        this.config.productName,
                        this.config.buildVersion,
                        this.oneDsNamespaceProfile,
                        EventLatency.RealTime,
                        true));
                }
            } else if (logItem.LogType === LogType.BeginActivity || logItem.LogType === LogType.EndActivity) {

                // Log item is of type activity (BeginActivity or EndActivity)
                this.analytics.track(OneDsActivityEventData.createFrom(
                    <IActivityLogItem>logItem,
                    this.windowContext,
                    this.config.application,
                    this.config.source,
                    impressionGuid,
                    this.config.tenant,
                    logItem.LogDateTime.toISOString(),
                    this.serverImpressionGuid,
                    this.oneDsNamespaceProfile,
                    EventLatency.Normal,
                    isSync));
            } else if (logItem.LogLevel === LogLevel.Error || logItem.LogLevel === LogLevel.Fatal) {

                // Log the error
                let oneDsErrorEventData = OneDsErrorEventData.createFrom(
                    logItem,
                    this.windowContext,
                    this.config.application,
                    this.config.source,
                    impressionGuid,
                    this.config.tenant,
                    logItem.LogDateTime.toISOString(),
                    this.serverImpressionGuid,
                    this.oneDsNamespaceProfile,
                    EventLatency.RealTime,
                    isSync);
                this.analytics.track(oneDsErrorEventData);
            } else if (logItem.LogType === LogType.Instrumentation) {

                // Log the performance counter item
                let perfCounterData = OneDsPerfCounterEventData.createFrom(
                    <IPerfCounterItem>logItem,
                    this.windowContext,
                    this.config.application,
                    this.config.source,
                    impressionGuid,
                    this.config.tenant,
                    logItem.LogDateTime.toISOString(),
                    this.serverImpressionGuid,
                    this.oneDsNamespaceProfile,
                    EventLatency.RealTime,
                    isSync);
                this.analytics.track(perfCounterData);
            } else if (logItem.LogType === LogType.Default) {

                // Log the operational item (Trace/ Debug/ Warning)
                let oneDsTracingEventData = OneDsTracingEventData.createFrom(
                    logItem,
                    this.windowContext,
                    this.config.application,
                    this.config.source,
                    impressionGuid,
                    this.config.tenant,
                    logItem.LogDateTime.toISOString(),
                    this.serverImpressionGuid,
                    this.oneDsNamespaceProfile,
                    EventLatency.Normal,
                    isSync);
                this.analytics.track(oneDsTracingEventData);
            }
        });
    }

    /**
     * Initializes the 1DS logging by providing the configuration values.
     * @method
     */
    private initialize(): void {

        // Set the default values
        if (!this.config.source) {
            this.config.source = "OneDsWebAppender";
        }

        if (!this.config.applicationId && this.windowContext) {
            this.config.applicationId = this.windowContext.location && this.windowContext.location.hostname ? this.windowContext.location.hostname : "";
        }

        this.config.sendMode = this.config.sendMode === null || this.config.sendMode === undefined ? OneDsSendMode.BatchEvents : this.config.sendMode;
        let userConsented = this.config.userConsented != null ? this.config.userConsented : false;

        this.serverImpressionGuid = this.config.serverImpressionGuid || Guid.newGuid();

        let profileName = <string>this.config.oneDsRoutingProfile;
        if (profileName === OneDsRoutingProfile.CUSTOM) {
            profileName = this.config.oneDsCustomRoutingProfile;
        }

        this.oneDsNamespaceProfile = new OneDsNamespaceProfile(profileName);

        // Define 1DS plugins and Web Analytics SKU
        this.webAnalyticsPlugin = new WebAnalyticsPluginApplicationInsights();
        let cvPlugin: CorrelationVectorPlugin = new CorrelationVectorPlugin();
        let qosPlugin: QosPlugin = new QosPlugin();
        let authPlugin: AuthPlugin = new AuthPlugin();

        // Construct 1DS config
        let coreConfig: IExtendedConfiguration = {
            instrumentationKey: this.config.instrumentationKey,
            extensions: [this.webAnalyticsPlugin, cvPlugin], // Extra plugins
            extensionConfig: [], // Config for extra plugins
            propertyConfiguration: { // Properties Plugin configuration
                populateBrowserInfo: true,
                populateOperatingSystemInfo: true,
                userConsented: userConsented
            }
        };

        if (this.config.endpointUrl && this.config.endpointUrl.trim().length > 0) {
            coreConfig.endpointUrl = this.config.endpointUrl;
        } else if (this.config.oneDsEnvironment !== OneDsEnvironment.Prod) {
            // The non-prod endpoint url "https://events-sandbox.data.microsoft.com/OneCollector/1.0/" of 1DS collector doesn't work on Chrome and Chromium Edge due to stricter SSL policy.
            // An alternative url "https://self.pipe.aria.int.microsoft.com/OneCollector/1.0/" that uses Baltimore provided SSL certificate is recommended to use.
            coreConfig.endpointUrl = "https://self.pipe.aria.int.microsoft.com/OneCollector/1.0/";
        }

        let webAnalyticsConfig: IWebAnalyticsConfiguration = {
            manageCv: false,
            urlCollectHash: false,
            isLoggedIn: false,
            shareAuthStatus: false,
            autoCapture: {
                click: false,
                scroll: false,
                pageView: false,
                onLoad: false,
                onUnload: false,
                resize: false
            }
        };

        let qosConfig: IQosConfiguration = {
            enableCvHeaders: true
        };

        if (!!this.config.expId) {
            coreConfig.propertyConfiguration.expId = this.config.expId;
        }

        if (this.config.isLoggedIn) {
            webAnalyticsConfig.isLoggedIn = true;

            // Setup authentication autotracking related settings if user id is not set explicitly.
            if (!this.config.user && this.config.shareAuthStatus) {
                webAnalyticsConfig.shareAuthStatus = true;

                // Setup authentication plugin if MSA or AAD is used as the authentication method in the application.
                if (this.config.authMethod !== OneDsUserAuthMethod.None) {
                    let authConfig: IAuthenticationConfiguration = {
                        authType: this.config.authMethod,
                        loggedInStatusCallback: () => {
                            return true;
                        }
                    };

                    coreConfig.extensions.push(authPlugin);
                    coreConfig.extensionConfig[authPlugin.identifier] = authConfig;
                }
            }
        }

        coreConfig.extensionConfig[this.webAnalyticsPlugin.identifier] = webAnalyticsConfig;
        coreConfig.extensionConfig[qosPlugin.identifier] = qosConfig;

        // Initialize SDK
        this.analytics = new ApplicationInsights();
        this.analytics.initialize(coreConfig, []);

        const propertiesManager = this.analytics.getPropertyManager();
        const propertiesContext = propertiesManager.getPropertiesContext();
        propertiesContext.app.id = this.config.applicationId;
        propertiesContext.app.name = this.config.application;

        if (this.config.deviceClass) {
            propertiesManager.getPropertiesContext().device.deviceClass = this.config.deviceClass;
        }

        if (this.config.user) {
            // According to validation rules, for internal applications, user id should be prefixed with i:.
            const user = "i:" + this.config.user;
            propertiesContext.user.localId = user;
        }

        const transmissionProfile = this.config.transmissionProfile || OneDsTransmissionProfile.Custom;
        if (transmissionProfile === OneDsTransmissionProfile.Custom) {
            const customProfile = { "CUSTOM": [this.config.customTransmissionFrequencyForNoramlLatencyEvents || 60, this.config.customTransmissionFrequencyForRealTimeLatencyEvents || 30] };
            this.analytics.getPostChannel()._loadTransmitProfiles(customProfile);
        }

        this.analytics.getPostChannel()._setTransmitProfile(transmissionProfile);

        // Configure flushing of the events when page is unloaded
        if (this.windowContext) {
            this.windowContext.addEventListener("unload", (event) => {
                this.analytics.getPostChannel().flush();
            });
        }
    }
}