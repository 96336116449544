import * as axiosStatic from "axios";

import { AxiosWrapperBase } from "../axios-wrapper/AxiosWrapperBase";
import { Token } from "./Token";
import { merge } from "lodash-es";

/**
 * Wraps axios and adds a query param that triggers agent token authentication for the PROSE service.
 * This is only enabled in dev/ppe environments for automated tests.
 * @class
 */
export class AxiosAgentTokenAuthenticationWrapper extends AxiosWrapperBase {

    /**
     * The agent key, which identifies the agent token query parameter.
     * @type {string}
     */
    private agentKey: string = "tat";

    /**
     * Initializes a new instance of the `AxiosAgentTokenAuthenticationWrapper` class.
     * @constructor
     * @param sharedKey {string} The shared key.
     * @param spn {string} The SPN email address.
     * @param message {string} The shared message to sign.
     * @param axios? {axiosStatic.AxiosStatic} Axios service instance.
     */
    constructor(
        private sharedKey: string,
        private spn: string,
        private message: string,
        axios?: axiosStatic.AxiosStatic) {

        super(axios || axiosStatic);

        if (!sharedKey) {
            throw "'sharedKey' can't be null";
        }

        if (!spn) {
            throw "'spn' can't be null";
        }

        if (!message) {
            throw "'message' can't be null";
        }
    }

    /**
     * Adds the PROSE bypass token to every request before it is sent.
     * @param config {axiosStatic.RequestOptions} The request detail.
     * @returns {void}
     */
    protected onRequest<T>(config: axiosStatic.RequestOptions): void {

        if (!config) {
            throw "'config' can't be null";
        }

        var configWithToken = {
            params: {
                [this.agentKey]: Token.generateToken(this.sharedKey, this.spn, this.message)
            }
        };

        config = merge(config, configWithToken) as axiosStatic.RequestOptions;
    }
}