/**
 * Enum for the authentication method to be used for auto-tracking the user identifier in 1DS pipeline.
 * @readonly
 * @enum {number}
 */
export enum OneDsUserAuthMethod {

    /**
     * No authentication method.
     */
    None = 0,

    /**
     * Microsoft Account.
     */
    MSA = 1,

    /**
     * Azure Active Directory account.
     */
    AAD = 2
}
