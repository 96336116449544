import { IEvent } from "../../browser-object-models/IEvent";
import { IEventData } from "./IEventData";

/**
 * Model for the event data.
 * @class
 */
export class EventData implements IEventData {

    /**
     * Initializes a new instance of the `EventData` class.
     * @constructor
     * @param elementTitle {string} The element title or name.
     * @param eventType {string} The event type.
     * @param elementId? {string} The unique identifier of the UI element.
     * @param areaOfPage? {string} The area of the page where the event has occurred.
     */
    constructor(
        public elementTitle: string,
        public eventType: string,
        public elementId?: string,
        public areaOfPage?: string) {

        if (!elementTitle) {
            throw "'elementTitle' is required for constructing EventData";
        }

        if (!eventType) {
            throw "'eventType' is required for constructing EventData";
        }

        // Replace \r and \n values from element title
        this.elementTitle = elementTitle.replace("\r\n", " ").replace("\r", " ").replace("\n", " ");
    }

    /**
     * Creates a new EventData from a DOM event.
     * @param event {IEvent} The DOM event.
     * @param elementTitle {string} The element title or name to override.
     * @param eventType {string} The event type to override.
     * @param elementId {string} The unique identifier of the UI element to override.
     * @param areaOfPage {string} The area of the page to override.
     * @return {IEventData} The event data.
     */
    public static createFrom(event: IEvent, elementTitle?: string, eventType?: string, elementId?: string, areaOfPage?: string): IEventData {

        let id = elementId;
        let title = elementTitle;
        let type = eventType;
        let area = areaOfPage;

        // TODO: Ideally, HTML events should never be null. But an unknown event is getting triggered during page load time of React-Redux SPA application with null value. Product teams are currently investigating it.
        // Meanwhile, to avoid any exceptions, event data is being created with white spaces. These events will be filtered out in UserActivityTrackingService.
        if (!event) {
            const whiteSpace = " ";
            return new EventData(title || whiteSpace, type || whiteSpace, id || whiteSpace, area || whiteSpace);
        }

        if (!type) {
            type = event.type;
        }

        if (event.target) {
            if (!title) {
                title = event.target.title;
            }

            if (!id) {
                id = event.target.id;
            }
        }

        return new EventData(title, type, id, area);
    }
}
