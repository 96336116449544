/**
 * Provides functionality related to Array process
 * @class 
 */
export class Array {
    /**
     * Slices array into specified number of arrays.
     * @param array {any[]} the array to be sliced
     * @param numberOfSlices {number} the number of slices wanted.
     */
    public static sliceEqually(array: any[], numberOfSlices: number): any[][] {
        if (!numberOfSlices || numberOfSlices === 0) {
            throw ("Number of slices must be provided and cannot be zero!");
        }
        var collection: any[][] = [];
        var sliceCount = Math.floor(array.length / numberOfSlices);
        for (var i = 0; i < numberOfSlices; i++) {
            var currentSliceCount = sliceCount;
            if (i === numberOfSlices - 1) {
                currentSliceCount = sliceCount + array.length % numberOfSlices;
            }
            var assetsSlice = array.slice((i * sliceCount), (i * sliceCount) + currentSliceCount);
            collection.push(assetsSlice);
        }
        return collection;
    }

    /**
     * Convert the given array into a dictionary.
     * @param array {TItemType[]} The array to be converted into a dictionary.
     * @param keySelector {(value: TItemType) => string)} A selector that chooses the key for each item from the array.
     * @returns {{ [key: string]: TItemType }} A dictionary that contains each value of the array with the key provided by the key selector.
     */
    public static toDictionary<TItemType>(array: TItemType[], keySelector: (value: TItemType) => string): { [key: string]: TItemType } {
        if (!array || array.length === 0) {
            return {};
        }

        if (!keySelector) {
            throw ("The 'keySelector' parameter is required.");
        }

        let resultDictionary: { [key: string]: TItemType } = {};
        array.forEach(value => {
            const key = keySelector(value);
            resultDictionary[key] = value;
        });

        return resultDictionary;
    }

    /**
     * Removes an item from an array at a specific index.
     * @method
     * @param inputArray {T[]]} The input array to modify.
     * @param index {number} The index to remove the item from.
     * @returns {T[]} The modified array.
     */
    public static removeAtIndex<T>(inputArray: T[], index: number): T[] {
        if (inputArray.length <= index) {
            throw ("Out of range exception: Unable to remove item from array '" + JSON.stringify(inputArray) + "' at index " + index);
        }

        let beforeItem = index === 0 ? [] : inputArray.slice(0, index);
        let afterItem = inputArray.slice(index + 1);

        let newArray = [
            ...beforeItem,
            ...afterItem
        ];
        return newArray;
    }
}