/**
 * Provides functionality related to URL manipulation
 * @class 
 */
export class Url {

    /**
     * Regular expression for normalization
     * @type {RegExp}
     */
    private static NormalizeExpression: RegExp = new RegExp("^\\/|\\/$", "g");

    /**
     * Builds a URL by joining the different parts provided.
     * @param args parts of the URL
     * @returns {string} a URL made up of the different parts joined together.
     */
    public static join(...args: string[]): string {
        const startsWithSlashes = args[0].slice(0, 2) === "//";

        let url = args.map(element => element.replace(Url.NormalizeExpression, "")).join("/");
        if (startsWithSlashes) {
            url = `/${url}`;
        }

        return url;
    }

    /**
     * Extracts the base url from given url
     * @param url {string} the full url
     * @returns {string} the base url from given url.
     */
    public static getDomainName(url: string): string {
        let domain = "";
        if (url.indexOf("//") > -1) {
            domain = url.split("/")[2];
        } else {
            domain = url.split("/")[0];
        }

        return domain;
    }

    /**
     * Returns a copy of the given url with the scheme removed.
     * @param url {string} The full url with the scheme to remove.
     * @returns {string} The copy of the given url with the scheme removed.
     */
    public static removeScheme(url: string): string {
        const schemeEndIndex = url.indexOf("://");

        // Return the input url if there is no scheme in the url already.
        if (schemeEndIndex < 0) {
            return url;
        }

        return url.substr(schemeEndIndex + 1);
    }
}