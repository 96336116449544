import { BaseService } from "./BaseService";
import { IActivityLogItem } from "./models/IActivityLogItem";
import { ILogger } from "./loggers/ILogger";
import { ILoggingService } from "./ILoggingService";
import { Logger } from "./loggers/Logger";
import { LogItem } from "./models/LogItem";
import { LogLevel } from "./models/LogLevel";
import { RuntimeEnvironment } from "./RuntimeEnvironment";

/**
 * Class to log the messages by sending messages to all registered appenders.
 * @class
 */
export class LoggingService extends BaseService implements ILoggingService {

    /**
     * Dictionary to the mapping of name to logging service
     */
    private static LoggingInstancesMap: { [name: string]: LoggingService; } = {};

    /**
     * Initializes a new instance of the `LoggingService` class.
     * @constructor
     * @param logger {ILogger} The logger.
     */
    constructor(logger: ILogger) {
        super(logger);
    }

    /**
     * Gets the instance of the `LoggingService` class.
     * @method
     * @param loggerName {string} Name of the logger.
     * @param logLevel {LogLevel} Logging level or threshold.
     * @param runtimeEnvironment {RuntimeEnvironment} The runtime environment the code is running under. Default is Browser.
     */
    public static getInstance(loggerName?: string, logLevel?: LogLevel, runtimeEnvironment?: RuntimeEnvironment): ILoggingService {
        var name = loggerName || "Default";
        var instance = LoggingService.LoggingInstancesMap[name];
        if (instance) {
            return instance;
        }

        instance = new LoggingService(new Logger(name, logLevel));
        instance.addUnhandledErrorCallback((error) => instance.fatalCallback(() => "UnhandledError:" + LoggingService.constructErrorMessage(error)), runtimeEnvironment);
        LoggingService.LoggingInstancesMap[name] = instance;

        return instance;
    }

    /**
     * Constructs the message from error and error stack.
     * @method
     * @param error {Error} The error.
     * @return {string} The error message and stack to log.
     * @private
     */
    private static constructErrorMessage(error: Error): string {
        let message = "";
        if (error) {
            message = error.toString();

            if (error.stack) {
                message = message + "\r\n Stack:" + error.stack;
            }
        }

        return message;
    }

    /**
     * Logs the message or log item or error at level TRACE.
     * @method
     * @param messages {any} The logging message or log item or error.
     * @param activity {IActivityLogItem} The activity item associated with the message.
     * @param data {any} The additional data.
     * @param correlationVector {string} The correlation vector.
     * @example
     *      trace("message"), trace("message", activity), trace(logitem), trace(logitem, activity), trace(e), trace(e, activity)
     */
    public trace(message: string, activity?: IActivityLogItem, data?: any, correlationVector?: string): void;
    public trace(message: LogItem, activity?: IActivityLogItem, data?: any, correlationVector?: string): void;
    public trace(message: Error, activity?: IActivityLogItem, data?: any, correlationVector?: string): void;
    public trace(message: any, activity?: IActivityLogItem, data?: any, correlationVector?: string): void {
        let logItem = this.processMessage(LogLevel.Trace, message, activity, data, correlationVector);
        this.logger.log(LogLevel.Trace, logItem);
    }

    /**
     * Logs the message or log item that are processed by callback method, at level TRACE.
     * @method
     * @param callback {function} The callback function.
     * @param activity {IActivityLogItem} The activity item associated with the message.
     * @param data {any} The additional data.
     * @param correlationVector {string} The correlation vector.
     * @example
     *      traceCallback(function), traceCallback(() => "message1" + "message2"), traceCallback(() => "message", activity)
     */
    public traceCallback(callback: () => string, activity?: IActivityLogItem, data?: any, correlationVector?: string): void {
        if (this.isTraceEnabled()) {
            this.trace(callback(), activity, data, correlationVector);
        }
    }

    /**
     * Logs the message or log item or error at level DEBUG.
     * @method
     * @param messages {any} The logging message or log item or error.
     * @param activity {IActivityLogItem} The activity item associated with the message.
     * @param data {any} The additional data.
     * @param correlationVector {string} The correlation vector.
     * @example
     *      debug("message"), debug("message", activity), debug(logitem), debug(logitem, activity), debug(e), debug(e, activity)
     */
    public debug(message: string, activity?: IActivityLogItem, data?: any, correlationVector?: string): void;
    public debug(message: LogItem, activity?: IActivityLogItem, data?: any, correlationVector?: string): void;
    public debug(message: Error, activity?: IActivityLogItem, data?: any, correlationVector?: string): void;
    public debug(message: any, activity?: IActivityLogItem, data?: any, correlationVector?: string): void {
        let logItem = this.processMessage(LogLevel.Debug, message, activity, data, correlationVector);
        this.logger.log(LogLevel.Debug, logItem);
    }

    /**
     * Logs the message or log item that are processed by callback method, at level DEBUG.
     * @method
     * @param callback {function} The callback function.
     * @param activity {IActivityLogItem} The activity item associated with the message.
     * @param data {any} The additional data.
     * @param correlationVector {string} The correlation vector.
     * @example
     *      debugCallback(function), debugCallback(() => "message1" + "message2")
     */
    public debugCallback(callback: () => string, activity?: IActivityLogItem, data?: any, correlationVector?: string): void {
        if (this.isDebugEnabled()) {
            this.debug(callback(), activity, data, correlationVector);
        }
    }

    /**
     * Logs the message or log item or error at level INFO.
     * @method
     * @param messages {any} The logging message or log item or error.
     * @param activity {IActivityLogItem} The activity item associated with the message.
     * @param data {any} The additional data.
     * @param correlationVector {string} The correlation vector.
     * @example
     *      info("message"), info("message", activity), info(logitem), info(logitem, activity), info(e), info(e, activity)
     */
    public info(message: string, activity?: IActivityLogItem, data?: any, correlationVector?: string): void;
    public info(message: LogItem, activity?: IActivityLogItem, data?: any, correlationVector?: string): void;
    public info(message: Error, activity?: IActivityLogItem, data?: any, correlationVector?: string): void;
    public info(message: any, activity?: IActivityLogItem, data?: any, correlationVector?: string): void {
        let logItem = this.processMessage(LogLevel.Info, message, activity, data, correlationVector);
        this.logger.log(LogLevel.Info, logItem);
    }

    /**
     * Logs the message or log item that are processed by callback method, at level INFO.
     * @method
     * @param callback {function} The callback function.
     * @param activity {IActivityLogItem} The activity item associated with the message.
     * @param data {any} The additional data.
     * @param correlationVector {string} The correlation vector.
     * @example
     *      infoCallback(function), infoCallback(() => "message1" + "message2")
     */
    public infoCallback(callback: () => string, activity?: IActivityLogItem, data?: any, correlationVector?: string): void {
        if (this.isInfoEnabled()) {
            this.info(callback(), activity, data, correlationVector);
        }
    }

    /**
     * Logs the message or log item or error at level WARN.
     * @method
     * @param messages {any} The logging message or log item or error.
     * @param activity {IActivityLogItem} The activity item associated with the message.
     * @param data {any} The additional data.
     * @param correlationVector {string} The correlation vector.
     * @example
     *      warn("message"), warn("message", activity), warn(logitem), warn(logitem, activity), warn(e), warn(e, activity)
     */
    public warn(message: string, activity?: IActivityLogItem, data?: any, correlationVector?: string): void;
    public warn(message: LogItem, activity?: IActivityLogItem, data?: any, correlationVector?: string): void;
    public warn(message: Error, activity?: IActivityLogItem, data?: any, correlationVector?: string): void;
    public warn(message: any, activity?: IActivityLogItem, data?: any, correlationVector?: string): void {
        let logItem = this.processMessage(LogLevel.Warn, message, activity, data, correlationVector);
        this.logger.log(LogLevel.Warn, logItem);
    }

    /**
     * Logs the message or log item that are processed by callback method, at level WARN.
     * @method
     * @param callback {function} The callback function.
     * @param activity {IActivityLogItem} The activity item associated with the message.
     * @param data {any} The additional data.
     * @param correlationVector {string} The correlation vector.
     * @example
     *      warnCallback(function), warnCallback(() => "message1" + "message2")
     */
    public warnCallback(callback: () => string, activity?: IActivityLogItem, data?: any, correlationVector?: string): void {
        if (this.isWarnEnabled()) {
            this.warn(callback(), activity, data, correlationVector);
        }
    }

    /**
     * Logs the message or log item or error at level ERROR.
     * @method
     * @param messages {any} The logging message or log item or error.
     * @param activity {IActivityLogItem} The activity item associated with the message.
     * @param data {any} The additional data.
     * @param correlationVector {string} The correlation vector.
     * @example
     *      error("message"), error("message", activity), error(logitem), error(logitem, activity), error(e), error(e, activity)
     */
    public error(message: string, activity?: IActivityLogItem, data?: any, correlationVector?: string): void;
    public error(message: LogItem, activity?: IActivityLogItem, data?: any, correlationVector?: string): void;
    public error(message: Error, activity?: IActivityLogItem, data?: any, correlationVector?: string): void;
    public error(message: any, activity?: IActivityLogItem, data?: any, correlationVector?: string): void {
        let logItem = this.processMessage(LogLevel.Error, message, activity, data, correlationVector);
        this.logger.log(LogLevel.Error, logItem);
    }

    /**
     * Logs the message or log item that are processed by callback method, at level ERROR.
     * @method
     * @param callback {function} The callback function.
     * @param activity {IActivityLogItem} The activity item associated with the message.
     * @param data {any} The additional data.
     * @param correlationVector {string} The correlation vector.
     * @example
     *      errorCallback(function), errorCallback(() => "message1" + "message2")
     */
    public errorCallback(callback: () => string, activity?: IActivityLogItem, data?: any, correlationVector?: string): void {
        if (this.isErrorEnabled()) {
            this.error(callback(), activity, data, correlationVector);;
        }
    }

    /**
     * Logs the message or log item or error at level FATAL.
     * @method
     * @param messages {any} The logging message or log item or error.
     * @param activity {IActivityLogItem} The activity item associated with the message.
     * @param data {any} The additional data.
     * @param correlationVector {string} The correlation vector.
     * @example
     *      fatal("message"), fatal("message", activity), fatal(logitem), fatal(logitem, activity), fatal(e), fatal(e, activity)
     */
    public fatal(message: string, activity?: IActivityLogItem, data?: any, correlationVector?: string): void;
    public fatal(message: LogItem, activity?: IActivityLogItem, data?: any, correlationVector?: string): void;
    public fatal(message: Error, activity?: IActivityLogItem, data?: any, correlationVector?: string): void;
    public fatal(message: any, activity?: IActivityLogItem, data?: any, correlationVector?: string): void {
        let logItem = this.processMessage(LogLevel.Fatal, message, activity, data, correlationVector);
        this.logger.log(LogLevel.Fatal, logItem);
    }

    /**
     * Logs the message or log item that are processed by callback method, at level FATAL.
     * @method
     * @param callback {function} The callback function.
     * @param activity {IActivityLogItem} The activity item associated with the message.
     * @param data {any} The additional data.
     * @param correlationVector {string} The correlation vector.
     * @example
     *      fatalCallback(function), fatalCallback(() => "message1" + "message2")
     */
    public fatalCallback(callback: () => string, activity?: IActivityLogItem, data?: any, correlationVector?: string): void {
        if (this.isFatalEnabled()) {
            this.fatal(callback(), activity, data, correlationVector);
        }
    }

    /**
     * Returns whether the logging service is enabled for TRACE messages or not.
     * @method
     * @return {boolean} True, if enabled. Otherwise, false.
     */
    public isTraceEnabled(): boolean {
        return this.logger.isEnabledFor(LogLevel.Trace);
    }

    /**
     * Returns whether the logging service is enabled for DEBUG messages or not.
     * @method
     * @return {boolean} True, if enabled. Otherwise, false.
     */
    public isDebugEnabled(): boolean {
        return this.logger.isEnabledFor(LogLevel.Debug);
    }

    /**
     * Returns whether the logging service is enabled for INFO messages or not.
     * @method
     * @return {boolean} True, if enabled. Otherwise, false.
     */
    public isInfoEnabled(): boolean {
        return this.logger.isEnabledFor(LogLevel.Info);
    }

    /**
     * Returns whether the logging service is enabled for WARN messages or not.
     * @method
     * @return {boolean} True, if enabled. Otherwise, false.
     */
    public isWarnEnabled(): boolean {
        return this.logger.isEnabledFor(LogLevel.Warn);
    }

    /**
     * Returns whether the logging service is enabled for ERROR messages or not.
     * @method
     * @return {boolean} True, if enabled. Otherwise, false.
     */
    public isErrorEnabled(): boolean {
        return this.logger.isEnabledFor(LogLevel.Error);
    }

    /**
     * Returns whether the logging service is enabled for FATAL messages or not.
     * @method
     * @return {boolean} True, if enabled. Otherwise, false.
     */
    public isFatalEnabled(): boolean {
        return this.logger.isEnabledFor(LogLevel.Fatal);
    }

    /**
     * Adds callback method for global/ unhandled errors.
     * @method
     * @param callback {(error: Error) => void} The callback method to subscribe.
     * @param runtimeEnvironment {RuntimeEnvironment} The runtime environment the code is running under. Default is Browser.
     */
    public addUnhandledErrorCallback(callback: (error: Error) => void, runtimeEnvironment: RuntimeEnvironment = RuntimeEnvironment.Browser): void {
        if (runtimeEnvironment === RuntimeEnvironment.Browser) {
            window.addEventListener("error", (event: ErrorEvent) => {
                callback(event.error);
            });

            // Currently only chrome browser (from version 49+) supports the unhandledrejection and onunhandledrejection listeners
            if (window["onunhandledrejection"] !== undefined) {
                let rejectionErrorPropertyName = "reason";
                window.addEventListener("unhandledrejection", (event) => {
                    if (event && event[rejectionErrorPropertyName]) {
                        callback(new Error(event[rejectionErrorPropertyName]));
                    }
                });
            } else {
                this.warn("'unhandledrejection' event is not yet supported by the current browser version");
            }
        }
    }

    /**
     * Processes the messages and adds the missing details.
     * @method
     * @param logLevel {LogLevel} The log level.
     * @param message {any} The logging message or log item.
     * @param activity {IActivityLogItem} The activity item associated with the message.
     * @param data {any} The additional data.
     * @param correlationVector {string} The correlation vector.
     * @private
     */
    private processMessage(logLevel: LogLevel, message: any, activity: IActivityLogItem, data: any, correlationVector: string): LogItem {

        let logItem: LogItem;
        if (message instanceof LogItem) {
            logItem = <LogItem>message;
        } else {
            let messageToLog;
            if (message instanceof Error) {
                messageToLog = LoggingService.constructErrorMessage(message);
            } else if (typeof message === "string") {
                messageToLog = message;
            }

            logItem = new LogItem(messageToLog);
        }

        logItem.LogLevel = logLevel;
        if (data) {
            if (typeof (data) !== "object") {
                data = { data: data };
            }

            logItem.Data = data;
        }

        if (correlationVector) {
            logItem.Data = logItem.Data || {};
            logItem.Data[BaseService.CorrelationVectorKey] = correlationVector;
        }

        if (activity) {
            logItem.ActivityId = activity.ActivityId || logItem.ActivityId;
            logItem.ParentActivityId = activity.ParentActivityId || logItem.ParentActivityId;

            if (!correlationVector && activity.correlationVector) {
                logItem.Data = logItem.Data || {};
                logItem.Data[BaseService.CorrelationVectorKey] = activity.correlationVector;
            }
        }

        return logItem;
    }
}
