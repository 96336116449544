/**
* TelemetryContext.ts
* @author Hector Hernandez (hectorh)
* @copyright Microsoft 2019
*/

import { IExtendedConfiguration, IAppInsightsCore, ITelemetryItem, isString } from '@ms/1ds-core-js';
import {
    IPropertyConfiguration, IOperatingSystemContext,
    IWebContext, IAppContext, IUserContext
} from './DataModels';
import { Application } from './context/Application';
import { User } from './context/User';
import { Web } from './context/Web';
import { OperatingSystem } from './context/OperatingSystem';
import { IntWeb } from './context/IntWeb';
import { Utc } from './context/Utc';
import { Loc } from './context/Loc';
import { Device } from './context/Device';
import { Session } from './context/Session';
import { Trace } from './context/Trace';
import {
    AppExtensionKeys, UserExtensionKeys, WebExtensionKeys,
    SDKExtKeys, OSExtKeys, IntWebExtKeys, UtcExtKeys, LocExtKeys,
    SessionExtKeys, DeviceExtKeys, TraceExtKeys
} from './ExtensionKeys';
import { Extensions } from './Extensions';
import { Sdk } from './context/Sdk';
import { SessionManager } from './SessionManager';

// Use a local variable as the reference so it will be minified for the 25ish usages
let _isString = isString;

export class TelemetryContext {
    public app: IAppContext;
    public user: IUserContext;
    public os: IOperatingSystemContext;
    public web: IWebContext;
    public session: Session;
    public device: Device;
    private sessionManager: SessionManager;
    private sdk: Sdk;
    private intWeb: IntWeb;
    private utc: Utc;
    private loc: Loc;
    private telemetryTrace: Trace;

    constructor(coreConfig: IExtendedConfiguration, propertiesConfig: IPropertyConfiguration, core: IAppInsightsCore) {
        this.app = new Application(propertiesConfig);
        this.user = new User(coreConfig, propertiesConfig, core && core.logger);
        this.os = new OperatingSystem(propertiesConfig);
        this.web = new Web(propertiesConfig);
        this.sdk = new Sdk(coreConfig);
        this.intWeb = new IntWeb(propertiesConfig);
        this.utc = new Utc();
        this.loc = new Loc();
        this.device = new Device();
        this.telemetryTrace = new Trace(propertiesConfig);

        this.sessionManager = new SessionManager(propertiesConfig, core && core.logger);
        this.session = new Session();
    }

    public applyApplicationContext(event: ITelemetryItem) {
        let app = this.app;
        if (_isString(app.id)) {
            event.ext[Extensions.AppExt][AppExtensionKeys.id] = app.id;
        }
        if (_isString(app.ver)) {
            event.ext[Extensions.AppExt][AppExtensionKeys.ver] = app.ver;
        }
        if (_isString(app.name)) {
            event.ext[Extensions.AppExt][AppExtensionKeys.appName] = app.name;
        }
        if (_isString(app.locale)) {
            event.ext[Extensions.AppExt][AppExtensionKeys.locale] = app.locale;
        }
        const expId = app.expId;
        if (_isString(expId)) {
            event.ext[Extensions.AppExt][AppExtensionKeys.expId] = expId;
        }
        if (_isString(app.env)) {
            event.ext[Extensions.AppExt][AppExtensionKeys.env] = app.env;
        }
    }

    public applyUserContext(event: ITelemetryItem) {
        let user = this.user;
        const localId = user.localId;
        if (_isString(localId)) {
            event.ext[Extensions.UserExt][UserExtensionKeys.localId] = localId;
        }
        if (_isString(user.locale)) {
            event.ext[Extensions.UserExt][UserExtensionKeys.locale] = user.locale;
        }
        if (_isString(user.id)) {
            event.ext[Extensions.UserExt][UserExtensionKeys.id] = user.id;
        }
    }

    public applyWebContext(event: ITelemetryItem) {
        let web = this.web;
        if (_isString(web.domain)) {
            event.ext[Extensions.WebExt][WebExtensionKeys.domain] = web.domain;
        }
        if (_isString(web.browser)) {
            event.ext[Extensions.WebExt][WebExtensionKeys.browser] = web.browser;
        }
        if (_isString(web.browserVer)) {
            event.ext[Extensions.WebExt][WebExtensionKeys.browserVer] = web.browserVer;
        }
        if (_isString(web.screenRes)) {
            event.ext[Extensions.WebExt][WebExtensionKeys.screenRes] = web.screenRes;
        }
        event.ext[Extensions.WebExt][WebExtensionKeys.userConsent] = web.userConsent;
    }

    public applyOsContext(event: ITelemetryItem) {
        let os = this.os;
        if (_isString(os.name)) {
            event.ext[Extensions.OSExt][OSExtKeys.osName] = os.name;
        }
        if (_isString(os.ver)) {
            event.ext[Extensions.OSExt][OSExtKeys.ver] = os.ver;
        }
    }

    public applySdkContext(event: ITelemetryItem) {
        let sdk = this.sdk;
        event.ext[Extensions.SdkExt][SDKExtKeys.seq] = sdk.getSequenceId();
        event.ext[Extensions.SdkExt][SDKExtKeys.epoch] = sdk.epoch;
        if (_isString(sdk.installId)) {
            event.ext[Extensions.SdkExt][SDKExtKeys.installId] = sdk.installId;
        }
    }

    public applyIntWebContext(event: ITelemetryItem) {
        let intWeb = this.intWeb;
        const msfpc = intWeb.getMsfpc();
        if (_isString(msfpc)) {
            event.ext[Extensions.IntWebExt][IntWebExtKeys.msfpc] = msfpc;
        }
        const anid = intWeb.getAnid();
        if (_isString(anid)) {
            event.ext[Extensions.IntWebExt][IntWebExtKeys.anid] = anid;
        }
        if (_isString(intWeb.serviceName)) {
            event.ext[Extensions.IntWebExt][IntWebExtKeys.serviceName] = intWeb.serviceName;
        }
    }

    public applyUtcContext(event: ITelemetryItem) {
        event.ext[Extensions.UtcExt][UtcExtKeys.popSample] = this.utc.popSample;
    }

    public applyLocContext(event: ITelemetryItem) {
        event.ext[Extensions.LocExt][LocExtKeys.tz] = this.loc.tz;
    }

    public applySessionContext(event: ITelemetryItem) {
        let session = this.session;
        let sessionManager = this.sessionManager;
        if (session) {
            if (!_isString(session.getId())) {
                // Session was not specified, so create one
                sessionManager.update();
            }
        }
        const autoSession = sessionManager.automaticSession;
        if (autoSession && autoSession.id) {
            if (_isString(autoSession.id)) {
                session.automaticId = autoSession.id;
                event.ext[Extensions.AppExt][SessionExtKeys.sessionId] = autoSession.id;
            }
        }

        if (session && session.getId()) {
            // If customer set session info, apply that context; otherwise apply context automatically generated
            if (_isString(session.getId())) {
                event.ext[Extensions.AppExt][SessionExtKeys.sessionId] = session.getId();
            }
        }
    }

    public applyDeviceContext(event: ITelemetryItem) {
        let device = this.device;
        if (_isString(device.localId)) {
            event.ext[Extensions.DeviceExt][DeviceExtKeys.localId] = device.localId;
        }
        if (_isString(device.make)) {
            event.ext[Extensions.DeviceExt][DeviceExtKeys.make] = device.make;
        }
        if (_isString(device.model)) {
            event.ext[Extensions.DeviceExt][DeviceExtKeys.model] = device.model;
        }
        if (_isString(device.deviceClass)) {
            event.ext[Extensions.DeviceExt][DeviceExtKeys.deviceClass] = device.deviceClass;
        }
    }

    public applyAITraceContext(event: ITelemetryItem) {
        let trace = this.telemetryTrace;
        if (_isString(trace.traceId)) {
            event.ext[Extensions.TraceExt][TraceExtKeys.traceId] = trace.traceId;
        }
        if (_isString(trace.name)) {
            event.ext[Extensions.TraceExt][TraceExtKeys.traceName] = trace.name;
        }
        if (_isString(trace.parentId)) {
            event.ext[Extensions.TraceExt][TraceExtKeys.parentId] = trace.parentId;
        }
    }
}
