import * as axiosStatic from "axios";

import { AxiosWrapperAbstractBase } from "./AxiosWrapperAbstractBase";

/**
 * Contains code to convert an Axios wrapper implementation to an AxiosStatic object.
 * Axios has a static interface that cannot be implemented by a TypeScript class, so this
 * class contains code to help convert the AxiosWrapper implementation to the AxiosStatic interface.
 * @class
 */
export class AxiosWrapperConverter {

    /**
     * Convert the given Axios wrapper to an AxiosStatic object.
     * @method
     * @param {AxiosWrapperBase} axiosWrapperBase The axios wrapper implementation to convert.
     * @returns {axiosStatic.AxiosStatic} The AxiosStatic equavalent of the given wrapper.
     */
    // tslint:disable-next-line: ext-variable-name
    public static ConvertToAxiosStatic(axiosWrapperBase: AxiosWrapperAbstractBase): axiosStatic.AxiosStatic {

        if (!axiosWrapperBase) {
            throw "axiosWrapperBase must not be null";
        }

        var wrapper = <any>function <T>(config: axiosStatic.RequestOptions): any {
            return axiosWrapperBase.sendRequest(config);
        };

        var callBase = function (baseFunction: <T>(url: string, config?: axiosStatic.InstanceOptions) => Promise<axiosStatic.Response>): any {
            return function <T>(url: string, config?: axiosStatic.InstanceOptions): Promise<axiosStatic.Response> {
                return baseFunction.call(axiosWrapperBase, url, config);
            };
        };

        var callBaseWithData = function (baseFunction: <T>(url: string, data: any, config?: axiosStatic.InstanceOptions) => Promise<axiosStatic.Response>): any {
            return function <T>(url: string, data: any, config?: axiosStatic.InstanceOptions): Promise<axiosStatic.Response> {
                return baseFunction.call(axiosWrapperBase, url, data, config);
            };
        };

        wrapper.get = callBase(axiosWrapperBase.get);
        wrapper.delete = callBase(axiosWrapperBase.delete);
        wrapper.head = callBase(axiosWrapperBase.head);
        wrapper.post = callBaseWithData(axiosWrapperBase.post);
        wrapper.put = callBaseWithData(axiosWrapperBase.put);
        wrapper.patch = callBaseWithData(axiosWrapperBase.patch);

        return <axiosStatic.AxiosStatic>wrapper;
    }
}