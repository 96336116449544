import { cloneDeep, isEmpty } from "lodash-es";

import { BaseService } from "./BaseService";
import { ILogger } from "./loggers/ILogger";
import { IPerfCounterItem } from "./models/IPerfCounterItem";
import { IPerfCounterLoggingService } from "./IPerfCounterLoggingService";
import { KeyValuePairCollection } from "./models/KeyValuePairCollection";
import { LogLevel } from "./models/LogLevel";
import { Logger } from "./loggers/Logger";
import { PerfCounterItem } from "./models/PerfCounterItem";

/**
 * Class to log the performance counters.
 * @class
 */
export class PerfCounterLoggingService extends BaseService implements IPerfCounterLoggingService {
    /**
     * Dictionary to the mapping of name to performance counter logging service
     * @type {KeyValuePairCollection<PerfCounterLoggingService>}
     */
    private static InstancesMap: KeyValuePairCollection<PerfCounterLoggingService> = {};

    /**
     * constructor
     * @param logger {ILogger}
     */
    constructor(logger: ILogger) {
        super(logger);
    }

    /**
     * Gets the instance of the `PerfCounterLoggingService` class.
     * @method
     * @param loggerName {string} Name of the logger.
     * @return {IPerfCounterLoggingService} the instance of performance counter logging service
     */
    public static getInstance(loggerName?: string): IPerfCounterLoggingService {
        const name = loggerName || "Default";
        let instance = PerfCounterLoggingService.InstancesMap[name];
        if (instance) {
            return instance;
        }

        let logger = new Logger(name, LogLevel.Trace);
        instance = new PerfCounterLoggingService(logger);
        PerfCounterLoggingService.InstancesMap[name] = instance;

        return instance;
    }

    /**
     * Generates the derived performance counter items based on the given dimensions and the base counter.
     * @param perfCounterItem {IPerfCounterItem} The performance counter item.
     * @param dimensions {KeyValuePairCollection<string[]>} The dimensions. Ex: { "domain": ["http://msn.com"] }
     * @return {IPerfCounterItem[]} The list of counters containing original performance counter and the derived performance counters.
     * @description If a base counter name is NetworkLatency, value(in milliseconds) is 1000 and dimensions parameter is { "domain": ["http://msn.com"] } then total 2 counter items will be created
     * 1. NetworkLatency = 1000
     * 2. NetworkLatency (domain: http://msn.com) = 1000
     */
    private static getDimensionedLogItems(perfCounterItem: IPerfCounterItem, dimensions: KeyValuePairCollection<string[]>): IPerfCounterItem[] {
        let logItems: IPerfCounterItem[] = [];

        // current UI implementation is logging multiple dimensions multiple times with only the name difference. The message name is in the format of "counter name + (dimension)".
        let defaultLogItem = new PerfCounterItem(perfCounterItem.CounterName, perfCounterItem.CounterValue, cloneDeep(perfCounterItem.Data), perfCounterItem.ActivityId, perfCounterItem.LogDateTime);
        defaultLogItem.LogLevel = LogLevel.Trace;
        logItems.push(defaultLogItem);

        if (dimensions && !isEmpty(dimensions)) {
            for (let dimension of Object.keys(dimensions)) {
                for (let dimensionValue of dimensions[dimension]) {
                    let name = (dimension.indexOf(":") > -1) ? `${perfCounterItem.CounterName}(${dimension.replace(":", "-")}: ${dimensionValue})` : `${perfCounterItem.CounterName}(${dimension}: ${dimensionValue})`;
                    let dimensionLogItem = new PerfCounterItem(name, perfCounterItem.CounterValue, cloneDeep(perfCounterItem.Data), perfCounterItem.ActivityId, perfCounterItem.LogDateTime);
                    dimensionLogItem.LogLevel = LogLevel.Trace;
                    logItems.push(dimensionLogItem);
                }
            }
        }

        return logItems;
    }

    /**
     * Logs a new performance counter with provided name and values
     * @param counterName {string } The performance counter item name.
     * @param value {number} counter value.
     * @param dimensions {KeyValuePairCollection<string[]>} optional dimensions.
     * @param data {any} optional open schema data.
     */
    public logPerfCounter(counterName: string, value: number, dimensions?: KeyValuePairCollection<string[]>, data?: any): void {
        const perfCounter = new PerfCounterItem(counterName, value, data);

        this.logPerfCounterItem(perfCounter, dimensions);
    }

    /**
     * Logs the given performance counter item.
     * @param perfCounter {IPerfCounterItem} the performance counter item to log
     * @param dimensions {KeyValuePairCollection<string[]>}  optional dimensions
     */
    public logPerfCounterItem(perfCounterItem: IPerfCounterItem, dimensions?: KeyValuePairCollection<string[]>): void {
        const dimensionedLogItems = PerfCounterLoggingService.getDimensionedLogItems(perfCounterItem, dimensions);

        for (let logItem of dimensionedLogItems) {
            this.logger.log(LogLevel.Trace, logItem);
        }
    };
} 