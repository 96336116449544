import { ActivityLogItem } from "./models/ActivityLogItem";
import { BaseService } from "./BaseService";
import { IActivityLogItem } from "./models/IActivityLogItem";
import { IActivityLoggingService } from "./IActivityLoggingService";
import { ICorrelationVectorProvider } from "./ICorrelationVectorProvider";
import { ILogger } from "./loggers/ILogger";
import { LogLevel } from "./models/LogLevel";
import { LogType } from "./models/LogType";
import { Logger } from "./loggers/Logger";
import { NetworkActivityLogItem } from "./models/NetworkActivityLogItem";
import { cloneDeep } from "lodash-es";

/**
 * Class to log the activity messages by sending messages to all registered appenders.
 * @class
 */
export class ActivityLoggingService extends BaseService implements IActivityLoggingService {

    /**
     * Dictionary to the mapping of name to activity logging service
     */
    private static InstancesMap: { [name: string]: ActivityLoggingService; } = {};

    /** 
     * Gets or sets the correlation vector provider to manage the vector that is used to correlate the client and server events.
     * @property
     * @type {ICorrelationVectorProvider}
     */
    public correlationVectorProvider: ICorrelationVectorProvider;

    /**
     * Initializes a new instance of the `ActivityLoggingService` class.
     * @constructor
     * @param logger {ILogger} The logger.
     * @param correlationVectorProvider? {ICorrelationVectorProvider} Provider to manage the correlation vector that is used to correlate the client and server events.
     */
    constructor(logger: ILogger, correlationVectorProvider?: ICorrelationVectorProvider) {
        super(logger);

        this.correlationVectorProvider = correlationVectorProvider;
    }

    /**
     * Gets the instance of the `ActivityLoggingService` class.
     * @method
     * @param loggerName {string} Name of the logger.
     * @param correlationVectorProvider? {ICorrelationVectorProvider} Provider to manage the correlation vector that is used to correlate the client and server events.
     * @return {IActivityLoggingService} The instance of activity logging service indexed by logger name.
     */
    public static getInstance(loggerName?: string, correlationVectorProvider?: ICorrelationVectorProvider): IActivityLoggingService {
        var name = loggerName || "Default";
        var instance = ActivityLoggingService.InstancesMap[name];
        if (instance) {
            return instance;
        }

        var logger = new Logger(name, LogLevel.Activity);
        instance = new ActivityLoggingService(logger, correlationVectorProvider);
        ActivityLoggingService.InstancesMap[name] = instance;

        return instance;
    }

    /**
     * Returns whether the activity logging is enabled or not.
     * @method
     * @return {boolean} True, if enabled. Otherwise, false.
     */
    public isActivityEnabled(): boolean {
        return this.logger.isEnabledFor(LogLevel.Activity);
    }

    /**
     * Creates an activity and starts it.  Calling code is responsible for calling end.
     * @param Name {string} The name of the activity.
     * @param parentActivity {IActivityLogItem} The parent activity.
     * @param data {any} Any additional JSON data.
     * @return {IActivityLogItem} The activity log item.
     * @description Method creates the activity if activity logging is enabled, otherwise checks the parent activity to check activity is
     * enabled at parent level. If enabled at parent activity exists then the method returns it.
     */
    public createActivity(name: string, parentActivity?: IActivityLogItem, data?: any): IActivityLogItem {

        if (this.isActivityEnabled()) {
            let parentActivityId;
            let correlationVector;
            if (parentActivity) {
                parentActivityId = parentActivity.ActivityId;
                correlationVector = this.getVectorForNewActivity(parentActivity, false);
            }

            let activityItem = new ActivityLogItem(name, parentActivityId, data, correlationVector);
            this.logger.log(LogLevel.Activity, activityItem);

            return activityItem;
        } else if (parentActivity) {
            return parentActivity;
        }

        return null;
    }

    /**
     * Creates an activity and starts it.  Calling code is responsible for calling end.
     * @param callback {function} The callback function that results in string.
     * @param parentActivity {IActivityLogItem} The parent activity.
     * @param data {any} Any additional JSON data.
     * @return {IActivityLogItem} The activity log item.
     * @description Method creates the activity if activity logging is enabled, otherwise checks the parent activity to check activity is
     * enabled at parent level. If enabled at parent activity exists then the method returns it.
     */
    public createActivityCallback(callback: () => string, parentActivity?: IActivityLogItem, data?: any): IActivityLogItem {
        if (this.isActivityEnabled()) {
            return this.createActivity(callback(), parentActivity, data);
        } else if (parentActivity) {
            return parentActivity;
        }

        return null;
    }

    /**
     * Ends an activity, logging the end activity event.
     * @param activityItem {IActivityLogItem} The activity log item.
     */
    public endActivity(activityItem: IActivityLogItem): void {
        if (this.isActivityEnabled() && activityItem && !activityItem.Ended) {
            activityItem.Ended = true;

            const dateTimeNow = new Date();
            const clonedActivityItem = cloneDeep(activityItem);
            clonedActivityItem.LogType = LogType.EndActivity;
            clonedActivityItem.LogDateTime = dateTimeNow;

            const elapsedTime = clonedActivityItem.LogDateTime.getTime() - activityItem.LogDateTime.getTime();

            // TODO: Remove elapsed time from the message. Inform consumers and mark it as breaking change.
            clonedActivityItem.Message = clonedActivityItem.Message + " ElapsedTime (Milliseconds):" + elapsedTime.toString();
            clonedActivityItem.elapsedTimeInMs = elapsedTime;

            this.logger.log(LogLevel.Activity, clonedActivityItem);
        }
    }

    /**
     * Creates a network activity. Caller is responsible for ending it.
     * @param name {string}  The name of the activity.
     * @param serviceName {string} The name of the service being called.
     * @param requestUri? {string} The URI for the request.
     * @param requestHttpMethod? {string} The HTTP method of the request.
     * @param currentOperationName? {string} The name of the current operation.
     * @param correlationVector? {string} The correlation vector.
     * @param parentActivity? {IActivityLogItem} The parent activity.
     * @param data? {any} Any additional JSON data.
     * @returns {NetworkActivityLogItem} The network activity log item.
     */
    public createNetworkActivity(name: string, serviceName: string, requestUri?: string, requestHttpMethod?: string, currentOperationName?: string, correlationVector?: string, parentActivity?: IActivityLogItem, data?: any): NetworkActivityLogItem {
        if (this.isActivityEnabled()) {

            if (!correlationVector && !parentActivity) {
                console.warn("'correlationVector' or 'parentActivity' is required to create network activity");
            }

            let vector = correlationVector || this.getVectorForNewActivity(parentActivity, true);
            let parentActivityId = parentActivity ? parentActivity.ActivityId : "";
            return new NetworkActivityLogItem(name, serviceName, requestUri, requestHttpMethod, currentOperationName, vector, parentActivityId, data);
        }

        return null;
    }

    /**
     * Ends an activity where the request succeeded, logging the end network activity event (e.g. in Asimov appender, an OSR event).
     * @param activityItem {NetworkActivityLogItem} The network activity log item.
     * @param responseSize? {number} The size of the response.
     * @param responseStatusCode? {number} The resulting HTTP status code of the network request.
     * @returns {void}
     */
    public endSuccessfulNetworkActivity(activityItem: NetworkActivityLogItem, responseSize?: number, responseStatusCode?: number): void {
        this.endNetworkActivity(activityItem, true, responseSize, responseStatusCode);
    }

    /**
     * Ends an activity where the request failed, logging the end network activity event (e.g. in Asimov appender, an OSR event).
     * @param activityItem {NetworkActivityLogItem} The network activity log item.
     * @param responseSize? {number} The size of the response.
     * @param responseStatusCode? {number} The resulting HTTP status code of the network request.
     * @param errorMessage? {string} The error message from the network request, if any.
     * @returns {void}
     */
    public endFailedNetworkActivity(activityItem: NetworkActivityLogItem, responseSize?: number, responseStatusCode?: number, errorMessage?: string): void {
        this.endNetworkActivity(activityItem, false, responseSize, responseStatusCode, errorMessage);
    }

    /**
     * Ends an activity, logging the end network activity event (e.g. in Asimov appender, an OSR event).
     * @param activityItem {NetworkActivityLogItem} The network activity log item.
     * @param successful {boolean} If the network request was successful or not.
     * @param responseSize? {number} The size of the response.
     * @param responseStatusCode? {number} The resulting HTTP status code of the network request.
     * @param errorMessage? {string} The error message from the network request, if any.
     * @returns {void}
     */
    private endNetworkActivity(activityItem: NetworkActivityLogItem, successful: boolean, responseSize?: number, responseStatusCode?: number, errorMessage?: string): void {
        if (activityItem && !activityItem.Ended) {
            activityItem.Ended = true;

            let dateTimeNow = new Date();
            let clonedActivityItem = cloneDeep(activityItem);
            clonedActivityItem.LogType = LogType.EndNetworkActivity;
            clonedActivityItem.LogDateTime = dateTimeNow;
            clonedActivityItem.elapsedTimeInMs = clonedActivityItem.LogDateTime.getTime() - activityItem.LogDateTime.getTime();
            clonedActivityItem.successful = successful;
            clonedActivityItem.responseSize = responseSize;
            clonedActivityItem.responseStatusCode = responseStatusCode;
            clonedActivityItem.errorMessage = errorMessage;

            this.logger.log(LogLevel.Activity, clonedActivityItem);
        }
    }

    /**
     * Updates and returns the correlation vector based on the new activity type and its position as a child activity.
     * @method
     * @param parentActivity {IActivityLogItem} The parent activity item.
     * @param isNetworkActivity {boolean} The flag to indicate if the new activity is network activity or not.
     * @return {string} The correlation vector for creating a new activity.
     */
    private getVectorForNewActivity(parentActivity: IActivityLogItem, isNetworkActivity: boolean): string {

        if (!parentActivity || !this.correlationVectorProvider) {
            return "";
        }

        // Correlation vector creation for new activity follows the below rules
        // 1. If new activity is the first child activity then extend CV of the parent.
        // 2. If new activity is the first child and also a network activity then extend CV of the parent and then increment it, to make it compliant with Outgoing Service Request (OSR) standard.
        // 3. If new activity is not the first child, that means CV of the parent is already extended by Rule 1 or 2, so increment it.
        // The property latestChildCorrelationVector always has the CV of latest child. Empty value for latestChildCorrelationVector means no child activity is created yet.

        // If latestChildCorrelationVector has value then new activity is not the first child. Increment it.
        if (parentActivity.latestChildCorrelationVector) {
            parentActivity.latestChildCorrelationVector = this.correlationVectorProvider.incrementExternalVector(parentActivity.latestChildCorrelationVector);
        } else {
            parentActivity.latestChildCorrelationVector = this.correlationVectorProvider.extendExternalVector(parentActivity.correlationVector);

            if (isNetworkActivity) {
                parentActivity.latestChildCorrelationVector = this.correlationVectorProvider.incrementExternalVector(parentActivity.latestChildCorrelationVector);
            }
        }

        return parentActivity.latestChildCorrelationVector;
    }
}
 