/**
 * Class containing shortcuts and methods that should be in javascript but isn't.
 * @class
 */
export class JavaScriptExtensions {

    /**
     * Tests whether an object is defined or not.
     * @method
     * @param object {any} The object to check.
     * @returns {boolean} true if the object is defined, false otherwise.
     */
    public static isDefined(object: any): boolean {
        return typeof object !== "undefined";
    }

    /**
     * Replaces the placeholders in the format string with the given arguments.
     * @method
     * @param format {string} The format string with the placeholders to replace.
     * @param args {string[]} The arguments to replace the placeholders with.
     * @returns {string} The format string with the placeholders replaced.
     */
    public static stringFormat(format: string, ...args: string[]): string {
        for (var ndx = 0; ndx < args.length; ++ndx) {
            format = format.replace(new RegExp("\\{" + ndx + "\\}", "g"), args[ndx]);
        }

        return format;
    };
}