import * as axiosStatic from "axios";

import { AxiosWrapperBase } from "../axios-wrapper/AxiosWrapperBase";
import { IErrorCallbackHandler } from "./IErrorCallbackHandler";

/**
 * Wraps axios and adds a default response error parser for each network request.
 * @class
 */
export class AxiosErrorParserWrapper extends AxiosWrapperBase {

    /**
     * Initializes a new instance of the AxiosErrorParserWrapper class.
     * @param errorCallbackHandler {IErrorCallbackHandler} the errorCallbackHandler instance
     * @param promiseService? {typeof Promise}  the promise service
     * @param axios? {axiosStatic.AxiosStatic}  axios to wrap around
     */
    constructor(
        private errorCallbackHandler: IErrorCallbackHandler,
        axios?: axiosStatic.AxiosStatic
    ) {
        super(axios || axiosStatic);

        if (!errorCallbackHandler) {
            throw "'callbackHandler' cannot be null.";
        }
    }

    /**
     * Calls the custom callback on the response when an error happens.
     * @param result {Promise<axiosStatic.Response>} The response detail.
     * @returns {Promise<axiosStatic.Response>} The result of the call with any required changes added.
     */
    protected onResponse<T>(result: Promise<axiosStatic.Response>): Promise<axiosStatic.Response> {
        if (!result) {
            throw "result must not be null";
        }

        result.catch((response) => {
            this.errorCallbackHandler(response);
        });

        return result;
    }
}