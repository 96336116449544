import { ICancellationToken } from "./ICancellationToken";

/**
 * A cancellation token used to cancel actions.
 * Can check if it has been activated synchronously (isCancelled property) or asynchronously (setOnCancelCallback callback).
 * @class 
 */
export class CancellationToken implements ICancellationToken {

    /**
     * Indicates if the token as been activated (i.e. if the cancellation happened).
     * @returns {boolean} 
     */
    public get isCancelled(): boolean { return this._isCancelled; }
    private _isCancelled: boolean = false;

    /**
     * Sets the callback for when the cancellation happens. 
     * @param handler {() => void} Callback handler to call when the token is activated.
     */
    public setOnCancelCallback(handler: () => void): void {
        this._onCancelCallback = handler || (() => undefined);
    }

    /**
     * Activates the cancellation.
     * @returns {void}
     */
    public cancel(): void {
        if (!this._isCancelled) {
            this._isCancelled = true;
            this._onCancelCallback();
        }
    }

    /**
     * Handler for when the cancellation event happens, so it will be notified.
     * @type {() => void}
     */
    private _onCancelCallback: () => void = () => undefined;
}