import * as axiosStatic from "axios";
import * as jscookie from "js-cookie";

import { IAuthenticationManager } from "./IAuthenticationManager";
import { IReAuthenticationCallback } from "./IReAuthenticationCallback";
import { JavaScriptExtensions } from "cms-infrastructure-javascriptextensions/JavaScriptExtensions";
import { TokenStatus } from "./TokenStatus";

/**
 * Monitors whether the user is authenticated or not, provides a callback to show the login dialog when needed, and provides access to the token once logged in.
 * @class
 */
export class AuthenticationManager implements IAuthenticationManager {

    /**
     * es6-promise service instance.
     * @type {Promise}
     */
    private promiseService: typeof Promise;

    /**
     * Window or worker global scope instance providing access to timer functionality.
     * @type {WindowOrWorkerGlobalScope}
     */
    private windowOrWorkerGlobalScope: WindowOrWorkerGlobalScope;

    /**
     * js-cookie service instance.
     * @type {jscookie.CookiesStatic}
     */
    private cookies: jscookie.CookiesStatic;

    /**
     * A callback to the show the user the login dialog.
     * @type {IReAuthenticationCallback}
     */
    private loginCallback: IReAuthenticationCallback;

    /**
     * Axios service instance to use for refreshing tokens.
     * @type {axiosStatic.AxiosStatic}
     */
    private axios: axiosStatic.AxiosStatic;

    /**
     * Defines the interval in milliseconds after which to check the tokens status.
     * @type {number}
     */
    private intervalPause: number = 60000;

    /**
     * The length of time in minutes before the expiration time that the token should be refreshed.
     * @type {number}
     */
    private refreshStart: number = 10;      // 10 minutes

    /**
     * Initializes a new instance of the `AuthenticationManager` class.
     * @constructor
     * @param {IReAuthenticationCallback} loginCallback A callback to the show the user the login dialog.
     * @param {axiosStatic.AxiosStatic} axios Axios service instance to use for refreshing tokens.
     * @param {CookiesStatic} cookies js-cookie service instance.
     * @param {WindowOrWorkerGlobalScope} windowOrWorkerGlobalScope window instance providing access to timer functionality.
     * @param {Promise} promiseService es6-promise service instance.
     */
    constructor(
        loginCallback?: IReAuthenticationCallback,
        axios?: axiosStatic.AxiosStatic,
        cookies?: jscookie.CookiesStatic,
        windowOrWorkerGlobalScope?: WindowOrWorkerGlobalScope,
        promiseService?: typeof Promise) {

        this.loginCallback = loginCallback || AuthenticationManager.NoActionLoginCallback;
        this.axios = axios || axiosStatic;
        this.cookies = cookies || jscookie;
        this.windowOrWorkerGlobalScope = windowOrWorkerGlobalScope || window;
        this.promiseService = promiseService || Promise;

        // Refresh token, and then setup a timer to refresh periodically.
        var refreshInterval = () => {
            var onRefresh = () => {
                this.windowOrWorkerGlobalScope.setTimeout(refreshInterval, this.intervalPause);
            };
            this.refresh().then(onRefresh, onRefresh);
        };
        refreshInterval();
    }

    /**
     * The default login callback to use if the user did not supply a callback.  This callback just does nothing.
     * @returns {Promise<void>}
     */
    private static NoActionLoginCallback = () => new Promise<void>((resolve) => resolve());

    /**
     * Gets current status of the token.
     * @returns {TokenStatus} Indicates whether a token is valid, expired or close to expiration.
     */
    public getTokenStatus(): TokenStatus {
        var status: TokenStatus;

        var timeLeft = this.getMinutesTillCookieExpiration();
        if (timeLeft === 0) {
            status = TokenStatus.Expired;
        } else if (timeLeft <= this.refreshStart) {
            status = TokenStatus.AlmostExpired;
        } else {
            status = TokenStatus.NotExpired;
        }

        return status;
    }

    /**
     * Gets the current auth token.
     * @returns {any} The current auth token.
     */
    public getToken(): any {
        return this.cookies.get("token");
    }

    /**
     * Forces the UI to show the login dialog.
     * @retuns {Promise<void>}
     */
    public triggerLogin(): Promise<void> {
        return this.loginCallback({ getTokenStatus: this.getTokenStatus.bind(this) });
    }

    /**
     * Refresh the token if it will be expired in {this.refreshStart} minutes.
     * @returns {Promise<void>} A promise that completes when the refresh is complete.
     */
    private refresh(): Promise<void> {
        return new this.promiseService<void>((resolve, reject) => {
            var resolver = () => resolve();

            switch (this.getTokenStatus()) {
                case TokenStatus.NotExpired:
                    resolve();
                    break;
                case TokenStatus.AlmostExpired:
                    // If we renew before the token expires, the user don't need to login again.
                    this.axios.get("/renew").then(resolver).catch(resolver);
                    break;
                case TokenStatus.Expired:
                    // The token is expired so the user needs to login again.
                    this.triggerLogin().then(resolver, resolver);
                    break;
                default:
                    resolve();
            }
        });
    }

    /**
     * Gets the time left until token expiration in minutes.
     * @returns {number} The number of minutes left until the token expires.
     */
    private getMinutesTillCookieExpiration(): number {
        var tokenExpiresAt = this.cookies.get("tokenExpiresAt");
        var expireTime = JavaScriptExtensions.isDefined(tokenExpiresAt) ? new Date(Date.parse(tokenExpiresAt)) : new Date(0);
        var now = new Date();
        return now > expireTime ? 0 : (expireTime.getTime() - now.getTime()) / 60000;
    }
}
