import { IClientTransport } from "./IClientTransport";
import { IProxyMetadata } from "./IProxyMetadata";
import { IRpcClient } from "./IRpcClient";

/**
 * Provides proxy objects on local (client) side.
 * @class
 */
export class RpcClient implements IRpcClient {

    /**
     * The object to use to communicate with the remote service.
     * @type {IClientTransport}
     */
    private clientTransport: IClientTransport;

    /**
     * Initializes a new instance of the RpcClient class.
     * @constructor
     * @param clientTransport {IClientTransport} The object to use to communicate with the remote service.
     */
    constructor(clientTransport: IClientTransport) {

        if (!clientTransport) {
            throw "The clientTransport parameter is required";
        }

        this.clientTransport = clientTransport;
    }

    /**
     * Builds a proxy object for the remote service.
     * @param serviceName {string} The name of the service to get a proxy for.
     * @returns {Promise<any>} A promise for the proxy.
     */
    public getProxy<T>(serviceName: string): Promise<T> {

        if (!serviceName) {
            throw "The serviceName parameter is required";
        }

        let clientTransport = this.clientTransport;
        return this.clientTransport.sendRequest("rpc.ServiceMetadata", "getProxyMetadata", [serviceName]).then((proxyMetadata: IProxyMetadata) => {
            let stub: any = {};

            // Loop through all functions and create a stub function for each.
            for (let member in proxyMetadata) {
                if (proxyMetadata[member] === "function") {

                    // Create the stub method inside a closure so that each stub has it's own copy of the member variable, otherwise
                    // all stubs end up with the last value that member has in the loop.
                    let createStub = (member) => {
                        // Create a stub function that forwards the request to the transport code.
                        stub[member] = function (): Promise<any> {
                            return clientTransport.sendRequest(serviceName, member, Array.prototype.slice.call(arguments));
                        };
                    };
                    createStub(member);
                }
            }

            return stub;
        });
    }
}