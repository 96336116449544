/**
* QosEvent.ts
* @author Hector Hernandez (hectorh)
* @copyright Microsoft 2018
*/

import { IExtendedTelemetryItem, isValueAssigned } from '@ms/1ds-core-js';
import { IQoS } from '../interfaces/ITelemetryEvents';

export default class QosEvent {

    public _setCommonProperties(event: IExtendedTelemetryItem, eventData: IQoS) {
        // Part A
        if (isValueAssigned(eventData.cV)) {
            event.ext = event.ext || {};
            event.ext['mscv'] = event.ext['mscv'] || {};
            event.ext['mscv']['cV'] = eventData.cV;
        }
        // Part B
        event.baseData['operationName'] = eventData.operationName;
        event.baseData['latencyMs'] = eventData.latencyMs;
        event.baseData['serviceErrorCode'] = eventData.serviceErrorCode || -1.0;
        event.baseData['succeeded'] = eventData.succeeded;
        event.baseData['requestStatus'] = eventData.requestStatus;
        event.baseData['requestMethod'] = eventData.requestMethod;

        if (isValueAssigned(eventData.target)) {
            event.baseData['target'] = eventData.target;
        }
        if (isValueAssigned(eventData.responseContentType)) {
            event.baseData['responseContentType'] = eventData.responseContentType;
        }
        if (isValueAssigned(eventData.protocol)) {
            event.baseData['protocol'] = eventData.protocol;
        }
        if (isValueAssigned(eventData.protocolStatusCode)) {
            event.baseData['protocolStatusCode'] = eventData.protocolStatusCode;
        }
    }
}
