/**
* id.ts
* @author Hector Hernandez (hectorh)
* @copyright Microsoft 2018
*/

import { createGuid } from '@ms/1ds-core-js';

export default class Id {
    private lastPageViewId: string;
    private traceId: string;

    public constructor() {
        this.lastPageViewId = createGuid();
        this.traceId = this.createTraceId();
    }

    public createTraceId(): string {
        return createGuid().replace(/-/g, '');
    }

    public getTraceId(): string {
        return this.traceId;
    }

    public getLastPageViewId(): string {
        return this.lastPageViewId;
    }
}
