import * as axiosStatic from "axios";

import { AxiosWrapperBase } from "../axios-wrapper/AxiosWrapperBase";
import { IInstrumentationService } from "clientcore-infrastructure-analytics/IInstrumentationService";
import { StopWatch } from "cms-infrastructure-javascriptextensions/StopWatch";
import { Url } from "cms-infrastructure-javascriptextensions/Url";

/**
 * Wraps axios and adds an default network latency telemetry call for each network request.
 * @class
 */
export class AxiosTelemetryWrapper extends AxiosWrapperBase {

    /**
     * es6-promise service instance.
     * @type {Promise}
     */
    private promiseService: typeof Promise;

    /**
     * The instrumentation service to log messages to.
     * @type {IInstrumentationService}
     */
    private instrumentationService: IInstrumentationService;

    /**
     * A stop watch factory returns stop watch instance to provide the timer for latency calculation
     * @type { () => StopWatch}
     */
    private stopWatchFactory: () => StopWatch;

    /**
     * Initializes a new instance of the AxiosTelemetryWrapper class.
     * @param instrumentationService {IInstrumentationService} instrumentation service
     * @param promiseService? {typeof Promise}  the primise service
     * @param axios? {axiosStatic.AxiosStatic}  axios to wrap around
     * @param stopWatchFactory? { () => StopWatch} latency timer
     */
    constructor(
        instrumentationService: IInstrumentationService,
        promiseService?: typeof Promise,
        axios?: axiosStatic.AxiosStatic,
        stopWatchFactory?: () => StopWatch
    ) {
        super(axios || axiosStatic);

        if (!instrumentationService) {
            throw "'instrumentationService' can't be null";
        }

        this.stopWatchFactory = stopWatchFactory ? stopWatchFactory : () => new StopWatch();
        this.promiseService = promiseService || Promise;
        this.instrumentationService = instrumentationService;
    }

    /**
     * Overrides the sendRequest function to add a telemetry call to log the network performance.
     * @param config {axiosStatic.RequestOptions} The request details.
     * @returns {Promise<axiosStatic.Response>} The result of the call.
     */
    public sendRequest<T>(config: axiosStatic.RequestOptions): Promise<axiosStatic.Response> {
        let stopWatch = this.stopWatchFactory();
        return new this.promiseService<axiosStatic.Response>((resolve, reject) => {
            let requestStart = stopWatch.start();
            super.sendRequest(config).then((response) => {
                let requestEnd = stopWatch.stop();
                let clientElapsed = stopWatch.elapsedMilliseconds();
                let counterName = "ServiceClient.NetworkLatency";
                let serverElapsedUnparsed = response.headers["X-CMS-ExecutionTimeInMilliseconds"];
                let serverElapsed = parseInt(serverElapsedUnparsed);
                let latency = serverElapsed ? clientElapsed - serverElapsed : null; // if for whatever reason either or both client and server elapsed are not valid, use null to indicate invalid result.
                let domainName = config.baseURL || Url.getDomainName(config.url);

                let logItemData = {
                    ActivityName: counterName,
                    Elapsed: latency,
                    Domain: domainName,
                    url: config.url,
                    clientElapsed: clientElapsed,
                    clientStartTime: requestStart,
                    clientEndTime: requestEnd,
                    serverElapsed: serverElapsed,
                    RequestMethod: config.method
                };

                this.instrumentationService.perfCounterLoggingService.logPerfCounter(counterName, latency, { "domain": [domainName] }, logItemData);
                resolve(response);
            }).catch(reject);
        });
    }
}