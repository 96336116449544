/**
 * Enum for send modes for 1DS events.
 * @readonly
 * @enum {number}
 */
export enum OneDsSendMode {

    /**
     * Send events immediately (Synchronous call).
     */
    SendEventsImmediately = 1,

    /**
     * Send events as a batch.
     */
    BatchEvents = 2
}
