import { IAdalAuthenticationContext } from "adal-types";
import { IAdalAxiosAuthenticationWrapperContext } from "./IAdalAxiosAuthenticationWrapperContext";

/**
 * Converts the interface of selected methods on the AuthenticationContext object from the adal library to match the required interface
 * of the AdalAxiosAuthenticationWrapper.
 * @class
 */
export class AdalAuthenticationContextAdapter implements IAdalAxiosAuthenticationWrapperContext {

    /**
     * The adal authenticationContext to use to get tokens.
     * @type {IAdalAuthenticationContext}
     */
    private authenticationContext: IAdalAuthenticationContext;

    /**
     * Initializes a new instance of the AdalAuthenticationContextAdapter class.
     * @constructor
     * @param authenticationContext {any} The adal authenticationContext to use to get tokens.
     */
    constructor(authenticationContext: any) {

        if (!authenticationContext) {
            throw "'authenticationContext' can't be null";
        }

        if (!authenticationContext.getResourceForEndpoint || !authenticationContext.acquireToken) {
            throw "'authenticationContext' needs to implement getResourceForEndpoint and acquireToken";
        }

        this.authenticationContext = authenticationContext;

        this.getResourceForEndpoint = this.getResourceForEndpoint.bind(this);
        this.acquireToken = this.acquireToken.bind(this);
    }

    /**
     * Gets a resource id for a service url.
     * @param endpoint {string} The service url to get a resource id for.
     * @returns {Promise<string>} A promise for the resource id of the requested service url.
     */
    public getResourceForEndpoint(endpoint: string): Promise<string> {
        return Promise.resolve(this.authenticationContext.getResourceForEndpoint(endpoint));
    }

    /**
     * Gets a authentication token for a given resource id.
     * @param resourceId {string} The resource id to request a token for.
     * @returns {Promise<string>} A promise to return the token for the requested resource id.
     */
    public acquireToken(resourceId: string): Promise<string> {
        return new Promise((resolve, reject) => {
            this.authenticationContext.acquireToken(resourceId, (error, tokenOut) => {
                if (error) {
                    reject(error);
                } else {
                    resolve(tokenOut);
                }
            });
        });
    }
}