import { IEventBasicData } from "./IEventBasicData";
import { IOutgoingServiceRequestEventContent } from "./IOutgoingServiceRequestEventContent";
import { NetworkActivityLogItem } from "../NetworkActivityLogItem";

/**
 * Represents the outgoing service request event content schema of Asimov and 1DS pipeline.
 * @class
 */
export class OutgoingServiceRequestEventContent implements IOutgoingServiceRequestEventContent {

    /**
     * Initializes a new instance of the `OutgoingServiceRequestEventContent` class.
     * @param operationName {string} The name of the operation.
     * @param serviceName {string} The name of the service being called.
     * @param httpStatusCode {string} The HTTP status code of the service response.
     * @param isSuccess {boolean} If the service request was successful or not.
     * @param requestUri {string} The URI of the request.
     * @param httpMethod {string} The HTTP method of the request.
     * @param contentType {string} The content type of the request.
     * @param responseSize {number} The response size.
     * @param errorMessage {string} The error message from the service, if any.
     * @param currentOperationName {string} The name of the current operation.
     * @param latencyMs {number} The execution time in milliseconds of the service request.
     * @param message {string} The log message.
     * @param logLevel {string} The log level.
     * @param logType {string} The log type.
     * @param activityId {string} The activity id.
     * @param parentActivityId {string} The parent activity id.
     * @param application {string} The application name.
     * @param source {string} The source name.
     * @param impressionGuid {string} The impression guid.
     * @param tenant {string} The tenant name or id.
     * @param data {string} The additional data with open JSON schema.
     * @param uri {string} The page uri.
     * @param pageTitle {string} The page title.
     * @param serverImpressionGuid {string} The server impression guid.
     */
    constructor(
        public operationName: string,
        public serviceName: string,
        public httpStatusCode: string,
        public isSuccess: boolean,
        public requestUri: string,
        public httpMethod: string,
        public contentType: string,
        public responseSize: number,
        public errorMessage: string,
        public currentOperationName: string,
        public latencyMs: number,
        public activityId: string,
        public parentActivityId: string,
        public application: string,
        public source: string,
        public impressionGuid: string,
        public tenant: string,
        public data: string,
        public uri: string,
        public pageTitle: string,
        public serverImpressionGuid: string) {
    }

    /**
     * Creates a new OutgoingServiceRequestEventContent from a given log item.
     * @param logItem {ILogItem} The log item.
     * @param basicData {IEventBasicData} the basic parameters of the asimov events.
     * @param application {string} The application name.
     * @param source {string} The source name.
     * @param impressionGuid {string} The impression guid.
     * @param tenant {string} The tenant name or id.
     * @param serverImpressionGuid {string} The server impression guid.
     * @return {IOutgoingServiceRequestEventContent} The outgoing service request event content data.
     */
    public static createFrom(
        logItem: NetworkActivityLogItem,
        basicData: IEventBasicData,
        application: string,
        source: string,
        impressionGuid: string,
        tenant: string,
        serverImpressionGuid: string): IOutgoingServiceRequestEventContent {

        return new OutgoingServiceRequestEventContent(
            logItem.currentOperationName || logItem.Message,
            logItem.serviceName,
            logItem.responseStatusCode ? logItem.responseStatusCode.toString() : undefined,
            logItem.successful,
            logItem.requestUri,
            logItem.requestHttpMethod,
            "json",
            logItem.responseSize || 0,
            logItem.errorMessage,
            logItem.Message,
            logItem.elapsedTimeInMs,
            logItem.ActivityId,
            logItem.ParentActivityId,
            application,
            source,
            impressionGuid,
            tenant,
            JSON.stringify(basicData.customData),
            basicData.pageUri,
            basicData.title,
            serverImpressionGuid
        );
    }
}
