/**
* Utils.ts
* @author  Abhilash Panwar (abpanwar) Hector Hernandez (hectorh)
* @copyright Microsoft 2018
* File containing utility functions.
*/

import { CoreUtils, IDiagnosticLogger, cookieAvailable, isWindowObjectAvailable, LoggingSeverity, _ExtendedInternalMessageId } from '@ms/1ds-core-js';

var _canUseLocalStorage: boolean = undefined;

/**
* Type of storage to differentiate between local storage and session storage
*/
export enum StorageType {
    LocalStorage,
    SessionStorage
}

/**
    * helper method to set userId and sessionId cookie
    */
export function _setCookie(logger: IDiagnosticLogger, name, value, domain?) {
    var domainAttrib = "";
    var secureAttrib = "";
    if (!isWindowObjectAvailable) {
        return;
    }
    if (domain) {
        domainAttrib = ";domain=" + domain;
    }
    if (window.document.location && window.document.location.protocol === "https:") {
        secureAttrib = ";secure";
    }
    if (cookieAvailable()) {
        window.document.cookie = name + "=" + value + domainAttrib + ";path=/" + secureAttrib;
    }
}


/**
* generate random id string
*/
export function newId(): string {
    var base64chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
    var result = "";
    // tslint:disable-next-line:insecure-random
    var random = Math.random() * 1073741824; //5 symbols in base64, almost maxint

    while (random > 0) {
        var char = base64chars.charAt(random % 64);
        result += char;
        random = Math.floor(random / 64);
    }
    return result;
}

/**
     *  Check if the browser supports local storage.
     *
     *  @returns {boolean} True if local storage is supported.
     */
export function canUseLocalStorage(): boolean {
    if (_canUseLocalStorage === undefined) {
        _canUseLocalStorage = !!_getVerifiedStorageObject(StorageType.LocalStorage);
    }

    return _canUseLocalStorage;
}

/**
* Gets the localStorage object if available
* @return {Storage} - Returns the storage object if available else returns null
*/
function _getLocalStorageObject(): Storage {
    if (canUseLocalStorage()) {
        return _getVerifiedStorageObject(StorageType.LocalStorage);
    }

    return null;
}

/**
     * Tests storage object (localStorage or sessionStorage) to verify that it is usable
     * More details here: https://mathiasbynens.be/notes/localstorage-pattern
     * @param storageType Type of storage
     * @return {Storage} Returns storage object verified that it is usable
     */
function _getVerifiedStorageObject(storageType: StorageType): Storage {
    var storage: Storage = null;
    var fail: boolean;
    var uid;
    try {
        if (typeof window === 'undefined') {
            return null;
        }
        uid = new Date;
        storage = storageType === StorageType.LocalStorage ? window.localStorage : window.sessionStorage;
        storage.setItem(uid, uid);
        fail = storage.getItem(uid) != uid;
        storage.removeItem(uid);
        if (fail) {
            storage = null;
        }
    } catch (exception) {
        storage = null;
    }

    return storage;
}

/**
     *  Set the contents of an object in the browser's local storage
     *
     *  @param {string} name - the name of the object to set in storage
     *  @param {string} data - the contents of the object to set in storage
     *  @returns {boolean} True if the storage object could be written.
     */
export function setStorage(logger: IDiagnosticLogger, name: string, data: string): boolean {
    var storage = _getLocalStorageObject();
    if (storage !== null) {
        try {
            storage.setItem(name, data);
            return true;
        } catch (e) {
            _canUseLocalStorage = false;
            logger.throwInternal(
                LoggingSeverity.CRITICAL,
                _ExtendedInternalMessageId.BrowserCannotWriteLocalStorage, "Browser failed write to local storage. " + e
            )
        }
    }
    return false;
}

/**
     *  Get an object from the browser's local storage
     *
     *  @param {string} name - the name of the object to get from storage
     *  @returns {string} The contents of the storage object with the given name. Null if storage is not supported.
     */
export function getStorage(logger: IDiagnosticLogger, name: string): string {
    var storage = _getLocalStorageObject();
    if (storage !== null) {
        try {
            return storage.getItem(name);
        } catch (e) {
            _canUseLocalStorage = false;
            logger.throwInternal(
                LoggingSeverity.CRITICAL,
                _ExtendedInternalMessageId.BrowserCannotReadLocalStorage, "Browser failed read of local storage. " + e
            )
        }
    }
    return null;
}

/**
* generate a random 32bit number (-0x80000000..0x7FFFFFFF).
*/
export function random32() {
    return (0x100000000 * Math.random()) | 0;
}

/**
* generate W3C trace id
*/
export function generateW3CId() {
    const hexValues = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "a", "b", "c", "d", "e", "f"];

    // rfc4122 version 4 UUID without dashes and with lowercase letters
    let oct = "", tmp;
    for (let a = 0; a < 4; a++) {
        tmp = random32();
        oct +=
            hexValues[tmp & 0xF] +
            hexValues[tmp >> 4 & 0xF] +
            hexValues[tmp >> 8 & 0xF] +
            hexValues[tmp >> 12 & 0xF] +
            hexValues[tmp >> 16 & 0xF] +
            hexValues[tmp >> 20 & 0xF] +
            hexValues[tmp >> 24 & 0xF] +
            hexValues[tmp >> 28 & 0xF];
    }

    // "Set the two most significant bits (bits 6 and 7) of the clock_seq_hi_and_reserved to zero and one, respectively"
    const clockSequenceHi = hexValues[8 + (Math.random() * 4) | 0];
    return oct.substr(0, 8) + oct.substr(9, 4) + "4" + oct.substr(13, 3) + clockSequenceHi + oct.substr(16, 3) + oct.substr(19, 12);
}

/**
 * A utility class that helps getting time related parameters
 */
export class DateTimeUtils {
    /**
     * Get the number of milliseconds since 1970/01/01 in local timezone
     */
    public static Now = (typeof window === 'undefined') ? function () { return new Date().getTime(); } :
        (window.performance && window.performance.now && window.performance.timing) ?
            function () {
                return window.performance.now() + window.performance.timing.navigationStart;
            }
            :
            function () {
                return new Date().getTime();
            }

    /**
     * Gets duration between two timestamps
     */
    public static GetDuration = function (start: number, end: number): number {
        var result = null;
        if (start !== 0 && end !== 0 && !CoreUtils.isNullOrUndefined(start) && !CoreUtils.isNullOrUndefined(end)) {
            result = end - start;
        }

        return result;
    }
}

/**
* Check if an object is of type Date
*/
export let isDate = CoreUtils.isDate;

/**
* Convert a date to I.S.O. format in IE8
*/
export let toISOString = CoreUtils.toISOString;