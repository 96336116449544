import { BaseAppender } from "./BaseAppender";
import { ILogItem } from "../models/ILogItem";
import { LogLevel } from "../models/LogLevel";

/**
 * Class to represent an console appender.
 * @class
 */
export class ConsoleAppender extends BaseAppender {

    /*
     * The console to use.
     * @type {Console}
     */
    private console: Console;

    /**
     * Initializes a new instance of the `ConsoleAppender` class.
     * @constructor
     * @param logLevel {LogLevel} Logging level or threshold.
     * @param console {Console} The implementation of console to use.
     */
    constructor(
        logLevel: LogLevel,
        console: Console) {
        super(logLevel);
        this.console = console;

        if (!(this.console && this.console.log)) {
            throw `${this.toString()} requires a Console to log to.`;
        }
    }

    /**
     * Returns a string representation of the appender.
     * @method
     * @return {string} The string representation of the appender.
     */
    public toString(): string {
        return "ConsoleAppender";
    }

    /**
     * Appender specific method to append a log message. In this case, appender logs the message to the browser console.
     * @method
     * @param logItems {Array<ILogItem>} The collection of log items.
     */
    protected log(logItems: Array<ILogItem>): void {
        logItems.forEach((logItem) => {
            switch (logItem.LogLevel) {
                case LogLevel.Trace:
                    this.console.trace(logItem.Message, logItem, logItem.LogDateTime);
                    break;

                case LogLevel.Debug:
                    this.console.debug(logItem.Message, logItem, logItem.LogDateTime);
                    break;

                case LogLevel.Info:
                    this.console.info(logItem.Message, logItem, logItem.LogDateTime);
                    break;

                case LogLevel.Warn:
                    this.console.warn(logItem.Message, logItem, logItem.LogDateTime);
                    break;

                case LogLevel.Error:
                case LogLevel.Fatal:
                    this.console.error(logItem.Message, logItem, logItem.LogDateTime);
                    break;

                default:
                    this.console.log(logItem.Message, logItem, logItem.LogDateTime);
                    break;
            }
        });
    }
}
