/**
* Enums.ts
* @author Hector Hernandez (hectorh)
* @copyright Microsoft 2019
*/

export const AuthType = {
    NONE: 1,
    MSA: 1,
    AAD: 2
};
