/* tslint:disable:ext-variable-name */

import { Guid } from "cms-infrastructure-javascriptextensions/Guid";

import { ILogItem } from "./ILogItem";
import { LogLevel } from "./LogLevel";
import { LogType } from "./LogType";

export class LogItem implements ILogItem {

    /* Property names are in UpperCamelCase (PascalCase) to keep them consistent with the existing webservice contract */

    /** 
     * The level at whcih this ILogItem should be logged.
     * @property 
     * @type {LogLevel}
     */
    public LogLevel: LogLevel;

    /** 
     * Type of the log.
     * @property 
     * @type {number}
     */
    public LogType: LogType;

    /** 
     * The date and time the log was created.
     * @property 
     * @type {Date}
     */
    public LogDateTime: Date;

    /** 
     * The activity ID.
     * @property 
     * @type {string}
     */
    public ActivityId: string;

    /** 
     * The message.
     * @property 
     * @type {string}
     */
    public Message: string;

    /** 
     * The data.
     * @property 
     * @type {any}
     */
    public Data: any;

    /** 
     * The activity ID of the parent activity.
     * @property 
     * @type {string}
     */
    public ParentActivityId: string;

    /**
     * Initializes a new instance of the `LoggingService` class.
     * @constructor
     * @param message {message} Log message.
     * @param data {any} Any additional JSON data.
     * @param logType {LogType} Type of the log.
     * @param activityId {string} Activity Id.
     * @param logTime {Date} Time of the log.
     * @param parentActivityId {string} Parent activity id.
     */
    constructor(message?: string, data?: any, logType?: LogType, activityId?: string, logDateTime?: Date, parentActivityId?: string) {
        this.Message = message || "";
        this.LogType = logType || LogType.Default;
        this.ActivityId = activityId || Guid.newGuid();
        this.LogDateTime = logDateTime || new Date();
        this.ParentActivityId = parentActivityId || "";

        if (data) {
            if (typeof (data) !== "object") {
                data = { data: data };
            }

            this.Data = data;
        }
    }
}
