import { ActionInputMethodType } from "../userActivityData/ActionInputMethodType";
import { IBrowserWindow } from "../../browser-object-models/IBrowserWindow";
import { IBaseTrackingData } from "../userActivityData/IBaseTrackingData";
import { IEventBasicData } from "../commonSchema/IEventBasicData";
import { ITrackingPartBContent } from "./ITrackingPartBContent";
import { IPageTags } from "../userActivityData/IPageTags";
import { IUserActivityLogItem } from "../IUserActivityLogItem";

/**
 * Represents the parameters of the PART B content that is common for both page view and page action events.
 * @class
 */
export class TrackingPartBContent implements ITrackingPartBContent {

    /**
     * The content Part B version as per JSLL.
     * @type {string}
     */
    private static ContentPartBVersion: string = "1.0";

    /**
     * The content version as per JSLL.
     * @type {string}
     */
    private static ContentVersion: string = "2.0";

    /**
     * Initializes a new instance of the `TrackingPartBContent` class.
     * @param ver {string} The content Part B version as per JSLL.
     * @param pageName {string} The page name.
     * @param uri {string} The page uri.
     * @param pageType? {string} The page type.
     * @param pageTags? {string} The tags or additional data associated with the event.
     * @param screenState? {number} The state of the application on the screen such as Full, Minimized, SnappedOneHalf etc.
     * @param actionType? {string} The device, mechanism, or apparatus used to “input” or trigger the action such as a keyboard, controller, mouse, gesture, etc.
     * @param impressionGuid? {string} The impression guid that changes for every page load.
     * @param contentVer {string} The content JSON version.
     */
    constructor(
        public ver: string,
        public pageName: string,
        public uri: string,
        public pageType?: string,
        public pageTags?: string,
        public screenState?: number,
        public actionType?: string,
        public impressionGuid?: string,
        public contentVer?: string) {
    }

    /**
     * Creates a new TrackingPartBContent from a given log item.
     * @param userActivityLogItem {IUserActivityLogItem} The log item.
     * @param windowContext {IBrowserWindow} The window object of the browser.
     * @param toolsProduct {string} The tools product name.
     * @param toolsBuild {string} The tools build version.
     * @param impressionGuid {string} The impression guid.
     * @param basicData {IEventBasicData} The basic parameters of the asimov events.
     * @return {ITrackingPartBContent} The part B content that is common for both page view and page action events.
     */
    protected static createFrom(
        userActivityLogItem: IUserActivityLogItem,
        windowContext: IBrowserWindow,
        toolsProduct: string,
        toolsBuild: string,
        impressionGuid: string,
        basicData: IEventBasicData): ITrackingPartBContent {

        let pageTags: IPageTags = {
            toolsProduct: toolsProduct,
            toolsBuild: toolsBuild
        };

        let partBContent: ITrackingPartBContent = {
            ver: TrackingPartBContent.ContentPartBVersion,
            pageName: userActivityLogItem.Message,
            uri: userActivityLogItem.pageUri || basicData.pageUri,
            impressionGuid: impressionGuid,
            pageTags: JSON.stringify(pageTags),
            contentVer: TrackingPartBContent.ContentVersion
        };

        if (userActivityLogItem.trackingData) {
            let trackingData = <IBaseTrackingData>userActivityLogItem.trackingData;

            // Populate PART B Contents
            partBContent.pageType = trackingData.pageType;

            if (trackingData.pageTags) {
                pageTags = trackingData.pageTags;
            }

            partBContent.pageTags = JSON.stringify(pageTags);
            partBContent.screenState = trackingData.screenState;
            partBContent.actionType = ActionInputMethodType[trackingData.actionInputMethod];
        }

        return partBContent;
    }
}
