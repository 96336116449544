import * as axiosStatic from "axios";

import { AdalAuthenticationErrorType } from "./AdalAuthenticationErrorType";
import { AxiosWrapperBase } from "../axios-wrapper/AxiosWrapperBase";
import { IAdalClientCredentialsAuthenticationContext } from "./IAdalClientCredentialsAuthenticationContext";

/**
 * Wraps axios and adds an authentication token from adal to the request headers using client credentials grant flow.
 * This flow uses a client id and a client secret for accessing the requested resource.
 * @class
 */
export class AxiosAdalClientCredentialsAuthenticationWrapper extends AxiosWrapperBase {

    /**
     * Initializes a new instance of the `AxiosAdalClientCredentialsAuthenticationWrapper` class.
     * @constructor
     * @param adalClientCredentialsAuthenticationContext {IAdalClientCredentialsAuthenticationContext<AdalAuthenticationErrorType, axiosStatic.Response>} Authentication context for getting the token using credentials.
     * @param axios {axiosStatic.AxiosStatic} Optional, Axios service instance.
     */
    constructor(
        private adalClientCredentialsAuthenticationContext: IAdalClientCredentialsAuthenticationContext<AdalAuthenticationErrorType, axiosStatic.Response>,
        axios?: axiosStatic.AxiosStatic) {

        super(axios || axiosStatic);

        if (!adalClientCredentialsAuthenticationContext) {
            throw "'adalClientCredentialsAuthenticationContext' is required for AxiosAdalClientCredentialsAuthenticationWrapper.";
        }
    }

    /**
     * Overrides the sendRequest function to add an authentication token using client credentials.
     * @param config {axiosStatic.RequestOptions} The request details.
     * @returns {Promise<axiosStatic.Response>} The result of the call.
     */
    public sendRequest(config: axiosStatic.RequestOptions): Promise<axiosStatic.Response> {

        return new Promise<axiosStatic.Response>((resolve, reject) => {

            const resource = config.url;

            // Get a token for the given resource.
            this.adalClientCredentialsAuthenticationContext.acquireTokenWithClientCredentials(resource).then(token => {
                // Attach the token to the request and send the request.
                if (!config.headers) {
                    config.headers = {};
                }

                config.headers.Authorization = "Bearer " + token;
                super.sendRequest(config).then(resolve).catch(reject);
            }).catch(reject);
        });
    }
}