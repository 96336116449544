import { IPromisesResults } from "./IPromisesResults";
import { TypedPromise } from "cms-typed-promise";

/**
 * Contains methods to help work with promises.
 * @class
 */
export class PromiseExtension {

    /**
     * Calls the callback method on completion (for both resolve and reject) of the promise.
     * @method
     * @param promise {Promise<T>} The promise.
     * @param callback {function} The callback function.
     * @return {Promise<T>} The original promise without affecting the result.
     */
    public static onComplete<T>(promise: Promise<T>, callback: () => void): Promise<T> {

        var method = () => {
            callback();
        };

        promise.then(method, method);
        return promise;
    }

    /**
     * Calls the callback method on complete of the typed promise.
     * @method
     * @param promise: {TypedPromise<T, TError>} The typed promise.
     * @param callback {function} The callback function.
     * @return {TypedPromise<T, U>} The original promise without affecting the result.
     */
    public static onCompleteTyped<T, TError>(promise: TypedPromise<T, TError>, callback: () => void): TypedPromise<T, TError> {
        var method = () => {
            callback();
        };

        promise.then(method, method);
        return promise;
    }

    /**
     * Build a list of the succeeded results and discard the results for the failed promises.
     * @param promises {Promise<T>[]} The list of promises to wait for.
     * @returns {Promise<T[]>} A promise for the succeeded results.
     */
    public static onSucceededPromises<T>(promises: Promise<T>[]): Promise<T[]> {
        let resultPromise = new Promise<T[]>((resolve, reject) => {

            let succeededResults: T[] = [];

            if (promises.length === 0) {
                resolve(succeededResults);
            }

            let processedCount = 0;

            promises.forEach((promise) => {

                promise.then((result) => {
                    succeededResults.push(result);
                });

                PromiseExtension.onComplete(promise, () => {
                    processedCount++;
                    if (processedCount === promises.length) {
                        resolve(succeededResults);
                    }
                });
            });
        });

        return resultPromise;
    }

    /**
     * Build a list of the all completed promises with succeeded and failed results.
     * @param promises {Promise<T>[]} The list of promises to wait for.
     * @returns {Promise<IBatchResponse<T>>} A promise for all completed results.
     */
    public static onAllComplete<T>(promises: Promise<T>[]): Promise<IPromisesResults<T>> {
        let resultPromise = new Promise<IPromisesResults<T>>((resolve, reject) => {

            let response: IPromisesResults<T> = { succeeded: [], failed: [] };

            if (promises.length === 0) {
                resolve(response);
            }

            let processedCount = 0;

            promises.forEach((promise) => {

                promise.then((result) => {
                    response.succeeded.push(result);
                }).catch((error) => {
                    response.failed.push(error);
                });

                PromiseExtension.onComplete(promise, () => {
                    processedCount++;
                    if (processedCount === promises.length) {
                        resolve(response);
                    }
                });
            });
        });

        return resultPromise;
    }
}