import { ITryOrCatch } from "./ITryOrCatch";
import { TryCatchFinallyBlock } from "./TryCatchFinallyBlock";

/**
 * Start the fluid design try catch finally block creation.
 * @method
 * @param {function} tryBlock The try block to execute.
 * @returns {ITryOrCatch} A fluid design object to add more steps.
 */
export function tryBlock(tryBlock: () => void): ITryOrCatch {
    return new TryCatchFinallyBlock(tryBlock);
}
