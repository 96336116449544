/**
* SessionManager.ts
* @author Hector Hernandez (hectorh)
* @copyright Microsoft 2019
*/

import { IDiagnosticLogger, DiagnosticLogger, CoreUtils, getCookie, LoggingSeverity, _ExtendedInternalMessageId } from '@ms/1ds-core-js';
import { IPropertyConfiguration } from './DataModels';
import { Session } from './context/Session';
import * as Utils from './Utils';

export interface ISessionConfig {
    sessionRenewalMs?: () => number;
    sessionExpirationMs?: () => number;
    cookieDomain?: () => string;
    namePrefix?: () => string;
}

export class SessionManager {

    public static acquisitionSpan = 86400000; // 24 hours in ms
    public static renewalSpan = 1800000; // 30 minutes in ms
    public static cookieUpdateInterval = 60000 // 1 minute in ms
    public automaticSession: Session;
    public config: ISessionConfig;
    private static cookieNameConst = 'ai_session';

    private cookieUpdatedTimestamp: number;
    private _logger: IDiagnosticLogger;
    private _storageNamePrefix: () => string;

    constructor(config: IPropertyConfiguration, logger?: IDiagnosticLogger) {

        const functionalConfig = this.getDefaultConfig(config);
        if (CoreUtils.isNullOrUndefined(logger)) {
            this._logger = new DiagnosticLogger();
        } else {
            this._logger = logger;
        }

        if (!(typeof config.sessionExpirationMs === "function")) {
            functionalConfig.sessionExpirationMs = () => SessionManager.acquisitionSpan;
        }

        if (!(typeof config.sessionRenewalMs === "function")) {
            functionalConfig.sessionRenewalMs = () => SessionManager.renewalSpan;
        }

        this.config = functionalConfig;
        this._storageNamePrefix = () => this.config.namePrefix && this.config.namePrefix() ? SessionManager.cookieNameConst + this.config.namePrefix() : SessionManager.cookieNameConst;

        this.automaticSession = new Session();
    }

    public update() {
        if (!this.automaticSession.id) {
            this.initializeAutomaticSession();
        }

        let autoSession = this.automaticSession;
        let config = this.config;
        var now = Utils.DateTimeUtils.Now();

        var acquisitionExpired = now - autoSession.acquisitionDate > config.sessionExpirationMs();
        var renewalExpired = now - autoSession.renewalDate > config.sessionRenewalMs();

        // renew if acquisitionSpan or renewalSpan has ellapsed
        if (acquisitionExpired || renewalExpired) {
            // update automaticSession so session state has correct id
            this.renew();
        } else {
            // do not update the cookie more often than cookieUpdateInterval
            let cookieUpdatedTimestamp = this.cookieUpdatedTimestamp;
            if (!cookieUpdatedTimestamp || now - cookieUpdatedTimestamp > SessionManager.cookieUpdateInterval) {
                autoSession.renewalDate = now;
                this.setCookie(autoSession.id, autoSession.acquisitionDate, autoSession.renewalDate);
            }
        }
    }

    /**
    * Create functional configs if value is provided, else SessionManager provides the defaults
    * @param config
    */
    private getDefaultConfig(config: IPropertyConfiguration) {
        return {
            sessionRenewalMs: config.sessionRenewalMs && (() => config.sessionRenewalMs),
            sessionExpirationMs: config.sessionExpirationMs && (() => config.sessionExpirationMs),
            cookieDomain: config.cookieDomain && (() => config.cookieDomain),
            namePrefix: config.namePrefix && (() => config.namePrefix)
        };
    }

    /**
     *  Record the current state of the automatic session and store it in our cookie string format
     *  into the browser's local storage. This is used to restore the session data when the cookie
     *  expires.
     */
    public backup() {
        this.setStorage(this.automaticSession.id, this.automaticSession.acquisitionDate, this.automaticSession.renewalDate);
    }

    /**
     *  Use config.namePrefix + ai_session cookie data or local storage data (when the cookie is unavailable) to
     *  initialize the automatic session.
     */
    private initializeAutomaticSession() {
        var cookie = getCookie(this._storageNamePrefix());
        if (cookie && typeof cookie.split === "function") {
            this.initializeAutomaticSessionWithData(cookie);
        } else {
            // There's no cookie, but we might have session data in local storage
            // This can happen if the session expired or the user actively deleted the cookie
            // We only want to recover data if the cookie is missing from expiry. We should respect the user's wishes if the cookie was deleted actively.
            // The User class handles this for us and deletes our local storage object if the persistent user cookie was removed.
            var storage = Utils.getStorage(this._logger, this._storageNamePrefix());
            if (storage) {
                this.initializeAutomaticSessionWithData(storage);
            }
        }

        if (!this.automaticSession.id) {
            this.renew();
        }
    }

    /**
     *  Extract id, aquisitionDate, and renewalDate from an ai_session payload string and
     *  use this data to initialize automaticSession.
     *
     *  @param {string} sessionData - The string stored in an ai_session cookie or local storage backup
     */
    private initializeAutomaticSessionWithData(sessionData: string) {
        var params = sessionData.split("|");

        if (params.length > 0) {
            this.automaticSession.id = params[0];
        }

        try {
            if (params.length > 1) {
                var acq = +params[1];
                this.automaticSession.acquisitionDate = +new Date(acq);
                this.automaticSession.acquisitionDate = this.automaticSession.acquisitionDate > 0 ? this.automaticSession.acquisitionDate : 0;
            }

            if (params.length > 2) {
                var renewal = +params[2];
                this.automaticSession.renewalDate = +new Date(renewal);
                this.automaticSession.renewalDate = this.automaticSession.renewalDate > 0 ? this.automaticSession.renewalDate : 0;
            }
        } catch (e) {
            this._logger.throwInternal(
                LoggingSeverity.CRITICAL,
                _ExtendedInternalMessageId.ErrorParsingAISessionCookie, "Error parsing ai_session cookie, session will be reset: " + e
            )
        }

        if (this.automaticSession.renewalDate == 0) {
            this._logger.throwInternal(
                LoggingSeverity.WARNING,
                _ExtendedInternalMessageId.SessionRenewalDateIsZero, "AI session renewal date is 0, session will be reset."
            )
        }
    }

    private renew() {
        var now = Utils.DateTimeUtils.Now();

        this.automaticSession.id = Utils.newId();
        this.automaticSession.acquisitionDate = now;
        this.automaticSession.renewalDate = now;

        this.setCookie(this.automaticSession.id, this.automaticSession.acquisitionDate, this.automaticSession.renewalDate);

        // If this browser does not support local storage, fire an internal log to keep track of it at this point
        if (!Utils.canUseLocalStorage()) {
            this._logger.throwInternal(
                LoggingSeverity.WARNING,
                _ExtendedInternalMessageId.BrowserDoesNotSupportLocalStorage, "Browser does not support local storage. Session durations will be inaccurate."
            )
        }
    }

    private setCookie(guid: string, acq: number, renewal: number) {
        // Set cookie to expire after the session expiry time passes or the session renewal deadline, whichever is sooner
        // Expiring the cookie will cause the session to expire even if the user isn't on the page
        var acquisitionExpiry = acq + this.config.sessionExpirationMs();
        var renewalExpiry = renewal + this.config.sessionRenewalMs();
        var cookieExpiry = new Date();
        var cookie = [guid, acq, renewal];

        if (acquisitionExpiry < renewalExpiry) {
            cookieExpiry.setTime(acquisitionExpiry);
        } else {
            cookieExpiry.setTime(renewalExpiry);
        }

        var cookieDomnain = this.config.cookieDomain ? this.config.cookieDomain() : null;
        Utils._setCookie(this._logger, this._storageNamePrefix(), cookie.join('|') + ';expires=' + cookieExpiry.toUTCString(), cookieDomnain);

        this.cookieUpdatedTimestamp = Utils.DateTimeUtils.Now();
    }

    private setStorage(guid: string, acq: number, renewal: number) {
        // Keep data in local storage to retain the last session id, allowing us to cleanly end the session when it expires
        // Browsers that don't support local storage won't be able to end sessions cleanly from the client
        // The server will notice this and end the sessions itself, with loss of accurate session duration
        Utils.setStorage(this._logger, this._storageNamePrefix(), [guid, acq, renewal].join('|'));
    }
}