/**
* OutgoingQosEvent.ts
* @author Hector Hernandez (hectorh)
* @copyright Microsoft 2018
*/

import { IExtendedTelemetryItem, isValueAssigned } from '@ms/1ds-core-js';
import QosEvent from './QosEvent';
import { IOutgoingServiceRequest } from '../interfaces/ITelemetryEvents';

export default class OutgoingQosEvent {

    private _qosEvent: QosEvent;

    constructor() {
        this._qosEvent = new QosEvent();
    }

    public _setQosProperties(event: IExtendedTelemetryItem, eventData: IOutgoingServiceRequest) {
        this._qosEvent._setCommonProperties(event, eventData);
        event.baseData['dependencyOperationName'] = eventData.dependencyOperationName;
        event.baseData['dependencyName'] = eventData.dependencyName;
        event.baseData['dependencyType'] = eventData.dependencyType || 'WebService'; // WebService is a common schema term for reffering any kind of webservice

        if (isValueAssigned(eventData.dependencyOperationVersion)) {
            event.baseData['dependencyOperationVersion'] = eventData.dependencyOperationVersion;
        }
        if (isValueAssigned(eventData.responseSizeBytes)) {
            event.baseData['responseSizeBytes'] = eventData.responseSizeBytes;
        }
    }
}
