import { IAppender } from "./IAppender";
import { ILogItem } from "../models/ILogItem";
import { LogLevel } from "../models/LogLevel";

/**
 * Abstract class to appenders of the logging service.
 * @class
 */
export abstract class BaseAppender implements IAppender {

    /**
     * The log level (Threshold)
     */
    private logLevel: LogLevel;

    /**
     * Initializes a new instance of the `SampleAppender` class.
     * @constructor
     * @param logLevel {LogLevel} Logging level or threshold.
     */
    constructor(logLevel: LogLevel) {
        this.logLevel = logLevel;
    }

    /**
     * Appender specific method to append a log item.
     * @method
     * @param logItem {ILogItem} The log item containing log details.
     */
    public append(logItem: ILogItem): void {

        if (!(logItem && this.isEnabledFor(logItem.LogLevel))) {
            return;
        }

        this.log(new Array<ILogItem>(logItem));
    }

    /**
     * Appender specific method to append collection of log items.
     * @method
     * @param logItems {Array<ILogItem>} The collection of log items.
     */
    public appendItems(logItems: Array<ILogItem>): void {

        var allowedItems = logItems.filter(item => item && this.isEnabledFor(item.LogLevel));
        this.log(allowedItems);
    }

    /**
     * Returns a string representation of the appender.
     * @method
     * @return {string} The string representation of the appender.
     */
    public abstract toString(): string;

    /**
     * Sets the log level.
     * @method
     * @param logLevel {LogLevel} Logging level or threshold.
     */
    public setLevel(logLevel?: LogLevel): void {
        this.logLevel = logLevel;
    }

    /**
     * Gets the log level.
     * @method
     * @returns {LogLevel} The Logging level or threshold.
     */
    public getLevel(): LogLevel {
        return this.logLevel;
    }

    /**
     * Evaluates if the appender is enabled for the given log level.
     * @method
     * @param logLevel {LogLevel} The Logging level or threshold.
     * @returns {boolean} True, if the appender is enabled for the level. Otherwise, False.
     */
    public isEnabledFor(logLevel: LogLevel): boolean {

        // If log level threshold is greater than the given log level then it is enabled.
        return this.logLevel >= logLevel;
    }

    /**
     * Flushes the log items.
     * @method
     */
    public flush(): void {
        // Do nothing
    }

    /**
     * Appender specific method to log the log items.
     * @method
     * @param logItems {Array<ILogItem>} The collection of log items.
     */
    protected abstract log(logItems: Array<ILogItem>): void;
}
