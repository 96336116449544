import { IBrowserWindow } from "../../browser-object-models/IBrowserWindow";

/**
 * Utility class to provide the browser based data.
 * @class
 */
export class BrowserBasedDataProvider {

    /**
     * Gets the Size of the browser window. Ex: 1024x768.
     * @method 
     * @param windowContext? {IBrowserWindow} The window object of the browser.
     * @return {string} The browser size.
     */
    public static getBrowserSize(windowContext: IBrowserWindow): string {

        var browserSize = "";
        if (windowContext) {
            let browserWidth = windowContext.innerWidth || windowContext.document.body.clientWidth || windowContext.document.documentElement.clientWidth;
            let browserHeight = windowContext.innerHeight || windowContext.document.body.clientHeight || windowContext.document.documentElement.clientHeight;

            if (windowContext.document) {
                if (!browserWidth && windowContext.document.body && windowContext.document.body.clientWidth) {
                    browserWidth = windowContext.document.body.clientWidth;
                }

                if (!browserHeight && windowContext.document.body && windowContext.document.body.clientHeight) {
                    browserHeight = windowContext.document.body.clientHeight;
                }

                if (!browserWidth && windowContext.document.documentElement && windowContext.document.documentElement.clientWidth) {
                    browserWidth = windowContext.document.documentElement.clientWidth;
                }

                if (!browserHeight && windowContext.document.documentElement && windowContext.document.documentElement.clientHeight) {
                    browserHeight = windowContext.document.documentElement.clientHeight;
                }
            }

            if (browserWidth && browserHeight) {
                browserSize = browserWidth + "x" + browserHeight;
            }
        }

        return browserSize;
    }

    /**
     * Gets the flag to report if the cookies are enabled/disabled in the browser.
     * @method 
     * @param windowContext? {IBrowserWindow} The window object of the browser.
     * @return {string} The cookies enabled/ disabled.
     */
    public static cookieEnabled(windowContext: IBrowserWindow): boolean {

        if (windowContext && windowContext.navigator) {
            return windowContext.navigator.cookieEnabled;
        }

        return undefined;
    }

    /**
     * Gets the list of cookies in semi-colon delimited name value pair.
     * @method 
     * @param windowContext? {IBrowserWindow} The window object of the browser.
     * @return {string} The cookies .
     */
    public static getCookies(windowContext: IBrowserWindow): string {

        if (windowContext && windowContext.document && windowContext.document.cookie) {
            return windowContext.document.cookie;
        }

        return "";
    }
}
