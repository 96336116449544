import { IBrowserWindow } from "../../browser-object-models/IBrowserWindow";
import { IEventBasicData } from "../commonSchema/IEventBasicData";
import { IPageViewEventContent } from "./IPageViewEventContent";
import { IUserActivityLogItem } from "../IUserActivityLogItem";
import { PageViewPartBContent } from "./PageViewPartBContent";
import { PageViewPartCContent } from "./PageViewPartCContent";

import { merge } from "lodash-es";

/**
 * Page view content creator that is registered with JSLL (Javascript logging library) of Asimov and also used in 1DS pipeline.
 * @class
 */
export class PageViewEventContent {

    /**
     * Creates a new PageViewEventContent from a given log item.
     * @param userActivityLogItem {IUserActivityLogItem} The user activity log item.
     * @param basicData {IEventBasicData} the basic parameters of the events.
     * @param windowContext {IBrowserWindow} The window object of the browser.
     * @param impressionGuid {string} The impression guid.
     * @param serverImpressionGuid? {string} The server impression guid.
     * @param application {string} The application name.
     * @param source {string} The source name.
     * @param productName {string} The product name.
     * @param buildVesrion {string} The build version.
     * @param tenant {string} The tenant name.
     * @return {IPageViewEventContent} The page view event content.
     */
    public static createFrom(
        logItem: IUserActivityLogItem,
        basicData: IEventBasicData,
        windowContext: IBrowserWindow,
        impressionGuid: string,
        serverImpressionGuid: string,
        application: string,
        source: string,
        productName: string,
        buildVesrion: string,
        tenant: string): IPageViewEventContent {

        const pageViewPartBContent = PageViewPartBContent.createFrom(logItem, windowContext, productName, buildVesrion, impressionGuid, basicData);
        const pageViewPartCContent = PageViewPartCContent.createFrom(logItem, windowContext, basicData, serverImpressionGuid);

        const eventContent = {
            activityId: logItem.ActivityId,
            parentActivityId: logItem.ParentActivityId,
            application: application,
            source: source,
            tenant: tenant
        };

        return merge(pageViewPartBContent, pageViewPartCContent, eventContent, {});

    }
}
