import * as axiosStatic from "axios";

import { AxiosWrapperBase } from "../axios-wrapper/AxiosWrapperBase";
import { ICallbackHandler } from "./ICallbackHandler";
import { IInstrumentationService } from "clientcore-infrastructure-analytics/IInstrumentationService";

/**
 * Wraps axios and adds a default response header parser for each network request.
 * @class
 */
export class AxiosHeaderParserWrapper extends AxiosWrapperBase {

    /**
     * es6-promise service instance.
     * @type {Promise}
     */
    private promiseService: typeof Promise;

    /**
     * Initializes a new instance of the AxiosHeaderParserWrapper class.
     * @param headerName {string} the header name to get
     * @param callbackHandler {ICallbackHandler} the healthStatusCallbackHandler instance
     * @param instrumentationService {IInstrumentationService} instrumentation service
     * @param promiseService? {typeof Promise}  the promise service
     * @param axios? {axiosStatic.AxiosStatic}  axios to wrap around
     */
    constructor(
        private headerName: string,
        private callbackHandler: ICallbackHandler,
        private instrumentationService: IInstrumentationService,
        promiseService?: typeof Promise,
        axios?: axiosStatic.AxiosStatic
    ) {
        super(axios || axiosStatic);

        if (!headerName) {
            throw "'headerName' cannot be null.";
        }

        if (!callbackHandler) {
            throw "'callbackHandler' cannot be null.";
        }

        if (!instrumentationService) {
            throw "'instrumentationService' cannot be null.";
        }

        this.promiseService = promiseService || Promise;
    }

    /**
     * Check if the response came back with the header specified, call the custom callback with its.
     * @param result {Promise<axiosStatic.Response>} The response detail.
     * @returns {Promise<axiosStatic.Response>} The result of the call with any required changes added.
     */
    protected onResponse<T>(result: Promise<axiosStatic.Response>): Promise<axiosStatic.Response> {

        if (!result) {
            throw "result must not be null";
        }

        result.then((response) => {
            this.processResponseHeader(response);
        }).catch((response) => {
            this.processResponseHeader(response);
        });

        return result;
    }

    /**
     * processes the response header given using the custom callback method
     * @param {axiosStatic.Response}
     * @returns {void}
     */
    private processResponseHeader(result: axiosStatic.Response): void {
        if (result.headers[this.headerName]) {
            this.callbackHandler.execCustomAction(result.headers[this.headerName]);
        } else {
            this.instrumentationService.loggingService.errorCallback(() => `${this.headerName} does not exist in the reponse header.`);
        }
    }
}