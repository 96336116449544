/**
* AutoCaptureHandler.ts
* @author Ram Thiru (ramthi) and Hector Hernandez (hectorh)
* @copyright Microsoft 2018
*/

import {
    _debounce, _getScrollOffset, _isRightClick, _isLeftClick,
    _isKeyboardEnter, _isKeyboardSpace, _isMiddleClick
} from '../common/Utils';
import {
    IContentUpdateOverrideValues, IPageActionOverrideValues, IPageViewOverrideValues, IPageViewPerformanceOverrideValues
} from '../interfaces/IOverrides';
import { ApplicationInsights } from "../WebAnalyticsPlugin";
import { ActionType } from '../Enums';
import { IAutoCaptureHandler } from "../interfaces/IAutoCaptureHandler";
import { IDiagnosticLogger, isDocumentObjectAvailable, isWindowObjectAvailable, addPageUnloadEventListener } from "@ms/1ds-core-js";
import { IDebounce } from '../DataModel';

const clickCaptureInputTypes = { BUTTON: true, CHECKBOX: true, RADIO: true, RESET: true, SUBMIT: true };

export class AutoCaptureHandler implements IAutoCaptureHandler {

    /**
  * @param {ApplicationInsights} analyticsPlugin - WebAnalytics plugin
  * @param {IDiagnosticLogger} traceLogger - Trace logger to log to console.
  */
    constructor(protected _analyticsPlugin: ApplicationInsights, protected _traceLogger: IDiagnosticLogger) {

    }

    public pageView() {
        this._analyticsPlugin.capturePageView(<IPageViewOverrideValues>{ isAuto: true });
    }

    public onLoad() {
        this.onDomReadyDo(() => {
            if (isDocumentObjectAvailable && document.readyState === 'complete') {
                this._analyticsPlugin.capturePageViewPerformance(<IPageViewPerformanceOverrideValues>{ isAuto: true });
                this._analyticsPlugin.captureContentUpdate(<IContentUpdateOverrideValues>{ isAuto: true, isDomComplete: true });
            } else {
                if (isWindowObjectAvailable) {
                    if (window.addEventListener) {
                        window.addEventListener('load', () => {
                            this._analyticsPlugin.capturePageViewPerformance(<IPageViewPerformanceOverrideValues>{ isAuto: true });
                            this._analyticsPlugin.captureContentUpdate(<IContentUpdateOverrideValues>{ isAuto: true, isDomComplete: true });
                        }); // NB **not** 'onload'
                    } else if ((<any>window).attachEvent) {
                        (<any>window).attachEvent('onload', () => {
                            this._analyticsPlugin.capturePageViewPerformance(<IPageViewPerformanceOverrideValues>{ isAuto: true });
                            this._analyticsPlugin.captureContentUpdate(<IContentUpdateOverrideValues>{ isAuto: true, isDomComplete: true });
                        }); // IE8
                    }
                }
            }
        });
    }

    // handle automatic event firing on user click
    public click() {
        if (isWindowObjectAvailable && window.addEventListener) {
            // IE9 onwards addEventListener is available, 'click' event captures mouse click. mousedown works on other browsers
            var event = (navigator.appVersion.indexOf('MSIE') !== -1) ? 'click' : 'mousedown';
            window.addEventListener(event, (evt) => { this._processClick(evt); }, false);
            window.addEventListener('keyup', (evt) => { this._processClick(evt); }, false);
        } else if (isDocumentObjectAvailable && (<any>document).attachEvent) {
            // IE8 and below doesn't have addEventListener so it will use attachEvent
            // attaching to window does not work in IE8
            (<any>document).attachEvent('onclick', (evt: Event) => { this._processClick(evt); });
            (<any>document).attachEvent('keyup', (evt: Event) => { this._processClick(evt); });
        }
    }

    // handle automatic event firing on user scroll
    public scroll(debounceConfig: IDebounce) {
        var processScroll = _debounce(
            // on first call do nothing
            null,
            // after debounce, send contentView with viewportOffset
            () => {
                this._analyticsPlugin.captureContentUpdate(<IContentUpdateOverrideValues>{ isAuto: true, actionType: ActionType.SCROLL });
            },
            debounceConfig.scroll, this);
        if (isWindowObjectAvailable) {
            if (window.addEventListener) {
                window.addEventListener('scroll', processScroll);
            } else if ((<any>window).attachEvent) {
                (<any>window).attachEvent('onscroll', processScroll);
            }
        }
    }

    // handle automatic event firing on user scroll
    public maxScroll(maxScroll: { h: number, v: number }) {
        var getMaxScrollDepth = function () {
            var currentScroll = _getScrollOffset();
            maxScroll.v = maxScroll.v > currentScroll.v ? maxScroll.v : currentScroll.v;
        };
        if (isWindowObjectAvailable) {
            if (window.addEventListener) {
                window.addEventListener('scroll', getMaxScrollDepth);
            } else if ((<any>window).attachEvent) {
                (<any>window).attachEvent('onscroll', getMaxScrollDepth);
            }
        }
    }

    // handle automatic event firing on user resize
    public resize(debounceConfig: IDebounce) {
        /// I wasn�t able to repro the bug 
        /// (https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/7958464) 
        /// until new content was loaded on the page and the resize 
        /// rendering became slow. When resize happens, the page rendering 
        /// time is longer than the resize debounce, and resize render 
        /// happens in time > debounce, and so resize is acting like 
        /// it's being done twice: once at the beginning of user resize, 
        /// one at the end of browser render resize. 

        var processResize = _debounce(
            // on first call, send resize contentUpdate
            () => {
                this._analyticsPlugin.captureContentUpdate(<IContentUpdateOverrideValues>{ isAuto: true, actionType: ActionType.RESIZE });
            },
            // after debounce, do nothing
            null,
            debounceConfig.resize, this);
        if (isWindowObjectAvailable) {
            if (window.addEventListener) {
                window.addEventListener('resize', processResize);
            } else if ((<any>window).attachEvent) {
                (<any>window).attachEvent('onresize', processResize);
            }
        }
    }

    public onUnload() {
        addPageUnloadEventListener( () => { this._analyticsPlugin.capturePageUnload({ isAuto: true }); });
    }

    private _processClick(clickEvent: any) {
        var clickCaptureElements = { A: true, BUTTON: true, AREA: true, INPUT: true };
        clickEvent = clickEvent || window.event; // IE 8 does not pass the event
        let element = clickEvent.srcElement || clickEvent.target;

        // populate overrideValues 
        var overrideValues: IPageActionOverrideValues = {
            isAuto: true,
            clickCoordinateX: clickEvent.pageX,
            clickCoordinateY: clickEvent.pageY
        };
        var isRightClick = _isRightClick(clickEvent as MouseEvent);
        if (isRightClick) {
            overrideValues.actionType = ActionType.CLICKRIGHT;
        } else if (_isLeftClick(clickEvent as MouseEvent)) {
            overrideValues.actionType = ActionType.CLICKLEFT;
        } else if (_isKeyboardEnter(clickEvent as KeyboardEvent)) {
            overrideValues.actionType = ActionType.KEYBOARDENTER;
        } else if (_isKeyboardSpace(clickEvent as KeyboardEvent)) {
            overrideValues.actionType = ActionType.KEYBOARDSPACE;
        } else if (_isMiddleClick(clickEvent as MouseEvent)) {
            overrideValues.actionType = ActionType.CLICKMIDDLE;
        } else {
            return;
        }

        while (element && element.tagName) {
            // control property will be available for <label> elements with 'for' attribute, only use it when is a 
            // valid JSLL capture element to avoid infinite loops
            if (element.control && clickCaptureElements[element.control.tagName.toUpperCase()]) {
                element = element.control;
            }
            if (!clickCaptureElements[element.tagName.toUpperCase()]) {
                element = element.parentElement || element.parentNode;
                continue;
            } else {
                // Check allowed INPUT types
                var sendEvent = element.tagName.toUpperCase() === 'INPUT' ? clickCaptureInputTypes[element.type.toUpperCase()] : true;
                if (sendEvent) {
                    this._analyticsPlugin.capturePageAction(element, overrideValues, {}, isRightClick);
                }
                break;
            }
        }
    }

    // use smallest domready ever for IE8. When IE8 is deprecated, use addEventListener('DomContentLoaded')
    private onDomReadyDo(f: any) {
        /// <summary> fires function f on domRead </summary>
        /// <param type='function'>function to call on domRead</param>

        /in/.test(document.readyState) ? setTimeout(() => { this.onDomReadyDo(f); }, 100) : f.call();
    }

}
