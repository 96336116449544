import * as axiosStatic from "axios";

import { AxiosWrapperAbstractBase } from "../axios-wrapper/AxiosWrapperAbstractBase";
import { IBatchingManager } from "./IBatchingManager";

/**
 * Replaces axios and intercepts all requests.  Intercepted requests are added to batches controlled by the BatchingManager.
 * @class
 */
export class AxiosBatchingWrapper extends AxiosWrapperAbstractBase {

    /** es6-promise service instance.
     */
    private promiseService: typeof Promise;

    /** The service that controls batching of calls.
     */
    private batchingManager: IBatchingManager;

    /**
     * Initializes a new instance of the `AxiosBatchingWrapper` class.
     * @constructor
     * @param {IBatchingManager} batchingManager The service that controls batching of calls.
     * @param {Promise} promiseService es6-promise service instance.
     */
    constructor(
        batchingManager: IBatchingManager,
        promiseService?: typeof Promise) {

        if (!batchingManager) {
            throw "batchingManager must not be null";
        }

        super();

        this.batchingManager = batchingManager;
        this.promiseService = promiseService || Promise;
    }

    /**
     * Method to send any type of http call (get/post/put/etc.).
     * @method
     * @param {axiosStatic.RequestOptions} config The request detail.
     * @returns {Promise<axiosStatic.Response>} The result of the call.
     */
    public sendRequest<T>(config: axiosStatic.RequestOptions): Promise<axiosStatic.Response> {

        if (!config) {
            throw "config must not be null";
        }

        return this.batchingManager.addRequestToBatch(config);
    }
}