import * as javascriptExtensions from "cms-infrastructure-javascriptextensions";

import { IClientTransport } from "../IClientTransport";
import { IWindowPostMessageRequest } from "./IWindowPostMessageRequest";
import { IWindowPostMessageResponse } from "./IWindowPostMessageResponse";

/**
 * Provides ability to create and send messages using window.postMessage.
 * @class
 */
export class WindowPostMessageClientTransport implements IClientTransport {

    /**
     * The domain name of the iframe to send messages to.
     * @type {string}
     */
    private targetOrigin: string;

    /**
     * The list of promises for which requests have been sent and we need to resolve when they return.
     * @type {{ [requestId: string]: { resolve: any, reject: any } }}
     */
    private promises: { [requestId: string]: { resolve: any, reject: any } };

    /**
     * An id for this client instance.
     * @type {string}
     */
    private senderId: string;

    /**
     * The window object of the server iframe to post messages to.
     * @type {Window}
     */
    private remoteWindow: Window;

    /**
     * Initializes a new instance of the PostMessageClientTransport class.
     * @constructor
     * @param targetOrigin {string} The domain name of the iframe to send messages to.
     * @param localWindow {Window} The window object of the local iframe to listen for response messages at.
     * @param remoteWindow {Window} The window object of the server iframe to post messages to.
     */
    constructor(targetOrigin: string, localWindow: Window, remoteWindow: Window) {

        if (!targetOrigin) {
            throw "The targetOrigin parameter is required";
        }

        if (!localWindow) {
            throw "The localWindow parameter is required";
        }

        if (!remoteWindow) {
            throw "The remoteWindow parameter is required";
        }

        this.promises = {};
        this.targetOrigin = targetOrigin;
        this.remoteWindow = remoteWindow;
        this.senderId = javascriptExtensions.Guid.newGuid();
        localWindow.addEventListener("message", (event) => this.receiveMessage(event), false);
    }

    /**
     * Sends a request to the server to execute the given service call.
     * @param serviceName {string} The name of the service to call.
     * @param serviceMethod {string} The name of the service method to call.
     * @param parameters {any[]} The parameters to pass to the service method.
     * @returns {Promise<any>} The service response.
     */
    public sendRequest(serviceName: string, serviceMethod: string, parameters: any[]): Promise<any> {

        if (!serviceName) {
            throw "The serviceName parameter is required";
        }

        if (!serviceMethod) {
            throw "The serviceMethod parameter is required";
        }

        if (!parameters) {
            throw "The parameters parameter is required";
        }

        let requestId = javascriptExtensions.Guid.newGuid();

        return new Promise((resolve, reject) => {
            this.promises[requestId] = { resolve: resolve, reject: reject };
            var request: IWindowPostMessageRequest = {
                senderId: this.senderId,
                requestId: requestId,
                serviceName: serviceName,
                serviceMethod: serviceMethod,
                parameters: parameters
            };

            this.remoteWindow.postMessage(request, this.targetOrigin);
        });
    }

    /**
     * Processes messages received back from the server.
     * @param event {MessageEvent} The event arguments.
     * @returns {void}
     */
    private receiveMessage(event: MessageEvent): void {

        // Check that the request came from this class and is a valid response.
        if (event.origin === this.targetOrigin &&
            event.data &&
            event.data.senderId &&
            event.data.requestId &&
            (event.data.data || event.data.error)
            ) {

            // Check that we have a corresponding request in our list.
            let response = <IWindowPostMessageResponse>event.data;
            if (response.senderId === this.senderId && this.promises[response.requestId]) {

                // Resolve or reject our saved promise.
                if (response.error) {
                    this.promises[response.requestId].reject(response.error);
                } else {
                    this.promises[response.requestId].resolve(response.data);
                }

                // Remove the saved promise from our list as we don't need it anymore now that we have resolved/rejected.
                delete this.promises[response.requestId];
            }
        }
    }
}