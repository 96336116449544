/**
* webEvents.ts
* @author Ram Thiru (ramthi) and Hector Hernandez (hectorh)
* @copyright Microsoft 2018
*/

import Id from '../Id';
import {
    _returnDomObjectIfjQuery, _bracketIt, _findClosestByAttribute, _removeInvalidElements, _walkUpDomChainWithElementValidation
} from '../common/Utils';
import * as DataCollector from '../DataCollector';
import { MSCONTENT_PARTB_VERSION } from '../common/Constants';
import { IDiagnosticLogger, isValueAssigned, extend, cookieAvailable, isWindowObjectAvailable } from '@ms/1ds-core-js';
import { IWebAnalyticsConfiguration, IPageTags, ICoreData } from '../DataModel';
import { IOverrideValues } from '../interfaces/IOverrides';
import { ITelemetryEventInternal, ITelemetryEventProperties } from '../interfaces/ITelemetryEvents';
import { ApplicationInsights } from '../WebAnalyticsPlugin';
import { IContentHandler } from '../interfaces/IContentHandler';

export class WebEvent {

    protected _pageTags: IPageTags = {};
    protected _pageTypeMetaTag: string;
    protected _marketMetaTag: string;
    protected _behaviorMetaTag: string;

    /**
   * @param {ApplicationInsights} webAnalyticsPlugin - Web Analytics plugin instance
   * @param {WebAnalyticsConfiguration} config - WebAnalytics configuration object
   * @param {IContentHandler} contentHandler - Content handler
   * @param {Id} id - Id object
   * @param {any} pageTagsCallback - callback methods to get pageTags value
   * @param { {[name: string]: string }} metaTags - Meta tags
   * @param {IDiagnosticLogger} traceLogger - Trace logger to log to console.
   */
    constructor(protected _webAnalyticsPlugin: ApplicationInsights, protected _config: IWebAnalyticsConfiguration, protected _contentHandler: IContentHandler,
        protected _id: Id, protected _pageTagsCallback: any, protected _metaTags: { [name: string]: string },
        protected _traceLogger: IDiagnosticLogger) {

    }

    public _setBasicProperties(event: ITelemetryEventInternal, overrideValues: IOverrideValues) {
        // Fill common PartB fields
        event.ver = MSCONTENT_PARTB_VERSION;
        event.id = this._id.getLastPageViewId();
        if (!isValueAssigned(event.name)) {
            event.name = DataCollector._getPageName(this._config, overrideValues);
        }
        if (!isValueAssigned(event.uri) && isWindowObjectAvailable) {
            event.uri = DataCollector._getUri(this._config, window.location);
        }
    }

    /**
    * Sets common properties for events that are based on the WebEvent schema.
    * @param {IExtendedTelemetryItem} event - The event
    */
    public _setCommonProperties(event: ITelemetryEventInternal, eventProperties: ITelemetryEventProperties, overrideValues: IOverrideValues) {
        this._setBasicProperties(event, overrideValues);
        // Prepare the pageTags object that is mostly the same for all events.  Event specific pageTags will be added inside event constructors.
        if (this._config.coreData && this._config.coreData.pageTags) {
            this._pageTags = extend(true, this._pageTags, this._config.coreData.pageTags);
        }
        if (this._pageTagsCallback) {
            this._pageTags = extend(true, this._pageTags, this._pageTagsCallback());
        }
        // If metadata is present add it to pageTags property
        if (this._metaTags) {
            this._pageTags.metaTags = {};
            // Remove not supported meta data in pageTags.metaTags
            for (var metaTag in this._metaTags) {
                if (metaTag != 'behavior' && metaTag != 'market' && metaTag != 'pageType') {
                    this._pageTags.metaTags[metaTag] = this._metaTags[metaTag];
                }
            }
        }

        // extract specific meta tags out of the pageTags.metaTags collection.  These will go into assigned first class fields in the event.
        // the rest will go into pageTags.metaTags collection as is.
        this._pageTypeMetaTag = this._getMetaData(this._metaTags, this._config.coreData, 'pageType');
        this._marketMetaTag = this._getMetaData(this._metaTags, this._config.coreData, 'market');
        this._behaviorMetaTag = this._getMetaData(this._metaTags, this._config.coreData, 'behavior');

        if (isValueAssigned(this._pageTypeMetaTag)) {
            event.pageType = this._pageTypeMetaTag;
        }
        if (isValueAssigned(this._marketMetaTag)) {
            event.market = this._marketMetaTag;
        }

        // All metadata tags that must be saved as properties have been extracted at this point.  Assign pageTags as is.
        event.properties = {};
        event.properties['pageTags'] = isValueAssigned(overrideValues.pageTags) ?
            overrideValues.pageTags : this._pageTags;
        event.isLoggedIn = this._config.callback && typeof (this._config.callback.signedinStatus) === 'function' ?
            this._config.callback.signedinStatus() : this._config.isLoggedIn;

        // Fill common PartC fields
        eventProperties.cookieEnabled = cookieAvailable();
    }

    /**
    * Get the specified metadata value from the collection
    * If overrideValue is specified in the config that takes precedence.
    * @param { [name: string]: string } metaTags - Meta data.
    * @param {ICoreData} awaTags - Coredata values from configuration.
    * @param {string} metaTagName - Name of the metaTag to get.
    * @returns {string} - Meta data value
    */
    private _getMetaData(metaTags: { [name: string]: string }, coreData: ICoreData, metaTagName: string): string {
        if (coreData && coreData[metaTagName]) {
            return coreData[metaTagName];
        } else if (metaTags) {
            return metaTags[metaTagName];
        }
        return "";
    }

    protected _getBehavior(overrideValues: IOverrideValues) {
        var currentBehavior = 0; /*UNDEFINED*/
        if (isValueAssigned(overrideValues.behavior)) {
            currentBehavior = overrideValues.behavior;
        } else {
            if (isValueAssigned(this._behaviorMetaTag)) {
                let behaviorValue = parseInt(this._behaviorMetaTag);
                if (!isNaN(behaviorValue)) {
                    currentBehavior = behaviorValue;
                }
            }
        }
        return currentBehavior;
    }
}
