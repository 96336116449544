/**
 * Common error types for for operations that include service calls.
 * @type
 */
export type BaseErrorType =
    "ServiceClientResponseError" |
    "ArgumentException" |
    "ArgumentNullException" |
    "UnexpectedError"

/**
 * Predefine BaseErrorType constants to match the type
 */
// supress the tslint rule for variable name. since this is a string enum, we want the const to be the same name as the type so that it behaves as a string enum when importing once, rather than import the type and const individually.
// tslint:disable-next-line: ext-variable-name
export const BaseErrorType = {
    ServiceClientResponseError: "ServiceClientResponseError" as BaseErrorType,
    ArgumentException: "ArgumentException" as BaseErrorType,
    ArgumentNullException: "ArgumentNullException" as BaseErrorType,
    UnexpectedError: "UnexpectedError" as BaseErrorType
};
