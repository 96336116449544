/**
* AppInsightsCore.ts
* @author Abhilash Panwar (abpanwar) Hector Hernandez (hectorh)
* @copyright Microsoft 2018
*/
import { AppInsightsCore as InternalCore, IPlugin, LoggingSeverity } from '@microsoft/applicationinsights-core-js';
import { IExtendedConfiguration, IExtendedTelemetryItem, IExtendedAppInsightsCore } from './DataModels';
import { EventLatency, _ExtendedInternalMessageId } from './Enums';
import { isLatency, FullVersionString } from './Utils';

export default class AppInsightsCore extends InternalCore implements IExtendedAppInsightsCore {

    // Function to get w parameter used by OneCollector
    public getWParam: () => number;
    public pluginVersionStringArr: string[] = [];
    public pluginVersionString: string = "";

    /**
     * Initialize the sdk.
     * @param {IExtendedConfiguration} config - The configuration to initialize the SDK.
     * @param {IPlugin[]} extensions          - An array of extensions that are to be used by the core.
     */
    initialize(config: IExtendedConfiguration, extensions: IPlugin[]) {
        //Add default collector url
        if (config) {
            if (!config.endpointUrl) {
                config.endpointUrl = 'https://browser.events.data.microsoft.com/OneCollector/1.0/';
            }
            let propertyStorageOverride = config.propertyStorageOverride;
            //Validate property storage override
            if (propertyStorageOverride && (!propertyStorageOverride.getProperty || !propertyStorageOverride.setProperty)) {
                throw 'Invalid property storage override passed.';
            }
            if (config.channels) {
                for (let i = 0; i < config.channels.length; i++) {
                    if (config.channels[i]) {
                        for (let j = 0; j < config.channels[i].length; j++) {
                            let channel = config.channels[i][j];
                            if (channel.identifier && channel.version) {
                                let ver = channel.identifier + "=" + channel.version;
                                this.pluginVersionStringArr.push(ver);
                            }
                        }
                    }
                }
            }
        }
        this.getWParam = () => {
            return typeof document !== 'undefined' ? 0 : -1;
        };
        if (extensions) {
            for (let i = 0; i < extensions.length; i++) {
                let ext = extensions[i];
                if (ext.identifier && ext.version) {
                    let ver = ext.identifier + "=" + ext.version;
                    this.pluginVersionStringArr.push(ver);
                }
            }
        }
        this.pluginVersionString = this.pluginVersionStringArr.join(";");
        try {
            super.initialize(config, extensions);
        } catch (e) {
            this.logger.throwInternal(
                LoggingSeverity.CRITICAL,
                _ExtendedInternalMessageId.ErrorProvidedChannels, "Channels must be provided through config.channels only"
            )
        }
        this.pollInternalLogs("InternalLog");
    }

    track(telemetryItem: IExtendedTelemetryItem) {
        if (telemetryItem) {
            if (!isLatency(telemetryItem.latency)) {
                telemetryItem.latency = EventLatency.Normal;
            }
            telemetryItem.ext = telemetryItem.ext || {};
            telemetryItem.ext.sdk = telemetryItem.ext.sdk || {};
            telemetryItem.ext.sdk.ver = FullVersionString;
            telemetryItem.baseData = telemetryItem.baseData || {};
            if (!telemetryItem.baseData.properties) telemetryItem.baseData.properties = {};
            if (!telemetryItem.baseData.properties['version']) telemetryItem.baseData.properties['version'] = "";
            if (this.pluginVersionString != "") telemetryItem.baseData.properties['version'] = this.pluginVersionString;
        }
        super.track(telemetryItem);
    }
}
