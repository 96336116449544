/**
 * Enum for user activity Type
 * @readonly
 * @enum {number}
 */
export enum UserActivityType {
    None = 0,
    PageAction = 1,
    PageView = 2
}
