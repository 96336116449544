import { EventBasicData } from "../commonSchema/EventBasicData";
import { EventLatency } from "@ms/1ds-core-js";
import { IBrowserWindow } from "../../browser-object-models/IBrowserWindow";
import { IOneDsEventData } from "./IOneDsEventData";
import { IOneDsExtension } from "./IOneDsExtension";
import { IPerfCounterEventContent } from "../commonSchema/IPerfCounterEventContent";
import { IPerfCounterItem } from "../IPerfCounterItem";
import { PerfCounterEventContent } from "../commonSchema/PerfCounterEventContent";
import { OneDsNamespaceProfile } from "./OneDsNamespaceProfile";

/**
 * Represents the parameters of the 1DS performance counter event data.
 * @class
 */
export class OneDsPerfCounterEventData implements IOneDsEventData<IPerfCounterEventContent> {

    /**
     * Initializes a new instance of the `OneDsPerfCounterEventData` class.
     * @param name {string} The name(namespace) of the performance counter custom event.
     * @param time {string} The date and time of the event in ISO format.
     * @param data {[key: string]: IPerfCounterEventContent | string} The performance counter event data contains activity data and namepsace.
     * @param ext {IOneDsExtension} The extension fields.
     * @param latency {number} The latency corresponding to EventLatency.
     * @param sync {boolean} The flag to set if the event is to be sent synchronously.
     */
    constructor(
        public name: string,
        public time: string,
        public data: { [key: string]: IPerfCounterEventContent | string },
        public ext?: IOneDsExtension,
        public latency?: number,
        public sync?: boolean) {
    }

    /**
     * Creates a new PerfCounterEventData from a given log item.
     * @param logItem {ILogItem} The log item.
     * @param windowContext {IBrowserWindow} The window object of the browser.
     * @param application {string} The application name.
     * @param source {string} The source name.
     * @param impressionGuid {string} The impression guid.
     * @param tenant {string} The tenant name or id.
     * @param time {string} The date and time of the event in ISO format.
     * @param serverImpressionGuid {string} The server impression guid.
     * @param oneDsNamespaceProfile {OneDsNamespaceProfile} The namespace profile for the 1DS events.
     * @param latency {number} The latency corresponding to EventLatency.
     * @param sync {boolean} The flag to set if the event is to be sent synchronously.
     * @return {OneDsPerfCounterEventData} The performance counter event data.
     */
    public static createFrom(
        logItem: IPerfCounterItem,
        windowContext: IBrowserWindow,
        application: string,
        source: string,
        impressionGuid: string,
        tenant: string,
        time: string,
        serverImpressionGuid: string,
        oneDsNamespaceProfile: OneDsNamespaceProfile,
        latency?: number,
        sync?: boolean): OneDsPerfCounterEventData {

        var basicData = EventBasicData.createFrom(logItem, windowContext);
        var operationalEventContent = PerfCounterEventContent.createFrom(logItem, basicData, application, source, impressionGuid, tenant, serverImpressionGuid);

        const eventData: { [key: string]: IPerfCounterEventContent | string } = {
            baseData: operationalEventContent,
            baseType: oneDsNamespaceProfile.clientPerfCounterDataSchemaNamespace
        };

        const ext = {
            web: { isManual: true }
        };

        return new OneDsPerfCounterEventData(oneDsNamespaceProfile.clientPerfCounterSchemaNamespace, time, eventData, ext, latency || EventLatency.Normal, sync);
    }
}
